import { EntityExtensionValue } from '../../../view/containers/meta/entityExtension'
import { Attachment, AttachmentCandidate } from '../../value-object/Attachment'
import {
  DateTermV2,
  DateTermV2ForApiRequest,
} from '../../value-object/DateTermV2'
import { DateTimeV2 } from '../../value-object/DateTimeV2'
import {
  EntityExtensionValuesVO,
  entityExtensionValuesVoService,
} from '../../value-object/EntityExtensionValuesVO'
import { IItemDelta } from '../../value-object/ItemDeltaInputVO'
import {
  ReferencedEntity,
  ReferencedEntityWithBackgroundColor,
  ReferencedEntityWithIcon,
} from '../../value-object/ReferencedEntity'
import {
  WbsItemAdditionalPropertyValueDeltaInput,
  WbsItemAdditionalPropertyValueObject,
  WbsItemAdditionalPropertyValuesVO,
  wbsItemAdditionalPropertyValuesVoService,
} from '../../value-object/WbsItemAdditionalPropertyValuesVO'
import { WbsItemTypeVO } from '../../value-object/WbsItemTypeVO'

/**
 * TODO: implement.
 * DO NOT USE this entity before implementation.
 */
export type WbsItemEntity = {
  projectUuid: string
  uuid: string
  code: string
  type: WbsItemType
  status: WbsItemStatus
  substatus?: string
  displayName: string
  description?: string
  startIf?: string
  completeIf?: string
  tags?: ReferencedEntityWithBackgroundColor[]
  team?: ReferencedEntityWithIcon
  accountable?: ReferencedEntityWithIcon
  responsible?: ReferencedEntityWithIcon
  assignee?: ReferencedEntityWithIcon
  watchers?: ReferencedEntityWithIcon[]
  difficulty?: string
  // TODO: delete this.
  estimatedStoryPoint?: number
  estimatedHour?: number
  actualHour?: number
  priority?: string
  sprint?: ReferencedEntity
  scheduledDate?: DateTermV2
  actualDate?: DateTermV2
  deliverableAttachments?: Attachment[]
  createdBy: ReferencedEntityWithIcon
  createdAt: DateTimeV2
  updatedBy: ReferencedEntityWithIcon
  updatedAt: DateTimeV2
  revision: string
  additionalPropertyValues?: WbsItemAdditionalPropertyValuesVO
  entityExtensionValues?: EntityExtensionValuesVO // TODO Remove after all entity extensions are transfered.
}

export const fromApiResponseToEntity = (src: any): WbsItemEntity => {
  return {
    projectUuid: src.projectUuid,
    uuid: src.uuid,
    code: src.code,
    type: WbsItemType[src.type],
    status: WbsItemStatus[src.status],
    substatus: src.substatus,
    displayName: src.displayName,
    description: src.description,
    startIf: src.startIf,
    completeIf: src.completeIf,
    tags: src.tags?.map(tag => ({
      uuid: tag.uuid,
      name: tag.name,
      backgroundColor: tag.backgroundColor,
    })),
    team: src.team && {
      uuid: src.team.uuid,
      name: src.team.displayName,
      iconUrl: src.team.iconUrl,
    },
    accountable: src.accountable && {
      uuid: src.accountable.uuid,
      name: src.accountable.name,
      iconUrl: src.accountable.iconUrl,
    },
    responsible: src.responsible && {
      uuid: src.responsible.uuid,
      name: src.responsible.name,
      iconUrl: src.responsible.iconUrl,
    },
    assignee: src.assignee && {
      uuid: src.assignee.uuid,
      name: src.assignee.name,
      iconUrl: src.assignee.iconUrl,
    },
    watchers:
      src.watchers &&
      src.watchers.map(watcher => ({
        uuid: watcher.uuid,
        name: watcher.name,
        iconUrl: watcher.iconUrl,
      })),
    difficulty: src.difficulty,
    estimatedStoryPoint: src.estimatedStoryPoint,
    estimatedHour: src.estimatedHour,
    actualHour: src.cumulation?.actualHour || src.cumulation?.sumActualHour,
    priority: src.priority,
    sprint: src.sprint && {
      uuid: src.sprint.uuid,
      name: src.sprint.name,
    },
    scheduledDate: src.scheduledDate && {
      startDate:
        src.scheduledDate.startDate && new Date(src.scheduledDate.startDate),
      endDate: src.scheduledDate.endDate && new Date(src.scheduledDate.endDate),
    },
    actualDate: src.actualDate && {
      startDate: src.actualDate.startDate && new Date(src.actualDate.startDate),
      endDate: src.actualDate.endDate && new Date(src.actualDate.endDate),
    },
    deliverableAttachments: src.deliverableAttachments,
    createdBy: src.createdBy,
    createdAt: new Date(src.createdAt),
    updatedBy: src.updatedBy,
    updatedAt: new Date(src.updatedAt),
    revision: src.revision,
    entityExtensionValues: src.extensions
      ? entityExtensionValuesVoService.of(src.extensions)
      : undefined,
    additionalPropertyValues: src.additionalPropertyValues
      ? wbsItemAdditionalPropertyValuesVoService.of(
          src.uuid,
          src.additionalPropertyValues
        )
      : undefined,
  }
}

export type WbsItemBasic = {
  projectUuid: string
  uuid: string
  type: WbsItemType
  ticketType?: string
  typeVo: WbsItemTypeVO
}
export type ReferencedParentWbsItem = ReferencedEntity

export enum WbsItemType {
  WORKGROUP = 'WORKGROUP',
  PROCESS = 'PROCESS',
  DELIVERABLE_LIST = 'DELIVERABLE_LIST',
  DELIVERABLE = 'DELIVERABLE',
  TASK = 'TASK',
}

export enum WbsItemStatus {
  TODO = 'TODO',
  DOING = 'DOING',
  REVIEW = 'REVIEW',
  DONE = 'DONE',
  DISCARD = 'DISCARD',
}
export const toLabel = (src: WbsItemStatus) => {
  switch (src) {
    case WbsItemStatus.TODO:
      return 'ToDo'
    case WbsItemStatus.DOING:
      return 'Doing'
    case WbsItemStatus.REVIEW:
      return 'Review'
    case WbsItemStatus.DONE:
      return 'Done'
    case WbsItemStatus.DISCARD:
      return 'Discard'
  }
}

// TODO Move it
// Report
export type AggregateTarget = WbsItemType.DELIVERABLE | WbsItemType.TASK
export enum AggregateField {
  WBS_ITEM_COUNT = 'WBS_ITEM_COUNT',
  WBS_ITEM_WORKLOAD = 'WBS_ITEM_WORKLOAD',
}

export type UpdateWbsItemDeltaRequest = {
  input: UpdateWbsItemDeltaInput
  watchers: string[]
  tags: UpdateTagInput
}

export type CreateWbsItemInput = {
  uuid: string
  code: string
  type: WbsItemType
  typeUuid: string
  status: string
  substatus?: string
  displayName: string
  description?: string
  startIf?: string
  completeIf?: string
  rule?: string
  teamUuid?: string
  accountableUuid?: string
  responsibleUuid?: string
  assigneeUuid?: string
  watchers?: string[]
  closedByUuid?: string
  critical?: boolean
  deadline?: string
  difficulty?: string
  estimatedStoryPoint?: number
  estimatedHour?: number
  actualHour?: number
  estimatedAmount?: number
  actualAmount?: number
  priority?: string
  scheduledDate?: DateTermV2ForApiRequest
  actualDate?: DateTermV2ForApiRequest
  sprintUuid?: string
  extensions?: EntityExtensionValue[]
  additionalPropertyValues?: WbsItemAdditionalPropertyValueObject[]
}

export type UpdateWbsItemDeltaInput = {
  uuid: string
  type: WbsItemType
  status?: IItemDelta<string>
  substatus?: IItemDelta<string>
  displayName?: IItemDelta<string>
  description?: IItemDelta<string>
  teamUuid?: IItemDelta<string>
  accountableUuid?: IItemDelta<string>
  responsibleUuid?: IItemDelta<string>
  assigneeUuid?: IItemDelta<string>
  difficulty?: IItemDelta<string>
  estimatedStoryPoint?: IItemDelta<number>
  estimatedHour?: IItemDelta<number>
  estimatedAmount?: IItemDelta<number>
  actualAmount?: IItemDelta<number>
  priority?: IItemDelta<string>
  sprintUuid?: IItemDelta<string>
  scheduledDate?: IItemDelta<DateTermV2ForApiRequest>
  actualDate?: IItemDelta<DateTermV2ForApiRequest>
  additionalPropertyValues?: WbsItemAdditionalPropertyValueDeltaInput[]
}

export type UpdateWbsItemDeltaResult = {
  // TODO: implement
}

export type UpdateTagInput = {
  wbsItemUuid: string
  tagUuids: string[]
}

export type AddWbsItemAttachmentRequest = {
  wbsItemUuid: string
  attachmentType: 'DELIVERABLE'
  attachments: AttachmentCandidate[]
}
export type AddWbsItemAttachmentResult = {
  wbsItemUuid: string
}
export type DeleteWbsItemAttachmentRequest = {
  wbsItemUuid: string
  attachmentType: 'DELIVERABLE'
  attachmentItemUuids: string[]
}
export type DeleteWbsItemAttachmentResult = {
  wbsItemUuid: string
}
