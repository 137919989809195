import { MenuItemDef } from 'ag-grid-community'

export enum ContextMenuGroupId {
  ADD_ROW_GROUP = 'ADD_ROW_GROUP',
  MANAGE_ROW_GROUP = 'MANAGE_ROW_GROUP',
  EXPORT_ROW_GROUP = 'EXPORT_ROW_GROUP',
  CUSTOM = 'CUSTOM',
}

export interface ContextMenuGroup {
  id: ContextMenuGroupId | string
  items: MenuItemDef[]
}

export enum ContextMenuItemId {
  ADD_ROW = 'ADD_ROW',
  ADD_MULTIPLE_ROW = 'ADD_MULTIPLE_ROW',
  ADD_CHILD_ROW = 'ADD_CHILD_ROW',
  ADD_MULTIPLE_CHILD_ROW = 'ADD_MULTIPLE_CHILD_ROW',
  REMOVE_ROW = 'REMOVE_ROW',
  EXPAND_ALL = 'EXPAND_ALL',
  COLLAPSE_ALL = 'COLLAPSE_ALL',
  EXPAND_TO_LEVEL_MENU = 'EXPAND_TO_LEVEL_MENU',
  EXPORT_ALL = 'EXPORT_ALL',
  COPY_ROW = 'COPY_ROW',
  BULK_COPY_ROW = 'BULK_COPY_ROW',
  PASTE_ROW = 'PASTE_ROW',
  PASTE_ROW_AS_CHILD = 'PASTE_ROW_AS_CHILD',
  CUT_ROW = 'CUT_ROW',
  INSERT_CUT_ROW = 'INSERT_CUT_ROW',
  GET_PLAN_FROM_CHILDREN = 'GET_PLAN_FROM_CHILDREN',
  GET_PLAN_FROM_CHILDREN_BOTH_DATE = 'GET_PLAN_FROM_CHILDREN_BOTH_DATE',
  GET_PLAN_FROM_CHILDREN_EARLIEST_DATE = 'GET_PLAN_FROM_CHILDREN_EARLIEST_DATE',
  GET_PLAN_FROM_CHILDREN_LATEST_DATE = 'GET_PLAN_FROM_CHILDREN_LATEST_DATE',
  OPEN_PROGRESS_REPORT = 'OPEN_PROGRESS_REPORT',
  OPEN_SPRINT_REPORT = 'OPEN_SPRINT_REPORT',
  SEARCH_DELAYED_WBS = 'SEARCH_DELAYED_WBS',
  OPEN_TICKET_LIST = 'OPEN_TICKET_LIST',
  PASTE_ROW_AS_CHILD_NAME = 'PASTE_ROW_AS_CHILD_NAME',
  PASTE_ROW_AS_CHILD_SELECT = 'PASTE_ROW_AS_CHILD_SELECT',
  EDIT = 'EDIT',
  LOGIN_PROJECT = 'LOGIN',
  DISPLAY_GRAPH = 'DISPLAY_GRAPH',
  ADD_MEMBER = 'ADD_MEMBER',
  ADD_TEAM = 'ADD_TEAM',
  RESEND_REGISTRATION_EMAIL = 'RESEND_REGISTRATION_EMAIL',
  RESET_PASSWORD = 'RESET_PASSWORD',
  TEMPORARY_PASSWORD = 'TEMPORARY_PASSWORD',
  REMOVE_USER = 'REMOVE_USER',
  SWITCH_ROOT_WBS_ITEM = 'SWITCH_ROOT_WBS_ITEM',
  OPEN_KANBAN = 'OPEN_KANBAN',
  COPY_URL = 'COPY_URL',
  COPY_URL_AND_NAME = 'COPY_URL_AND_NAME',
}

export default class ContextMenu {
  constructor(private groups: ContextMenuGroup[]) {}

  flattenContextMenuGroups = (): (MenuItemDef | string)[] => {
    const menuItemDefs: (MenuItemDef | string)[] = []
    this.groups.forEach((group, index) => {
      group.items.forEach(item => {
        menuItemDefs.push(item)
      })
      if (index !== this.groups.length - 1 && group.items.length !== 0) {
        menuItemDefs.push('separator')
      }
    })
    return menuItemDefs
  }
}

export const getMenuIconHtml = (id: ContextMenuItemId) => {
  let icon = document.createElement('img')
  switch (id) {
    case ContextMenuItemId.REMOVE_ROW:
      icon.setAttribute(
        'src',
        `${process.env.FLAGXS_GLOBAL_IMAGE_BASE_URL}/cdn/global/icons/remove_row.svg`
      )
      break
    case ContextMenuItemId.COPY_ROW:
      icon.setAttribute(
        'src',
        `${process.env.FLAGXS_GLOBAL_IMAGE_BASE_URL}/cdn/global/icons/copy_row.svg`
      )
      break
    case ContextMenuItemId.CUT_ROW:
      icon.setAttribute(
        'src',
        `${process.env.FLAGXS_GLOBAL_IMAGE_BASE_URL}/cdn/global/icons/cut_row.svg`
      )
      break
    case ContextMenuItemId.EXPAND_ALL:
      icon.setAttribute(
        'src',
        `${process.env.FLAGXS_GLOBAL_IMAGE_BASE_URL}/cdn/global/icons/expand_row.svg`
      )
      break
    case ContextMenuItemId.COLLAPSE_ALL:
      icon.setAttribute(
        'src',
        `${process.env.FLAGXS_GLOBAL_IMAGE_BASE_URL}/cdn/global/icons/collapse_row.svg`
      )
      break
    case ContextMenuItemId.EXPAND_TO_LEVEL_MENU:
      icon.setAttribute(
        'src',
        `${process.env.FLAGXS_GLOBAL_IMAGE_BASE_URL}/cdn/global/icons/account_tree_icon.svg`
      )
      break
    case ContextMenuItemId.EXPORT_ALL:
      icon.setAttribute(
        'src',
        `${process.env.FLAGXS_GLOBAL_IMAGE_BASE_URL}/cdn/global/icons/export_row.svg`
      )
      break
    case ContextMenuItemId.PASTE_ROW_AS_CHILD:
    case ContextMenuItemId.INSERT_CUT_ROW:
      icon.setAttribute(
        'src',
        `${process.env.FLAGXS_GLOBAL_IMAGE_BASE_URL}/cdn/global/icons/paste_row.svg`
      )
      break
    case ContextMenuItemId.PASTE_ROW_AS_CHILD_NAME:
      icon.setAttribute(
        'src',
        `${process.env.FLAGXS_GLOBAL_IMAGE_BASE_URL}/cdn/global/icons/name_only.svg`
      )
      break
    case ContextMenuItemId.PASTE_ROW_AS_CHILD_SELECT:
      icon.setAttribute(
        'src',
        `${process.env.FLAGXS_GLOBAL_IMAGE_BASE_URL}/cdn/global/icons/select_data.svg`
      )
      break
    case ContextMenuItemId.ADD_ROW:
      icon.setAttribute(
        'src',
        `${process.env.FLAGXS_GLOBAL_IMAGE_BASE_URL}/cdn/global/icons/add_row.svg`
      )
      break
    case ContextMenuItemId.ADD_MULTIPLE_ROW:
      icon.setAttribute(
        'src',
        `${process.env.FLAGXS_GLOBAL_IMAGE_BASE_URL}/cdn/global/icons/add_rows.svg`
      )
      break
    case ContextMenuItemId.GET_PLAN_FROM_CHILDREN_BOTH_DATE:
      icon.setAttribute(
        'src',
        `${process.env.FLAGXS_GLOBAL_IMAGE_BASE_URL}/cdn/global/icons/both_date.svg`
      )
      break
    case ContextMenuItemId.GET_PLAN_FROM_CHILDREN_EARLIEST_DATE:
      icon.setAttribute(
        'src',
        `${process.env.FLAGXS_GLOBAL_IMAGE_BASE_URL}/cdn/global/icons/earliest_date.svg`
      )
      break
    case ContextMenuItemId.GET_PLAN_FROM_CHILDREN_LATEST_DATE:
      icon.setAttribute(
        'src',
        `${process.env.FLAGXS_GLOBAL_IMAGE_BASE_URL}/cdn/global/icons/latest_date.svg`
      )
      break
    case ContextMenuItemId.OPEN_PROGRESS_REPORT:
      icon.setAttribute(
        'src',
        `${process.env.FLAGXS_GLOBAL_IMAGE_BASE_URL}/cdn/global/icons/open_progress_report.svg`
      )
      break
    case ContextMenuItemId.OPEN_SPRINT_REPORT:
      icon.setAttribute(
        'src',
        `${process.env.FLAGXS_GLOBAL_IMAGE_BASE_URL}/cdn/global/icons/sprint_report.svg`
      )
      break
    case ContextMenuItemId.SEARCH_DELAYED_WBS:
      icon.setAttribute(
        'src',
        `${process.env.FLAGXS_GLOBAL_IMAGE_BASE_URL}/cdn/global/icons/show_delayed_wbs.svg`
      )
      break
    case ContextMenuItemId.OPEN_TICKET_LIST:
      icon.setAttribute(
        'src',
        `${process.env.FLAGXS_GLOBAL_IMAGE_BASE_URL}/cdn/global/icons/show_ticket_list.svg`
      )
      break
    case ContextMenuItemId.GET_PLAN_FROM_CHILDREN:
      icon.setAttribute(
        'src',
        `${process.env.FLAGXS_GLOBAL_IMAGE_BASE_URL}/cdn/global/icons/reflect_child_wbs.svg`
      )
      break
    case ContextMenuItemId.EDIT:
      icon.setAttribute(
        'src',
        `${process.env.FLAGXS_GLOBAL_IMAGE_BASE_URL}/cdn/global/icons/edit_information.svg`
      )
      break
    case ContextMenuItemId.LOGIN_PROJECT:
      icon.setAttribute(
        'src',
        `${process.env.FLAGXS_GLOBAL_IMAGE_BASE_URL}/cdn/global/icons/login_project.svg`
      )
      break
    case ContextMenuItemId.DISPLAY_GRAPH:
      icon.setAttribute(
        'src',
        `${process.env.FLAGXS_GLOBAL_IMAGE_BASE_URL}/cdn/global/icons/display_graph.svg`
      )
      break
    case ContextMenuItemId.ADD_MEMBER:
      icon.setAttribute(
        'src',
        `${process.env.FLAGXS_GLOBAL_IMAGE_BASE_URL}/cdn/global/icons/add_user.svg`
      )
      break
    case ContextMenuItemId.ADD_TEAM:
      icon.setAttribute(
        'src',
        `${process.env.FLAGXS_GLOBAL_IMAGE_BASE_URL}/cdn/global/icons/add_team.svg`
      )
      break
    case ContextMenuItemId.RESEND_REGISTRATION_EMAIL:
      icon.setAttribute(
        'src',
        `${process.env.FLAGXS_GLOBAL_IMAGE_BASE_URL}/cdn/global/icons/resend_registration_email.svg`
      )
      break
    case ContextMenuItemId.RESET_PASSWORD:
      icon.setAttribute(
        'src',
        `${process.env.FLAGXS_GLOBAL_IMAGE_BASE_URL}/cdn/global/icons/reset_password.svg`
      )
      break
    case ContextMenuItemId.TEMPORARY_PASSWORD:
      icon.setAttribute(
        'src',
        `${process.env.FLAGXS_GLOBAL_IMAGE_BASE_URL}/cdn/global/icons/temporary_password.svg`
      )
      break
    case ContextMenuItemId.REMOVE_USER:
      icon.setAttribute(
        'src',
        `${process.env.FLAGXS_GLOBAL_IMAGE_BASE_URL}/cdn/global/icons/remove_user.svg`
      )
      break
    case ContextMenuItemId.SWITCH_ROOT_WBS_ITEM:
      icon.setAttribute(
        'src',
        `${process.env.FLAGXS_GLOBAL_IMAGE_BASE_URL}/cdn/global/icons/open_in_new.svg`
      )
      break
    case ContextMenuItemId.OPEN_KANBAN:
      icon.setAttribute(
        'src',
        `${process.env.FLAGXS_GLOBAL_IMAGE_BASE_URL}/cdn/global/icons/kanban_material_icon.svg`
      )
      break
    case ContextMenuItemId.COPY_URL:
      icon.setAttribute(
        'src',
        `${process.env.FLAGXS_GLOBAL_IMAGE_BASE_URL}/cdn/global/icons/link_material_icon.svg`
      )
      break
    case ContextMenuItemId.COPY_URL_AND_NAME:
      icon.setAttribute(
        'src',
        `${process.env.FLAGXS_GLOBAL_IMAGE_BASE_URL}/cdn/global/icons/link_material_icon.svg`
      )
      break
  }
  return icon
}
