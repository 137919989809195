import {
  Alert,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Step,
  StepLabel,
  Stepper,
  styled,
} from '@mui/material'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { generateSecretToken } from '../../../../../utils/secretToken'
import Auth from '../../../../../lib/commons/auth'
import ContentCopyIcon from '@mui/icons-material/ContentCopyRounded'
import Loading from '../../../../components/process-state-notifications/Loading'
import { useDevelopmentExternalServiceStorageService } from '../../../../../services/storage-services/external-services/developmentExternalServiceStorageService'
import AlertDialog from '../../../../components/dialogs/AlertDialog'
import { intl } from '../../../../../i18n'
import CancelButton from '../../../../components/buttons/CancelButton'
import SubmitButton from '../../../../components/buttons/SubmitButton'
import { CheckBox } from '@mui/icons-material'

type SetupGithubProps = {
  projectUuid: string
  open: boolean
  close: () => void
}

const StepArea = styled('div')({
  padding: '18px 6px',
})

const StepTitle = styled('div')({
  fontSize: '14px',
  fontWeight: 600,
  color: '#555555',
  textAlign: 'center',
  padding: '12px 0',
})

const FormArea = styled('div')({
  padding: '4px 24px',
})

const FormRow = styled('div')({
  display: 'flex',
  alignItems: 'top',
  margin: '8px 0',
})

const FormTitle = styled('div')({
  fontSize: '12px',
  fontWeight: 600,
  margin: '0 4px',
  width: '120px',
})

const FormContent = styled('div')({
  fontSize: '12px',
})

const FormContentList = styled('ul')({
  margin: 0,
  paddingLeft: 20,
})
const FormContentListItem = styled('li')({})

const FormCopyIconArea = styled('div')({})

const FormCopyIconButton = styled(IconButton)({
  padding: '2px',
})

const getFlagxsApiDomainName = () => {
  if (process.env.FLAGXS_ENV === 'production') {
    return 'flagxs-api.com'
  }
  return 'sevend-api.net'
}

export const SetupGithub = ({ projectUuid, open, close }: SetupGithubProps) => {
  const [step, setStep] = useState<number>(0)
  const incrementStep = useCallback(() => {
    setStep(prev => prev + 1)
  }, [])
  const secret = useMemo(() => {
    return generateSecretToken(20)
  }, [])
  useEffect(() => {
    // Every time opening the dialog, reset the step.
    if (open) setStep(0)
  }, [open])

  // Show alert when secret is already registered.
  const { isGithubConnected } = useDevelopmentExternalServiceStorageService()
  const [isConnected, setIsConnected] = useState<boolean>(false)
  useEffect(() => {
    const fn = async () => {
      const isAlreadyConnected = await isGithubConnected(projectUuid)
      setIsConnected(isAlreadyConnected)
    }
    if (open) fn()
  }, [open])

  // Show alert dialog when step forward to registering secret.
  const [alertDialogOpen, setAlertDialogOpen] = useState<boolean>(false)
  const openAlertDialog = useCallback(() => {
    setAlertDialogOpen(true)
  }, [])
  const continueStep = useCallback(() => {
    setAlertDialogOpen(false)
    incrementStep()
  }, [])
  const obeyAlert = useCallback(() => {
    setAlertDialogOpen(false)
  }, [])
  return (
    <>
      <Dialog
        open={open}
        maxWidth="lg"
        fullWidth={true}
        PaperProps={{ sx: { height: '450px' } }}
      >
        <DialogTitle>
          {intl.formatMessage({
            id: 'externalService.github.configureGitHubConnect',
          })}
        </DialogTitle>
        <DialogContent>
          {isConnected && step === 0 && (
            <Alert severity="warning">
              {intl.formatMessage({
                id: 'externalService.github.isAlreadyConnected',
              })}
            </Alert>
          )}
          <Stepper activeStep={step} alternativeLabel={true}>
            <Step key={1} sx={{ flex: 1 }}>
              <StepLabel>
                {intl.formatMessage({
                  id: 'externalService.github.configureGitHub',
                })}
              </StepLabel>
            </Step>
            <Step key={2} sx={{ flex: 1 }}>
              <StepLabel>
                {intl.formatMessage({
                  id: 'externalService.github.registerTokenToFlagxs',
                })}
              </StepLabel>
            </Step>
            <Step key={3} sx={{ flex: 1 }}>
              <StepLabel>{intl.formatMessage({ id: 'completed' })}</StepLabel>
            </Step>
          </Stepper>
          {step === 0 && (
            <SetupGithubWebhook projectUuid={projectUuid} secret={secret} />
          )}
          {step === 1 && (
            <SetupFlagxsSecret
              projectUuid={projectUuid}
              secret={secret}
              onComplete={incrementStep}
            />
          )}
          {step === 2 && <SetupComplete />}
        </DialogContent>
        <DialogActions
          sx={{ display: 'flex', justifyContent: 'center', padding: '12px' }}
        >
          {step === 0 && (
            <>
              <SubmitButton
                title={intl.formatMessage({ id: 'continue' })}
                onClick={openAlertDialog}
                hideIcon={true}
                hideTooltip={true}
              />
              <CancelButton
                onClick={close}
                title={intl.formatMessage({ id: 'close' })}
              />
            </>
          )}
          {step === 2 && (
            <CancelButton
              onClick={close}
              title={intl.formatMessage({ id: 'close' })}
            />
          )}
        </DialogActions>
      </Dialog>
      <AlertDialog
        isOpen={alertDialogOpen}
        message={intl.formatMessage({ id: 'externalService.github.confirm' })}
        submitButtonTitle={intl.formatMessage({ id: 'continue' })}
        submitHandler={continueStep}
        closeButtonTitle={intl.formatMessage({ id: 'back' })}
        closeHandler={obeyAlert}
      ></AlertDialog>
    </>
  )
}

type SetupGithubWebhookProps = {
  projectUuid: string
  secret: string
}

const SetupGithubWebhook = ({
  projectUuid,
  secret,
}: SetupGithubWebhookProps) => {
  const payloadUrl = useMemo(() => {
    const tenant = Auth.getCurrentTenant()
    if (!tenant) return ''
    const domainName = getFlagxsApiDomainName()
    return `https://${domainName}/v1/development?tenantUuid=${tenant.tenantUuid}&projectUuid=${projectUuid}`
  }, [])
  const copyPayloadUrl = useCallback(() => {
    navigator.clipboard.writeText(payloadUrl)
  }, [payloadUrl])
  const copySecret = useCallback(() => {
    navigator.clipboard.writeText(secret)
  }, [secret])
  return (
    <StepArea>
      <StepTitle>
        {intl.formatMessage({
          id: 'externalService.github.webhookInstruction',
        })}
      </StepTitle>
      <FormArea>
        <FormRow>
          <FormTitle>Payload URL</FormTitle>
          <FormContent>{payloadUrl}</FormContent>
          <FormCopyIconArea>
            <FormCopyIconButton size="small" onClick={copyPayloadUrl}>
              <ContentCopyIcon sx={{ height: '16px', width: '16px' }} />
            </FormCopyIconButton>
          </FormCopyIconArea>
        </FormRow>
        <FormRow>
          <FormTitle>Content Type</FormTitle>
          <FormContent>application/json</FormContent>
        </FormRow>
        <FormRow>
          <FormTitle>Secret</FormTitle>
          <FormContent>{secret}</FormContent>
          <FormCopyIconArea>
            <FormCopyIconButton size="small" onClick={copySecret}>
              <ContentCopyIcon sx={{ height: '16px', width: '16px' }} />
            </FormCopyIconButton>
          </FormCopyIconArea>
        </FormRow>
        <FormRow>
          <FormTitle>Trigger Events</FormTitle>
          <FormContent>
            Let me select individual events.
            <br />
            {intl.formatMessage({
              id: 'externalService.github.checkFollowingEvents',
            })}
            <FormContentList>
              <FormContentListItem>Branch or tag creation</FormContentListItem>
              <FormContentListItem>Pushes</FormContentListItem>
              <FormContentListItem>Pull requests</FormContentListItem>
            </FormContentList>
          </FormContent>
        </FormRow>
      </FormArea>
    </StepArea>
  )
}

type SetupFlagxsSecretProps = {
  projectUuid: string
  secret: string
  onComplete: () => void
}

const SetupFlagxsSecret = ({
  projectUuid,
  secret,
  onComplete,
}: SetupFlagxsSecretProps) => {
  const [completed, setCompleted] = useState<boolean>(false)
  const { setupGitHubConnect } = useDevelopmentExternalServiceStorageService()
  useEffect(() => {
    const fn = async () => {
      setupGitHubConnect(projectUuid, secret).then(_ => {
        // Since this step completes too fast, wait for a while to show user the step is actually running.
        setTimeout(() => {
          setCompleted(true)
        }, 600)
      })
    }
    fn()
  }, [])
  useEffect(() => {
    if (completed) {
      onComplete()
    }
  }, [completed])
  return (
    <StepArea>
      <StepTitle>
        {intl.formatMessage({
          id: 'externalService.github.proceedingRegistration',
        })}
      </StepTitle>
      <Loading isLoading={!completed} />
    </StepArea>
  )
}

const SetupComplete = () => {
  return (
    <StepArea>
      <StepTitle>
        {intl.formatMessage({
          id: 'externalService.github.registrationCompleted',
        })}
      </StepTitle>
    </StepArea>
  )
}
