import { PropsWithChildren, useMemo } from 'react'
import { CellDef, FormCellWidth } from '../model'
import objects from '../../../../../utils/objects'
import { styled } from '@mui/material'
import { colorPalette } from '../../../../style/colorPallete'
import { SingleSheetPropertyValidationErrors } from '../../hooks/formValidation'
import Tooltip from '../../../../components/tooltips/Tooltip'
import { ErrorTooltip } from '../../tooltip/ErrorTooltip'

export type CellPropsBase = FormCellProps & {
  label: string
  editable?: boolean
  required?: boolean
  validationErrors?: SingleSheetPropertyValidationErrors
}
type FormCellProps = {
  cellWidth: FormCellWidth
}
export const FormCell = styled('div')(({ cellWidth }: FormCellProps) => {
  const width = useMemo(() => {
    switch (cellWidth) {
      case 'full':
        return '100%'
      case 'half':
        return '50%'
    }
  }, [cellWidth])
  return {
    width,
    height: '34px',
    display: 'flex',
  }
})

type CellTitleProps = {
  title: string
  required?: boolean
}
export const CellTitle = ({ title, required = false }: CellTitleProps) => {
  return (
    <CellTitleArea>
      <CellTitleSpan>{title}</CellTitleSpan>
      {required && <RequiredIndicatorSpan>*</RequiredIndicatorSpan>}
    </CellTitleArea>
  )
}
const CellTitleArea = styled('div')({
  backgroundColor: colorPalette.monotone[1],
  height: '100%',
  width: '120px',
  border: `1px solid ${colorPalette.monotone[2]}`,
  display: 'flex',
  alignItems: 'center',
  padding: '4px',
  gap: '2px',
})
const CellTitleSpan = styled('span')({
  color: colorPalette.monotone[10],
  fontSize: '12px',
  lineHeight: 1,
})
const RequiredIndicatorSpan = styled('span')({
  color: colorPalette.pink[5],
})
const CellValueAreaDiv = styled('div')(
  ({ errorMessage }: { errorMessage?: string }) => ({
    border: `1px solid ${colorPalette.monotone[2]}`,
    height: '100%',
    width: '100%',
    flex: '1 1 0%',
    display: 'flex',
    padding: '0 4px',
    alignItems: 'center',
    // overflowY: 'scroll',
    backgroundColor: errorMessage ? colorPalette.pink[2] : undefined,
    position: 'relative',
  })
)

export const CellValueArea = ({
  children,
  onClick,
  validationErros,
  edited,
}: PropsWithChildren<{
  onClick?: () => void
  validationErros?: SingleSheetPropertyValidationErrors
  edited?: boolean
}>) => {
  const errorMessage = useMemo(() => {
    if (!validationErros || validationErros.length === 0) {
      return undefined
    }
    return validationErros.join('\n')
  }, [validationErros])
  return (
    <ErrorTooltip errorMessage={errorMessage}>
      <CellValueAreaDiv onClick={onClick} errorMessage={errorMessage}>
        {children}
        {edited && <EditedMark />}
      </CellValueAreaDiv>
    </ErrorTooltip>
  )
}
const EditedMark = styled('div')({
  position: 'absolute',
  top: 0,
  right: 0,
  width: 0,
  height: 0,
  borderStyle: 'solid',
  borderWidth: '0 10px 10px 0',
  borderColor: `transparent ${colorPalette.yellow[2]} transparent transparent`,
})

export const TableRow = styled('div')({
  display: 'flex',
})

// BELOW ARE TEMPORARY CODE

type CellFacadeProps<D> = {
  cellDef: CellDef
  data: D
}
export const CellFacade = <D extends any>({
  cellDef,
  data,
}: CellFacadeProps<D>) => {
  const value = useMemo(
    // TODO: Consider not to rerender when an unrelevant field is changed.
    () => objects.getValue(data, cellDef.field),
    [cellDef.field, data]
  )
  switch (cellDef.type) {
    case 'TEXT':
      return <></>
  }
  return <></>
}
