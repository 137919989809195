/* eslint-disable no-throw-literal */
import moment from 'moment'
import { Client as StompClient, IMessage } from '@stomp/stompjs'
import lazyStoreLoader from '../../store/lazyStoreLoader'
import { addGlobalMessage, MessageLevel } from '../../store/messages'
import Auth from './auth'
import { isLocalhost, parseUrl, Url } from '../../utils/urls'
import { intl } from '../../i18n'
import VOBase from '../../vo/base'

const DEFAULT_TIMEOUT = 17 * 1000
const DEFAULT_RETRY_INTERVAL = 3 * 1000
export const successDummyResponse = {
  hasError: false,
  hasWarning: false,
  json: {},
  status: 0,
}

const FUNCTIONAL_API_URL = parseUrl(
  process.env.FLAGXS_FUNCTIONAL_API_SERVER_URL!
)
const PRESENTATIONAL_API_URL = parseUrl(
  process.env.FLAGXS_PRESENTATIONAL_API_SERVER_URL!
)

const makeRequestUrl = (apiUrl: Url, path: string) => {
  if (apiUrl.isLocalHost || isLocalhost(window.location.hostname)) {
    // For development
    return `${apiUrl.url}${path}`
  }
  // Use tenant domain as API endpoint
  return `${apiUrl.protocol}//${window.location.host}${apiUrl.path}${path}`
}

export interface APIResponse {
  status: number
  json: any
  hasWarning?: boolean
  hasError?: boolean
}

export interface ElasticsearchResponse {
  total: number
  hit: number
  data: any[]
}

export interface ApiMessage {
  propertyName: string
  uuid: string
  id: string
  readableMessage: any
  args: any[]
}

export class SwitchableRequest {
  private abortController?: AbortController
  switch = async (request: (signal: AbortSignal) => Promise<APIResponse>) => {
    try {
      if (this.abortController && !this.abortController.signal.aborted) {
        this.abortController.abort()
      }
      this.abortController = new AbortController()
      return await request(this.abortController.signal)
    } catch (e: any) {
      if (e.name === 'AbortError') {
        // Expected error.
        throw e
      }
      return Promise.reject({
        error: e,
      })
    } finally {
      this.abortController = undefined
    }
  }
}

const MAX_RETRY_COUNT = 3

export const isAbortError = (err: any): boolean => {
  return (
    err.toString().includes('The user aborted a request') ||
    err.toString().includes('The operation was aborted') ||
    err.toString().includes('signal is aborted without reason')
  )
}

const fetchInternal = async (
  input: RequestInfo,
  init?: RequestInit,
  retryCount = 0,
  ignoreError = false,
  successCallback?: () => void,
  errorCallback?: (err: any, input: RequestInfo) => void
): Promise<Response> => {
  try {
    const response = await fetch(input, init)
    if (ignoreError && !response.ok && response.status !== 401) {
      throw new Error('Ignore optional api error')
    }
    successCallback && successCallback()
    return response
  } catch (err: any) {
    if (isAbortError(err)) {
      throw err
    }
    console.warn(err)
    // Unknown error cause of the client side e.g. No internet connection.
    if (retryCount < MAX_RETRY_COUNT) {
      return new Promise<Response>(resolve => {
        setTimeout(() => {
          resolve(
            fetchInternal(
              input,
              init,
              retryCount + 1,
              ignoreError,
              successCallback,
              errorCallback
            )
          )
        }, retryCount * 500)
      })
    }
    errorCallback && errorCallback(err, input)
    return Promise.resolve(new Response())
  }
}

const callApi = async (
  input: RequestInfo,
  init?: RequestInit,
  ignoreError: boolean = false,
  disableRetry: boolean = false,
  successCallback?: () => void,
  errorCallback?: (err: any, input: RequestInfo) => void
): Promise<APIResponse> => {
  const response = await fetchInternal(
    input,
    init,
    disableRetry ? MAX_RETRY_COUNT : 0,
    ignoreError,
    successCallback,
    errorCallback
  )
  let json
  try {
    json = await response.json()
  } catch (paseError) {
    if (isAbortError(paseError)) {
      throw paseError
    }
    // ignore
    json = {}
  }

  if (response.ok) {
    await showDebugMessage(json)
    const errors = extractValuesFromResponse(json, 'errors')
    const messages = extractValuesFromResponse(json, 'messages')
    return {
      status: response.status,
      json,
      hasError: errors && errors.length > 0,
      hasWarning: messages && messages.length > 0,
    }
  }
  throw {
    ...json,
    status: response.status,
  }
}

const showDebugMessage = async json => {
  if (process.env.FLAGXS_SHOW_DEBUG_LOG) {
    const errors = extractValuesFromResponse(json, 'errors')
    if (errors && errors.length > 0) {
      const store = await lazyStoreLoader()
      for (let v of errors) {
        store.dispatch(
          addGlobalMessage({
            type: MessageLevel.DEBUG,
            title: intl.formatMessage({
              id: 'debug.businessProcessErrorOccurred',
            }),
            text: javaExceptionToString(v),
          })
        )
      }
    }
  }
}

const messageToString = (messages: any[]) => {
  return messages.map(v => `- ${v.readableMessage.message}`).join('\n')
}

const javaExceptionToString = (e: any) => {
  let message = e.message || ''
  return message
}

export const extractValuesFromResponse = (
  json: any,
  extractKey: string,
  depth = 0,
  values: any[] = []
) => {
  if (!json || depth > 2) {
    return values
  }
  if (Array.isArray(json[extractKey]) && json[extractKey].length > 0) {
    values =
      values.length > 0 ? [...values, ...json[extractKey]] : json[extractKey]
  }
  for (let k of Object.keys(json)) {
    let child = json[k]
    if (Array.isArray(child)) {
      for (let j of child) {
        values = extractValuesFromResponse(j, extractKey, depth + 1, values)
      }
    } else {
      values = extractValuesFromResponse(json[k], extractKey, depth + 1, values)
    }
  }
  return values
}

const filterBody = (body: any) => {
  let filteredBody: { [key: string]: string | number | boolean | object } = {}
  if (!body) {
    return filteredBody
  }
  for (const [key, value] of Object.entries(body)) {
    if (value === null || typeof value === 'undefined') {
      continue
    }
    if (moment.isDate(value)) {
      filteredBody[key] = value
    } else if (!Array.isArray(value) && typeof value === 'object') {
      filteredBody[key] = filterBody(value)
    } else {
      filteredBody[key] = value as string | number | boolean
    }
  }
  return filteredBody
}

const isEnumObject = (value: object): boolean =>
  !!value &&
  !!value['value'] &&
  !!(value['name'] || value['displayName'] || value['code'])
const isEntityObject = (value: object): boolean =>
  !!value &&
  !!value['uuid'] &&
  !!(value['name'] || value['displayName'] || value['code'])
export const toUrlQuery = (
  body: object,
  keyPrefix: string = '',
  removeNegativeFlag: boolean = false // Set true when all default values of boolean parameters are false
) => {
  return Object.keys(body)
    .map(key => {
      let value = body[key]
      const queryKey = `${keyPrefix}${key}`
      if (
        value === undefined ||
        value === null ||
        (typeof value === 'string' && !value) ||
        (removeNegativeFlag && typeof value === 'boolean' && !value)
      ) {
        return
      }
      if (value instanceof VOBase) {
        value = value.serialize()
      }
      if (moment.isDate(value)) {
        return `${queryKey}=${value.getTime()}`
      } else if (!Array.isArray(value) && typeof value === 'object') {
        if (isEnumObject(value)) {
          return `${queryKey}=${value.value}`
        }
        if (isEntityObject(value)) {
          return (
            `${queryKey}.uuid=${value.uuid}` +
            `&${queryKey}.code=${value.code}` +
            (value.name ? `&${queryKey}.name=${value.name}` : '') +
            (value.displayName
              ? `&${queryKey}.displayName=${value.displayName}`
              : '')
          )
        }
        return toUrlQuery(value, `${queryKey}.`, removeNegativeFlag)
      } else if (Array.isArray(value)) {
        if (value.length === 0) return
        if (value.every(v => isEnumObject(v))) {
          return `${queryKey}=${value.map(v => v.value).join(',')}`
        }
        if (typeof value[0] === 'object') {
          return value
            .map((v, i) => {
              return toUrlQuery(
                v,
                encodeURIComponent(`${queryKey}[${i}].`),
                removeNegativeFlag
              )
            })
            .join('&')
        }
        return `${queryKey}=${value.map(v => encodeURIComponent(v)).join(',')}`
      } else {
        return `${queryKey}=${encodeURIComponent(value)}`
      }
    })
    .filter(v => !!v)
    .join('&')
}

const makeUrlAndParams = (
  method: string,
  body: any,
  url: string,
  params: any
) => {
  if (body instanceof FormData) {
    params = { ...params, body: body }
  } else {
    const filteredBody = filterBody(body)
    switch (method.toLowerCase()) {
      case 'get':
      case 'delete':
      case 'head':
        const queries = toUrlQuery(filteredBody)
        if (queries && queries.length > 0) {
          url += '?' + queries
        }
        break
      default:
        params = { ...params, body: JSON.stringify(filteredBody) }
    }
  }
  return [url, params]
}

class API {
  stopped = false

  constructor(
    private apiUrl: Url,
    // If the api does not affect the core functionality, set it to true
    private optional: boolean = false,
    private warningTitle: string = intl.formatMessage({
      id: 'global.warning.dataDeletedOrURLIncorrect',
    }),
    private warningText: string = ''
  ) {}

  async request(
    method: string,
    path: string,
    body: any = undefined,
    authorization: boolean = true,
    signal: AbortSignal | null = null,
    customHeaders: { [key: string]: string | number } = {},
    errorCallback?: (err: any, input: RequestInfo) => void
  ): Promise<APIResponse> {
    let url = makeRequestUrl(this.apiUrl, path)
    let headers: { [key: string]: string | number } = {
      Accept: 'application/json',
    }
    if (!(body instanceof FormData) && body) {
      headers['Content-Type'] = 'application/json'
    }
    if (authorization) {
      headers = await this.addAuthorizationHeader(headers)
    }
    let params: any = {
      method: method,
      mode: 'cors',
      credentials:
        // Allow sending credentials only for development to access different API domain from the client.
        process.env.FLAGXS_ENV === 'development' ? 'include' : undefined,
      headers: { ...headers, ...customHeaders },
    }
    if (signal) {
      params['signal'] = signal
    }

    const [requestUrl, requestParams] = makeUrlAndParams(
      method,
      body,
      url,
      params
    )

    return callApi(
      requestUrl,
      requestParams,
      this.optional,
      this.stopped,
      this.defaultSuccessCallback,
      errorCallback ? errorCallback : this.defaultErrorCallback
    )
  }

  defaultSuccessCallback = async () => {
    this.stopped = false
    // TODO Show message
  }

  defaultErrorCallback = async (
    err: any,
    input: RequestInfo
  ): Promise<void> => {
    if (!this.stopped) {
      const store = await lazyStoreLoader()
      store.dispatch(
        addGlobalMessage({
          type: MessageLevel.WARN,
          title: this.warningTitle,
          text: this.warningText,
        })
      )
    }
    this.stopped = true
    if (!this.optional) {
      throw new Error(`${err.message}: ${input}`)
    }
  }

  private async addAuthorizationHeader(
    headers: { [key: string]: string | number } = {}
  ): Promise<{ [key: string]: string | number }> {
    const tenant = Auth.getCurrentTenant()
    if (tenant) {
      return {
        ...headers,
        Authorization: `Bearer ${await tenant.getAccessToken()}`,
        'x-7d-userpoolid': tenant.userPoolId,
        'x-7d-timezone': new Date().getTimezoneOffset(),
      }
    }
    return headers
  }

  async requestUntil(
    method: string,
    path: string,
    body: { [key: string]: string | number | boolean } | any = {},
    errorCallback?: (err: any, input: RequestInfo) => void,
    authorization: boolean = true,
    jsonTestHandler: (json: any) => boolean = () => true,
    timeout: number = DEFAULT_TIMEOUT,
    retryInterval: number = DEFAULT_RETRY_INTERVAL
  ): Promise<any> {
    const startTime = new Date().getTime()
    return new Promise<any>((resolve, reject) => {
      const fetchFunc = async () => {
        const response = await this.request(
          method,
          path,
          body,
          authorization,
          null,
          {},
          errorCallback ? errorCallback : this.defaultErrorCallback
        )
        if (jsonTestHandler(response.json)) {
          resolve(response)
          return
        }
        if (startTime + timeout > new Date().getTime()) {
          setTimeout(fetchFunc, retryInterval)
        } else {
          reject('Timeout')
        }
      }
      return fetchFunc()
    })
  }
}

class WebSocketAPI {
  apiUrl: Url
  private client: StompClient
  private wating = false
  private subscribed: {
    [key: string]: { wating: boolean; callback: Function }
  } = {}

  constructor(apiUrl: Url, brokerPath: string) {
    this.apiUrl = parseUrl(
      `${apiUrl.isLocalHost ? 'ws' : 'wss'}://${apiUrl.host}${apiUrl.path}`
    )
    const brokerURL = `${this.apiUrl.url}${brokerPath}`

    this.client = new StompClient({
      brokerURL,
      reconnectDelay: 3000,
      // Uncomment for debug
      // debug: process.env.FLAGXS_SHOW_DEBUG_LOG
      //   ? str => console.log(brokerURL, str)
      //   : () => false,
    })

    this.client.onConnect = frame => {
      // Reset wating status
      this.wating = false
      Object.keys(this.subscribed).forEach(topic => {
        const listener = this.subscribed[topic]
        listener.wating = true
      })
      this.subscribeInternal()
    }
    this.client.onStompError = frame => {
      console.error(frame.body)
    }
  }

  private subscribeInternal = async () => {
    if (this.wating) {
      return
    }
    if (!this.client.connected) {
      this.wating = true
      this.client.activate()
      return
    }
    Object.keys(this.subscribed).forEach(topic => {
      const listener = this.subscribed[topic]
      if (!listener.wating) {
        return
      }
      listener.wating = false
      this.client.subscribe(topic, messaage => {
        let body = messaage.body
        try {
          body = JSON.parse(body)
        } catch {
          // ignore
        }
        listener.callback && listener.callback(body, messaage)
      })
    })
  }

  subscribe = async <T>(
    topic: string,
    callback: (body: T, message: IMessage) => void
  ) => {
    if (this.subscribed[topic] && !this.subscribed[topic].wating) {
      return
    }
    this.subscribed[topic] = {
      wating: true,
      callback,
    }
    this.subscribeInternal()
  }
}

export default {
  functional: new API(FUNCTIONAL_API_URL),
  presentational: new API(
    PRESENTATIONAL_API_URL,
    true,
    intl.formatMessage({
      id: 'global.warning.presentational.temporary.unavailable',
    }),
    intl.formatMessage({
      id: 'global.warning.presentational.temporary.unavailable.detail',
    })
  ),
  notification: new WebSocketAPI(
    PRESENTATIONAL_API_URL,
    '/api/v1/ui_notification'
  ),
}
