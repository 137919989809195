import { useCallback } from 'react'
import API from '../../lib/commons/api'
import { UserRepository } from '../../applications/ports/userRepository'

export const useUserRepository = (): UserRepository => {
  const fetchAsReferencedEntity = useCallback(async (uuid: string) => {
    const response = await API.functional.request(
      'GET',
      '/api/v1/users/referenced_values',
      { uuid },
      true
    )
    return response.json
  }, [])

  return { fetchAsReferencedEntity }
}
