import { useState, useEffect, useCallback } from 'react'
import { WbsItemType } from '../../../../../domain/entity/WbsItemEntity'
import {
  WbsItemFormModel,
  createInitialValue,
  toCreateInput,
  toDeltaInput,
} from '../../model'
import { useDispatch } from 'react-redux'
import { addScreenMessage, MessageLevel } from '../../../../../store/messages'
import { intl } from '../../../../../i18n'
import { ReferencedTicketListEntity } from '../../../../../domain/entity/TicketListEntity'
import {
  isReferencedEntityValueDiffered,
  toReferencedEntityDeltaValue,
} from '../../model/properties'
import {
  createTicketInitialValue,
  TicketFormModel,
  TicketInitialValue,
} from '../../model/ticket'
import {
  CreateTicketInput,
  CreateTicketRequest,
  TicketEntity,
} from '../../../../../domain/entity/ticket'
import { useTicketRepository } from '../../../../../services/adaptors/ticketRepositoryAdaptor'
import { usePageDataIsEdited } from '../../../../hooks/usePageDataIsEdited'
import { TicketRepository } from '../../../../../applications/ports/ticketRepository'
import { WbsItemTypeVO } from '../../../../../domain/value-object/WbsItemTypeVO'
import {
  EntityExtensionValuesVO,
  entityExtensionValuesVoService,
} from '../../../../../domain/value-object/EntityExtensionValuesVO'

export const useTicketSingleSheetData = (
  uuid: string,
  wbsItemTypeVo: WbsItemTypeVO,
  ticketInitialValue?: TicketInitialValue
) => {
  const repository = useTicketRepository()
  const [initialized, setInitialized] = useState<boolean>(false)
  const [isCreatingWbsItem, setIsCreatingWbsItem] = useState(false)
  const { edited, editedPageData, refreshedPageData } = usePageDataIsEdited()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [entity, setEntity] = useState<TicketEntity | undefined>()
  const [wbsItemFormModel, setWbsItemFormModel] = useState<WbsItemFormModel>(
    createInitialValue(WbsItemType.TASK, ticketInitialValue?.wbsItem)
  )
  const [ticketFormModel, setTicketFormModel] = useState<TicketFormModel>(
    createTicketInitialValue('', ticketInitialValue)
  )
  const fetchData = useCallback(async () => {
    setIsLoading(true)
    try {
      const response = await repository.fetchByWbsItemUuid(uuid)
      setEntity(response)
      const formModel = structuredClone(response)
      setWbsItemFormModel(formModel.wbsItem)
      setTicketFormModel(formModel)
    } catch (err: any) {
      if (err.code === 'NOT_FOUND' && ticketInitialValue) {
        // Ignore error because this wbs is unsaved.
        setIsCreatingWbsItem(true)
      } else {
        throw err
      }
    }
    setInitialized(true)
    setIsLoading(false)
    refreshedPageData()
  }, [repository, uuid, refreshedPageData, ticketInitialValue])
  useEffect(() => {
    fetchData()
  }, [fetchData, uuid])
  const onChangeWbsItem = useCallback(
    <K extends keyof WbsItemFormModel, T extends WbsItemFormModel[K]>(
      path: K,
      value: T
    ) => {
      setWbsItemFormModel(prev => {
        const newModel = {
          ...prev,
        }
        newModel[path] = value
        return newModel
      })
      editedPageData()
    },
    [editedPageData]
  )
  const onChangeTicketList = useCallback(
    (ticketList: ReferencedTicketListEntity | undefined) => {
      if (!ticketList) return
      setTicketFormModel(prev => ({
        ...prev,
        ticketList,
        ticketType: ticketList.ticketType,
      }))
      editedPageData()
    },
    [editedPageData]
  )
  // TODO Remove after all entity extensions are transfered.
  const onChangeEntityExtensionValues = useCallback(
    (value: EntityExtensionValuesVO) => {
      setTicketFormModel(prev => {
        return {
          ...prev,
          entityExtensionValues: value,
        }
      })
      editedPageData()
    },
    [editedPageData]
  )
  const dispatch = useDispatch()
  const create = useCreate(
    dispatch,
    wbsItemTypeVo,
    ticketFormModel,
    wbsItemFormModel,
    repository,
    ticketInitialValue
  )
  const updateDelta = useUpdateDelta(
    dispatch,
    ticketFormModel,
    wbsItemFormModel,
    repository,
    entity
  )
  const submit = useCallback(async () => {
    setIsLoading(true)
    if (isCreatingWbsItem) {
      await create()
    } else {
      await updateDelta()
    }
    await fetchData()
    setIsLoading(false)
  }, [isCreatingWbsItem, fetchData, create, updateDelta])

  return {
    initialized,
    isCreatingWbsItem,
    edited,
    isLoading,
    wbsItemFormModel,
    ticketFormModel,
    onChangeWbsItem,
    onChangeTicketList,
    onChangeEntityExtensionValues,
    reload: fetchData,
    submit,
  }
}

// Private hooks.
const useCreate = (
  dispatch: ReturnType<typeof useDispatch>,
  wbsItemTypeVo: WbsItemTypeVO,
  ticketFormModel: TicketFormModel,
  wbsItemFormModel: WbsItemFormModel,
  repository: TicketRepository,
  ticketInitialValue?: TicketInitialValue
) => {
  const create = useCallback(async () => {
    const projectUuid = ticketInitialValue?.wbsItem?.projectUuid || ''
    const wbsItem = toCreateInput(wbsItemFormModel, wbsItemTypeVo)
    const input: CreateTicketInput = {
      uuid: ticketFormModel.uuid,
      projectUuid,
      ticketType: ticketFormModel.ticketType,
      ticketListUuid: ticketFormModel.ticketList?.uuid || '',
      parentUuid: undefined,
      parentWbsItemUuid: ticketInitialValue?.parentWbsItem?.uuid,
      prevSiblingUuid: undefined,
      projectPlan: {
        uuid: ticketInitialValue?.projectPlanUuid || '',
        projectUuid,
        parentUuid: undefined,
        prevSiblingUuid: undefined,
        type: wbsItem.type,
        wbsItem,
      },
      // TODO Remove after all entity extensions are transfered.
      extensions: ticketFormModel.entityExtensionValues
        ? entityExtensionValuesVoService.serialize(
            ticketFormModel.entityExtensionValues
          )
        : undefined,
    }
    const request: CreateTicketRequest = {
      input,
      watchers: (wbsItemFormModel.watchers || []).map(watcher => watcher.uuid),
      tags: {
        wbsItemUuid: wbsItemFormModel.uuid,
        tagUuids: (wbsItemFormModel.tags || []).map(tag => tag.uuid),
      },
    }
    await repository.create(request)
    dispatch(
      addScreenMessage('wbsItem', {
        type: MessageLevel.SUCCESS,
        title: intl.formatMessage({ id: 'create.completed.title' }),
      })
    )
  }, [
    dispatch,
    ticketFormModel.uuid,
    ticketFormModel.ticketType,
    ticketFormModel.ticketList,
    ticketFormModel.entityExtensionValues,
    wbsItemFormModel,
    repository,
    ticketInitialValue,
  ])

  return create
}
const useUpdateDelta = (
  dispatch: ReturnType<typeof useDispatch>,
  ticketFormModel: TicketFormModel,
  wbsItemFormModel: WbsItemFormModel,
  repository: TicketRepository,
  entity?: TicketEntity
) => {
  const updateDelta = useCallback(async () => {
    if (!entity) return
    const wbsItem = toDeltaInput(entity.wbsItem, wbsItemFormModel)
    const input = {
      uuid: entity.uuid,
      ticketListUuid: isReferencedEntityValueDiffered(
        entity.ticketList,
        ticketFormModel.ticketList
      )
        ? toReferencedEntityDeltaValue(
            entity.ticketList,
            ticketFormModel.ticketList
          )
        : undefined,
      // TODO Remove after all entity extensions are transfered.
      extensions: entityExtensionValuesVoService.serializeDelta(
        entity.entityExtensionValues,
        ticketFormModel.entityExtensionValues
      ),
      wbsItem,
    }
    const request = {
      input,
      watchers: (wbsItemFormModel.watchers || []).map(watcher => watcher.uuid),
      tags: {
        wbsItemUuid: wbsItemFormModel.uuid,
        tagUuids: (wbsItemFormModel.tags || []).map(tag => tag.uuid),
      },
    }
    await repository.updateDelta(request)
    dispatch(
      addScreenMessage('wbsItem', {
        type: MessageLevel.SUCCESS,
        title: intl.formatMessage({ id: 'update.completed.title' }),
      })
    )
  }, [
    dispatch,
    entity,
    ticketFormModel.ticketList,
    ticketFormModel.entityExtensionValues,
    wbsItemFormModel,
    repository,
  ])

  return updateDelta
}
