import { useCallback, useEffect, useState } from 'react'
import { styled } from '@mui/system'
import { formatDate, formatDateTimeWithDay } from '../../../../utils/date'
import { FontSize } from '../../../../styles/commonStyles'
import { TimerMeasuringTaskOfUser } from '../../../../domain/entity/TimerEntity'
import { intl } from '../../../../i18n'
import { convertUnixToHMS } from '../../../../utils/time'
import { useTimerRepository } from '../../../../services/adaptors/timerRepositoryAdapter'
import { useDispatch, useSelector } from 'react-redux'
import { AllState } from '../../../../store'
import { stopTimer } from '../../../../store/measuringTimer'
import TimerStopButton from '../../../containers/commons/AgGrid/components/cell/custom/timerStopButton'
import { Avatar, Typography } from '@mui/material'
import { colorPalette } from '../../../style/colorPallete'

const INTERVAL_FOR_API_REQUEST = 30000
const INTERVAL_FOR_RENDERING = 1000

interface TimerMeasuringTasksForDisplay {
  userUuid: string
  userName: string
  userIconUrl: string
  taskUuid: string
  taskName: string
  startTime: string
  elapsedTime: string
}

const RootDiv = styled('div')({
  width: '100%',
  height: 'calc(100% - 7px)',
})

const StyledTrackTimerTitle = styled(Typography)({
  fontSize: FontSize.LARGE,
  fontWeight: 'bold',
  marginBottom: '10px',
})

const StyledTrackTimerHeader = styled('div')({
  width: '100%',
  position: 'relative',
  display: 'flex',
  backgroundColor: colorPalette.monotone[1],
})

const StyledHeaderUserNameCell = styled(Typography)({
  padding: '10px',
  fontSize: FontSize.MEDIUM,
  fontWeight: 'bold',
})

const StyledHeaderTaskNameCell = styled(Typography)({
  padding: '10px',
  marginLeft: '110px',
  fontSize: FontSize.MEDIUM,
  fontWeight: 'bold',
})

const StyledHeaderStartTimeCell = styled(Typography)({
  padding: '10px',
  marginLeft: '147px',
  fontSize: FontSize.MEDIUM,
  fontWeight: 'bold',
})

const StyledHeaderElapsedTimeCell = styled(Typography)({
  padding: '10px',
  marginLeft: '107px',
  fontSize: FontSize.MEDIUM,
  fontWeight: 'bold',
})

const StyledHeaderStopButtonCell = styled(Typography)({
  marginTop: '10px',
  marginLeft: '38px',
  fontSize: FontSize.MEDIUM,
  fontWeight: 'bold',
})

const StyledTrackTimerRow = styled('div')({
  width: '100%',
  height: '50px',
  display: 'flex',
  borderBottom: `1px solid ${colorPalette.monotone[2]}`,
})

const StyledUserIcon = styled(Avatar)({
  width: 25,
  height: 25,
  marginLeft: '10px',
  marginTop: '10px',
})

const StyledUserNameCell = styled(Typography)({
  width: '150px',
  margin: '15px 0px',
  fontSize: FontSize.MEDIUM,
})

const StyledTaskNameCell = styled(Typography)({
  width: '240px',
  margin: '15px 0px 0px -13px',
  fontSize: FontSize.MEDIUM,
})

const StyledStartTimeCell = styled(Typography)({
  width: '170px',
  margin: '15px 0px 0px 10px',
  fontSize: FontSize.MEDIUM,
})

const StyledElapsedTimeCell = styled(Typography)({
  width: '100px',
  margin: '15px 0px 0px 5px',
  fontSize: FontSize.MEDIUM,
})

const StyledStopButtonCell = styled(Typography)({
  marginTop: '6px',
  marginLeft: '-12px',
})

const nameFormat = (Name: string) => {
  return Name.length > 10 ? Name.slice(0, 10) + '...' : Name
}

const TimerMeasuringTaskList = () => {
  const [timerMeasuringTaskListData, setTimerMeasuringTaskListData] = useState<
    TimerMeasuringTaskOfUser[]
  >([])
  const [timerMeasuringTasksForDisplay, setTimerMeasuringTasksForDisplay] =
    useState<TimerMeasuringTasksForDisplay[]>([])
  const { projectUuid, me } = useSelector<
    AllState,
    { projectUuid: string | undefined; me: string | undefined }
  >(state => ({
    projectUuid: state.project.selected,
    me: state.user.user?.uuid,
  }))
  const { stopMemberTimer, getTimerMeasuringTaskList } = useTimerRepository()
  const dispatch = useDispatch()
  const today = formatDate(new Date())

  const fetchTimerMeasuringTasks = useCallback(async () => {
    if (!projectUuid) return
    const newTimerMeasuringTaskList = await getTimerMeasuringTaskList(
      projectUuid
    )
    setTimerMeasuringTaskListData(newTimerMeasuringTaskList)
  }, [projectUuid, getTimerMeasuringTaskList])

  const onClickStopButton = useCallback(
    async (userUuid: string, taskUuid: string) => {
      try {
        await stopMemberTimer(userUuid, taskUuid)
        userUuid === me && dispatch(stopTimer())
      } finally {
        fetchTimerMeasuringTasks()
      }
    },
    [stop, fetchTimerMeasuringTasks, me, dispatch]
  )

  useEffect(() => {
    if (projectUuid) {
      fetchTimerMeasuringTasks()
      const intervalId = setInterval(
        fetchTimerMeasuringTasks,
        INTERVAL_FOR_API_REQUEST
      )
      return () => clearInterval(intervalId)
    }
  }, [projectUuid, fetchTimerMeasuringTasks])

  useEffect(() => {
    const updateTimerMeasuringTaskList = () => {
      const formattedList = timerMeasuringTaskListData.map(item => {
        const startTime = new Date(item.startTime)
        const elapsedTime = new Date().getTime() - startTime.getTime()
        const formattedDate = formatDateTimeWithDay(item.startTime)

        return {
          userUuid: item.userUuid,
          userName: nameFormat(item.userName),
          userIconUrl: item.userIconUrl,
          taskUuid: item.taskUuid,
          taskName: `${nameFormat(item.parentDeliverableName)} / ${nameFormat(
            item.taskName
          )}`,
          startTime: formattedDate!.toString(),
          elapsedTime: convertUnixToHMS(elapsedTime),
        }
      })
      setTimerMeasuringTasksForDisplay(formattedList)
    }

    updateTimerMeasuringTaskList()
    const intervalId = setInterval(
      updateTimerMeasuringTaskList,
      INTERVAL_FOR_RENDERING
    )
    return () => clearInterval(intervalId)
  }, [timerMeasuringTaskListData])

  return timerMeasuringTaskListData.length === 0 ? (
    <RootDiv>
      <StyledTrackTimerTitle>{today}</StyledTrackTimerTitle>
      <div>
        {intl.formatMessage({
          id: 'timerMeasuringList.header.inoccupation',
        })}
      </div>
    </RootDiv>
  ) : (
    <RootDiv>
      <StyledTrackTimerTitle>{today}</StyledTrackTimerTitle>
      <StyledTrackTimerHeader>
        <StyledHeaderUserNameCell>
          {intl.formatMessage({
            id: 'timerMeasuringList.header.user',
          })}
        </StyledHeaderUserNameCell>
        <StyledHeaderTaskNameCell>
          {intl.formatMessage({
            id: 'timerMeasuringList.header.task',
          })}
        </StyledHeaderTaskNameCell>
        <StyledHeaderStartTimeCell>
          {intl.formatMessage({
            id: 'timerMeasuringList.header.start',
          })}
        </StyledHeaderStartTimeCell>
        <StyledHeaderElapsedTimeCell>
          {intl.formatMessage({
            id: 'timerMeasuringList.header.elapsed',
          })}
        </StyledHeaderElapsedTimeCell>
        <StyledHeaderStopButtonCell>
          {intl.formatMessage({
            id: 'timerMeasuringList.header.stopbutton',
          })}
        </StyledHeaderStopButtonCell>
      </StyledTrackTimerHeader>
      {timerMeasuringTasksForDisplay.map(timerMeasuringTaskList => (
        <StyledTrackTimerRow
          key={`${timerMeasuringTaskList.userUuid}-${timerMeasuringTaskList.taskUuid}`}
        >
          <StyledUserIcon src={timerMeasuringTaskList.userIconUrl} />
          <StyledUserNameCell>
            {timerMeasuringTaskList.userName}
          </StyledUserNameCell>
          <StyledTaskNameCell>
            {timerMeasuringTaskList.taskName}
          </StyledTaskNameCell>
          <StyledStartTimeCell>
            {timerMeasuringTaskList.startTime}
          </StyledStartTimeCell>
          <StyledElapsedTimeCell>
            {timerMeasuringTaskList.elapsedTime}
          </StyledElapsedTimeCell>
          <StyledStopButtonCell>
            <TimerStopButton
              onClick={() =>
                onClickStopButton(
                  timerMeasuringTaskList.userUuid,
                  timerMeasuringTaskList.taskUuid
                )
              }
              iconHeight={35}
              iconWidth={35}
            />
          </StyledStopButtonCell>
        </StyledTrackTimerRow>
      ))}
    </RootDiv>
  )
}

export default TimerMeasuringTaskList
