import React, { useState, useCallback, useMemo } from 'react'
import { useTimerRepository } from '../../../../../../../../services/adaptors/timerRepositoryAdapter'
import { TimerStatus } from '../../../../../../../../domain/entity/TimerEntity'
import { useDispatch } from 'react-redux'
import {
  fetchTimerItem,
  stopTimer,
} from '../../../../../../../../store/measuringTimer'
import { TaskTimer } from '../../../../../../../pages/MyWbsItems/myWbsItems'
import styled from 'styled-components'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import PauseIcon from '@mui/icons-material/Pause'
import { colorPalette } from '../../../../../../../style/colorPallete'

const StyledButton = styled('button')<{ status: string; isDisabled: boolean }>(
  props => ({
    width: '70px',
    height: '20px',
    background:
      props.status === TimerStatus.MEASURING
        ? colorPalette.pink[5]
        : colorPalette.monotone[1],
    border:
      props.status === TimerStatus.MEASURING
        ? `1px solid ${colorPalette.pink[6]}`
        : `1px solid ${colorPalette.monotone[2]}`,
    boxShadow: `0px 1px 2px ${colorPalette.monotone[2]}`,
    borderRadius: '5px',
    cursor: props.isDisabled ? 'not-allowed' : 'pointer',
    position: 'absolute',
    top: '4.5px',
    left: '15px',
  })
)

interface ButtonCellRendererProps {
  uuid: string
  data: {
    body: {
      timer: TaskTimer
    }
    uuid: string
  }
}

const ButtonCellRenderer: React.FC<ButtonCellRendererProps> = ({ data }) => {
  const [isDisabled, setIsDisabled] = useState(false)
  const dispatch = useDispatch()

  const status = useMemo(
    () => data.body.timer.timerStatus,
    [data.body.timer.timerStatus]
  )
  const taskUuid = useMemo(() => data.uuid, [data.uuid])

  const button = useMemo(() => {
    return status === TimerStatus.MEASURING ? (
      <>
        <PauseIcon
          sx={{
            height: '16px',
            width: '16px',
            color: colorPalette.monotone[1],
          }}
        />
      </>
    ) : (
      <>
        <PlayArrowIcon
          sx={{
            height: '16px',
            width: '16px',
            color: colorPalette.monotone[4],
          }}
        />
      </>
    )
  }, [status])

  const { start, stop } = useTimerRepository()

  const handleClick = useCallback(async () => {
    setIsDisabled(true)
    try {
      if (status === TimerStatus.MEASURING) {
        await stop(taskUuid)
        dispatch(stopTimer())
      } else {
        await start(taskUuid)
        dispatch(fetchTimerItem())
      }
    } finally {
      setIsDisabled(false)
    }
  }, [status, taskUuid, start, stop, dispatch])

  return (
    <StyledButton
      status={status}
      isDisabled={isDisabled}
      onClick={handleClick}
      disabled={isDisabled}
    >
      {button}
    </StyledButton>
  )
}
export default ButtonCellRenderer
