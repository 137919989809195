import store from '../store'
import { addGlobalMessage, MessageLevel } from '../store/messages'
import { ApiMessage, isAbortError } from '../lib/commons/api'
import { ScopeType } from '../lib/functions/role'
import { logout } from '../store/user'
import { intl } from '../i18n'
import { showAlert } from '../store/globalAlert'
import Auth, { AuthErrorCode } from '../lib/commons/auth'
import { setTabKey, TabKey } from '../store/information'

const toString = (value: any) => {
  if (typeof value === 'object') {
    return JSON.stringify(value)
  }
  return value || ''
}

const generateUiErrorCode = (): string => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
  const length = 8
  return Array.from(crypto.getRandomValues(new Uint8Array(length)))
    .map(n => characters[n % characters.length])
    .join('')
}

const showErrorInformation = (
  type: MessageLevel,
  errorCode: string,
  title: string,
  reason: string
) => {
  let detail = reason
  if (detail) {
    detail = `${detail}\n`
  }
  detail = intl.formatMessage(
    { id: 'global.warning.errorCode' },
    { text: detail || '', errorCode }
  )
  store.dispatch(
    addGlobalMessage({
      type,
      code: errorCode || undefined,
      title,
      text: reason,
      detail: detail || reason,
    })
  )
  store.dispatch(setTabKey(TabKey.MESSAGE))
}

export enum ErrorCode {
  INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR',
  INTERNAL_CLIENT_ERROR = 'INTERNAL_CLIENT_ERROR',
  NOT_FOUND = 'NOT_FOUND',
  CONSISTENCY_VIOLATION = 'CONSISTENCY_VIOLATION',
  CONSTRAINT_VIOLATION = 'CONSTRAINT_VIOLATION',
  UNAUTHORIZED = 'UNAUTHORIZED',
  INVALID_USER = 'INVALID_USER',
  UNAUTHORIZED_SCOPE = 'UNAUTHORIZED_SCOPE',
  RETRY_NEEDED = 'RETRY_NEEDED',
  BUSINESS_ERROR = 'BUSINESS_ERROR',
}

const handleError = async (reason: any) => {
  const errorCode = reason?.errorCode ? reason.errorCode : generateUiErrorCode()
  if (
    process.env.FLAGXS_ENV === 'production' ||
    process.env.FLAGXS_ENV === 'staging'
  ) {
    const userState = store.getState().user
    const tenant = store.getState().tenant
    newrelic.noticeError(
      typeof reason === 'string' ? new Error(reason) : reason,
      {
        errorCode,
        userUuid: userState.user?.uuid || '',
        userCode: userState.user?.code || '',
        tenantUuid: tenant.organization?.uuid || '',
        tenantAlias: tenant.organization?.alias || '',
        url: window.location.href,
      }
    )
  }
  switch (reason.code) {
    case ErrorCode.CONSTRAINT_VIOLATION: {
      const details: { propertyPath: string; message: string }[] = reason.detail
      const message = details
        .map(v => `${v.propertyPath}: ${v.message}`)
        .join('\n')
      showErrorInformation(
        MessageLevel.WARN,
        errorCode,
        intl.formatMessage({ id: 'global.warning.constraintViolation' }),
        message
      )
      return
    }
    case ErrorCode.CONSISTENCY_VIOLATION:
    case ErrorCode.INTERNAL_CLIENT_ERROR:
    case ErrorCode.RETRY_NEEDED:
    case ErrorCode.BUSINESS_ERROR: {
      showErrorInformation(
        MessageLevel.WARN,
        errorCode,
        reason.title ||
          intl.formatMessage({ id: 'global.warning.businessError' }),
        toString(reason.detail)
      )
      return
    }
    case ErrorCode.INVALID_USER: {
      store.dispatch(
        showAlert({
          message: toString(reason.detail),
          action: () => {
            window.location.href = '/loggedout'
          },
        })
      )
      store.dispatch(logout())
      return
    }
    case ErrorCode.UNAUTHORIZED:
    case ErrorCode.UNAUTHORIZED_SCOPE: {
      if (!(await Auth.getCurrentTenant()?.isLoggedin())) {
        return
      }
      showErrorInformation(
        MessageLevel.WARN,
        errorCode,
        intl.formatMessage({ id: 'global.warning.unauthorized' }),
        intl.formatMessage(
          { id: 'global.warning.message.unauthorized' },
          {
            location: window.location.href,
            reason: reason.detail,
          }
        )
      )
      return
    }
    case AuthErrorCode.SESSION_EXPIRED:
      store.dispatch(
        showAlert({
          message: intl.formatMessage({ id: 'auth.error.sessionExpired' }),
          action: () => {
            window.location.href = '/loggedout'
          },
        })
      )
      store.dispatch(logout())
      return
    default: {
      break
    }
  }
  if (reason === 'Timeout' || (reason.error && isAbortError(reason.error))) {
    // Ignore the error. The error is still shown on the console for developers.
    return
  }
  if (
    reason.status === 404 ||
    (reason.status === 400 && errorCode.slice(0, 3) === 'NFE')
  ) {
    showErrorInformation(
      MessageLevel.WARN,
      errorCode,
      intl.formatMessage({ id: 'global.warning.dataDeletedOrURLIncorrect' }),
      toString(reason.detail)
    )
    return
  }

  let title: string
  let message: string = intl.formatMessage(
    { id: 'global.warning.systemError.message' },
    {
      supportMailAddress: process.env.FLAGXS_SUPPORT_MAIL_ADDRESS,
    }
  )
  // API error
  message = `${message}${toString(reason.detail)}`
  title = intl.formatMessage({ id: 'global.warning.systemError.server' })

  showErrorInformation(MessageLevel.ERROR, errorCode, title, message)
}

class GlobalErrorHandler {
  register = () => {
    window.addEventListener(
      'error',
      (
        event: Event | string,
        source?: string,
        lineno?: number,
        colno?: number,
        error?: Error
      ) => {
        let reason: any = event
        if (event['error']) {
          reason = event['error']
          if (reason && reason['message']) {
            reason = {
              message: reason['message'],
              stack: reason['stack'],
            }
          }
        } else if (event['message']) {
          reason = event['message']
        }
        let extra = !source ? '' : `\nurl: ${source}\nline: ${lineno}`
        extra += !colno ? '' : `\ncolumn: ${colno}`
        extra += !error ? '' : `\nerror: ${toString(error)}`
        if (typeof reason === 'string') {
          handleError(`${toString(reason)}${extra}`)
        } else {
          handleError({ ...reason, extra })
        }
      }
    )
    window.addEventListener(
      'unhandledrejection',
      (event: PromiseRejectionEvent) => {
        let reason = event.reason
        handleError(reason)
      }
    )
  }
}

export const handleWarning = (
  messages: ApiMessage[],
  targetAccessor: (uuid: string) => any
) => {
  if (messages && messages.length > 0) {
    const displayMessages = messages.map(message => {
      if (message.id === 'UPDATE_FAILED') {
        const target = targetAccessor(message.uuid)
        return intl.formatMessage(
          { id: 'global.warning.updateFailed' },
          {
            code: target['code'],
            name: target['name'] || target['displayName'],
            option: target['revision']
              ? intl.formatMessage(
                  { id: 'global.warning.updateFailed.revision' },
                  { revision: target['revision'] }
                )
              : '',
          }
        )
      }
      if (message.id === 'NEED_PERMISSION') {
        const scope = message.args[1]
        let scopeStr: string
        if (scope.scopeType === ScopeType.DATA) {
          scopeStr = intl.formatMessage(
            { id: 'global.warning.dataScope' },
            scope
          )
        } else {
          scopeStr = intl.formatMessage(
            { id: 'global.warning.functionScope' },
            scope
          )
        }
        return intl.formatMessage(
          { id: 'global.warning.needPermission' },
          {
            role: message.args[0],
            scope: scopeStr,
          }
        )
      }
      return message.readableMessage.message
    })

    const text =
      displayMessages.length === 1
        ? displayMessages[0]
        : displayMessages.map(message => `- ${message}`).join('\n')
    store.dispatch(
      addGlobalMessage({
        type: MessageLevel.WARN,
        title: intl.formatMessage({ id: 'global.warning.businessError' }),
        text: text,
      })
    )
    store.dispatch(setTabKey(TabKey.MESSAGE))
  }
}

export default new GlobalErrorHandler()
