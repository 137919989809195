import {
  PropsWithChildren,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { Login } from '../authorizations-newdesign/Login'
import Auth, { clearExternalServiceCache } from '../../../lib/commons/auth'
import { IS_LOCAL_HOST, extractTenantAlias } from '../../../utils/urls'
import store, { AllState } from '../../../store'
import { subscribeDebugMessage } from '../../../store/messages'
import { subscribeApplication } from '../../../store/application'
import { Agreements } from '../authorizations-newdesign/Agreements'
import AgreementApi from '../../../lib/functions/agreement'
import ProjectAPI, { ProjectDetail } from '../../../lib/functions/project'
import {
  loginToProjectByUserAction,
  setAssignedProject,
} from '../../../store/project'
import { receivedAgreements } from '../../../store/user'
import Loading from '../../components/process-state-notifications/Loading'
import { showAlert } from '../../../store/globalAlert'
import { intl } from '../../../i18n'
import { connect } from 'react-redux'
import { OrganizationDetail } from '../../../lib/functions/tenant'

interface ReduxStateProps {
  tenant?: OrganizationDetail
}

interface Props extends PropsWithChildren {}

const TenantPrivate = (props: Props & ReduxStateProps) => {
  const history = useHistory()
  const location = useLocation()
  const [step, setStep] = useState<
    | 'INITIAL'
    | 'UNAUTHORIZED'
    | 'NEED_AGREEMENT'
    | 'AUTHORIZED'
    | 'REDIRECTED_FROM_EXTERNAL_SERVICE'
  >('INITIAL')
  const [loading, setLoading] = useState(false)

  const executeInit = useCallback(async () => {
    let tenant = Auth.getCurrentTenant()
    if (!IS_LOCAL_HOST) {
      const alias = extractTenantAlias(new URL(window.location.href))
      if (!alias) {
        tenant = undefined
      } else if (!tenant || tenant.tenantAlias !== alias) {
        try {
          tenant = await Auth.switchTenant(alias)
        } catch (e) {
          console.warn(`Tenant not found: ${alias}`)
          tenant = undefined
        }
      }
    }
    if (!tenant) {
      history.push(
        `/login${
          !!window.location.href.match(/^https?:\/\/[^\/]+\/?$/)
            ? ''
            : `?redirect_uri=${window.location.href}`
        }`
      )
      return
    }
    const query = queryString.parse(window.location.search)
    const authorized = await tenant.isLoggedin()
    const redirectedFromExternalService = !!Auth.loggedInWithExternalService()
    if (!redirectedFromExternalService) {
      if (!authorized || query['clearSession']) {
        const alias = tenant.tenantAlias
        await tenant.logoutFromTenant(
          (query['redirect_uri'] as string) || undefined
        )
        await Auth.switchTenant(alias)
      } else {
        // It's called every new tab is opened.
        await tenant.loggedIn()

        if (process.env.FLAGXS_ENV === 'development') {
          store.dispatch(subscribeDebugMessage())
        }
      }
    }
    store.dispatch(subscribeApplication(process.env.FLAGXS_APPLICATION_UUID!))
    if (authorized) {
      await Auth.switchTenant(tenant.tenantAlias)
      if (!tenant.skipAgreement) {
        const [userAgreementLogsResponse, agreementsResponse] =
          await Promise.all([
            AgreementApi.getAgreementLogs(),
            AgreementApi.getAll(),
          ])
        const userAgreements = userAgreementLogsResponse.json
        const agreements = agreementsResponse.json
        store.dispatch(receivedAgreements(userAgreements))
        if (
          userAgreements.length === 0 ||
          userAgreements.filter(v => v.latestVersion).length !==
            agreements.length
        ) {
          setStep('NEED_AGREEMENT')
          return
        }
      }
      const response = await ProjectAPI.getAssignedProjects()
      const assignedProjects: ProjectDetail[] = response.json
      store.dispatch(setAssignedProject(assignedProjects))
      if (query['redirect_uri']) {
        window.location.href = query['redirect_uri'] as string
      } else if (
        !location.pathname.replaceAll(/\/|loggedin/g, '') &&
        assignedProjects.length > 0
      ) {
        store.dispatch(loginToProjectByUserAction(assignedProjects[0].uuid))
      }
      setStep('AUTHORIZED')
    } else if (redirectedFromExternalService) {
      setStep('REDIRECTED_FROM_EXTERNAL_SERVICE')
      setLoading(true)
      setTimeout(() => {
        setLoading(false)
        if (!!Auth.loggedInWithExternalService()) {
          clearExternalServiceCache()
          store.dispatch(
            showAlert({
              message: intl.formatMessage({ id: 'login.error.federatedLogin' }),
              action: () => {
                setStep('UNAUTHORIZED')
              },
            })
          )
        }
      }, 5000)
    } else {
      setStep('UNAUTHORIZED')
    }
  }, [history, location.pathname])

  useEffect(() => {
    executeInit()
  }, [executeInit])

  if (step === 'INITIAL') {
    return <></>
  } else if (step === 'REDIRECTED_FROM_EXTERNAL_SERVICE') {
    return <Loading isLoading={loading} />
  } else if (step === 'UNAUTHORIZED') {
    return <Login />
  } else if (step === 'NEED_AGREEMENT') {
    return <Agreements />
  }

  if (!props.tenant) {
    return <></>
  }
  return props.children as ReactElement
}

const mapStateToProps = (state: AllState) => ({
  tenant: state.tenant.organization,
})

export default connect(mapStateToProps)(TenantPrivate)
