import _ from 'lodash'
import { useIntl } from 'react-intl'
import PersonIcon from '@mui/icons-material/Person'
import { Typography } from '@mui/material'
import { Button } from '../../../../components/buttons'

interface Props {
  openDialog: () => void
}

const WorkerTaskCheckButton = ({ openDialog }: Props) => {
  const intl = useIntl()

  return (
    <Button
      colorPattern="monotone"
      onClick={openDialog}
      sx={{
        margin: '0 5px 0 15px',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        minWidth: 'fit-content',
      }}
      variant="outlined"
    >
      <PersonIcon sx={{ height: '16px', width: '16px' }} />
      <Typography>
        {intl.formatMessage({ id: 'workReport.worker.task.check.button' })}
      </Typography>
    </Button>
  )
}

export default WorkerTaskCheckButton
