import { useCallback } from 'react'
import { SprintRepository } from '../../applications/ports/sprintRepository'
import api from '../../lib/commons/api'

export const useSprintRepository = (): SprintRepository => {
  const fetchActiveAsReferencedEntity = useCallback(
    async (teamUuid: string) => {
      const response = await api.functional.request(
        'GET',
        '/api/v1/projects/scrums/sprints',
        { teamUuid, statusList: ['STANDBY', 'INPROGRESS'] },
        true
      )
      // TODO: Consider the logic to assure type in runtime.
      return (
        response.json.map(e => ({
          uuid: e.uuid,
          name: e.name,
        })) || []
      )
    },
    []
  )
  const fetchAsReferencedEntity = useCallback(async (uuid: string) => {
    const response = await api.functional.request(
      'GET',
      '/api/v1/projects/scrums/sprints/referenced_values',
      { uuid },
      true
    )
    // TODO: Consider the logic to assure type in runtime.
    return response.json
  }, [])

  return { fetchActiveAsReferencedEntity, fetchAsReferencedEntity }
}
