import _ from 'lodash'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  ColDef,
  ColumnApi,
  ColumnState,
  ColumnVisibleEvent,
  FilterChangedEvent,
  GetContextMenuItemsParams,
  GridApi,
  GridReadyEvent,
  MenuItemDef,
  RowDataUpdatedEvent,
  RowDragEvent,
  RowHeightParams,
  RowNode,
  SortChangedEvent,
} from 'ag-grid-community'
import { handleWarning } from '../../../handlers/globalErrorHandler'
import { intl } from '../../../i18n'
import { AggregateField } from '../../../domain/entity/WbsItemEntity'
import { extractValuesFromResponse } from '../../../lib/commons/api'
import {
  FunctionProperty,
  PropertyType,
} from '../../../lib/commons/appFunction'
import { UiStateKey } from '../../../lib/commons/uiStates'
import { TicketType } from '../../../lib/functions/ticket'
import {
  TicketListBasic,
  TicketListDetail,
} from '../../../lib/functions/ticketList'
import { WorkloadUnit } from '../../../lib/functions/workload'
import store, { AllState } from '../../../store'
import { FunctionLayer } from '../../../store/functionLayer'
import {
  MessageLevel,
  addGlobalMessage,
  addScreenMessage,
} from '../../../store/messages'
import { fetchTagsByProject } from '../../../store/tag'
import { DISPLAY_DATE_SHORT_FORMAT_WITH_DAY } from '../../../utils/date'
import SelectVO from '../../../vo/SelectVO'
import CancelConfirmDialog from '../../components/dialogs/CancelConfirmDialog'
import SavedUIStateDialog from '../../components/dialogs/SavedUIStateDialog'
import { SavedUIState } from '../../components/dialogs/SavedUIStateDialog/SavedUIStateList'
import TaskActualWorkDialog from '../../components/dialogs/TaskActualWorkDialog'
import { ROW_HEIGHT } from '../../containers/BulkSheet'
import AddRowCountInputDialog from '../../containers/BulkSheet/AddRowCountInputDialog'
import { BulkSheetView } from '../../containers/BulkSheetView'
import ColumnSettingPopper from '../../containers/BulkSheetView/components/columnSelector/ColumnSettingPopper'
import { useColumnSetting } from '../../containers/BulkSheetView/components/columnSelector/useColumnSetting'
import { DeleteRowConfirmationDialog } from '../../containers/BulkSheetView/components/dialog/DeleteRowConfirmationDialog'
import SavePopper from '../../containers/BulkSheetView/components/header/SaveButtonArea'
import { removeRows } from '../../containers/BulkSheetView/hooks/actions/crudTreeRows'
import { useDragStyle } from '../../containers/BulkSheetView/hooks/gridEvents/rowDrag'
import { getSelectedNode } from '../../containers/BulkSheetView/lib/gridApi'
import {
  InputError,
  addInputError,
} from '../../containers/BulkSheetView/lib/validation'
import validator from '../../containers/meta/validator'
import { StatusChangePopper } from '../../components/poppers/StatusChangePopper'
import Loading from '../../components/process-state-notifications/Loading'
import {
  ToolbarToggleValue,
  storedUiStateWithToolbarToggleAdaptor,
} from '../../components/toolbars/Toolbar/ToolbarToggle'
import { SortedColumnState } from '../../model/bulkSheetColumnSortState'
import { KEY_SAVE } from '../../model/keyBind'
import { useProjectPrivateContext } from '../../context/projectContext'
import { projectPrivate } from '../../higher-order-components/projectPrivate'
import { usePageState } from '../../hooks/usePageState'
import { useWorkloadUnit } from '../../hooks/useWorkloadUnit'
import { PageArea, PageProps } from '..'
import { usePopper } from '../ProjectPlanNew/hooks'
import { TicketListSelector } from '../Tickets/Header/TicketListSelector'
import TicketsReport from '../Tickets/Header/TicketsReport'
import TicketsWbsHeader from '../Tickets/Header/TicketsWbsHeader'
import { useTicketGridOptions } from './GridOptions'
import { useColumnAndFilterState } from './hooks/columnFilterState'
import { useDialog } from './hooks/dialog'
import { useExtension } from './hooks/extension'
import { useSearchConditionState } from './hooks/searchConditionState'
import { useSelectColumnOptions } from './hooks/selectOptions'
import { useTicketsData } from './hooks/ticketsData'
import { useKeyBind } from '../../hooks/useKeyBind'
import { ExcelExportDialog } from './components/ExcelExportDialog'
import {
  addDeleteMenuItems,
  addMultipleRowsMenuItems,
  addRowMenuItems,
} from './GridOptions/contextMenu'
import { TicketRow, TicketWbsItemRow } from './tickets'
import { useExcel } from './hooks/excel'
import { getDataManager, TicketDataManager } from './dataManager'
import { difference } from 'd3'
import {
  addCopyURLAndNameMenuItems,
  addCopyURLMenuItems,
} from '../../containers/BulkSheetView/gridOptions/contextMenu'
import {
  FIELD_WBSITEM_ACTUALDATE_END,
  FIELD_WBSITEM_ACTUALDATE_START,
} from './GridOptions/columnDefs/common'
import { useWbsItemAdditionalProperties } from '../../hooks/useWbsItemAdditionalProperties'
import { WbsItemTypeVO } from '../../../domain/value-object/WbsItemTypeVO'
import { useWbsItemCodeService } from '../../../services/adaptors/wbsItemCodeServiceAdaptor'

type TicketsPageState = {
  toolbar: ToolbarToggleValue | undefined
  aggregateField: AggregateField
  workloadUnit: WorkloadUnit
  dateFormat: string
  isReportOpen: boolean
  rowHeight: number
}

const ticketsDefaultPageState = {
  toolbar: ToolbarToggleValue.DISPLAY,
  aggregateField: AggregateField.WBS_ITEM_WORKLOAD,
  workloadUnit: WorkloadUnit.DAY,
  dateFormat: DISPLAY_DATE_SHORT_FORMAT_WITH_DAY,
  isReportOpen: false,
  rowHeight: ROW_HEIGHT.SMALL,
}

type Props = PageProps

const SUMMARY_ROW_DATA: TicketRow = {
  isTotal: true,
  uuid: '',
  lockVersion: 0,
  treeValue: [],
}

const TicketsNew = ({ uuid: functionUuid }: Props) => {
  const functionLayer = useSelector<
    AllState,
    Immutable.Map<number, FunctionLayer>
  >(state => state.functionLayer.layers)

  const { projectUuid } = useProjectPrivateContext()
  const { generateCode } = useWbsItemCodeService()
  const ticketTypes = useSelector<AllState, WbsItemTypeVO[]>(
    state => state.project.ticketTypes
  )
  const ref = useRef<HTMLDivElement>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [ticketList, setTicketList] = useState<TicketListDetail | undefined>(
    undefined
  )
  const [rowNodes, setRowNodes] = useState<RowNode<TicketRow>[]>([])
  const [filteredColumns, setFilteredColumns] = useState<ColDef[]>([])
  const [sortColumnsState, setSortColumnsState] = useState<SortedColumnState[]>(
    []
  )
  const [initializedGridOptions, setInitializedGridOptions] =
    useState<boolean>(false)

  const gridApi = useRef<GridApi>()
  const columnApi = useRef<ColumnApi>()

  const {
    initialized: searchConditionStateInited,
    ticketType,
    setTicketType,
    lastTicketListUuidMap,
    setLastTicketListUuidMap,
    ticketListUuid,
    setTicketListUuid,
    getLastSelectedTicketListUuid,
  } = useSearchConditionState(functionUuid, projectUuid)
  const ticketTypeUuidsForWbsItemAdditionalProperty = useMemo(() => {
    const ticketTypeVO = ticketTypes.find(v => v.code === ticketType)
    if (!ticketTypeVO) {
      return undefined
    }
    return [ticketTypeVO.uuid]
  }, [ticketTypes, ticketType])
  const {
    fetched: fetchedWbsItemAdditionalProperties,
    wbsItemAdditionalProperties,
  } = useWbsItemAdditionalProperties(
    projectUuid,
    ticketTypeUuidsForWbsItemAdditionalProperty
  )

  const dataManager = useRef<TicketDataManager>()
  dataManager.current = getDataManager(ticketType)

  const { extensions, clearExtensions } = useExtension(
    functionUuid,
    projectUuid,
    ticketType,
    ticketListUuid
  )

  const {
    initialized: columnAndFilterStateInited,
    columnState,
    saveColumnState,
    filterState,
    saveFilterState,
    startRestoring,
    finishRestoring,
    saveImmediately,
  } = useColumnAndFilterState(
    searchConditionStateInited,
    functionUuid,
    projectUuid,
    ticketType,
    ticketListUuid
  )

  const {
    workloadUnit,
    aggregateField,
    dateFormat,
    isReportOpen,
    rowHeight,
    updatePageState,
  } = usePageState<TicketsPageState>(
    functionUuid,
    ticketsDefaultPageState,
    storedUiStateWithToolbarToggleAdaptor
  )
  const dialog = useDialog()
  const statusPopper = usePopper()
  const columnSetting = useColumnSetting()
  const dispatch = useDispatch()
  const { selectColumnOptions, clearSelectColumnOptions } =
    useSelectColumnOptions(projectUuid, ticketType)
  // Fetch the options to use when pasting tags
  useEffect(() => {
    dispatch(fetchTagsByProject(projectUuid))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectUuid])

  // Row data ---
  const {
    data,
    setData,
    clearData,
    refresh,
    refreshSingleRow,
    save,
    saveSingleRow,
    generateWbsItemDeltaRequest,
    deleteRows,
  } = useTicketsData(
    projectUuid,
    ticketListUuid,
    extensions?.extensionProperties,
    dataManager.current
  )
  const prevData = useRef<TicketRow[]>([])
  prevData.current = data
  const reload = useRef<Function>()
  reload.current = refresh
  const submit = useRef<Function>()
  submit.current = save
  const refreshSingleRowRef = useRef<Function>()
  refreshSingleRowRef.current = refreshSingleRow
  const saveSingleRowFunc = useRef<(uuid: string) => Promise<void>>()
  useEffect(() => {
    saveSingleRowFunc.current = saveSingleRow
  }, [saveSingleRow])

  const hasChanged = useCallback((): boolean => {
    return (prevData.current || data).some(v => v.added || v.edited)
  }, [data])

  // Context menu ---
  const refreshRowNumber = useCallback(() => {
    setTimeout(() => {
      gridApi.current?.refreshCells({
        columns: ['rowNumber'],
        force: true,
      })
    }, 500)
  }, [])

  const onAddRows = useCallback(
    (
      srcData: TicketRow[],
      ticketList: TicketListDetail | TicketListBasic | undefined,
      prevSiblingRowUuid: string | undefined,
      numOfAddRows: number
    ) => {
      if (!ticketList || !dataManager.current) return
      const newData = srcData.concat()
      const prevIndex = newData.findIndex(d => d.uuid === prevSiblingRowUuid)
      const index = prevIndex < 0 ? newData.length : prevIndex + 1
      const newRows = Array.from({ length: numOfAddRows }).map(_ =>
        dataManager.current!.createNewRow(
          {
            ...ticketList,
          } as TicketListBasic,
          generateCode
        )
      )
      newData.splice(index, 0, ...newRows)
      setData(newData)
      refreshRowNumber()
    },
    [setData, refreshRowNumber]
  )

  const onDeleteRows = useCallback(() => {
    if (!gridApi.current) return
    const deletedRows = getSelectedNode(gridApi.current).map(
      v => v.data as TicketRow
    )
    const newData = removeRows(data, deletedRows)
    if (_.isEmpty(newData)) {
      const newRow = dataManager.current!.createNewRow(
        {
          ...ticketList,
        } as TicketListBasic,
        generateCode
      )
      newRow && newData.push(newRow)
    }
    setData(newData)
    deleteRows(deletedRows)
    dialog.closeDeleteConfirmation()
    refreshRowNumber()
  }, [data, dialog, ticketList, setData, deleteRows, refreshRowNumber])

  const contextMenu = useCallback(
    (
      params: GetContextMenuItemsParams<TicketRow>
    ): (MenuItemDef | string)[] => {
      if (!gridApi.current || !params.node || params.node.data?.isTotal) {
        return []
      }
      const selectedNodes: RowNode<TicketRow>[] = getSelectedNode(
        gridApi.current
      )
      const selectedRows = selectedNodes
        .map(v => v.data)
        .filter(v => v) as TicketRow[]
      if (selectedRows.length <= 0) return []
      const targetRow: TicketRow = selectedRows[selectedRows.length - 1]

      const items = [
        addCopyURLMenuItems(targetRow.wbsItem),
        addCopyURLAndNameMenuItems(targetRow.wbsItem),
        'separator',
        addRowMenuItems(params, () =>
          onAddRows(data, ticketList, targetRow.uuid, 1)
        ),
        addMultipleRowsMenuItems(params, () =>
          dialog.openAddMultipleRow(targetRow.uuid)
        ),
        'separator',
        addDeleteMenuItems(params, dialog.openDeleteConfirmation),
      ].filter(v => v) as (MenuItemDef | string)[]

      return items.filter(
        (item: string | MenuItemDef, index: number) =>
          item !== 'separator' ||
          (0 < index && items[index - 1] !== 'separator')
      )
    },
    [dialog, onAddRows, data, ticketList]
  )

  // Grid option ---
  const onClickedStatusCell = useCallback(
    (target: EventTarget | undefined, row: TicketRow | undefined) => {
      const getAgCellElement = (elem): any => {
        if (!elem || elem.className.toString().includes('sevend-ag-cell')) {
          return elem
        }
        return getAgCellElement(elem.parentElement)
      }
      const targetElement = getAgCellElement(target)
      if (!target || !row?.wbsItem) return
      statusPopper.open(
        targetElement,
        row.wbsItem,
        generateWbsItemDeltaRequest(row)!
      )
    },
    [statusPopper, generateWbsItemDeltaRequest]
  )

  const onClickedActualHourCell = useCallback(
    (row: TicketRow | undefined) => {
      if (!row?.wbsItem?.uuid) return
      dialog.openActualResult(row?.wbsItem.uuid)
    },
    [dialog]
  )

  const onClickedActionCellAddRowButton = useCallback(
    (row: TicketRow) =>
      onAddRows(
        prevData.current || data,
        ticketList || row.ticketList,
        row.uuid,
        1
      ),
    [data, ticketList, onAddRows]
  )

  const getValidateValue = useCallback(
    (node: RowNode<TicketRow>, colId: string, uiMeta: FunctionProperty) => {
      if (!gridApi.current) return
      const val = gridApi.current.getValue(colId, node)
      if (uiMeta?.propertyType === PropertyType.Select) {
        const target = uiMeta.valuesAllowed.find(v => v.value === val)
        return target ? SelectVO.fromCustomEnum(target) : val
      }
      return val
    },
    []
  )

  const validateNode = useCallback(
    (
      node: RowNode<TicketRow>,
      columnIds?: string[],
      inputErrors?: InputError
    ) => {
      if (!gridApi.current || !columnApi.current) return
      const colIds =
        columnIds ??
        columnApi.current.getColumnState().map(colState => colState.colId)

      const errors = inputErrors ?? new InputError()
      colIds.forEach(colId => {
        const colDef = columnApi.current!.getColumn(colId)?.getColDef()
        if (!colDef || !colDef.cellRendererParams) return
        const uiMeta: FunctionProperty = colDef.cellRendererParams.uiMeta
        const val = getValidateValue(node, colId, uiMeta)
        if (uiMeta?.propertyType === PropertyType.EntitySearch) {
          if (
            uiMeta.referenceEntity &&
            ((selectColumnOptions &&
              selectColumnOptions[uiMeta.referenceEntity]) ||
              (extensions?.extensionOptions &&
                extensions?.extensionOptions[uiMeta.referenceEntity]))
          ) {
            return
          }
          addInputError(
            errors,
            node.id,
            intl.formatMessage({ id: 'validator.masterDataNotAvailable' }),
            colDef
          )
        } else {
          const err = uiMeta
            ? validator
                .validate(val, node.data, uiMeta, () => undefined)
                ?.getMessage()
            : undefined
          if (err) {
            addInputError(errors, node.id, err, colDef)
          }
        }
      })
      return errors
    },
    [getValidateValue, selectColumnOptions, extensions?.extensionOptions]
  )

  const getRowNumber = useCallback(
    (node: RowNode<TicketRow> | undefined) =>
      node?.rowIndex || node?.rowIndex === 0
        ? (node.rowIndex + 1).toString()
        : '',
    []
  )

  const submitSingleRow = useCallback(
    async (uuid: string): Promise<boolean | undefined> => {
      if (!gridApi.current) return
      const node: RowNode<TicketRow> | undefined =
        gridApi.current.getRowNode(uuid)
      if (!node) return
      const errors = validateNode(node)
      if (errors?.hasMessage()) {
        store.dispatch(
          addGlobalMessage({
            type: MessageLevel.WARN,
            title: intl.formatMessage({ id: 'global.warning.businessError' }),
            text: errors.toMessage(id => {
              const node = gridApi.current!.getRowNode(id)
              return getRowNumber(node)
            }),
          })
        )
        return
      }
      await saveSingleRowFunc.current?.(uuid)
      return true
    },
    [validateNode, getRowNumber]
  )

  const reloadSingleRow = useCallback((wbsItemUuid: string | undefined) => {
    if (!refreshSingleRowRef.current) return
    refreshSingleRowRef.current(wbsItemUuid)
  }, [])

  const recreateGrid = useCallback(() => {
    setInitializedGridOptions(true)
  }, [])

  const destroyGrid = useCallback(() => {
    setInitializedGridOptions(false)
    startRestoring()
    // Recreate after DOM rewriting is complete (setTimeout 0sec)
    setTimeout(() => recreateGrid(), 0)
  }, [recreateGrid, startRestoring])

  const gridOptions = useTicketGridOptions({
    projectUuid,
    ticketType,
    onClickedStatusCell,
    onClickedActualHourCell,
    extensions,
    addRow: onClickedActionCellAddRowButton,
    reloadSingleRow,
    submitSingleRow,
    context: selectColumnOptions,
    destroyGrid,
    wbsItemAdditionalProperties,
  })

  useEffect(
    () => {
      if (gridOptions && selectColumnOptions) {
        gridOptions.context = {
          ...gridOptions.context,
          ...selectColumnOptions,
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectColumnOptions]
  )

  const dragStyle = useDragStyle(gridOptions)

  const onRowDragEnd = useCallback(
    (event: RowDragEvent<TicketRow>) => {
      const movedIds = event.nodes.map(v => v.id)
      const overNode = event.overNode
      try {
        if (!overNode || movedIds.includes(overNode.id)) {
          return
        }
        const result = data.concat().filter(v => !movedIds.includes(v.uuid))
        const moved = data.concat().filter(v => movedIds.includes(v.uuid))
        // If moved toward down, move after overNode.
        const index =
          result.findIndex(v => v.uuid === overNode.id!) +
          (event.overIndex > (event.node.rowIndex || 0) ? 1 : 0)
        result.splice(index, 0, ...moved.map(v => ({ ...v, edited: true })))
        setData(result)
      } finally {
        // Clear drag cell style
        dragStyle.refreshDragStyle()
      }
    },
    [data, dragStyle, setData]
  )

  // Header action ----
  const onChangeTicketType = useCallback(
    (value?: string) => {
      const newType = value as TicketType
      if (ticketType === newType) return
      setTicketType(newType)
      const lastTicketListUuid = getLastSelectedTicketListUuid(
        newType,
        lastTicketListUuidMap
      )
      setTicketListUuid(lastTicketListUuid)
      startRestoring()
      clearData()
      clearExtensions()
      clearSelectColumnOptions()
    },
    [
      lastTicketListUuidMap,
      setTicketType,
      getLastSelectedTicketListUuid,
      setTicketListUuid,
      startRestoring,
      clearData,
      clearExtensions,
      clearSelectColumnOptions,
    ]
  )

  const onChangeTicketList = useCallback(
    (value: TicketListDetail | undefined) => {
      if (ticketListUuid !== value?.uuid) {
        setTicketListUuid(value?.uuid)
        clearExtensions()
      }
      if (ticketList?.uuid !== value?.uuid) {
        setTicketList(value)
      }
      if (ticketType) {
        lastTicketListUuidMap[ticketType] = value?.uuid
        setLastTicketListUuidMap(_.cloneDeep(lastTicketListUuidMap))
      }
    },
    [
      ticketType,
      ticketListUuid,
      ticketList,
      lastTicketListUuidMap,
      setTicketListUuid,
      setLastTicketListUuidMap,
      clearExtensions,
    ]
  )

  const refreshAll = useCallback(async () => {
    if (!gridOptions) return
    setLoading(true)
    try {
      const refreshRows = reload.current ?? refresh
      await refreshRows(projectUuid)
    } finally {
      gridOptions.context = {
        ...gridOptions.context,
        errors: new InputError(),
      }
      setLoading(false)
    }
  }, [projectUuid, gridOptions, refresh])

  const onReload = useCallback(() => {
    if (hasChanged()) {
      dialog.openCancelConfirmation()
    } else {
      refreshAll()
    }
  }, [dialog, hasChanged, refreshAll])

  const onChangeAggregateField = useCallback(
    (value: AggregateField) => {
      updatePageState({ aggregateField: value })
    },
    [updatePageState]
  )
  const onChangeWorkloadUnit = useCallback(
    (value: WorkloadUnit) => {
      updatePageState({ workloadUnit: value })
    },
    [updatePageState]
  )
  const onChangeDateFormat = useCallback(
    (value: string) => {
      updatePageState({ dateFormat: value })
    },
    [updatePageState]
  )
  const onChangeReportOpen = useCallback(
    (value: boolean) => {
      updatePageState({ isReportOpen: value })
    },
    [updatePageState]
  )
  const onChangeRowHeight = useCallback(
    (value: number) => {
      updatePageState({ rowHeight: value })
    },
    [updatePageState]
  )

  useEffect(() => {
    gridApi.current?.resetRowHeights()
  }, [rowHeight])

  const workloadUnitState = useWorkloadUnit(workloadUnit)
  useEffect(() => {
    if (!gridOptions) return
    gridOptions.context = {
      ...gridOptions.context,
      aggregateField,
      workloadUnit,
      dateFormat,
      workloadUnitState,
    }
    gridApi.current?.refreshCells({ force: true })
  }, [gridOptions, aggregateField, workloadUnit, dateFormat, workloadUnitState])

  // Filter / Sort action ---
  const onDeleteFilteredColumn = useCallback(
    (column: ColDef) => {
      if (!column || !gridApi.current) return
      const filterModel = gridApi.current.getFilterModel()
      delete filterModel[column.colId || column.field || '']
      gridApi.current.setFilterModel(filterModel)
    },
    [gridApi]
  )

  const resetFilters = useCallback(() => {
    gridApi.current?.setFilterModel([])
  }, [])

  const onDeleteSortedColumn = useCallback((colId: string | ColDef<any>) => {
    columnApi.current?.applyColumnState({
      state: [{ colId: colId.toString(), sort: null }],
    })
  }, [])

  const onDeleteSortedAllColumns = useCallback(() => {
    columnApi.current?.applyColumnState({
      defaultState: { sort: null },
    })
  }, [])

  const onChangeSortColumnState = useCallback(
    (colId: string | ColDef<any>, sort: 'asc' | 'desc' | null) => {
      columnApi.current?.applyColumnState({
        state: [{ colId: colId.toString(), sort }],
      })
    },
    []
  )

  // BulkSheet action ---
  const onFilterChanged = useCallback(
    (e: FilterChangedEvent) => {
      const filterModel = e.api?.getFilterModel()
      delete filterModel['uuid']
      saveFilterState(filterModel)
      setFilteredColumns(
        Object.keys(filterModel)
          .map(col => e.api.getColumnDef(col))
          .filter(v => !!v && v.field !== 'uuid') as ColDef[]
      )
    },
    [saveFilterState]
  )

  const rememberColumnState = useCallback(() => {
    const columnState = columnApi.current?.getColumnState()
    columnState && saveColumnState(columnState)
  }, [saveColumnState])

  const onSortedColumnsStateChanged = useCallback((e: SortChangedEvent) => {
    const sortedColumns = e.columnApi
      .getColumnState()
      .filter(col => !!col.sort)
      .map(col => {
        return {
          ...e.api.getColumnDef(col.colId),
          colId: col.colId,
        }
      })
      .filter(v => !!v) as ColDef[]
    e.api.setSuppressRowDrag(0 < sortedColumns.length)

    const columnState = columnApi.current?.getColumnState()
    const sortedColumnState: { [colId: string]: ColumnState } = {}
    columnState &&
      columnState.forEach(state => {
        if (state.sort) {
          sortedColumnState[state.colId] = state
        }
      })
    const sortedColumnsState: SortedColumnState[] = sortedColumns.map(col => {
      return {
        colId: col.colId,
        field: col.field,
        headerName: col.headerName,
        sort: col.colId ? sortedColumnState[col.colId]?.sort : null,
      }
    })

    setSortColumnsState(sortedColumnsState)
  }, [])

  const onSortChanged = useCallback(
    (e: SortChangedEvent) => {
      rememberColumnState()
      onSortedColumnsStateChanged(e)
    },
    [rememberColumnState, onSortedColumnsStateChanged]
  )

  const onFirstDataRendered = useCallback(
    e => {
      gridApi.current?.setFilterModel(filterState)
      finishRestoring()
      setLoading(false)
    },
    [filterState, finishRestoring]
  )

  const onColumnVisible = useCallback(
    (e: ColumnVisibleEvent) => {
      rememberColumnState()
      onSortedColumnsStateChanged(e)
    },
    [rememberColumnState, onSortedColumnsStateChanged]
  )

  const onGridReady = useCallback(
    (e: GridReadyEvent) => {
      gridApi.current = e.api
      columnApi.current = e.columnApi

      if (!_.isEmpty(columnState)) {
        e.columnApi.applyColumnState({
          state: columnState,
          applyOrder: true,
        })
      }
      setTimeout(() => setLoading(false), 2000)
    },
    [columnState]
  )

  const onRowDataUpdated = useCallback((e: RowDataUpdatedEvent<TicketRow>) => {
    if (!e.api) return
    const summaryRow = e.api.getPinnedTopRow(0)
    if (summaryRow) e.api.refreshCells({ rowNodes: [summaryRow], force: true })

    const refredhRows: string[] = [
      FIELD_WBSITEM_ACTUALDATE_START,
      FIELD_WBSITEM_ACTUALDATE_END,
    ]
    e.api.refreshCells({ columns: refredhRows, force: true })

    const nodes: RowNode<TicketRow>[] = []
    e.api.forEachNode((node: RowNode<TicketRow>) => nodes.push(node))
    setRowNodes(nodes)
  }, [])

  const validateBeforeSubmit = useCallback(
    (rows: TicketRow[]) => {
      if (!gridApi.current || !columnApi.current || !gridOptions) return
      if (!gridOptions?.context.errors) {
        gridOptions.context = {
          ...gridOptions.context,
          errors: new InputError(),
        }
      }
      const colIds = columnApi.current
        .getColumnState()
        .map(colState => colState.colId)
      rows
        .filter(row => row.added || row.edited)
        .forEach(row => {
          const node = gridApi.current!.getRowNode(row.uuid)
          if (!node) return
          validateNode(node, colIds, gridOptions.context.errors)
        })
    },
    [gridOptions, validateNode]
  )

  const onSubmit = useCallback(async () => {
    if (!gridOptions) return
    setLoading(true)
    try {
      gridApi.current?.stopEditing()
      validateBeforeSubmit(
        data.filter(
          (row: TicketRow) => !(row.added && !row.wbsItem?.displayName)
        )
      )
      if (gridOptions.context.errors?.hasMessage()) {
        store.dispatch(
          addGlobalMessage({
            type: MessageLevel.WARN,
            title: intl.formatMessage({ id: 'global.warning.businessError' }),
            text: gridOptions.context.errors.toMessage(id => {
              const node = gridApi.current?.getRowNode(id)
              return getRowNumber(node)
            }),
          })
        )
        return
      }

      saveImmediately()

      // Save
      const [response, sprintResponse] = submit.current
        ? await submit.current()
        : await save()

      if (response.hasWarning) {
        const messages = extractValuesFromResponse(response.json, 'messages')
        handleWarning(messages, uuid => {
          const target = data.find(v => v.wbsItem?.uuid === uuid)
          return target?.wbsItem?.displayName
        })
      }

      if (
        !response.hasError &&
        !response.hasWarning &&
        !sprintResponse.hasError
      ) {
        store.dispatch(
          addScreenMessage(functionUuid, {
            type: MessageLevel.SUCCESS,
            title: intl.formatMessage({ id: 'registration.complete' }),
          })
        )
        await refresh()
      }
    } finally {
      setLoading(false)
    }
  }, [
    data,
    gridOptions,
    functionUuid,
    save,
    refresh,
    validateBeforeSubmit,
    saveImmediately,
    getRowNumber,
  ])

  const onCellDoubleClicked = useCallback(
    () => statusPopper.close(),
    [statusPopper]
  )

  const onChangeColumnFilter = useCallback(() => {
    columnApi.current?.resetColumnState()
    const filterModel = gridApi.current?.getFilterModel()
    const quick = filterModel?.['uuid']
    gridApi.current?.setFilterModel(quick ? { uuid: quick } : null)
  }, [])

  const getRowHeight = useCallback(
    (params: RowHeightParams<TicketRow>): number => {
      const row: TicketRow | undefined = params.data
      if (!!row?.isTotal) return ROW_HEIGHT.SMALL
      return rowHeight
    },
    [rowHeight]
  )

  // Key bind ---
  const addRowForKeyBind = useCallback(() => {
    if (!gridApi?.current) return
    const selectedNodes: RowNode<TicketRow>[] = getSelectedNode(gridApi.current)
    const selectedRows = selectedNodes
      .map(v => v.data)
      .filter(v => v) as TicketRow[]
    if (selectedRows.length <= 0) return []
    const targetRow: TicketRow = selectedRows[selectedRows.length - 1]
    onAddRows(data, ticketList, targetRow.uuid, 1)
  }, [data, ticketList, onAddRows])

  useKeyBind(
    [
      {
        key: KEY_SAVE,
        fn: onSubmit,
        stopDefaultBehavior: true,
      },
      {
        key: 'alt+shift+l',
        fn: addRowForKeyBind,
      },
      {
        key: 'mod+shift+l',
        fn: addRowForKeyBind,
      },
      {
        key: 'alt+shift+d',
        fn: dialog.openDeleteConfirmation,
      },
    ],
    [data, save]
  )

  // Excel import / export
  const { onExcelExport, onExcelImport } = useExcel(
    gridOptions,
    data,
    dataManager.current,
    setLoading,
    setData,
    generateCode
  )

  const onClickExcelImport = useCallback(
    (raw: Uint8Array) => {
      if (!ticketType) return
      const defaultTicketList: TicketListBasic | undefined = ticketList?.wbsItem
        ? ({
            uuid: ticketList.uuid,
            code: ticketList.wbsItem.code,
            wbsItemUuid: ticketList.wbsItem.uuid,
            ticketType: ticketList.wbsItem.ticketType,
            projectUuid: ticketList.wbsItem.projectUuid,
            displayName: ticketList.wbsItem.displayName,
          } as TicketListBasic)
        : undefined
      onExcelImport(ticketType, defaultTicketList, raw)
    },
    [ticketType, ticketList, onExcelImport]
  )

  if (
    !searchConditionStateInited ||
    !columnAndFilterStateInited ||
    !fetchedWbsItemAdditionalProperties
  ) {
    return <></>
  }

  return (
    <PageArea>
      {functionLayer.size === 1 && (
        <SavePopper loading={loading} onSubmit={onSubmit} />
      )}
      <TicketListSelector
        ticketType={ticketType}
        onChangeTicketType={onChangeTicketType}
        ticketListUuid={ticketListUuid}
        onChangeTicketList={onChangeTicketList}
      />
      <TicketsReport
        aggregateField={aggregateField}
        workloadUnit={workloadUnit}
        rowNodes={rowNodes}
        projectUuid={projectUuid}
        ticketList={ticketList}
        open={isReportOpen}
        closeReport={() => updatePageState({ isReportOpen: false })}
      />
      <TicketsWbsHeader
        onChangeAggregateField={onChangeAggregateField}
        aggregateField={aggregateField}
        onChangeWorkloadUnit={onChangeWorkloadUnit}
        workloadUnit={workloadUnit}
        rowHeight={rowHeight}
        onChangeHeight={onChangeRowHeight}
        onClickImportExcel={onClickExcelImport}
        onClickExportExcel={dialog.openExcel}
        filteredColumns={filteredColumns}
        onDeleteFilteredColumn={onDeleteFilteredColumn}
        resetFilters={resetFilters}
        onDeleteSortedColumn={onDeleteSortedColumn}
        onDeleteSortedAllColumns={onDeleteSortedAllColumns}
        onChangeSortColumnState={onChangeSortColumnState}
        onReload={onReload}
        onClickColumnSettingButton={columnSetting.toggle}
        columnSettingOpen={columnSetting.isOpen}
        onClickFavoriteColumnFilterButton={dialog.openUiState}
        sortColumnsState={sortColumnsState}
        currentFormat={dateFormat}
        onChangeDateFormat={onChangeDateFormat}
        isReportOpen={isReportOpen}
        onChangeReportOpen={onChangeReportOpen}
      />
      <Loading isLoading={loading} elem={ref.current} />
      {initializedGridOptions && (
        // initializedGridOptions is to solve the problem that GridOptions does not switch.
        <BulkSheetView
          getContextMenuItems={contextMenu}
          getRowHeight={getRowHeight}
          gridOptions={gridOptions}
          onCellDoubleClicked={onCellDoubleClicked}
          onColumnMoved={rememberColumnState}
          onColumnResized={rememberColumnState}
          onColumnVisible={onColumnVisible}
          onFilterChanged={onFilterChanged}
          onFirstDataRendered={onFirstDataRendered}
          onGridReady={onGridReady}
          onRowDataUpdated={onRowDataUpdated}
          onRowDragEnter={dragStyle.onRowDragEnter}
          onRowDragMove={dragStyle.onRowDragMove}
          onRowDragEnd={onRowDragEnd}
          onRowDragLeave={dragStyle.refreshDragStyle}
          onSortChanged={onSortChanged}
          pinnedTopRowData={[SUMMARY_ROW_DATA]}
          ref={ref}
          rowData={data}
          rowHeight={rowHeight}
        />
      )}
      {dialog.cancelConfirmation && (
        <CancelConfirmDialog
          open={true}
          onConfirm={() => {
            dialog.closeCancelConfirmation()
            refreshAll()
          }}
          onClose={dialog.closeCancelConfirmation}
        />
      )}
      {dialog.actualResult && (
        <TaskActualWorkDialog
          open={true}
          taskUuid={dialog.taskUuid ?? ''}
          closeHandler={dialog.closeActualResult}
        />
      )}
      <ColumnSettingPopper
        anchorEl={columnSetting.anchorEl}
        open={columnSetting.isOpen}
        columnApi={columnApi.current ?? undefined}
        gridApi={gridApi.current ?? undefined}
        close={columnSetting.close}
        height={ref.current?.offsetHeight}
        openSavedUiStateDialog={dialog.openUiState}
        initializeColumnState={onChangeColumnFilter}
        applicationFunctionUuid={functionUuid}
        uiStateKey={UiStateKey.BulkSheetUIStateColumnAndFilter}
        columnState={columnState}
        offset={110}
      />
      {dialog.uiState && (
        <SavedUIStateDialog
          applicationFunctionUuid={functionUuid}
          open={true}
          title={intl.formatMessage({
            id: 'savedUIState.BULK_SHEET_UI_STATE_COLUMN_AND_FILTER',
          })}
          uiStateKey={UiStateKey.BulkSheetUIStateColumnAndFilter}
          sharable={false}
          currentUIState={{
            columnState: columnState,
            filterState: filterState,
          }}
          onSelect={(uiState: SavedUIState) => {
            const { columnState, filterState } = uiState.UIState
            if (columnState) {
              saveColumnState(columnState)
              columnApi.current?.applyColumnState({
                state: columnState,
                applyOrder: true,
              })
            }
            if (filterState) {
              saveFilterState(filterState)
              gridApi.current?.setFilterModel(filterState)
            }
            dialog.closeUiState()
          }}
          onClose={dialog.closeUiState}
        />
      )}
      {dialog.addMultipleRow && (
        <AddRowCountInputDialog
          open={true}
          title={intl.formatMessage({
            id: 'bulksheet.contextMenu.insert.multipleRow.title',
          })}
          submitHandler={(count: number | undefined) => {
            onAddRows(data, ticketList, dialog.prevSiblingUuid, count ?? 1)
            dialog.closeAddMultipleRow()
          }}
          closeHandler={dialog.closeAddMultipleRow}
        />
      )}
      {dialog.deleteConfirmation && gridApi.current && (
        <DeleteRowConfirmationDialog
          target={_.uniqBy(getSelectedNode(gridApi.current), 'data.uuid').map(
            (v: RowNode<TicketRow>) =>
              v.data?.wbsItem?.displayName ??
              `(${intl.formatMessage({ id: 'displayName.undefined' })})`
          )}
          onConfirm={onDeleteRows}
          onClose={dialog.closeDeleteConfirmation}
        />
      )}
      <StatusChangePopper
        anchorEl={statusPopper.anchorEl}
        projectUuid={projectUuid}
        wbsItem={statusPopper.wbsItem}
        wbsItemDelta={statusPopper.wbsItemDelta}
        onClose={statusPopper.close}
        onAfterUpdate={() => reloadSingleRow(statusPopper.wbsItem?.uuid)}
      />
      {dialog.excel && (
        <ExcelExportDialog
          onClose={dialog.closeExcel}
          onSubmit={onExcelExport}
          columnApi={columnApi.current}
        />
      )}
    </PageArea>
  )
}

export default projectPrivate(TicketsNew)
