import { List, Map } from 'immutable'
import { Epic, ofType } from 'redux-observable'
import { Observable, Subscriber, of } from 'rxjs'
import { map, mergeMap, filter, catchError } from 'rxjs/operators'
import API from '../lib/commons/api'
import Auth from '../lib/commons/auth'
import { UserBasic } from '../lib/functions/user'
import { generateUuid } from '../utils/uuids'
import { MessageLevel, addGlobalMessage } from './messages'
import { intl } from '../i18n'
import store from '.'
import { ErrorCode } from '../handlers/globalErrorHandler'

export enum MentionType {
  USER = 'USER',
  TEAM_MEMBER = 'TEAM_MEMBER',
  PROJECT_MEMBER = 'PROJECT_MEMBER',
}

export interface Mention {
  mentionType: MentionType
  uuid: string
}

export interface Comment {
  uuid: string
  text: string
  group?: string
  createdAt: number
  createdBy: UserBasic
}

export class CommentSummary {
  groupUuid: string = ''
  hasComment: boolean = false
  numberOfComments: number
  latestComment: Comment | undefined = undefined
}

export const commentListToSummary = (comments?: List<Comment>) => {
  return {
    groupUuid: comments?.first()?.group || '',
    hasComment: comments ? !comments.isEmpty() : false,
    numberOfComments: comments?.size,
    latestComment: !comments?.isEmpty()
      ? comments?.reduce(
          (last, current) => {
            return !last || last.createdAt < current.createdAt ? current : last
          },
          { createdAt: 0 } as Comment
        )
      : undefined,
  }
}

export const generateCommentDraftKey = (
  applicationFunctionUuid: string,
  group: string,
  suffix?: string,
  commentUuid?: string
) => {
  const tenant = Auth.getCurrentTenant()
  if (!tenant || !tenant.user) return ''
  const userUuid = tenant.user.uuid
  return `${applicationFunctionUuid}-${group}-${userUuid}-${suffix}-${
    commentUuid || ''
  }`
}

export const generateEditingCommentUuidKey = (
  applicationFunctionUuid: string,
  group: string,
  suffix?: string
) => {
  return `${applicationFunctionUuid}-${group}-${suffix}`
}

type State = {
  comments: Map<string, List<Comment>>
  drafts: Map<string, string>
  editingCommentUuids: Map<string, string>
}

// Actions
enum ActionType {
  // Action for comments
  FETCH_COMMENTS = 'FETCH_COMMENTS',
  SUBSCRIBE_COMMENTS = 'SUBSCRIBE_COMMENTS',
  UNSHIFT_COMMENTS = 'UNSHIFT_COMMENTS',
  UPDATE_COMMENTS = 'UPDATE_COMMENTS',
  REMOVE_COMMENTS = 'REMOVE_COMMENTS',
  PUSH_COMMENTS = 'PUSH_COMMENTS',
  POST_COMMENT = 'POST_COMMENT',
  POSTED_COMMENT = 'POSTED_COMMENT',
  EDIT_COMMENT = 'EDIT_COMMENT',
  EDITED_COMMENT = 'EDITED_COMMENT',
  DELETE_COMMENT = 'DELETE_COMMENT',
  DELETED_COMMENT = 'DELETED_COMMENT',
  // Action for drafts
  SAVE_DRAFT = 'SAVE_DRAFT',
  DELETE_DRAFT = 'DELETE_DRAFT',
  // Action for editComment
  START_EDIT_COMMENT = 'START_EDIT_COMMENT',
  FINISH_EDIT_COMMENT = 'FINISH_EDIT_COMMENT',
}

export const subscribeComments = (
  applicationFunctionUuid: string,
  group: string
) => ({
  type: ActionType.SUBSCRIBE_COMMENTS,
  applicationFunctionUuid,
  group,
})

export const fetchComments = (
  applicationFunctionUuid: string,
  group: string,
  offset: number
) => ({
  type: ActionType.FETCH_COMMENTS,
  applicationFunctionUuid,
  offset,
  group,
})

export const unshiftComments = (
  applicationFunctionUuid: string,
  group: string,
  comments: List<Comment>
) => ({
  type: ActionType.UNSHIFT_COMMENTS,
  applicationFunctionUuid,
  group,
  comments,
})

export const updateComments = (
  applicationFunctionUuid: string,
  group: string,
  comments: List<Comment>
) => ({
  type: ActionType.UPDATE_COMMENTS,
  applicationFunctionUuid,
  group,
  comments,
})

export const removeComments = (
  applicationFunctionUuid: string,
  group: string,
  comments: List<Comment>
) => ({
  type: ActionType.REMOVE_COMMENTS,
  applicationFunctionUuid,
  group,
  comments,
})

export const pushComments = (
  applicationFunctionUuid: string,
  group: string,
  offset: number,
  comments: List<Comment>
) => ({
  type: ActionType.PUSH_COMMENTS,
  applicationFunctionUuid,
  group,
  offset,
  comments,
})

export const postComment = (
  applicationFunctionUuid: string,
  group: string,
  text: string,
  mentions: Mention[]
) => ({
  type: ActionType.POST_COMMENT,
  applicationFunctionUuid,
  group,
  text,
  mentions,
})

export const editComment = (
  applicationFunctionUuid: string,
  group: string,
  comment: Comment,
  mentions: Mention[]
) => ({
  type: ActionType.EDIT_COMMENT,
  applicationFunctionUuid,
  group,
  comment,
  mentions,
})

export const deleteComment = (
  applicationFunctionUuid: string,
  group: string,
  uuid: string
) => ({
  type: ActionType.DELETE_COMMENT,
  applicationFunctionUuid,
  group,
  uuid,
})

export const postedComment = (
  applicationFunctionUuid: string,
  group: string,
  comment: Comment
) => ({
  type: ActionType.POSTED_COMMENT,
  applicationFunctionUuid,
  group,
  text: comment.text,
  comment,
  mentions: [],
})

export const editedComment = (
  applicationFunctionUuid: string,
  group: string,
  comment: Comment
) => ({
  type: ActionType.EDITED_COMMENT,
  applicationFunctionUuid,
  group,
  text: comment.text,
  uuid: comment.uuid,
  comment,
  mentions: [],
})

export const deletedComment = (
  applicationFunctionUuid: string,
  group: string,
  uuid: string
) => ({
  type: ActionType.DELETED_COMMENT,
  applicationFunctionUuid,
  group,
  uuid,
})

export const saveDraft = (
  applicationFunctionUuid: string,
  group: string,
  suffix: string | undefined,
  originalUuid: string | undefined,
  text: string
) => ({
  type: ActionType.SAVE_DRAFT,
  applicationFunctionUuid,
  group,
  suffix,
  originalUuid,
  text,
})

export const deleteDraft = (
  applicationFunctionUuid: string,
  group: string,
  suffix: string | undefined,
  originalUuid: string | undefined
) => ({
  type: ActionType.DELETE_DRAFT,
  applicationFunctionUuid,
  group,
  suffix,
  originalUuid,
})

export const startEditComment = (
  applicationFunctionUuid: string,
  group: string,
  suffix: string | undefined,
  commentUuid: string
) => ({
  type: ActionType.START_EDIT_COMMENT,
  applicationFunctionUuid,
  group,
  suffix,
  commentUuid,
})

export const finishEditComment = (
  applicationFunctionUuid: string,
  group: string,
  suffix: string | undefined,
  originalUuid: string | undefined
) => ({
  type: ActionType.FINISH_EDIT_COMMENT,
  applicationFunctionUuid,
  group,
  suffix,
  originalUuid,
})

// Epics
type CommentNotification = {
  applicationFunctionUuid: string
  comment: {
    group: string
    uuid: string
    text: string
    createdAt: number
    createdBy: UserBasic
  }
}
const subscribeNotification =
  (eventName: string) =>
  (action): Observable<CommentNotification> => {
    let subscriber: Subscriber<CommentNotification>
    const observable = new Observable<CommentNotification>(sub => {
      subscriber = sub
    })
    const tenant = Auth.getCurrentTenant()
    if (tenant) {
      API.notification.subscribe<Comment>(
        `${tenant.tenantUuid}/${eventName}/${action.applicationFunctionUuid}`,
        comment => {
          subscriber.next({
            applicationFunctionUuid: action.applicationFunctionUuid,
            comment: {
              group: action.group,
              ...comment,
            },
          })
        }
      )
    }
    return observable
  }

const subscribed = (
  notification: CommentNotification,
  state: {
    value: {
      comments: State
      singleSheet: {
        data?: any
      }
    }
  }
) =>
  Boolean(
    state.value.comments.comments.get(
      `${notification.applicationFunctionUuid}-${notification.comment.group}`
    )
  ) ||
  state.value.singleSheet.data[0]?.uuid === notification.comment.group ||
  state.value.singleSheet.data[0]?.wbsItem?.uuid === notification.comment.group

export const subscribeAddedCommentEpic: Epic<
  ReturnType<typeof subscribeComments>,
  ReturnType<typeof unshiftComments>
> = (action$, state$) =>
  action$.pipe(
    ofType(ActionType.SUBSCRIBE_COMMENTS),
    mergeMap(subscribeNotification('CommentAdded')),
    filter(notification => subscribed(notification, state$)),
    map(notification =>
      unshiftComments(
        notification.applicationFunctionUuid,
        notification.comment.group,
        List([notification.comment])
      )
    )
  )

export const subscribeUpdatedCommentEpic: Epic<
  ReturnType<typeof subscribeComments>,
  ReturnType<typeof updateComments>
> = (action$, state$) =>
  action$.pipe(
    ofType(ActionType.SUBSCRIBE_COMMENTS),
    mergeMap(subscribeNotification('CommentUpdated')),
    filter(notification => subscribed(notification, state$)),
    map(notification =>
      updateComments(
        notification.applicationFunctionUuid,
        notification.comment.group,
        List([notification.comment])
      )
    )
  )

export const subscribeDeletedCommentEpic: Epic<
  ReturnType<typeof subscribeComments>,
  ReturnType<typeof removeComments>
> = (action$, state$) =>
  action$.pipe(
    ofType(ActionType.SUBSCRIBE_COMMENTS),
    mergeMap(subscribeNotification('CommentDeleted')),
    filter(notification => subscribed(notification, state$)),
    map(notification =>
      removeComments(
        notification.applicationFunctionUuid,
        notification.comment.group,
        List([notification.comment])
      )
    )
  )

export const fetchCommentEpic: Epic<
  ReturnType<typeof fetchComments>,
  ReturnType<typeof pushComments>
> = action$ =>
  action$.pipe(
    ofType(ActionType.FETCH_COMMENTS),
    mergeMap(async action => {
      const query = {
        applicationFunctionUuid: action.applicationFunctionUuid,
        group: action.group,
        offset: action.offset,
        limit: 99,
      }
      const response = await API.presentational.request(
        'GET',
        '/api/v1/ui_comments',
        query
      )
      return { query, response }
    }),
    map(result => {
      let comments
      try {
        comments = List(result.response.json)
      } catch (e) {
        comments = []
      }
      return pushComments(
        result.query.applicationFunctionUuid,
        result.query.group,
        result.query.offset,
        comments
      )
    })
  )

export const postCommentEpic: Epic<
  ReturnType<typeof postComment>,
  ReturnType<typeof postedComment | any>
> = action$ =>
  action$.pipe(
    ofType(ActionType.POST_COMMENT),
    mergeMap(async action => {
      const comment = {
        uuid: generateUuid(),
        text: action.text,
        mentions: action.mentions,
        createdAt: new Date().getTime(),
        createdBy: Auth.getCurrentTenant()!.user!,
      }
      const response = await API.presentational.request(
        'POST',
        '/api/v1/ui_comments',
        {
          applicationFunctionUuid: action.applicationFunctionUuid,
          group: action.group,
          comment,
        }
      )
      return { action, comment, response }
    }),
    map(result =>
      postedComment(
        result.action.applicationFunctionUuid,
        result.action.group,
        result.comment
      )
    ),
    catchError(error => {
      if (error.code === ErrorCode.UNAUTHORIZED_SCOPE) {
        store.dispatch(
          addGlobalMessage({
            type: MessageLevel.WARN,
            code: error.errorCode,
            title: intl.formatMessage({ id: 'global.warning.unauthorized' }),
            text: intl.formatMessage({
              id: 'comment.warning.unauthorized.post',
            }),
          })
        )
        return of()
      }
      throw error
    })
  )

export const editCommentEpic: Epic<
  ReturnType<typeof editComment>,
  ReturnType<typeof editedComment | any>
> = action$ =>
  action$.pipe(
    ofType(ActionType.EDIT_COMMENT),
    mergeMap(async action => {
      const comment = {
        ...action.comment,
        mentions: action.mentions,
      }
      const response = await API.presentational.request(
        'PUT',
        '/api/v1/ui_comments',
        {
          applicationFunctionUuid: action.applicationFunctionUuid,
          group: action.group,
          comment,
        }
      )
      return { action, comment, response }
    }),
    map(result =>
      editedComment(
        result.action.applicationFunctionUuid,
        result.action.group,
        result.comment
      )
    ),
    catchError(error => {
      if (error.code === ErrorCode.UNAUTHORIZED_SCOPE) {
        store.dispatch(
          addGlobalMessage({
            type: MessageLevel.WARN,
            code: error.errorCode,
            title: intl.formatMessage({ id: 'global.warning.unauthorized' }),
            text: intl.formatMessage({
              id: 'comment.warning.unauthorized.edit',
            }),
          })
        )
        return of()
      }
      throw error
    })
  )

export const deleteCommentEpic: Epic<
  ReturnType<typeof deleteComment>,
  ReturnType<typeof deletedComment | any>
> = action$ =>
  action$.pipe(
    ofType(ActionType.DELETE_COMMENT),
    mergeMap(async action => {
      const response = await API.presentational.request(
        'DELETE',
        '/api/v1/ui_comments',
        {
          applicationFunctionUuid: action.applicationFunctionUuid,
          group: action.group,
          uuid: action.uuid,
        }
      )
      return { action, response }
    }),
    map(result =>
      deletedComment(
        result.action.applicationFunctionUuid,
        result.action.group,
        result.action.uuid
      )
    ),
    catchError(error => {
      if (error.code === ErrorCode.UNAUTHORIZED_SCOPE) {
        store.dispatch(
          addGlobalMessage({
            type: MessageLevel.WARN,
            code: error.errorCode,
            title: intl.formatMessage({ id: 'global.warning.unauthorized' }),
            text: intl.formatMessage({
              id: 'comment.warning.unauthorized.delete',
            }),
          })
        )
        return of()
      }
      throw error
    })
  )

// Reducers

const updateState = (
  state: State,
  applicationFunctionUuid: string,
  group: string,
  comments: List<Comment>,
  insertFirst: boolean
) =>
  state.comments.update(`${applicationFunctionUuid}-${group}`, List(), v => {
    comments.forEach(comment => {
      const index = v.findIndex(v => v.uuid === comment.uuid)
      if (index === -1) {
        v = v.insert(insertFirst ? 0 : v.size, comment)
      }
    })
    return v
  })

const editCommentState = (
  state: State,
  applicationFunctionUuid: string,
  group: string,
  comments: List<Comment>
) =>
  state.comments.update(`${applicationFunctionUuid}-${group}`, List(), v => {
    comments.forEach(comment => {
      const index = v.findIndex(v => v.uuid === comment.uuid)
      if (index !== -1) {
        v = v.set(index, comment)
      }
    })
    return v
  })

const deleteCommentState = (
  state: State,
  applicationFunctionUuid: string,
  group: string,
  uuids: List<string>
) =>
  state.comments.update(`${applicationFunctionUuid}-${group}`, List(), v => {
    uuids.forEach(uuid => {
      const index = v.findIndex(v => v.uuid === uuid)
      if (index !== -1) {
        v = v.remove(index)
      }
    })
    return v
  })

const saveDraftState = (
  state: State,
  applicationFunctionUuid: string,
  group: string,
  suffix: string | undefined,
  originalUuid: string | undefined,
  text: string
) =>
  state.drafts.set(
    generateCommentDraftKey(
      applicationFunctionUuid,
      group,
      suffix,
      originalUuid
    ),
    text
  )

const deleteDraftState = (
  state: State,
  applicationFunctionUuid: string,
  group: string,
  suffix: string | undefined,
  originalUuid: string | undefined
) =>
  state.drafts.delete(
    generateCommentDraftKey(
      applicationFunctionUuid,
      group,
      suffix,
      originalUuid
    )
  )

const startEditCommentState = (
  state: State,
  applicationFunctionUuid: string,
  group: string,
  suffix: string | undefined,
  commentUuid: string
) =>
  state.editingCommentUuids.set(
    generateEditingCommentUuidKey(applicationFunctionUuid, group, suffix),
    commentUuid
  )

const finishEditCommentState = (
  state: State,
  applicationFunctionUuid: string,
  group: string,
  suffix: string | undefined,
  originalUuid: string | undefined
) => {
  if (originalUuid) {
    return state.editingCommentUuids.delete(
      generateEditingCommentUuidKey(applicationFunctionUuid, group, suffix)
    )
  } else {
    return state.editingCommentUuids
  }
}

export const reducer = (
  state: State = { comments: Map(), drafts: Map(), editingCommentUuids: Map() },
  action: any
): State => {
  switch (action.type) {
    case ActionType.UNSHIFT_COMMENTS:
      return {
        ...state,
        comments: updateState(
          state,
          action.applicationFunctionUuid,
          action.group,
          action.comments.reverse(),
          true
        ),
      }
    case ActionType.PUSH_COMMENTS:
      return {
        ...state,
        comments: updateState(
          state,
          action.applicationFunctionUuid,
          action.group,
          action.comments,
          false
        ),
      }
    case ActionType.POSTED_COMMENT:
      return {
        ...state,
        comments: updateState(
          state,
          action.applicationFunctionUuid,
          action.group,
          List([action.comment]),
          true
        ),
      }
    case ActionType.UPDATE_COMMENTS:
      return {
        ...state,
        comments: editCommentState(
          state,
          action.applicationFunctionUuid,
          action.group,
          action.comments
        ),
      }
    case ActionType.EDITED_COMMENT:
      return {
        ...state,
        comments: editCommentState(
          state,
          action.applicationFunctionUuid,
          action.group,
          List([action.comment])
        ),
      }
    case ActionType.REMOVE_COMMENTS:
      return {
        ...state,
        comments: deleteCommentState(
          state,
          action.applicationFunctionUuid,
          action.group,
          action.comments.map(comment => comment.uuid)
        ),
      }
    case ActionType.DELETED_COMMENT:
      return {
        ...state,
        comments: deleteCommentState(
          state,
          action.applicationFunctionUuid,
          action.group,
          List([action.uuid])
        ),
      }
    case ActionType.SAVE_DRAFT:
      return {
        ...state,
        drafts: saveDraftState(
          state,
          action.applicationFunctionUuid,
          action.group,
          action.suffix,
          action.originalUuid,
          action.text
        ),
      }
    case ActionType.DELETE_DRAFT:
      return {
        ...state,
        drafts: deleteDraftState(
          state,
          action.applicationFunctionUuid,
          action.group,
          action.suffix,
          action.originalUuid
        ),
      }
    case ActionType.START_EDIT_COMMENT:
      return {
        ...state,
        editingCommentUuids: startEditCommentState(
          state,
          action.applicationFunctionUuid,
          action.group,
          action.suffix,
          action.commentUuid
        ),
      }
    case ActionType.FINISH_EDIT_COMMENT:
      return {
        ...state,
        drafts: deleteDraftState(
          state,
          action.applicationFunctionUuid,
          action.group,
          action.suffix,
          action.originalUuid
        ),
        editingCommentUuids: finishEditCommentState(
          state,
          action.applicationFunctionUuid,
          action.group,
          action.suffix,
          action.originalUuid
        ),
      }
    default:
      return state
  }
}
