import { styled } from '@mui/material'
import { colorPalette } from '../../../../style/colorPallete'
import { ValuePanel, ValuePanelProps } from './value-panel'
import {
  DataHandlerButtons,
  DataHandlerButtonsProps,
} from './DataHandlerButtons'
import { SubHeaderDivider } from './commons'
import TaskActualWorkRegistrationButton from './TaskActualWorkRegistrationButton'

type WbsItemSubHeaderProps = DataHandlerButtonsProps & ValuePanelProps
type TaskSubHeaderProps = DataHandlerButtonsProps &
  ValuePanelProps &
  TaskActualWorkRegistrationButtonProps

type TaskActualWorkRegistrationButtonProps = {
  isCreatingWbsItem: boolean
  onClickTaskActualWorkRegistrationButton: () => void
}

export const WbsItemSubHeader = ({
  submit,
  reload,
  submitIsActive,
  ...valuePanelProps
}: WbsItemSubHeaderProps) => {
  return (
    <SubHeaderRoot>
      <DataHandlerButtons
        submit={submit}
        reload={reload}
        submitIsActive={submitIsActive}
      />
      <SubHeaderDivider />
      <ValuePanel {...valuePanelProps} />
    </SubHeaderRoot>
  )
}

export const TaskSubHeader = ({
  submit,
  reload,
  submitIsActive,
  isCreatingWbsItem,
  onClickTaskActualWorkRegistrationButton,
  ...valuePanelProps
}: TaskSubHeaderProps) => {
  return (
    <SubHeaderRoot>
      <DataHandlerButtons
        submit={submit}
        reload={reload}
        submitIsActive={submitIsActive}
      />
      <SubHeaderDivider />
      {!isCreatingWbsItem && (
        <TaskActualWorkRegistrationButton
          onClick={onClickTaskActualWorkRegistrationButton}
        />
      )}
      <SubHeaderDivider />
      <ValuePanel {...valuePanelProps} />
    </SubHeaderRoot>
  )
}

const SubHeaderRoot = styled('div')({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  padding: '0 12px',
  backgroundColor: colorPalette.monotone[0],
  gap: '8px',
})
