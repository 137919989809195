import { useCallback, useMemo } from 'react'
import {
  WbsItemAdditionalPropertyType,
  EntitySearchReferenceEntity,
  toChangeLogPath as toWbsItemAdditionalPropertyChangeLogPath,
} from '../../../../domain/entity/WbsItemAdditionalPropertyEntity'
import { WbsItemAdditionalPropertyLayoutEntity } from '../../../../domain/entity/WbsItemAdditionalPropertyLayoutEntity'
import { ChangeLogValueParser } from '../model/changeLog'

type WbsItemAdditionalPropertyParsers = {
  [additionalPropertyUuid: string]: ChangeLogValueParser
}
export const useWbsItemAdditionalProperyValueChangeLogParser = (
  wbsItemAdditionalProperties:
    | WbsItemAdditionalPropertyLayoutEntity
    | undefined,
  booleanParser: ChangeLogValueParser,
  textParser: ChangeLogValueParser,
  multiLineTextParser: ChangeLogValueParser,
  dateParser: ChangeLogValueParser,
  dateTimeParser: ChangeLogValueParser,
  teamParser: ChangeLogValueParser,
  userParser: ChangeLogValueParser,
  wbsItemParser: ChangeLogValueParser,
  ticketParser: ChangeLogValueParser
): {
  isWbsItemAdditionalPropertyValueChangeLog: (path: string) => boolean
  wbsItemAdditionalPropertyValueParsers: WbsItemAdditionalPropertyParsers
} => {
  const isWbsItemAdditionalPropertyValueChangeLog = useCallback(
    (path: string) =>
      !!path.match(
        /additionalPropertyValues\.[a-z0-9]{8}\-[a-z0-9]{4}\-[a-z0-9]{4}\-[a-z0-9]{4}\-[a-z0-9]{12}/
      ),
    []
  )
  const wbsItemAdditionalPropertyValueParsers = useMemo(() => {
    if (!wbsItemAdditionalProperties) return {}

    const parsers: WbsItemAdditionalPropertyParsers = {}
    wbsItemAdditionalProperties.groupHeaderLayouts.forEach(
      ({ propertyLayouts }) => {
        propertyLayouts.forEach(({ wbsItemAdditionalProperty }) => {
          let parser: ChangeLogValueParser | undefined = undefined
          switch (wbsItemAdditionalProperty.propertyType) {
            case WbsItemAdditionalPropertyType.NUMBER:
            case WbsItemAdditionalPropertyType.TEXT:
              parser = textParser
              break
            case WbsItemAdditionalPropertyType.MULTI_LINE_TEXT:
              parser = multiLineTextParser
              break
            case WbsItemAdditionalPropertyType.DATE:
              parser = dateParser
              break
            case WbsItemAdditionalPropertyType.DATE_TIME:
              parser = dateTimeParser
              break
            case WbsItemAdditionalPropertyType.CHECKBOX:
              parser = booleanParser
              break
            case WbsItemAdditionalPropertyType.SELECT:
            case WbsItemAdditionalPropertyType.MULTI_SELECT:
              parser = textParser
              break
            case WbsItemAdditionalPropertyType.ENTITY_SEARCH: {
              switch (wbsItemAdditionalProperty.entitySearchReferenceEntity) {
                case EntitySearchReferenceEntity.PROCESS:
                case EntitySearchReferenceEntity.DELIVERABLE_LIST:
                case EntitySearchReferenceEntity.DELIVERABLE:
                case EntitySearchReferenceEntity.TASK:
                  parser = wbsItemParser
                  break
                case EntitySearchReferenceEntity.REFINEMENT:
                case EntitySearchReferenceEntity.ISSUE:
                  parser = ticketParser
                  break
                case EntitySearchReferenceEntity.TEAM:
                  parser = teamParser
                  break
                case EntitySearchReferenceEntity.PROJECT_MEMBER:
                  parser = userParser
                  break
              }
            }
          }
          if (parser) {
            parsers[
              toWbsItemAdditionalPropertyChangeLogPath(
                wbsItemAdditionalProperty
              )
            ] = parser
          }
        })
      }
    )
    return parsers
  }, [
    wbsItemAdditionalProperties,
    booleanParser,
    textParser,
    multiLineTextParser,
    dateParser,
    dateTimeParser,
    teamParser,
    userParser,
    wbsItemParser,
    ticketParser,
  ])

  return {
    isWbsItemAdditionalPropertyValueChangeLog,
    wbsItemAdditionalPropertyValueParsers,
  }
}
