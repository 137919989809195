import { useCallback, useMemo } from 'react'
import { WbsItemAdditionalPropertyType } from '../../../../domain/entity/WbsItemAdditionalPropertyEntity'
import { ChangeLogValueParser } from '../model/changeLog'
import { WbsItemEntityExtensionProperty } from '../model/entityExtension'

type EntityExtensionValueChangeLogParsers = {
  [entityExtensionUuid: string]: ChangeLogValueParser
}
export const useEntityExtensionValueChangeLogParser = (
  entityExtensionProperties: WbsItemEntityExtensionProperty[] | undefined,
  booleanParser: ChangeLogValueParser,
  textParser: ChangeLogValueParser,
  multiLineTextParser: ChangeLogValueParser,
  dateParser: ChangeLogValueParser,
  dateTimeParser: ChangeLogValueParser,
  wbsItemParser: ChangeLogValueParser,
  ticketParser: ChangeLogValueParser
): {
  isEntityExtensionValueChangeLog: (path: string) => boolean
  entityExtensionValueChangeLogParsers: EntityExtensionValueChangeLogParsers
} => {
  const isEntityExtensionValueChangeLog = useCallback(
    (path: string) => !!path.startsWith('extension.'),
    []
  )
  const entityExtensionValueChangeLogParsers = useMemo(() => {
    if (!entityExtensionProperties) return {}

    const parsers: EntityExtensionValueChangeLogParsers = {}
    entityExtensionProperties.forEach(entityExtensionProperty => {
      let parser: ChangeLogValueParser | undefined = undefined
      switch (entityExtensionProperty.propertyType) {
        case WbsItemAdditionalPropertyType.NUMBER:
        case WbsItemAdditionalPropertyType.TEXT:
          parser = textParser
          break
        case WbsItemAdditionalPropertyType.MULTI_LINE_TEXT:
          parser = multiLineTextParser
          break
        case WbsItemAdditionalPropertyType.DATE:
          parser = dateParser
          break
        case WbsItemAdditionalPropertyType.DATE_TIME:
          parser = dateTimeParser
          break
        case WbsItemAdditionalPropertyType.CHECKBOX:
          parser = booleanParser
          break
        case WbsItemAdditionalPropertyType.SELECT:
        case WbsItemAdditionalPropertyType.MULTI_SELECT:
          parser = entityExtensionProperty.valuesAllowed
            ? (v: string | undefined) =>
                entityExtensionProperty.valuesAllowed.find(
                  option => option.value === v
                )?.name
            : undefined
          break
        case WbsItemAdditionalPropertyType.ENTITY_SEARCH: {
          switch (entityExtensionProperty.referenceEntity) {
            case 'Process':
            case 'Deliverable':
              parser = wbsItemParser
              break
            case 'Ticket':
              parser = ticketParser
              break
          }
        }
      }
      if (parser) {
        parsers[`extension.${entityExtensionProperty.externalId}`] = parser
      }
    })
    return parsers
  }, [
    entityExtensionProperties,
    booleanParser,
    textParser,
    multiLineTextParser,
    dateParser,
    dateTimeParser,
    wbsItemParser,
    ticketParser,
  ])

  return {
    isEntityExtensionValueChangeLog,
    entityExtensionValueChangeLogParsers,
  }
}
