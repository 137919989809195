import { InputBase, styled } from '@mui/material'
import { CellPropsBase, CellTitle, CellValueArea, FormCell } from '.'
import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react'
import { useDebounce } from '../../../../hooks/useDebounce'
import { Switch } from '../../../../components/inputs/Switch'

type ToggleCellProps = {
  value: boolean | undefined
  onChange?: (v: boolean | undefined) => void
} & CellPropsBase
export const ToggleCell = ({
  label,
  cellWidth,
  value,
  onChange: _onChange,
  editable = true,
  required = false,
  validationErrors,
}: ToggleCellProps) => {
  const changed = useRef(false)
  const [checked, setChecked] = useState(value)
  const onChange = useCallback((_, newValue: boolean) => {
    changed.current = true
    setChecked(newValue)
  }, [])
  const debouncedChecked = useDebounce(checked, 300)
  useEffect(() => {
    !changed.current && setChecked(value)
  }, [value])
  useEffect(() => {
    _onChange && changed.current && _onChange(debouncedChecked)
  }, [_onChange, debouncedChecked])

  return (
    <FormCell cellWidth={cellWidth}>
      <CellTitle title={label} required={required} />
      <CellValueArea validationErros={validationErrors}>
        <Switch checked={checked} onChange={onChange} disabled={!editable} />
      </CellValueArea>
    </FormCell>
  )
}
