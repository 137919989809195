import { useCallback } from 'react'
import api from '../../lib/commons/api'
import { TimerRepository } from '../../applications/ports/timerRepository'
import {
  MeasuringTimer,
  TimerMeasuringTaskOfUser,
} from '../../domain/entity/TimerEntity'

export const useTimerRepository = (): TimerRepository => {
  const start = useCallback(async (taskUuid: string) => {
    await api.functional.request('POST', '/api/v1/projects/timer/start', {
      taskUuid,
    })
  }, [])

  const stop = useCallback(async (taskUuid: string) => {
    await api.functional.request('PUT', '/api/v1/projects/timer/stop', {
      taskUuid,
    })
  }, [])

  const stopMemberTimer = useCallback(
    async (userUuid: string, taskUuid: string) => {
      await api.functional.request('PUT', '/api/v1/projects/timer/admin/stop', {
        userUuid,
        taskUuid,
      })
    },
    []
  )

  const getTimerMeasuringTaskList = useCallback(
    async (projectUuid: string): Promise<TimerMeasuringTaskOfUser[]> => {
      const response = await api.functional.request(
        'GET',
        '/api/v1/projects/timer/measuring/list/project',
        { projectUuid: projectUuid }
      )
      return response.json
    },
    []
  )

  return {
    start,
    stop,
    stopMemberTimer,
    getTimerMeasuringTaskList,
  }
}

export const getMeasuringTimer = async (): Promise<MeasuringTimer> => {
  const response = await api.functional.request(
    'GET',
    '/api/v1/projects/timer/measuring/me'
  )
  return response.json
}
