import React, { useCallback, useState, useRef, useEffect, useMemo } from 'react'
import { Popper, InputBase, IconButton, Avatar, Tooltip } from '@mui/material'
import { styled, alpha } from '@mui/material/styles'
import SendIcon from '@mui/icons-material/Send'
import ListAltIcon from '@mui/icons-material/ListAlt'
import SmartToyIcon from '@mui/icons-material/SmartToy'
import {
  aiScheduling,
  AiSchedulingOutput,
  AiWbsItemSchdulingOutput,
  AiTaskType,
  defaultConverse,
  determineAiTaskType,
  taskDecomposition,
} from '../../../../lib/functions/ai'
import {
  fetchProjectPlanBody,
  ProjectPlanNewRow,
  ProjectPlanRowBody,
} from '../projectPlanNew'
import { addRowsToLastChild } from '../../../containers/BulkSheetView/hooks/actions/crudTreeRows'
import { generateUuid } from '../../../../utils/uuids'
import {
  WbsItemStatus,
  WbsItemType,
} from '../../../../domain/entity/WbsItemEntity'
import { ProjectPlanCumulation } from '../../../../lib/functions/projectPlan'
import store from '../../../../store'
import RefreshIcon from '@mui/icons-material/Refresh'
import EditCalendarRoundedIcon from '@mui/icons-material/EditCalendarRounded'
import { colorPalette } from '../../../style/colorPallete'
import {
  getChildren,
  getParentUuid,
} from '../../../containers/BulkSheetView/lib/tree'
import { ProjectMemberProps } from '../../../../lib/functions/projectMember'
import { useProjectPrivateContext } from '../../../context/projectContext'
import { useWbsItemCodeService } from '../../../../services/adaptors/wbsItemCodeServiceAdaptor'

type AiChatProps = {
  data: ProjectPlanNewRow[]
  setData: (data: ProjectPlanNewRow[]) => void
  setProjectPlanBody: (
    row: ProjectPlanNewRow,
    body: ProjectPlanRowBody
  ) => ProjectPlanNewRow
}

interface ChatMessage {
  text: string
  isUser: boolean
  isAiTaskRequest?: boolean
}

interface TaskInput {
  wbsItemTitle: string
  wbsItemDescription: string
  prompt: string
  history: string
}

interface TaskOutput {
  answer: string
  tasks: string[]
}

const StyledPopper = styled(Popper)<{ width: number }>(({ theme, width }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[4],
  padding: theme.spacing(2),
  width: width,
  maxWidth: '90vw',
  height: '80vh',
  display: 'flex',
  flexDirection: 'column',
  zIndex: 10,
  gap: '16px',
}))

const ChatHistoryArea = styled('div')({
  flexGrow: 1,
  overflowY: 'auto',
  marginBottom: 16,
  padding: 16,
  backgroundColor: 'transparent',
})

const ChatInput = styled(InputBase)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderRadius: 4,
  padding: '8px 12px',
  border: `1px solid ${theme.palette.divider}`,
  '&:hover': {
    borderColor: theme.palette.text.secondary,
  },
  '&.Mui-focused': {
    borderColor: theme.palette.primary.main,
    boxShadow: `0 0 0 2px ${alpha(theme.palette.primary.main, 0.2)}`,
  },
}))

const MessageContainer = styled('div')<{ isUser: boolean }>(({ isUser }) => ({
  display: 'flex',
  justifyContent: isUser ? 'flex-end' : 'flex-start',
  alignItems: 'flex-start',
  marginBottom: 8,
}))

const MessageBubble = styled('div')<{
  isUser: boolean
  isAiTaskRequest?: boolean
}>(({ theme, isUser, isAiTaskRequest }) => ({
  backgroundColor: isUser
    ? isAiTaskRequest
      ? alpha(theme.palette.error.light, 0.2)
      : '#e3f2fd'
    : '#f5f5f5',
  borderRadius: 12,
  padding: '8px 12px',
  maxWidth: '80%',
  marginLeft: isUser ? 0 : 8,
  marginRight: isUser ? 8 : 0,
  whiteSpace: 'pre-wrap',
}))

const AiAvatar = styled(Avatar)(({ theme }) => ({
  backgroundColor: colorPalette.skyBlue[7],
  width: 32,
  height: 32,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

const SendButton = styled(IconButton)(({ theme }) => ({
  '&.Mui-disabled': {
    color: theme.palette.action.disabled,
  },
}))

const TaskDecompositionButton = styled(IconButton)(({ theme }) => ({
  borderRadius: 4,
  backgroundColor: 'transparent',
  '&:hover': {
    backgroundColor: alpha(theme.palette.secondary.main, 0.1),
  },
}))

const LoadingBubble = styled('div')({
  display: 'inline-block',
  width: '20px',
  height: '20px',
  border: '2px solid #f3f3f3',
  borderTop: '2px solid #3498db',
  borderRadius: '50%',
  animation: 'spin 1s linear infinite',
  '@keyframes spin': {
    '0%': { transform: 'rotate(0deg)' },
    '100%': { transform: 'rotate(360deg)' },
  },
})

const LoadingMessageContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
})
const LoadingMessage = ({ type }: { type: AiTaskType | undefined }) => {
  const message = useMemo(() => {
    if (!type) return 'AI処理中'
    switch (type) {
      case 'WBS_DECOMPOSITION':
        return 'タスク分解中...'
      case 'AI_SCHEDULING':
        return 'スケジューリング中...'
    }
  }, [type])

  return (
    <MessageContainer isUser={false}>
      <AiAvatar>
        <SmartToyIcon fontSize="small" />
      </AiAvatar>
      <MessageBubble isUser={false}>
        <LoadingMessageContainer>
          {message} <LoadingBubble />
        </LoadingMessageContainer>
      </MessageBubble>
    </MessageContainer>
  )
}

export const AiChat = ({ data, setData, setProjectPlanBody }: AiChatProps) => {
  const [open, setOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [messages, setMessages] = useState<ChatMessage[]>([])
  const addMessage = useCallback((message: ChatMessage) => {
    setMessages(prev => [...prev, message])
  }, [])
  const [inputValue, setInputValue] = useState('')
  const [popperWidth, setPopperWidth] = useState(0)
  const anchorRef = useRef<HTMLDivElement>(null)
  const chatHistoryRef = useRef<HTMLDivElement>(null)
  const [currentAiTask, setCurrentAiTask] = useState<AiTaskType | undefined>(
    undefined
  )

  const { generateCode } = useWbsItemCodeService()

  useEffect(() => {
    const updateWidth = () => {
      if (anchorRef.current) {
        const parentWidth = anchorRef.current.offsetWidth
        setPopperWidth(Math.max(600, parentWidth * 0.8))
      }
    }

    updateWidth()
    window.addEventListener('resize', updateWidth)

    return () => window.removeEventListener('resize', updateWidth)
  }, [])

  useEffect(() => {
    if (!open) return
    setMessages(prev => {
      if (prev.length === 0) {
        return [
          {
            text: 'こんにちは！何かお手伝いすることがあればお聞かせください。',
            isUser: false,
          },
        ]
      }
      return prev
    })
  }, [open])

  const handleClick = () => {
    setOpen(prevOpen => !prevOpen)
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value)
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      if (event.ctrlKey || event.metaKey) {
        handleTask()
      } else {
        handleSendMessage()
      }
    }
  }

  const handleRefresh = () => {
    setMessages([
      {
        text: 'こんにちは！何かお手伝いすることがあればお聞かせください。',
        isUser: false,
      },
    ])
    setInputValue('')
  }

  const registerResponseToProjectPlan = useCallback(
    (aiResponse: any) => {
      const wbsItemTypes = store.getState().project.wbsItemTypes.getAll()
      const convertedData: ProjectPlanNewRow[] = []
      // 取得データ変換関数
      const convertRootTreeObjectToRowData = (
        rootTreeObject: any,
        parentTreeValue?: string[]
      ) => {
        // まず、根っことなるwbsItemのオブジェクトをRowDataに変換
        const localParentTreeValue = parentTreeValue ? parentTreeValue : []
        const uuid = generateUuid()
        const wbsItemUuid = generateUuid()
        const row: ProjectPlanNewRow = {
          uuid: uuid,
          treeValue: [...localParentTreeValue, uuid],
          type: rootTreeObject.wbsItem.type,
          wbsItemUuid: wbsItemUuid,
          body: {
            wbsItem: {
              uuid: wbsItemUuid,
              code: generateCode(),
              type: rootTreeObject.wbsItem.type,
              wbsItemType: wbsItemTypes.find(
                type => type.rootType === rootTreeObject.wbsItem.type
              )!,
              baseWbsItemType: wbsItemTypes.find(
                type => type.name === rootTreeObject.wbsItem.type
              )!,
              displayName: rootTreeObject.wbsItem.displayName,
              status: WbsItemStatus.TODO,
              scheduledDate: {
                startDate: rootTreeObject.wbsItem.scheduledDate?.startDate,
                endDate: rootTreeObject.wbsItem.scheduledDate?.endDate,
              },
              actualDate: { startDate: undefined, endDate: undefined },
            },
            cumulation: new ProjectPlanCumulation(),
          },
        }
        convertedData.push(row)

        // 次に、APIResponseがchildrenを持っている場合、children一つ一つに対して、同じ処理を行う
        if (rootTreeObject.children.length > 0) {
          rootTreeObject.children.forEach(child => {
            convertRootTreeObjectToRowData(child, [
              ...localParentTreeValue,
              uuid,
            ])
          })
        }
        return
      }
      const treeRootUuid = window.location.search.replace('?treeRootUuid=', '')
      aiResponse.children.forEach(child => {
        convertRootTreeObjectToRowData(child, [treeRootUuid])
      })
      const newData = addRowsToLastChild(data, convertedData, treeRootUuid)
      setData(newData)
    },
    [data, setData]
  )

  const { projectUuid } = useProjectPrivateContext()
  const requestTaskDecomposition = useCallback(async () => {
    setIsLoading(true)
    setCurrentAiTask('WBS_DECOMPOSITION')
    const response = await taskDecomposition({
      projectUuid,
      wbsItemUuid: data[0].body?.wbsItem.uuid || '',
      wbsItemTitle: data[0].body?.wbsItem.displayName ?? '',
      wbsItemDescription: data[0].body?.wbsItem.description ?? '',
      prompt: inputValue.trim(),
      history: messages
        .map(m => `${m.isUser ? 'Human' : 'Assistant'}: ${m.text}`)
        .join('\n'),
      startDate: data[0].body?.wbsItem.scheduledDate?.startDate,
      endDate: data[0].body?.wbsItem.scheduledDate?.endDate,
      type: data[0].body?.wbsItem.type,
    })
    if (response && response.json) {
      try {
        const result = response.json
        if (result.answerText) {
          addMessage({ text: result.answerText, isUser: false })
        }
        registerResponseToProjectPlan(result.wbsNode)
      } catch (jsonError) {
        console.error('JSON parse error:', jsonError)
        addMessage({
          text: `JSONのパースに失敗しました。元のレスポンス:\n${response.json.result}`,
          isUser: false,
        })
      }
    } else {
      throw new Error('Invalid API response')
    }
    setIsLoading(false)
  }, [addMessage, data, inputValue, messages, registerResponseToProjectPlan])

  const handleTaskDecomposition = useCallback(() => {
    if (isLoading) return
    const decompositionMessage = {
      text: 'タスク分解',
      isUser: true,
      isAiTaskRequest: true,
    }
    addMessage(decompositionMessage)
    requestTaskDecomposition()
  }, [addMessage, isLoading, requestTaskDecomposition])

  const scheduleWbsItemByAI = useCallback(
    (row: ProjectPlanNewRow, aiSchedulingOutput: AiWbsItemSchdulingOutput) => {
      return {
        ...row,
        edited: row.added ? undefined : true,
        body: {
          ...row.body,
          wbsItem: {
            ...row.body?.wbsItem!,
            // wbsItemType: row.body?.wbsItem.wbsItemType!,
            // baseWbsItemType:
            //   row.body?.wbsItem.baseWbsItemType!,
            scheduledDate: aiSchedulingOutput.schedule,
            // actualDate: row.body?.wbsItem.actualDate,
          },
          cumulation: row.body?.cumulation,
        },
      }
    },
    []
  )
  const scheduleChildWbsItemByAI = useCallback(
    (
      row: ProjectPlanNewRow,
      parentWbsItemAiSchedulingOutput: AiWbsItemSchdulingOutput,
      parentAssignee: ProjectMemberProps | undefined,
      projectPlanBodyMapByWbsItemUuid: Record<string, ProjectPlanRowBody>
    ): ProjectPlanNewRow => {
      const aiSchedulingOutput =
        parentWbsItemAiSchedulingOutput.children.shift()
      if (!aiSchedulingOutput) return row
      let originalRow = {
        ...row,
      }
      if (!row.body) {
        const fetchedProjectPlanBody =
          projectPlanBodyMapByWbsItemUuid[row.wbsItemUuid]
        if (!fetchedProjectPlanBody) return row
        originalRow = setProjectPlanBody(originalRow, fetchedProjectPlanBody)
      }
      return {
        ...originalRow,
        edited: originalRow.added ? undefined : true,
        body: {
          ...originalRow.body,
          wbsItem: {
            ...originalRow.body?.wbsItem,
            responsible:
              originalRow.body?.wbsItem.responsible || parentAssignee,
            assignee: originalRow.body?.wbsItem.assignee || parentAssignee,
            estimatedHour: aiSchedulingOutput.estimatedHour,
            scheduledDate: aiSchedulingOutput.schedule,
          },
          cumulation: originalRow.body?.cumulation,
        },
      } as ProjectPlanNewRow
    },
    [setProjectPlanBody]
  )
  const requestAiScheduling = useCallback(async () => {
    setIsLoading(true)
    let result: AiSchedulingOutput | undefined = undefined
    const parentAssigneeMapByRowId: Record<string, ProjectMemberProps> = {}
    const projectPlanBodyMapByWbsItemUuid: Record<string, ProjectPlanRowBody> =
      {}
    try {
      const fetchBodyProjectPlanUuids: string[] = []
      const aiSchedulingInput = {
        scheduledDateTermOfParent: {
          startDate: data[0].body?.wbsItem.scheduledDate?.startDate,
          endDate: data[0].body?.wbsItem.scheduledDate?.endDate,
        },
        // TODO: wbsItemsはrootの種類によって何を入れるか変える。今はrootが工程の前提で実装している
        wbsItems: data
          .filter(
            row =>
              row.body?.wbsItem.type === WbsItemType.DELIVERABLE &&
              row.body?.wbsItem.assignee
          )
          .map(row => {
            parentAssigneeMapByRowId[row.uuid] = row.body?.wbsItem.assignee!
            const children = getChildren(data, row)
            children.forEach(child => {
              if (child.body) return
              fetchBodyProjectPlanUuids.push(child.uuid)
            })
            return {
              treeUuid: row.uuid,
              estimatedHour: row.body?.wbsItem.estimatedHour,
              assigneeName: row.body?.wbsItem.assignee?.name,
              childCount: children.length,
            }
          }),
      }
      const [aiSchedulingOutput, fetchedProjectPlanBodies] = await Promise.all([
        aiScheduling(aiSchedulingInput),
        fetchBodyProjectPlanUuids.length > 0
          ? fetchProjectPlanBody(fetchBodyProjectPlanUuids).then(
              res => res.json
            )
          : Promise.resolve([]),
      ])
      result = aiSchedulingOutput
      fetchedProjectPlanBodies.forEach(fetchedBody => {
        projectPlanBodyMapByWbsItemUuid[fetchedBody.wbsItem.uuid] =
          new ProjectPlanRowBody(fetchedBody)
      })
    } catch (jsonError) {
      console.error('JSON parse error:', jsonError)
      addMessage({
        text: `JSONのパースに失敗しました。元のレスポンス:\n${result}`,
        isUser: false,
      })
      return
    }
    if (!result) {
      throw new Error('Invalid API response')
    }
    if (result.answerText) {
      addMessage({ text: result.answerText, isUser: false })
    }

    const aiSchedulingOutputMap: Record<string, AiWbsItemSchdulingOutput> = {}
    result.output.forEach(v => {
      aiSchedulingOutputMap[v.treeUuid] = v
    })
    const newData: ProjectPlanNewRow[] = data.map(row => {
      const aiOutput = aiSchedulingOutputMap[row.uuid]
      if (aiOutput) return scheduleWbsItemByAI(row, aiOutput)

      const parentUuid = getParentUuid(row)
      if (!parentUuid) return row
      const parentWbsItemAiOutput = aiSchedulingOutputMap[parentUuid]
      if (parentWbsItemAiOutput) {
        return scheduleChildWbsItemByAI(
          row,
          parentWbsItemAiOutput,
          parentAssigneeMapByRowId[parentUuid],
          projectPlanBodyMapByWbsItemUuid
        )
      }

      return row
    })
    setData(newData)
    setIsLoading(false)
  }, [addMessage, data, setData, scheduleWbsItemByAI, scheduleChildWbsItemByAI])

  const handleAiScheduling = useCallback(() => {
    if (isLoading) return
    setCurrentAiTask('AI_SCHEDULING')
    const message = {
      text: 'AIスケジューリング',
      isUser: true,
      isAiTaskRequest: true,
    }
    addMessage(message)
    requestAiScheduling()
  }, [addMessage, isLoading, requestAiScheduling])

  const handleTask = useCallback(async () => {
    if (isLoading) return
    const messageText = inputValue.trim()
    if (!messageText) return

    const newMessage: ChatMessage = {
      text: messageText,
      isUser: true,
      isAiTaskRequest: true,
    }
    addMessage(newMessage)

    setInputValue('')
    setIsLoading(true)
    try {
      const taskType = await determineAiTaskType(messageText)
      if (!taskType) {
        addMessage({
          text: 'AIが実行できるタスクではありません。',
          isUser: false,
          isAiTaskRequest: false,
        })
        setIsLoading(false)
        return
      }
      switch (taskType) {
        case 'WBS_DECOMPOSITION':
          requestTaskDecomposition()
          break
        case 'AI_SCHEDULING':
          requestAiScheduling()
          break
      }
    } finally {
      setIsLoading(false)
    }
  }, [
    addMessage,
    inputValue,
    isLoading,
    requestAiScheduling,
    requestTaskDecomposition,
  ])

  const handleSendMessage = async () => {
    if (isLoading) return
    const messageText = inputValue.trim()
    if (!messageText) return

    const newMessage: ChatMessage = {
      text: messageText,
      isUser: true,
      isAiTaskRequest: false,
    }
    addMessage(newMessage)

    setInputValue('')
    setIsLoading(true)

    try {
      const response = await defaultConverse({
        prompt: inputValue.trim(),
        history: messages
          .map(m => `${m.isUser ? 'Human' : 'Assistant'}: ${m.text}`)
          .join('\n'),
      })

      const aiResponse: ChatMessage = {
        text: response.json.result,
        isUser: false,
      }
      addMessage(aiResponse)
    } catch (error) {
      console.error('Error in AI processing:', error)
      const errorResponse: ChatMessage = {
        text: `申し訳ありません。エラーが発生しました。\nエラー詳細: ${
          error instanceof Error ? error.message : '不明なエラー'
        }\n\nもう一度お試しください。`,
        isUser: false,
      }
      addMessage(errorResponse)
    } finally {
      setIsLoading(false)
    }
  }
  useEffect(() => {
    if (chatHistoryRef.current) {
      chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight
    }
  }, [messages])

  return (
    <div ref={anchorRef} style={{ width: '100%' }}>
      <IconButton onClick={handleClick} size="small">
        <AiAvatar>
          <SmartToyIcon fontSize="small" />
        </AiAvatar>
      </IconButton>
      <StyledPopper
        open={open}
        anchorEl={anchorRef.current}
        placement="bottom-start"
        width={popperWidth}
      >
        <ChatHistoryArea ref={chatHistoryRef}>
          {messages.map((message, index) => (
            <MessageContainer key={`message-${index}`} isUser={message.isUser}>
              {!message.isUser && (
                <AiAvatar>
                  <SmartToyIcon fontSize="small" />
                </AiAvatar>
              )}
              <MessageBubble
                isUser={message.isUser}
                isAiTaskRequest={message.isAiTaskRequest}
              >
                {message.text}
              </MessageBubble>
            </MessageContainer>
          ))}
          {isLoading && <LoadingMessage type={currentAiTask} />}
        </ChatHistoryArea>
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <Tooltip title="Enter: 通常の応答 / Ctrl+Enter: AIタスク実行">
            <div style={{ flexGrow: 1 }}>
              <ChatInput
                placeholder="メッセージを入力... (Ctrl+Enter でAIタスク実行)"
                fullWidth={true}
                inputProps={{ 'aria-label': 'メッセージを入力' }}
                value={inputValue}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                disabled={isLoading}
              />
            </div>
          </Tooltip>
          <Tooltip title="通常の応答">
            <SendButton
              color="primary"
              onClick={() => handleSendMessage()}
              disabled={isLoading || inputValue.trim() === ''}
            >
              <SendIcon />
            </SendButton>
          </Tooltip>
          <Tooltip title="タスク分解">
            <TaskDecompositionButton
              color="secondary"
              onClick={handleTaskDecomposition}
              disabled={
                isLoading ||
                window.location.search.replace('?treeRootUuid=', '') ===
                  window.location.search
              }
            >
              <ListAltIcon />
            </TaskDecompositionButton>
          </Tooltip>
          <Tooltip title="AIスケジューリング">
            <TaskDecompositionButton
              color="secondary"
              onClick={handleAiScheduling}
              disabled={
                isLoading ||
                window.location.search.replace('?treeRootUuid=', '') ===
                  window.location.search
              }
            >
              <EditCalendarRoundedIcon />
            </TaskDecompositionButton>
          </Tooltip>
          <Tooltip title="チャットをリフレッシュ">
            <IconButton onClick={handleRefresh} disabled={isLoading}>
              <RefreshIcon />
            </IconButton>
          </Tooltip>
        </div>
      </StyledPopper>
    </div>
  )
}
