import React from 'react'
import DateVO from '../../../../../../../../../vo/DateVO'
import Tooltip from '../../../../../../../../components/tooltips/Tooltip'
import { GanttCellRendererProps } from './index'
import { GanttColor } from '../../../../../../../../../styles/commonStyles'
import { WbsItemStatus } from '../../wbsItemStatus'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import objects from '../../../../../../../../../utils/objects'
import { GanttDisplayUnit } from '../../../../../../../../../domain/value-object/GanttParameterVO'
import { CalendarDateVO } from '../../../../../../../../../domain/value-object/CalendarDateVO'
import { colorPalette } from '../../../../../../../../style/colorPallete'
import { isProduction } from '../../../../../../../../../utils/urls'

const Background = React.forwardRef<HTMLDivElement, { className: string }>(
  ({ className, ...tooltipProps }, ref) => {
    return (
      <div
        {...tooltipProps}
        className={'sevend-ag-cell-gantt-background ' + className}
        ref={ref}
      />
    )
  }
)
const BehindScheduledStartBar = React.forwardRef<HTMLSpanElement>(
  ({ ...tooltipProps }, ref) => (
    <span
      {...tooltipProps}
      className="sevend-ag-cell-gantt-background__behind-scheduled-start-bar"
      ref={ref}
    />
  )
)
const ActualResultBar = React.forwardRef<
  HTMLSpanElement,
  {
    endlate: number
    review: number
    done: number
  }
>(({ endlate, review, done, ...tooltipProps }, ref) => {
  let customClassName =
    'sevend-ag-cell-gantt-background__actual-result-bar--scheduled'
  if (review) {
    customClassName =
      'sevend-ag-cell-gantt-background__actual-result-bar--review'
  } else if (done) {
    customClassName = 'sevend-ag-cell-gantt-background__actual-result-bar--done'
  } else if (endlate) {
    customClassName = 'sevend-ag-cell-gantt-background__actual-result-bar--late'
  }
  return (
    <span
      {...tooltipProps}
      className={
        'sevend-ag-cell-gantt-background__actual-result-bar ' + customClassName
      }
      ref={ref}
    />
  )
})

interface Props extends WrappedComponentProps, GanttCellRendererProps {}
const GanttBackGroundLineColor = '#d8dde5'

export const GanttBackground = injectIntl((props: Props) => {
  const dataPath = props.dataPath ? props.dataPath + '.' : ''
  const { status, scheduledDate, actualDate } = objects.getValue(
    props.data,
    `${dataPath}wbsItem`
  )
  const cumulation = objects.getValue(props.data, `${dataPath}cumulation`)
  if (!status || !scheduledDate || !actualDate) return <></>
  const wbsIsDoneOrDiscard = [
    WbsItemStatus.DONE,
    WbsItemStatus.DISCARD,
  ].includes(status)
  const wbsIsReview = WbsItemStatus.REVIEW === status
  const scheduledStart = scheduledDate.startDate
    ? CalendarDateVO.of(scheduledDate.startDate)
    : undefined
  const scheduledEnd = scheduledDate.endDate
    ? CalendarDateVO.of(scheduledDate.endDate)
    : undefined
  const actualStart = actualDate.startDate
    ? CalendarDateVO.of(actualDate.startDate)
    : undefined
  const actualEnd = actualDate.endDate
    ? CalendarDateVO.of(actualDate.endDate)
    : undefined
  const now = new Date()
  const today = CalendarDateVO.of(now)
  const endOfToday = CalendarDateVO.of(new DateVO(now).addDays(1).toDate())
  const displayStartDate = actualStart || undefined
  const displayEndDate = actualEnd || endOfToday
  const maxScheduledDate = cumulation?.maxScheduledEndDate
    ? CalendarDateVO.of(cumulation.maxScheduledEndDate)
    : undefined
  const TOOLTIP_MESSAGE_START_DELAY = props.intl.formatMessage({
    id: 'wbs.start.delayed',
  })
  const TOOLTIP_MESSAGE_END_DELAY = props.intl.formatMessage({
    id: 'wbs.end.delayed',
  })
  const TOOLTIP_MESSAGE_SCHEDULED_TO_BE_EXCEEDED = props.intl.formatMessage({
    id: 'wbs.scheduledToBeExceeded',
  })
  return (
    <>
      {props.timeScale.map((date, i) => {
        const isToday = date.isEqual(today)
        const isEndOfToday = date.isEqual(endOfToday)
        const matchScale =
          props.parameter.unit === GanttDisplayUnit.DAY ||
          props.displayTimeScale.some(v => v.isEqual(date))
        const scheduleToBeExceeded =
          scheduledEnd &&
          maxScheduledDate &&
          date.isAfter(scheduledEnd) &&
          (date.isEqual(maxScheduledDate) || date.isBefore(maxScheduledDate))
        const isFirstday =
          props.parameter.unit !== GanttDisplayUnit.MONTH && date.isFirstday()
        const isFirstmonth =
          props.parameter.unit === GanttDisplayUnit.MONTH && date.isFirstmonth()
        const isFirstweek =
          props.parameter.unit === GanttDisplayUnit.WEEK && date.isFirstweek()
        const FORTY_PERCENT_OPACITY = '66'
        const GANTT_BORDER_COLOR = isProduction
          ? GanttColor.BORDER_GRID
          : !(isFirstday || isFirstmonth || isFirstweek)
          ? colorPalette.monotone[2] + FORTY_PERCENT_OPACITY
          : GanttBackGroundLineColor
        if (isProduction) {
          return (
            <div
              key={`gantt-${props.node.rowIndex}-${i}`}
              style={{
                height: '100%',
                width: '100%',
                borderLeft: `1px solid ${
                  matchScale ? GANTT_BORDER_COLOR : 'transparent'
                }`,
                marginTop: '-1px',
                display: 'flex',
                alignItems: 'center',
                position: 'relative',
              }}
            >
              {isEndOfToday ? (
                <span
                  style={{
                    height: 'calc(100% + 3px)',
                    width: '2px',
                    backgroundColor: GanttColor.BORDER_TODAY,
                    position: 'absolute',
                    left: '-1px',
                    zIndex: 2,
                  }}
                />
              ) : undefined}
              {isToday ? (
                <Background className="sevend-ag-cell-gantt-background--today" />
              ) : props.parameter.unit === GanttDisplayUnit.DAY &&
                date.isHoliday() ? (
                <Background className="sevend-ag-cell-gantt-background--holiday" />
              ) : undefined}
              {scheduleToBeExceeded && (
                <Tooltip message={TOOLTIP_MESSAGE_SCHEDULED_TO_BE_EXCEEDED}>
                  <Background className="sevend-ag-cell-gantt-background--warning" />
                </Tooltip>
              )}
              {!actualStart &&
                !actualEnd &&
                !!scheduledStart &&
                scheduledStart.isBefore(endOfToday) &&
                (date.isEqual(scheduledStart) ||
                  (date.isBetween(scheduledStart, endOfToday) &&
                    !date.isEqual(endOfToday))) && (
                  <Tooltip message={TOOLTIP_MESSAGE_START_DELAY} delay={600}>
                    <BehindScheduledStartBar />
                  </Tooltip>
                )}
              {displayStartDate &&
                (date.isEqual(displayStartDate) ||
                  (!!actualEnd && date.isEqual(displayEndDate)) ||
                  (date.isBetween(displayStartDate, displayEndDate) &&
                    !date.isEqual(displayEndDate))) && (
                  <Tooltip
                    message={
                      scheduledEnd &&
                      date.isAfter(scheduledEnd) &&
                      !wbsIsDoneOrDiscard
                        ? TOOLTIP_MESSAGE_END_DELAY
                        : undefined
                    }
                    delay={600}
                  >
                    <ActualResultBar
                      endlate={+(!!scheduledEnd && date.isAfter(scheduledEnd))}
                      review={+(wbsIsReview && !!actualEnd)}
                      done={+wbsIsDoneOrDiscard}
                    />
                  </Tooltip>
                )}
            </div>
          )
        } else {
          return (
            <div
              key={`gantt-${props.node.rowIndex}-${i}`}
              style={{
                height: '100%',
                width: '100%',
                borderLeft: `1px solid ${
                  matchScale ? GANTT_BORDER_COLOR : 'transparent'
                }`,
                marginTop: '-1px',
                display: 'flex',
                alignItems: 'center',
                position: 'relative',
              }}
            >
              {isEndOfToday ? (
                <span
                  style={{
                    height: 'calc(100% + 3px)',
                    width: '2px',
                    backgroundColor: GanttColor.BORDER_TODAY,
                    position: 'absolute',
                    left: '-1px',
                    zIndex: 2,
                  }}
                />
              ) : undefined}
              {isToday ? (
                <Background className="sevend-ag-cell-gantt-background--today" />
              ) : props.parameter.unit === GanttDisplayUnit.DAY &&
                date.isHoliday() ? (
                // TODO: The class sevend-ag-cell-gantt-background--holiday__staging was temporarily created to keep the calendar background color change specific to the Staging environment.
                <Background className="sevend-ag-cell-gantt-background--holiday__staging" />
              ) : undefined}
              {scheduleToBeExceeded && (
                <Tooltip message={TOOLTIP_MESSAGE_SCHEDULED_TO_BE_EXCEEDED}>
                  <Background className="sevend-ag-cell-gantt-background--warning" />
                </Tooltip>
              )}
              {!actualStart &&
                !actualEnd &&
                !!scheduledStart &&
                scheduledStart.isBefore(endOfToday) &&
                (date.isEqual(scheduledStart) ||
                  (date.isBetween(scheduledStart, endOfToday) &&
                    !date.isEqual(endOfToday))) && (
                  <Tooltip message={TOOLTIP_MESSAGE_START_DELAY} delay={600}>
                    <BehindScheduledStartBar />
                  </Tooltip>
                )}
              {displayStartDate &&
                (date.isEqual(displayStartDate) ||
                  (!!actualEnd && date.isEqual(displayEndDate)) ||
                  (date.isBetween(displayStartDate, displayEndDate) &&
                    !date.isEqual(displayEndDate))) && (
                  <Tooltip
                    message={
                      scheduledEnd &&
                      date.isAfter(scheduledEnd) &&
                      !wbsIsDoneOrDiscard
                        ? TOOLTIP_MESSAGE_END_DELAY
                        : undefined
                    }
                    delay={600}
                  >
                    <ActualResultBar
                      endlate={+(!!scheduledEnd && date.isAfter(scheduledEnd))}
                      review={+(wbsIsReview && !!actualEnd)}
                      done={+wbsIsDoneOrDiscard}
                    />
                  </Tooltip>
                )}
            </div>
          )
        }
      })}
    </>
  )
})
