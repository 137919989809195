import { toNumber } from '../../../utils/number'
import { WbsItemAdditionalPropertyType } from '../../entity/WbsItemAdditionalPropertyEntity'
import { DateV2 } from '../DateV2'
import {
  WbsItemAdditionalPropertyValueType,
  WbsItemAdditionalPropertyNumberValueType,
  WbsItemAdditionalPropertyTextValueType,
  WbsItemAdditionalPropertyMultiLineTextValueType,
  WbsItemAdditionalPropertyDateValueType,
  WbsItemAdditionalPropertyDateTimeValueType,
  WbsItemAdditionalPropertyCheckboxValueType,
  WbsItemAdditionalPropertySelectValueType,
  WbsItemAdditionalPropertyMultiSelectValueType,
  WbsItemAdditionalPropertyEntitySearchValueType,
  ReferenceEntityValueType,
} from '../WbsItemAdditionalPropertyValuesVO'

export type EntityExtensionPropertyType = WbsItemAdditionalPropertyType
export type EntityExtensionValueType = WbsItemAdditionalPropertyValueType
export type EntityExtensionDateValueType =
  WbsItemAdditionalPropertyDateValueType
export type EntityExtensionNumberValueType =
  WbsItemAdditionalPropertyNumberValueType
export type EntityExtensionTextValueType =
  WbsItemAdditionalPropertyTextValueType
export type EntityExtensionMultiLineTextValueType =
  WbsItemAdditionalPropertyMultiLineTextValueType
export type EntityExtensiontyDateValueType =
  WbsItemAdditionalPropertyDateValueType
export type EntityExtensionDateTimeValueType =
  WbsItemAdditionalPropertyDateTimeValueType
export type EntityExtensionCheckboxValueType =
  WbsItemAdditionalPropertyCheckboxValueType
export type EntityExtensionSelectValueType =
  WbsItemAdditionalPropertySelectValueType
export type EntityExtensionMultiSelectValueType =
  WbsItemAdditionalPropertyMultiSelectValueType
export type EntityExtensionEntitySearchValueType =
  WbsItemAdditionalPropertyEntitySearchValueType

export type EntityExtensionValueObject = {
  uuid: string
  value: EntityExtensionValueType
}
export type EntityExtensionValueVO = {
  entityExtensionUuid: string
  value: EntityExtensionValueType
}
type EntityExtensionValues = {
  [entityExtensionUuid: string]: EntityExtensionValueType
}
export type EntityExtensionValuesVO = {
  values: EntityExtensionValues
}
export type EntityExtensionValueDeltaInput = {
  uuid: string
  oldValue: string | undefined
  newValue: string | undefined
}

// Constructor
const construct = (): EntityExtensionValuesVO => {
  return {
    values: {},
  }
}
const of = (values: EntityExtensionValueObject[]): EntityExtensionValuesVO => {
  const instance: EntityExtensionValuesVO = {
    values: {},
  }
  values.forEach(({ uuid, value }) => {
    instance.values[uuid] = value
  })

  return instance
}
// Services
const getValue = (
  vo: EntityExtensionValuesVO,
  entityExtensionUuid: string
): EntityExtensionValueType | undefined => {
  return vo.values[entityExtensionUuid]
}
const setValue = (
  vo: EntityExtensionValuesVO,
  entityExtensionUuid: string,
  value: EntityExtensionValueType
): void => {
  vo.values[entityExtensionUuid] = value
}
const parseValueFromString = (
  propertyType: EntityExtensionPropertyType,
  value: string | null | undefined
): EntityExtensionValueType => {
  if (value === undefined || value === null) return undefined
  const v = value
  switch (propertyType) {
    case WbsItemAdditionalPropertyType.CHECKBOX:
      if (v.toString().match(/^([tT]|check|1)/)) return true
      if (v.toString().match(/^[fF]|0/)) return false
      return undefined
    case WbsItemAdditionalPropertyType.NUMBER:
      return toNumber(v)
  }
  return v
}
const serialize = (
  vo: EntityExtensionValuesVO
): EntityExtensionValueObject[] => {
  return Object.entries(vo.values).map(([uuid, value]) => ({
    uuid,
    value: serializeValue(value),
  }))
}
const serializeDelta = (
  oldVo: EntityExtensionValuesVO | undefined,
  newVo: EntityExtensionValuesVO | undefined
): EntityExtensionValueDeltaInput[] => {
  const entityExtensionUuids = new Set<string>()
  const oldValueMap: EntityExtensionValues = oldVo ? oldVo.values : {}
  const newValueMap: EntityExtensionValues = newVo ? newVo.values : {}
  if (oldVo) {
    Object.keys(oldValueMap).forEach(v => entityExtensionUuids.add(v))
  }
  if (newVo) {
    Object.keys(newValueMap).forEach(v => entityExtensionUuids.add(v))
  }

  const deltaInput: EntityExtensionValueDeltaInput[] = []
  entityExtensionUuids.forEach(entityExtensionUuid => {
    const oldValue = serializeValue(oldValueMap[entityExtensionUuid])
    const newValue = serializeValue(newValueMap[entityExtensionUuid])
    if (oldValue === newValue) {
      return
    }

    deltaInput.push({
      uuid: entityExtensionUuid,
      oldValue,
      newValue,
    })
  })
  return deltaInput
}
const toValueFromDate = (
  dateValue: DateV2 | undefined
): EntityExtensionDateValueType => {
  if (!dateValue) return undefined
  const fullYear = dateValue.getFullYear()
  const month = dateValue.getMonth() + 1
  const fullMonth = month < 10 ? `0${month}` : month.toString()
  const date = dateValue.getDate()
  const fullDate = date < 10 ? `0${date}` : date.toString()
  return `${fullYear}-${fullMonth}-${fullDate}`
}

export const entityExtensionValuesVoService = {
  construct,
  of,
  getValue,
  setValue,
  parseValueFromString,
  serialize,
  serializeDelta,
  toValueFromDate,
}

// Private
type EntityExtensionValueTypeForSerialize = undefined | string
const isReferenceEntityValue = (value: EntityExtensionValueType) =>
  typeof value === 'object' &&
  !Array.isArray(value) &&
  value &&
  Boolean(value.uuid)
const serializeValue = (
  value: EntityExtensionValueType
): EntityExtensionValueTypeForSerialize => {
  if (value === undefined) {
    return undefined
  }
  if (isReferenceEntityValue(value)) {
    return (value as ReferenceEntityValueType).uuid
  }
  return String(value)
}
