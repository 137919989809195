import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded'
import { styled } from '@mui/material'
import { intl } from '../../../../i18n'

const SupportRoot = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  padding: '24px 16px',
  fontSize: '14px',
  lineHeight: '14px',
  top: '2px',
  gap: '16px',
  alignItems: 'center',
  position: 'absolute',
  right: '0',
})

const SupportLinkButton = styled('button')({
  width: '128px',
  height: '32px',
  borderRadius: '4px',
  gap: '4px',
  color: '#7B8CAA',
  display: 'flex',
  alignItems: 'center',
  fontSize: '12px',
  border: '1px solid #D8DDE5',
  justifyContent: 'center',
  backgroundColor: '#FFFFFF',
  cursor: 'pointer',
})

const SupportText = styled('div')({
  display: 'flex',
  fontSize: '14px',
  fontWeight: '700',
  lineHeight: '14px',
  textAlign: 'left',
  color: '#7B8CAA',
})

export const Support = () => {
  return (
    <SupportRoot>
      <SupportText>
        {intl.formatMessage({ id: 'login.supportText' })}
      </SupportText>
      <SupportLinkButton
        onClick={() => {
          window.open(process.env.FLAGXS_SUPPORT_SITE_URL, '_blank')
        }}
      >
        <HelpOutlineRoundedIcon />
        {intl.formatMessage({ id: 'login.supportSite' })}
      </SupportLinkButton>
    </SupportRoot>
  )
}
