import { useCallback, useEffect, useRef, useState } from 'react'
import _ from 'lodash'
import {
  createNewProjectPlanNewRow,
  fetchProjectPlanBody,
  fetchProjectPlanSkeleton,
  getEditedAncestor,
  NewWbsItemRow,
  ProjectPlanNewRow,
  ProjectPlanRowBody,
  ProjectPlanSkeleton,
} from '../projectPlanNew'
import {
  AgGridTreeHelper,
  getParent,
  getPrevSiblings,
} from '../../../containers/BulkSheetView/lib/tree'
import { TreeRow } from '../../../containers/BulkSheetView/model'
import {
  ProjectPlanBatchDeltaInput,
  ProjectPlanUpdateBatchDeltaRequest,
  updateBatchDelta,
} from '../../../../lib/functions/projectPlan'
import { hasDiffDateTerm } from '../../../../utils/date'
import { WbsItemDeltaInput } from '../../../../lib/functions/wbsItem'
import { APIResponse, successDummyResponse } from '../../../../lib/commons/api'
import SprintBacklog, {
  UpdateBatchSprintBacklogProps,
} from '../../../../lib/functions/sprintBacklog'
import {
  UpdateSprintProductItemProps,
  updateSprintProductItems,
} from '../../../../lib/functions/sprintProductItem'
import store from '../../../../store'
import {
  doNotRequireSave,
  requireSave,
} from '../../../../store/requiredSaveData'
import {
  serializeExtensionValue,
  serializeExtensionValueDelta,
} from '../../../containers/BulkSheetView/gridOptions/extension'
import { createDelta } from '../../../../domain/value-object/ItemDeltaInputVO'
import { isTagColumnEdited } from '../../../../lib/functions/tag'
import { stringify } from 'querystring'
import { wbsItemAdditionalPropertyValuesVoService } from '../../../../domain/value-object/WbsItemAdditionalPropertyValuesVO'
import { updateRows } from '../../../containers/BulkSheetView/hooks/actions/crudTreeRows'
import { useWbsItemCodeService } from '../../../../services/adaptors/wbsItemCodeServiceAdaptor'

export const useProjectPlanNewData = (projectUuid, treeRootUuid) => {
  const [data, setDataInternal] = useState<ProjectPlanNewRow[]>([])
  const [projectPlanUuidOfProject, setProjectPlanUuidOfProject] = useState<
    string | undefined
  >()
  const [rootProjectPlanUuid, setRootProjectPlanUuid] = useState<
    string | undefined
  >(treeRootUuid)
  const queue = useRef<string[]>([])
  const [count, setCount] = useState<number>(0)
  const [isFetchingBody, setIsFetchingBody] = useState<boolean>(false)
  const [deletedRows, setDeletedRows] = useState<ProjectPlanNewRow[]>([])
  // Original data for delta update
  const [originalTree, setOriginalTree] = useState<TreeRow[]>([])
  const originalData = useRef<
    Map<string, Pick<ProjectPlanRowBody, 'wbsItem' | 'sprint' | 'extensions'>>
  >(new Map())
  const rootWbsItem = useRef<NewWbsItemRow>()
  const FETCH_BODY_BULK_SIZE = 100

  useEffect(() => {
    fetchSkeleton(rootProjectPlanUuid)
  }, [])

  useEffect(() => {
    if (isFetchingBody) return
    const fetchBodyUuid = dequeue()
    if (fetchBodyUuid.length === 0) return
    fetchBody(fetchBodyUuid)
  }, [count, isFetchingBody])

  const rowToOriginalTree = useCallback(
    (row: ProjectPlanNewRow) =>
      ({ uuid: row.uuid, treeValue: row.treeValue } as TreeRow),
    []
  )

  const updateOriginalTree = useCallback(
    (rowsToBeUpdated: TreeRow[]) => {
      if (_.isEmpty(rowsToBeUpdated)) return

      const newTree = _.cloneDeep(originalTree)
      rowsToBeUpdated.forEach((row: TreeRow) => {
        const index = newTree.findIndex(
          (tree: TreeRow) => tree.uuid === row.uuid
        )
        if (0 <= index) {
          newTree[index] = row
        } else {
          newTree.push(row)
        }
      })
      setOriginalTree(newTree)
    },
    [originalTree]
  )

  const setOriginalTreeByRows = useCallback(
    (rows: ProjectPlanNewRow[]) => {
      setOriginalTree(_.cloneDeep(rows.map(v => rowToOriginalTree(v))))
    },
    [rowToOriginalTree]
  )
  const { generateCode } = useWbsItemCodeService()

  // Fetch data
  /**
   * Fetch project plan skeleton, which is whole tree data of project plan.
   * fetchBodyUuid specifies the rows whose body is fetched, but the size of list is limited to FETCH_BODY_BULK_SIZE,
   * and overflow is ignored.
   *
   * @param projectUuid
   * @param rootProjectPlanUuid
   * @param fetchBodyUuid
   */
  const fetchSkeleton = useCallback(
    async (
      rootProjectPlanUuid?: string,
      fetchBodyUuid?: string[],
      option?: { forceRefreshTree?: boolean }
    ) => {
      option?.forceRefreshTree && setDataInternal([])
      const response = await fetchProjectPlanSkeleton({
        projectUuid,
        rootProjectPlanUuid,
        fetchBodyUuid,
      })
      const source: ProjectPlanSkeleton = response.json
      setProjectPlanUuidOfProject(
        source.type.toString() === 'PROJECT' ? source.uuid : undefined
      )
      const rows = (rootProjectPlanUuid ? [source] : source.children)
        .map(v =>
          AgGridTreeHelper.convert(
            v,
            (s: ProjectPlanSkeleton): ProjectPlanNewRow => {
              return {
                uuid: s.uuid,
                lockVersion: s.lockVersion,
                type: s.type,
                wbsItemUuid: s.wbsItemUuid,
                body: s.body ? new ProjectPlanRowBody(s.body) : undefined,
                revision: s.revision,
                updatedAt: s.updatedAt,
                updatedBy: s.updatedBy,
                createdAt: s.createdAt,
                createdBy: s.createdBy,
              } as ProjectPlanNewRow
            }
          )
        )
        .flat()
      setDataInternal(rows)
      setOriginalTreeByRows(rows)
      originalData.current.clear()
      rows
        .filter(row => !!row.body?.wbsItem)
        .forEach(row => {
          originalData.current.set(
            row.uuid,
            _.cloneDeep({
              wbsItem: row.body!.wbsItem,
              sprint: row.body!.sprint,
              extensions: row.body?.extensions,
            })
          )
          if (rootProjectPlanUuid && row.uuid === rootProjectPlanUuid) {
            rootWbsItem.current = row.body!.wbsItem
          }
        })
      setDeletedRows([])
      if (rows.length === 0) {
        const wbsItemTypes = store.getState().project.wbsItemTypes
        const firstRow = createNewProjectPlanNewRow(
          wbsItemTypes.workgroup,
          generateCode
        )
        setDataInternal([
          { ...firstRow, added: true, treeValue: [firstRow.uuid] },
        ])
      }
    },
    [projectUuid, setOriginalTreeByRows]
  )

  // TODO Temprary implementation for AI.
  const setProjectPlanBody = useCallback(
    (row: ProjectPlanNewRow, body: ProjectPlanRowBody) => {
      originalData.current.set(
        row.uuid,
        _.cloneDeep({
          wbsItem: body.wbsItem,
          sprint: body.wbsItem.sprint,
          extensions: body.extensions,
        })
      )
      return {
        ...row,
        edited: undefined,
        editedData: {},
        body,
      }
    },
    []
  )
  /**
   * Fetch body of some project plan row.
   *
   * @param uuid
   */
  const fetchBody = useCallback(
    async (uuids: string[]) => {
      setIsFetchingBody(true)
      const response = await fetchProjectPlanBody(uuids)
      const uuidToBody = new Map()
      response.json.forEach(body => {
        uuidToBody.set(body.wbsItem.uuid, new ProjectPlanRowBody(body))
      })
      const newData = data.map(row => {
        const body = uuidToBody.get(row.wbsItemUuid)
        if (!body) return row
        return setProjectPlanBody(row, body)
      })
      setDataInternal(newData)
      setIsFetchingBody(false)
    },
    [data, setProjectPlanBody]
  )

  const enqueue = useCallback((list: string[]) => {
    if (list.length === 0) return
    queue.current = [
      ...queue.current,
      ...list.filter(uuid => !queue.current.includes(uuid)),
    ]
    setCount(queue.current.length)
  }, [])

  const dequeue = useCallback(() => {
    if (queue.current.length === 0) return []
    const dequeued = queue.current.slice(0, FETCH_BODY_BULK_SIZE)
    queue.current = queue.current.slice(FETCH_BODY_BULK_SIZE)
    setCount(queue.current.length)
    return dequeued
  }, [])

  const deleteRows = useCallback(
    (rows: ProjectPlanNewRow[]) => {
      setDeletedRows([...deletedRows, ...rows.filter(v => !v.added)])
    },
    [deletedRows]
  )

  const generateUpdateWbsItemDeltaRequest = useCallback(
    (row: ProjectPlanNewRow): Partial<WbsItemDeltaInput> => {
      if (!row.edited) return {}
      const originalBody = originalData.current.get(row.uuid)
      const original = originalBody!.wbsItem
      const originalExtensions = originalBody!.extensions ?? []
      const wbsItem = row.body!.wbsItem

      return {
        uuid: wbsItem.uuid,
        type: wbsItem.type,
        typeUuid: createDelta(
          original.wbsItemType.uuid,
          wbsItem.wbsItemType.uuid
        ),
        status: createDelta(original.status, wbsItem.status),
        substatus: createDelta(original.substatus, wbsItem.substatus),
        displayName: createDelta(original.displayName, wbsItem.displayName),
        description: createDelta(original.description, wbsItem.description),
        teamUuid: createDelta(original.team?.uuid, wbsItem.team?.uuid),
        accountableUuid: createDelta(
          original.accountable?.uuid,
          wbsItem.accountable?.uuid
        ),
        responsibleUuid: createDelta(
          original.responsible?.uuid,
          wbsItem.responsible?.uuid
        ),
        assigneeUuid: createDelta(
          original.assignee?.uuid,
          wbsItem.assignee?.uuid
        ),
        estimatedStoryPoint: createDelta(
          original.estimatedStoryPoint,
          wbsItem.estimatedStoryPoint
        ),
        estimatedHour: createDelta(
          original.estimatedHour,
          wbsItem.estimatedHour
        ),
        priority: createDelta(original.priority, wbsItem.priority),
        difficulty: createDelta(original.difficulty, wbsItem.difficulty),
        scheduledDate: hasDiffDateTerm(
          original.scheduledDate,
          wbsItem.scheduledDate
        )
          ? {
              oldValue: original.scheduledDate,
              newValue: wbsItem.scheduledDate,
            }
          : undefined,
        actualDate: hasDiffDateTerm(original.actualDate, wbsItem.actualDate)
          ? {
              oldValue: original.actualDate,
              newValue: wbsItem.actualDate,
            }
          : undefined,
        estimatedAmount: createDelta(
          original.estimatedAmount,
          wbsItem.estimatedAmount
        ),
        actualAmount: createDelta(original.actualAmount, wbsItem.actualAmount),
        extensions: (row.body?.extensions ?? [])
          .map(v =>
            serializeExtensionValueDelta(v.uuid, {
              oldValue: originalExtensions.find(e => e.uuid === v.uuid)?.value,
              newValue: v.value,
            })
          )
          .filter(v => !!v),
        sprintUuid: createDelta(original.sprint?.uuid, wbsItem.sprint?.uuid),
        additionalPropertyValues: wbsItem.additionalPropertyValues
          ? wbsItemAdditionalPropertyValuesVoService.serializeDelta(
              original.additionalPropertyValues,
              wbsItem.additionalPropertyValues
            )
          : [],
      } as WbsItemDeltaInput
    },
    []
  )

  const updateProjectPlan = useCallback(
    async (changed: ProjectPlanNewRow[]): Promise<APIResponse> => {
      // Submit
      const deleted = deletedRows.concat()
      deleted.sort((a, b) => {
        if (a.treeValue.length < b.treeValue.length) return 1
        if (b.treeValue.length < a.treeValue.length) return -1
        return 0
      })
      const projectPlanInput: ProjectPlanUpdateBatchDeltaRequest = {
        projectUuid: projectUuid,
        added: changed
          .filter(row => row.added && !!row.body?.wbsItem?.displayName)
          .map((row: ProjectPlanNewRow) => {
            const wbsItem: NewWbsItemRow = row.body!.wbsItem
            const wbsItemInput = {
              ...wbsItem,
              typeUuid: wbsItem.wbsItemType.uuid,
              status: wbsItem.status,
              displayName: wbsItem.displayName ?? '',
              description: wbsItem.description ?? '',
              teamUuid: wbsItem.team?.uuid,
              accountableUuid: wbsItem.accountable?.uuid,
              responsibleUuid: wbsItem.responsible?.uuid,
              assigneeUuid: wbsItem.assignee?.uuid,
              watchers: wbsItem.watchers?.map(v => v.uuid),
              scheduledDate: {
                startDate: wbsItem.scheduledDate?.startDate,
                endDate: wbsItem.scheduledDate?.endDate,
              },
              actualDate: {
                startDate: wbsItem.actualDate?.startDate,
                endDate: wbsItem.actualDate?.endDate,
              },
              extensions: row.body?.extensions?.map(v =>
                serializeExtensionValue(v.uuid, v.value)
              ),
              sprintUuid: wbsItem.sprint?.uuid,
              additionalPropertyValues: wbsItem.additionalPropertyValues
                ? wbsItemAdditionalPropertyValuesVoService.serialize(
                    wbsItem.additionalPropertyValues
                  )
                : undefined,
            }
            const parent = getParent(data, row)
            const siblings = getPrevSiblings(data, row)
            return {
              uuid: row.uuid,
              parentUuid:
                parent?.uuid || rootProjectPlanUuid || projectPlanUuidOfProject,
              prevSiblingUuid: siblings[siblings.length - 1]?.uuid,
              type: wbsItem.type,
              wbsItem: wbsItemInput,
              ticketType: row.body?.wbsItem.ticketType,
            }
          }),
        edited: changed
          .filter(
            row =>
              row.edited &&
              originalTree.some(t => t.uuid === row.uuid) &&
              originalData.current.get(row.uuid)
          )
          .map((row: ProjectPlanNewRow) => {
            const originalRow = originalTree.find(t => t.uuid === row.uuid)
            const originalParent = getParent(originalTree, originalRow!)
            const originalSiblings = getPrevSiblings(originalTree, originalRow!)
            const parent = getParent(data, row)
            const siblings = getPrevSiblings(data, row)

            const wbsItem = row.body!.wbsItem

            const delta: ProjectPlanBatchDeltaInput = {
              uuid: row.uuid,
              type: wbsItem.type,
              wbsItem: generateUpdateWbsItemDeltaRequest(
                row
              ) as WbsItemDeltaInput,
            }
            if (row.uuid !== rootProjectPlanUuid) {
              delta.parentUuid = {
                oldValue: originalParent?.uuid || projectPlanUuidOfProject,
                newValue: parent?.uuid || projectPlanUuidOfProject,
              }
              delta.prevSiblingUuid = {
                oldValue: originalSiblings[originalSiblings.length - 1]?.uuid,
                newValue: siblings[siblings.length - 1]?.uuid,
              }
            }
            return delta
          }),
        deleted: deleted.map(row => {
          return {
            uuid: row.uuid,
            wbsItemUuid: row.body?.wbsItem.uuid!,
          }
        }),
      }
      const ticketListInput = changed
        .filter(
          row =>
            row.added &&
            row.body?.wbsItem?.wbsItemType.isDeliverable() &&
            row.body?.wbsItem?.wbsItemType.isTicket()
        )
        .map(row => ({
          uuid: row.uuid,
          ticketType: row.body!.wbsItem.ticketType!,
        }))
      const ticketInput = changed
        .filter(
          row =>
            row.added &&
            row.body?.wbsItem?.wbsItemType.isTask() &&
            row.body?.wbsItem?.wbsItemType.isTicket()
        )
        .map(row => ({
          uuid: row.uuid,
          ticketType: row.body!.wbsItem.ticketType!,
          ticketListUuid: row.body!.wbsItem.ticketListUuid!,
        }))
      const watcherInput = changed
        .filter(row => {
          const watcherToString = d =>
            d
              .map(v => v.uuid)
              .sort()
              .join()
          const original =
            originalData.current.get(row.uuid)?.wbsItem?.watchers ?? []
          const newData = row.body?.wbsItem?.watchers ?? []
          return (
            (row.added || row.edited) &&
            watcherToString(original) !== watcherToString(newData)
          )
        })
        .map(row => {
          const w = row.body!.wbsItem
          return {
            uuid: row.uuid,
            wbsItemUuid: w.uuid,
            userUuids:
              w.watchers && Array.isArray(w.watchers)
                ? w.watchers.map(v => v.uuid)
                : [],
          }
        })
      const tagInput = changed
        .filter(row => {
          const original =
            originalData.current.get(row.uuid)?.wbsItem?.tags ?? []
          const newData = row.body?.wbsItem?.tags ?? []
          return (
            (row.added || row.edited) && isTagColumnEdited(original, newData)
          )
        })
        .map(row => {
          const w = row.body!.wbsItem
          return {
            uuid: row.uuid,
            wbsItemUuid: w.uuid,
            tagUuids:
              w.tags && Array.isArray(w.tags) ? w.tags.map(v => v.uuid) : [],
          }
        })
      return updateBatchDelta({
        projectPlans: projectPlanInput,
        ticketLists: ticketListInput,
        tickets: ticketInput,
        watchers: watcherInput,
        tags: tagInput,
      })
    },
    [projectPlanUuidOfProject, data, deletedRows, originalTree]
  )

  const updateSprintProduct = useCallback(
    async (
      changed: ProjectPlanNewRow[],
      wbsItemLockVersionMap: { [wbsItemUuid: string]: number }
    ): Promise<APIResponse> => {
      const deliverables = changed.filter(v =>
        v.body?.wbsItem?.wbsItemType.isDeliverable()
      )
      if (_.isEmpty(deliverables)) return successDummyResponse
      const sprintProductRequest: UpdateSprintProductItemProps = {
        added: [],
        deleted: [],
      }
      deliverables.forEach(row => {
        const wbsItem = row.body?.wbsItem
        const sprint = wbsItem?.sprint
        const originalSprint = originalData.current.get(row.uuid)?.wbsItem
          .sprint
        if (!wbsItem?.uuid) return
        if (
          !!sprint?.uuid &&
          (row.added ||
            (row.edited &&
              (!originalSprint || originalSprint.uuid !== sprint.uuid)))
        ) {
          sprintProductRequest.added.push({
            deliverableUuid: wbsItem.uuid,
            deliverableLockVersion:
              wbsItemLockVersionMap[wbsItem.uuid] ?? wbsItem.lockVersion!,
            sprintUuid: sprint.uuid,
          })
        } else if (row.edited && !sprint?.uuid && !!originalSprint) {
          sprintProductRequest.deleted.push({
            deliverableUuid: wbsItem.uuid,
            deliverableLockVersion:
              wbsItemLockVersionMap[wbsItem.uuid] ?? wbsItem.lockVersion!,
            sprintUuid: originalSprint.uuid,
          })
        }
      })
      if (
        _.isEmpty(sprintProductRequest.added) &&
        _.isEmpty(sprintProductRequest.deleted)
      ) {
        return successDummyResponse
      }
      return updateSprintProductItems(sprintProductRequest)
    },
    []
  )

  const updateSprintBacklog = useCallback(
    async (
      changed: ProjectPlanNewRow[],
      wbsItemLockVersionMap: { [wbsItemUuid: string]: number }
    ): Promise<APIResponse> => {
      const tasks = changed.filter(v => v.body?.wbsItem?.wbsItemType.isTask())
      if (_.isEmpty(tasks)) return successDummyResponse
      const sprintBacklogRequest: UpdateBatchSprintBacklogProps = {
        sprintBacklogItems: [],
        sprintPlans: {
          added: [],
          deleted: [],
        },
      }
      tasks.forEach(row => {
        const wbsItem = row.body?.wbsItem
        const sprint = wbsItem?.sprint
        const originalSprint = originalData.current.get(row.uuid)?.wbsItem
          .sprint
        if (!wbsItem?.uuid) return
        if (
          !!sprint?.uuid &&
          (row.added ||
            (row.edited &&
              (!originalSprint || originalSprint.uuid !== sprint.uuid)))
        ) {
          sprintBacklogRequest.sprintPlans.added.push({
            taskUuid: wbsItem.uuid,
            taskLockVersion:
              wbsItemLockVersionMap[wbsItem.uuid] ?? wbsItem.lockVersion!,
            sprintUuid: sprint.uuid,
          })
        } else if (row.edited && !sprint?.uuid && !!originalSprint) {
          sprintBacklogRequest.sprintPlans.deleted.push({
            taskUuid: wbsItem.uuid,
            taskLockVersion:
              wbsItemLockVersionMap[wbsItem.uuid] ?? wbsItem.lockVersion!,
            sprintUuid: originalSprint.uuid,
          })
        }
      })
      if (
        _.isEmpty(sprintBacklogRequest.sprintPlans.added) &&
        _.isEmpty(sprintBacklogRequest.sprintPlans.deleted)
      ) {
        return successDummyResponse
      }
      return SprintBacklog.updateBatchSprintBacklog(sprintBacklogRequest)
    },
    []
  )

  const save = useCallback(async (): Promise<APIResponse[]> => {
    const changed = data.filter(row => row.added || row.edited)

    // Project plans and wbs items
    const response = await updateProjectPlan(changed)
    const wbsItemLockVersionMap = Object.fromEntries(
      [...response.json?.added, ...response.json?.edited].map(v => [
        v.wbsItemUuid,
        v.wbsItemLockVersion,
      ])
    )
    // Sprint product (sprint of deliverables)
    const sprintProductResponse = await updateSprintProduct(
      changed,
      wbsItemLockVersionMap
    )
    // Sprint backlog (sprint of tasks)
    const sprintBacklogResponse = await updateSprintBacklog(
      changed,
      wbsItemLockVersionMap
    )
    return [response, sprintProductResponse, sprintBacklogResponse]
  }, [data, deletedRows, originalTree])

  const saveSingleRow = useCallback(
    async (uuid: string) => {
      const target = getEditedAncestor(uuid, data)
      if (!target) return
      await updateProjectPlan(target)
      setDataInternal(data =>
        data.map(v => ({
          ...v,
          added: v.uuid === uuid ? undefined : v.added,
          edited: v.uuid === uuid ? undefined : v.edited,
        }))
      )
      return true
    },
    [data]
  )

  const setData = useCallback((d: ProjectPlanNewRow[]) => {
    setDataInternal(d)
    store.dispatch(requireSave())
  }, [])

  const refresh = useCallback(
    async (
      rootProjectPlanUuid?: string,
      fetchBodyUuid?: string[],
      option?: { forceRefreshTree?: boolean }
    ) => {
      await fetchSkeleton(
        rootProjectPlanUuid,
        [...(fetchBodyUuid ?? []), ...deletedRows.map(v => v.uuid)],
        option
      )
      store.dispatch(doNotRequireSave())
    },
    [fetchSkeleton, deletedRows]
  )

  const refreshRow = useCallback(
    async (
      srcData: ProjectPlanNewRow[],
      uuids: string[],
      rootProjectPlanUuid?: string
    ) => {
      const response = await fetchProjectPlanSkeleton({
        projectUuid,
        rootProjectPlanUuid,
        fetchBodyUuid: uuids,
      })
      const source: ProjectPlanSkeleton = response.json
      const findRefreshPlans = (
        plans: ProjectPlanSkeleton[],
        foundPlans: ProjectPlanSkeleton[]
      ) => {
        if (uuids.length <= foundPlans.length) return foundPlans
        const candidates = plans.filter((plan: ProjectPlanSkeleton) =>
          uuids.includes(plan.uuid)
        )
        if (!_.isEmpty(candidates)) foundPlans.push(...candidates)
        if (uuids.length <= foundPlans.length) return foundPlans

        for (const plan of plans) {
          if (_.isEmpty(plan.children)) continue
          findRefreshPlans(plan.children, foundPlans)
          if (uuids.length <= foundPlans.length) break
        }
        return foundPlans
      }
      const refreshPlans = findRefreshPlans(
        rootProjectPlanUuid ? [source] : source.children,
        []
      )
      const refreshedRows: ProjectPlanNewRow[] = []
      const updateTreeRows: TreeRow[] = []
      const rows = srcData
        .map(row => {
          if (!uuids.includes(row.uuid)) return row
          const refreshPlan = refreshPlans?.find(
            (plan: ProjectPlanSkeleton) => plan.uuid === row.uuid
          )
          if (!refreshPlan) {
            if (originalData.current.has(row.uuid)) {
              originalData.current.delete(row.uuid)
            }
            return undefined
          }
          const newRow: ProjectPlanNewRow = {
            ...row,
            lockVersion: refreshPlan.lockVersion,
            type: refreshPlan.type,
            wbsItemUuid: refreshPlan.wbsItemUuid,
            body: refreshPlan.body
              ? new ProjectPlanRowBody(refreshPlan.body)
              : undefined,
            revision: refreshPlan.revision,
            updatedAt: refreshPlan.updatedAt,
            updatedBy: refreshPlan.updatedBy,
            createdAt: refreshPlan.createdAt,
            createdBy: refreshPlan.createdBy,
            added: undefined,
            edited: undefined,
            editedData: {},
          }
          refreshedRows.push(newRow)
          updateTreeRows.push(rowToOriginalTree(newRow))
          originalData.current.set(
            row.uuid,
            _.cloneDeep({
              wbsItem: newRow.body?.wbsItem,
              sprint: newRow.body?.wbsItem.sprint,
              extensions: newRow.body?.extensions,
            })
          )
          return newRow
        })
        .filter(row => !!row) as ProjectPlanNewRow[]

      setDataInternal(rows)
      updateOriginalTree(updateTreeRows)
      return refreshedRows
    },
    [projectUuid, rowToOriginalTree, updateOriginalTree]
  )

  const switchRootWbsItem = useCallback(
    async (newRootProjectPlanUuid: string | undefined) => {
      setRootProjectPlanUuid(newRootProjectPlanUuid)
      const currentQueryParams = new URLSearchParams(window.location.search)
      const newQueryParams: { [key: string]: string } = {}
      currentQueryParams.forEach((value, key) => {
        newQueryParams[key] = value
      })
      if (newRootProjectPlanUuid) {
        newQueryParams['treeRootUuid'] = newRootProjectPlanUuid
      } else {
        delete newQueryParams['treeRootUuid']
      }
      history.replaceState(undefined, '', `?${stringify(newQueryParams)}`)
    },
    []
  )

  return {
    data,
    setData,
    enqueue,
    refresh,
    refreshRow,
    generateUpdateWbsItemDeltaRequest,
    save,
    getEditedAncestor,
    saveSingleRow,
    deleteRows,
    rootProjectPlanUuid,
    rootWbsItem: rootWbsItem.current,
    switchRootWbsItem,
    setProjectPlanBody,
  }
}
