import {
  CreateWbsItemInput,
  UpdateWbsItemDeltaInput,
  WbsItemEntity,
  WbsItemStatus,
  WbsItemType,
} from '../../../../domain/entity/WbsItemEntity'
import { dateTermV2Service } from '../../../../domain/value-object/DateTermV2'
import { entityExtensionValuesVoService } from '../../../../domain/value-object/EntityExtensionValuesVO'
import { wbsItemAdditionalPropertyValuesVoService } from '../../../../domain/value-object/WbsItemAdditionalPropertyValuesVO'
import { WbsItemTypeVO } from '../../../../domain/value-object/WbsItemTypeVO'
import { generateUuid } from '../../../../utils/uuids'
import {
  isTextValueDiffered,
  isSelectValueDiffered,
  isReferencedEntityValueDiffered,
  isNumberValueDiffered,
  isDateTermValueDiffered,
  toTextDeltaValue,
  toSelectDeltaValue,
  toReferencedEntityDeltaValue,
  toNumberDeltaValue,
  toDateTermDeltaValue,
} from './properties'

type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>

export type WbsItemInitialValue = Partial<WbsItemEntity>

export type WbsItemFormModel = PartialBy<
  Omit<WbsItemEntity, 'projectUuid'>,
  | 'displayName'
  | 'code'
  | 'status'
  | 'createdBy'
  | 'createdAt'
  | 'updatedBy'
  | 'updatedAt'
  | 'revision'
>
export type WbsItemEditableField = keyof Omit<
  WbsItemFormModel,
  | 'uuid'
  | 'type'
  | 'code'
  | 'actualHour'
  | 'createdBy'
  | 'createdAt'
  | 'updatedBy'
  | 'updatedAt'
  | 'revision'
>

export const createInitialValue = (
  type: WbsItemType,
  initialValue?: WbsItemInitialValue
): WbsItemFormModel => {
  return {
    ...(initialValue || {}),
    uuid: initialValue?.uuid || generateUuid(),
    type,
    status: initialValue?.status || WbsItemStatus.TODO,
  }
}

export type UpdateFormModel = <
  K extends keyof WbsItemFormModel,
  V extends WbsItemFormModel[K]
>(
  path: K,
  value: V
) => void
export type UpdateFormModelValue<K extends keyof WbsItemFormModel> = (
  value: WbsItemFormModel[K]
) => void

export const toCreateInput = (
  model: WbsItemFormModel,
  wbsItemType: WbsItemTypeVO
): CreateWbsItemInput => {
  const input: CreateWbsItemInput = {
    uuid: model.uuid,
    type: model.type,
    code: model.code || '',
    typeUuid: wbsItemType.uuid,
    status: model.status || WbsItemStatus.TODO,
    substatus: model.substatus,
    displayName: model.displayName || '',
    description: model.description,
    startIf: undefined, // Because this does not exist on WbsItem SingleSheet.
    completeIf: undefined, // Because this does not exist on WbsItem SingleSheet.
    rule: undefined, // Because this does not exist on WbsItem SingleSheet.
    teamUuid: model.team?.uuid,
    accountableUuid: model.accountable?.uuid,
    responsibleUuid: model.responsible?.uuid,
    assigneeUuid: model.assignee?.uuid,
    watchers: model.watchers ? model.watchers.map(v => v.uuid) : undefined,
    closedByUuid: undefined, // Because this does not exist on WbsItem SingleSheet.
    critical: undefined, // Because this does not exist on WbsItem SingleSheet.
    deadline: undefined, // Because this does not exist on WbsItem SingleSheet.
    difficulty: model.difficulty,
    estimatedStoryPoint: model.estimatedStoryPoint,
    estimatedHour: model.estimatedHour,
    actualHour: model.actualHour,
    estimatedAmount: undefined, // Because this does not exist on WbsItem SingleSheet.
    actualAmount: undefined, // Because this does not exist on WbsItem SingleSheet.
    priority: model.priority,
    scheduledDate: model.scheduledDate
      ? dateTermV2Service.fromVoToApiRequest(model.scheduledDate)
      : undefined,
    actualDate: model.actualDate
      ? dateTermV2Service.fromVoToApiRequest(model.actualDate)
      : undefined,
    sprintUuid: model.sprint?.uuid,
    extensions: model.entityExtensionValues
      ? entityExtensionValuesVoService.serialize(model.entityExtensionValues)
      : undefined,
    additionalPropertyValues: model.additionalPropertyValues
      ? wbsItemAdditionalPropertyValuesVoService.serialize(
          model.additionalPropertyValues
        )
      : undefined,
  }
  return input
}

export const toDeltaInput = (
  entity: WbsItemEntity,
  model: WbsItemFormModel
): UpdateWbsItemDeltaInput => {
  const input: UpdateWbsItemDeltaInput = {
    uuid: entity.uuid,
    type: entity.type,
  }
  // TODO: Consider to simplify, maybe by defining field properties.
  const updatableFields: WbsItemEditableField[] = [
    'displayName',
    'description',
    'startIf',
    'completeIf',
    'status',
    'substatus',
    'difficulty',
    'priority',
    'team',
    'accountable',
    'responsible',
    'assignee',
    'estimatedStoryPoint',
    'estimatedHour',
    'sprint',
    'scheduledDate',
    'actualDate',
    'entityExtensionValues',
    'additionalPropertyValues',
  ]
  for (let field of updatableFields) {
    if (field === 'entityExtensionValues') {
      // TODO Remove after all entity extensions are transfered.
      input['extensions'] = entityExtensionValuesVoService.serializeDelta(
        entity.entityExtensionValues,
        model.entityExtensionValues
      )
    } else if (field === 'additionalPropertyValues') {
      input[field] = wbsItemAdditionalPropertyValuesVoService.serializeDelta(
        entity.additionalPropertyValues,
        model.additionalPropertyValues
      )
    } else if (isValueDiffered(entity, model, field)) {
      input[toDeltaInputField(field)] = toDeltaValue(entity, model, field)
    }
  }
  return input
}

const toDeltaInputField = (field: WbsItemEditableField): string => {
  switch (field) {
    case 'team':
    case 'accountable':
    case 'responsible':
    case 'assignee':
    case 'sprint':
      return `${field}Uuid`
    default:
      return field
  }
}
const isValueDiffered = (
  entity: WbsItemEntity,
  model: WbsItemFormModel,
  path: WbsItemEditableField
) => {
  switch (path) {
    case 'displayName':
    case 'description':
    case 'startIf':
    case 'completeIf':
      return isTextValueDiffered(entity[path], model[path])
    case 'status':
    case 'substatus':
    case 'difficulty':
    case 'priority':
      return isSelectValueDiffered(entity[path], model[path])
    case 'team':
    case 'accountable':
    case 'responsible':
    case 'assignee':
      return isReferencedEntityValueDiffered(entity[path], model[path])
    case 'estimatedStoryPoint':
    case 'estimatedHour':
      return isNumberValueDiffered(entity[path], model[path])
    case 'sprint':
      return isReferencedEntityValueDiffered(entity[path], model[path])
    case 'scheduledDate':
    case 'actualDate':
      return isDateTermValueDiffered(entity[path], model[path])
  }
  return false
}

const toDeltaValue = (
  entity: WbsItemEntity,
  model: WbsItemFormModel,
  path: WbsItemEditableField
) => {
  switch (path) {
    case 'displayName':
    case 'description':
    case 'startIf':
    case 'completeIf':
      return toTextDeltaValue(entity[path], model[path])
    case 'status':
    case 'substatus':
    case 'difficulty':
    case 'priority':
      return toSelectDeltaValue(entity[path], model[path])
    case 'team':
    case 'accountable':
    case 'responsible':
    case 'assignee':
      return toReferencedEntityDeltaValue(entity[path], model[path])
    case 'estimatedStoryPoint':
    case 'estimatedHour':
      return toNumberDeltaValue(entity[path], model[path])
    case 'sprint':
      return toReferencedEntityDeltaValue(entity[path], model[path])
    case 'scheduledDate':
    case 'actualDate':
      return toDateTermDeltaValue(entity[path], model[path])
  }
  return undefined
}
