import { CellPropsBase, CellTitle, CellValueArea, FormCell } from '.'
import { useCallback } from 'react'
import { SelectOption } from '../../../../model/selectOption'
import { Select } from '../../../../components/select'
import { renderOptionWithIcon } from '../../../../components/select/variations'

type SelectCellProps = {
  value: string | undefined
  onChange: (v: string | undefined) => void
  options: SelectOption[]
  displayIcon?: boolean
} & CellPropsBase

export const SelectCell = ({
  label,
  options,
  value,
  onChange,
  cellWidth,
  required,
  validationErrors,
  displayIcon = false,
}: SelectCellProps) => {
  const renderOption = useCallback(
    (option: SelectOption) => {
      if (displayIcon) {
        return renderOptionWithIcon(option)
      }
      return <>{option.name}</>
    },
    [displayIcon]
  )
  const renderValue = useCallback(
    (value: SelectOption | undefined) => {
      if (!value) {
        return <></>
      }
      if (displayIcon) {
        return renderOptionWithIcon(value)
      }
      return <>{value.name}</>
    },
    [displayIcon]
  )
  return (
    <FormCell cellWidth={cellWidth}>
      <CellTitle title={label} required={required} />
      <CellValueArea validationErros={validationErrors}>
        <Select
          value={value}
          onChange={onChange}
          options={options}
          clearable={true}
          renderOption={renderOption}
          renderValue={renderValue}
        />
      </CellValueArea>
    </FormCell>
  )
}
