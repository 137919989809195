import { useCallback, useEffect, useMemo, useState } from 'react'
import { ResizableArea } from '../../../../components/draggers/ResizableArea'
import {
  MainArea,
  PanelRoot,
  ResizableMainArea,
  SideBarArea,
  SingleSheetRoot,
} from '../../../../containers/SingleSheetV2'
import {
  Tab,
  Tabs,
  TabMainArea,
  TabPanelArea,
} from '../../../../containers/SingleSheetV2/Tab'
import { TextCell } from '../../../../containers/SingleSheetV2/table/cells/TextCell'
import { PageArea } from '../../..'
import { WbsItemType } from '../../../../../domain/entity/WbsItemEntity'
import { TableRow } from '../../../../containers/SingleSheetV2/table/cells'
import { TableHeader } from '../../../../containers/SingleSheetV2/table/Header'
import { SelectCell } from '../../../../containers/SingleSheetV2/table/cells/SelectCell'
import { AutocompleteCell } from '../../../../containers/SingleSheetV2/table/cells/AutocompleteCell'
import { TicketProps } from '../..'
import { MultiAutocompleteCell } from '../../../../containers/SingleSheetV2/table/cells/MultiAutocompleteCell'
import { WorkloadCell } from '../../../../containers/SingleSheetV2/table/cells/WorkloadCell'
import { MultilineTextEditorTab } from '../tab-panel/multiline-text'
import { ProjectPlanTab } from '../tab-panel/project-plan'
import { AttachmentTab } from '../tab-panel/attachment'
import { DiscussionTab } from '../tab-panel/discussion'
import { TaskActualWorkTabPanel } from '../tab-panel/TaskActualWorkTabPanel'
import { WbsItemHeader } from '../header'
import { TaskSubHeader } from '../subheader'
import { DateTermCell } from '../../../../containers/SingleSheetV2/table/cells/DateTermCell'
import { DateTimeCell } from '../../../../containers/SingleSheetV2/table/cells/DateTimeCell'
import { DevelopmentTabPanel } from '../tab-panel/DevelopmentTabPanel'
import { ChangeLogTabPanel } from '../tab-panel/change-log'
import { WbsStatusCell } from '../../../../containers/SingleSheetV2/table/cells/WbsStatusCell'
import { useWbsItemSelectOptions } from '../../hooks/wbsItemSelectOptions'
import Loading from '../../../../components/process-state-notifications/Loading'
import { WbsTagsCell } from '../../../../containers/SingleSheetV2/table/cells/WbsTagsCell'
import { useAttachmentHandlers } from '../../hooks/attachments'
import { useWbsItemPropertySearch } from '../../hooks/wbsItemPropertySearch'
import { useWbsItemModelModifier } from '../../hooks/wbsItemModelModifier'
import { useTicketListSearch } from '../../hooks/tickets/ticketListSearch'
import { SingleSheetWrapper } from '.'
import { useTicketSingleSheetData } from '../../hooks/tickets/data'
import { useWbsItemLabel } from '../../hooks/label'
import { useWbsItemChangeLogParser } from '../../hooks/wbsItemChangeLogParser'
import { WbsItemAdditionalPropertyCells } from '../cell/WbsItemAdditionalProperties'
import { useWbsItemAdditionalPropertiesBySingleType } from '../../../../hooks/useWbsItemAdditionalProperties'
import { useWbsItemEntityExtension } from '../../hooks/wbsItemEntityExtension'
import {
  WbsItemEntityExtensionCells,
  WbsItemEntityExtensionCellsUnderDefaultHeader,
} from '../cell/WbsItemEntityExtensionCells'
import { useSingleSheetKeyBind } from '../../../../containers/SingleSheetV2/hooks/keyBind'
import CancelConfirmDialog, {
  useCancelConfirmDialog,
} from '../../../../components/dialogs/CancelConfirmDialog'
import TaskActualWorkRegistrationDialog, {
  useTaskActualWorkRegistrationDialog,
} from '../dialog/TaskActualWorkRegistrationDialog'
import { useResizableSingleSheet } from '../../../../containers/SingleSheetV2/hooks/resizableArea'
import {
  useWbsItemLeftTabs,
  WbsItemLeftTab,
  WbsItemLeftTabs,
} from '../tab/LeftTabs'
import {
  TaskRightTabs,
  useWbsItemRightTabs,
  WbsItemRightTab,
  WbsItemRightTabs,
} from '../tab/RightTabs'
import { useBrowserTabNameSetter } from '../../hooks/browserTabNameSetter'
import { useSingleSheetFormValidation } from '../../../../containers/SingleSheetV2/hooks/formValidation'
import {
  SingleSheetProperty,
  SingleSheetPropertyType,
} from '../../../../containers/SingleSheetV2/model/singleSheetProperty'
import { useSubmitButton } from '../../../../containers/SingleSheetV2/hooks/submitButton'

export const Ticket = ({
  projectUuid,
  wbsItemType,
  uuid,
  functionUuid,
  ticketType,
  functionLayerDepth,
  ticketInitialValue,
}: TicketProps) => {
  const singleSheetRef = useResizableSingleSheet<HTMLDivElement>()
  const {
    initialized,
    isCreatingWbsItem,
    edited,
    isLoading,
    wbsItemFormModel,
    ticketFormModel,
    onChangeWbsItem,
    onChangeTicketList,
    onChangeEntityExtensionValues,
    reload,
    submit,
  } = useTicketSingleSheetData(uuid, wbsItemType, ticketInitialValue)
  const ticketProperties = useMemo<SingleSheetProperty[]>(() => {
    return [
      {
        id: 'displayName',
        type: SingleSheetPropertyType.TEXT,
        label: '名称',
        required: true,
      },
    ]
  }, [])
  const {
    validate,
    validateWbsItemAdditionalPropertyValue,
    validateEntityExtensionPropertyValue,
    validationErrors,
    hasValidationErrors,
  } = useSingleSheetFormValidation(ticketProperties)
  useBrowserTabNameSetter(
    wbsItemFormModel.displayName || '',
    functionLayerDepth,
    initialized
  )
  const { leftTab, onChangeLeftTab } = useWbsItemLeftTabs(isCreatingWbsItem)
  const { rightTab, onChangeRightTab } = useWbsItemRightTabs(isCreatingWbsItem)
  const {
    subStatuses,
    difficulties,
    priorities,
    getSubStatusByStatus,
    getStatusBySubStatus,
  } = useWbsItemSelectOptions(projectUuid, WbsItemType.TASK, ticketType)
  const { searchTicketList } = useTicketListSearch(projectUuid)
  const { searchTeam, searchMember, searchSprint } = useWbsItemPropertySearch(
    projectUuid,
    wbsItemFormModel
  )
  const { wbsItemAdditionalProperties } =
    useWbsItemAdditionalPropertiesBySingleType(projectUuid, wbsItemType)
  const {
    groupHeaders: wbsItemEntityExtensionPropertyGroupHeaders,
    entityExtensionProperties,
    childrenUnderBasicInfoHeader:
      childEntityExtensionPropertiesUnderBasicInfoHeader,
    childrenUnderPlanHeader: childEntityExtensionPropertiesUnderPlanHeader,
  } = useWbsItemEntityExtension(functionUuid, projectUuid, ticketType)

  const getChangeLogParser = useWbsItemChangeLogParser(
    {
      subStatuses,
      difficulties,
      priorities,
    },
    entityExtensionProperties,
    wbsItemAdditionalProperties
  )

  const {
    onChangeDisplayName,
    onChangeStatus,
    onChangeSubStatus,
    onChangeTags,
    onChangeTeam,
    onChangeAccountable,
    onChangeResponsible,
    onChangeAssignee,
    onChangeWatchers,
    onChangeDifficulty,
    onChangePriority,
    onChangeEstimatedHour,
    onChangeSprint,
    onChangeScheduledDate,
    onChangeActualDate,
    onChangeDescription,
    onChangeAdditionalPropertyValues,
  } = useWbsItemModelModifier(
    onChangeWbsItem,
    {
      getStatusBySubStatus,
      getSubStatusByStatus,
    },
    validate
  )

  const { deliverableAttachments, addAttachments, deleteAttachments } =
    useAttachmentHandlers(uuid, wbsItemFormModel.deliverableAttachments)
  const labelMap = useWbsItemLabel(
    entityExtensionProperties,
    wbsItemAdditionalProperties
  )
  const {
    statusLabel,
    subStatusLabel,
    priorityLabel,
    teamLabel,
    accountableLabel,
    responsibleLabel,
    assigneeLabel,
    scheduledDateLabel,
    actualDateLabel,
    estimatedHourLabel,
    actualHourLabel,
  } = useMemo(() => {
    return {
      statusLabel: labelMap.get('status') || '',
      subStatusLabel: labelMap.get('substatus') || '',
      priorityLabel: labelMap.get('priority') || '',
      teamLabel: labelMap.get('team') || '',
      accountableLabel: labelMap.get('accountable') || '',
      responsibleLabel: labelMap.get('responsible') || '',
      assigneeLabel: labelMap.get('assignee') || '',
      scheduledDateLabel: labelMap.get('scheduledDate') || '',
      actualDateLabel: labelMap.get('actualDate') || '',
      estimatedHourLabel: labelMap.get('estimatedHour') || '',
      actualHourLabel: labelMap.get('actualHour') || '',
    }
  }, [labelMap, labelMap.size])

  const { onClickSubmitButton, submitButtonIsActive } = useSubmitButton(
    submit,
    edited,
    hasValidationErrors
  )
  useSingleSheetKeyBind(submit)
  const { openDialog: openCancelConfirmDialog, ...cancelConfirmDialogProps } =
    useCancelConfirmDialog(reload)
  const {
    openDialog: openTaskActualWorkRegistrationDialog,
    ...taskActualWorkRegistrationDialogProps
  } = useTaskActualWorkRegistrationDialog()

  return (
    <PageArea>
      <Loading isLoading={isLoading} />
      {initialized && (
        <>
          <WbsItemHeader
            uuid={uuid}
            isCreatingWbsItem={isCreatingWbsItem}
            title={wbsItemFormModel.displayName || ''}
            onChangeTitle={onChangeDisplayName}
            type={wbsItemType}
            watchers={wbsItemFormModel.watchers}
            onChangeWatchers={onChangeWatchers}
            functionLayerDepth={functionLayerDepth}
          />
          <TaskSubHeader
            projectUuid={projectUuid}
            submit={onClickSubmitButton}
            reload={edited ? openCancelConfirmDialog : reload}
            submitIsActive={submitButtonIsActive}
            model={wbsItemFormModel}
            statusLabel={statusLabel}
            subStatusLabel={subStatusLabel}
            priorityLabel={priorityLabel}
            teamLabel={teamLabel}
            accountableLabel={accountableLabel}
            responsibleLabel={responsibleLabel}
            assigneeLabel={assigneeLabel}
            scheduledDateLabel={scheduledDateLabel}
            actualDateLabel={actualDateLabel}
            estimatedHourLabel={estimatedHourLabel}
            actualHourLabel={actualHourLabel}
            onChangeStatus={onChangeStatus}
            onChangeSubStatus={onChangeSubStatus}
            onChangePriority={onChangePriority}
            onChangeTeam={onChangeTeam}
            onChangeAccountable={onChangeAccountable}
            onChangeResponsible={onChangeResponsible}
            onChangeAssignee={onChangeAssignee}
            onChangeScheduledDate={onChangeScheduledDate}
            onChangeActualDate={onChangeActualDate}
            onChangeEstimatedHour={onChangeEstimatedHour}
            subStatuses={subStatuses}
            priorities={priorities}
            isCreatingWbsItem={isCreatingWbsItem}
            onClickTaskActualWorkRegistrationButton={
              openTaskActualWorkRegistrationDialog
            }
          />
          <SingleSheetWrapper>
            <SingleSheetRoot ref={singleSheetRef}>
              <ResizableMainArea singleSheetRootRef={singleSheetRef}>
                <TabPanelArea>
                  <WbsItemLeftTabs
                    isCreatingWbsItem={isCreatingWbsItem}
                    leftTab={leftTab}
                    onChangeLeftTab={onChangeLeftTab}
                  />
                  <TabMainArea>
                    <PanelRoot>
                      {leftTab === WbsItemLeftTab.BASIC_INFORMATION && (
                        <>
                          <TableHeader title="基本情報" />
                          <TableRow>
                            <TextCell
                              label="コード"
                              cellWidth="half"
                              value={wbsItemFormModel.code}
                              required={true}
                              editable={false}
                            />
                          </TableRow>
                          <TableRow>
                            <AutocompleteCell
                              label="チケット管理表"
                              cellWidth="half"
                              value={ticketFormModel.ticketList}
                              onChange={onChangeTicketList}
                              search={searchTicketList}
                              displayIcon={true}
                              required={true}
                              validationErrors={validationErrors['ticketList']}
                            />
                          </TableRow>
                          <TableRow>
                            <TextCell
                              label="親WBS"
                              cellWidth="half"
                              value={ticketFormModel.parentWbsItem?.name}
                              required={true}
                              editable={false}
                            />
                          </TableRow>
                          <TableRow>
                            <TextCell
                              label="名称"
                              cellWidth="full"
                              value={wbsItemFormModel.displayName}
                              onChange={onChangeDisplayName}
                              required={true}
                              validationErrors={validationErrors['displayName']}
                            />
                          </TableRow>
                          <TableRow>
                            <WbsStatusCell
                              label="ステータス"
                              cellWidth="half"
                              value={wbsItemFormModel.status}
                              onChange={onChangeStatus}
                              required={true}
                              validationErrors={validationErrors['status']}
                            />
                          </TableRow>
                          <TableRow>
                            <SelectCell
                              label="サブステータス"
                              cellWidth="half"
                              value={wbsItemFormModel.substatus}
                              options={subStatuses}
                              onChange={onChangeSubStatus}
                              validationErrors={validationErrors['substatus']}
                            />
                          </TableRow>
                          <TableRow>
                            <WbsTagsCell
                              projectUuid={projectUuid}
                              label="タグ"
                              cellWidth="half"
                              value={wbsItemFormModel.tags || []}
                              onChange={onChangeTags}
                              validationErrors={validationErrors['tags']}
                            />
                          </TableRow>
                          <WbsItemEntityExtensionCellsUnderDefaultHeader
                            defaultHeaderId="basic-info"
                            projectUuid={projectUuid}
                            wbsItemUuid={uuid}
                            entityExtensionProperties={
                              childEntityExtensionPropertiesUnderBasicInfoHeader
                            }
                            wbsItemEntityExtensionValues={
                              ticketFormModel.entityExtensionValues
                            }
                            onChange={onChangeEntityExtensionValues}
                            validate={validateEntityExtensionPropertyValue}
                            validationErrors={validationErrors}
                          />
                          <TableHeader title="担当" />
                          <TableRow>
                            <AutocompleteCell
                              label="チーム"
                              cellWidth="half"
                              value={wbsItemFormModel.team}
                              onChange={onChangeTeam}
                              search={searchTeam}
                              displayIcon={true}
                            />
                          </TableRow>
                          <TableRow>
                            <AutocompleteCell
                              label="責任者"
                              cellWidth="half"
                              value={wbsItemFormModel.accountable}
                              onChange={onChangeAccountable}
                              search={searchMember}
                              displayIcon={true}
                            />
                          </TableRow>
                          <TableRow>
                            <AutocompleteCell
                              label="担当者"
                              cellWidth="half"
                              value={wbsItemFormModel.responsible}
                              onChange={onChangeResponsible}
                              search={searchMember}
                              displayIcon={true}
                            />
                          </TableRow>
                          <TableRow>
                            <AutocompleteCell
                              label="アサイン先"
                              cellWidth="half"
                              value={wbsItemFormModel.assignee}
                              onChange={onChangeAssignee}
                              search={searchMember}
                              displayIcon={true}
                            />
                          </TableRow>
                          <TableRow>
                            <MultiAutocompleteCell
                              label="ウォッチャー"
                              cellWidth="half"
                              value={wbsItemFormModel.watchers || []}
                              onChange={onChangeWatchers}
                              search={searchMember}
                            />
                          </TableRow>
                          <TableHeader title="期間・見積もり" />
                          <TableRow>
                            <SelectCell
                              label="難易度"
                              cellWidth="half"
                              value={wbsItemFormModel.difficulty}
                              onChange={onChangeDifficulty}
                              options={difficulties}
                            />
                            <SelectCell
                              label="優先度"
                              cellWidth="half"
                              value={wbsItemFormModel.priority}
                              onChange={onChangePriority}
                              options={priorities}
                              displayIcon={true}
                            />
                          </TableRow>
                          <TableRow>
                            <WorkloadCell
                              label="見積り工数"
                              cellWidth="half"
                              value={wbsItemFormModel.estimatedHour}
                              onChange={onChangeEstimatedHour}
                            />
                            <AutocompleteCell
                              label="スプリント"
                              cellWidth="half"
                              value={wbsItemFormModel.sprint}
                              onChange={onChangeSprint}
                              search={searchSprint}
                            />
                          </TableRow>
                          <TableRow>
                            <DateTermCell
                              label="予定期間"
                              cellWidth="half"
                              value={wbsItemFormModel.scheduledDate}
                              onChange={onChangeScheduledDate}
                            />
                          </TableRow>
                          <TableRow>
                            <DateTermCell
                              label="実績期間"
                              cellWidth="half"
                              value={wbsItemFormModel.actualDate}
                              onChange={onChangeActualDate}
                            />
                          </TableRow>
                          <WbsItemEntityExtensionCellsUnderDefaultHeader
                            defaultHeaderId="plan"
                            projectUuid={projectUuid}
                            wbsItemUuid={uuid}
                            entityExtensionProperties={
                              childEntityExtensionPropertiesUnderPlanHeader
                            }
                            wbsItemEntityExtensionValues={
                              ticketFormModel.entityExtensionValues
                            }
                            onChange={onChangeEntityExtensionValues}
                            validate={validateEntityExtensionPropertyValue}
                            validationErrors={validationErrors}
                          />
                          <WbsItemEntityExtensionCells
                            projectUuid={projectUuid}
                            wbsItemUuid={uuid}
                            wbsItemEntityExtensionPropertyGroupHeaders={
                              wbsItemEntityExtensionPropertyGroupHeaders
                            }
                            wbsItemEntityExtensionValues={
                              ticketFormModel.entityExtensionValues
                            }
                            onChange={onChangeEntityExtensionValues}
                            validate={validateEntityExtensionPropertyValue}
                            validationErrors={validationErrors}
                          />
                          <WbsItemAdditionalPropertyCells
                            projectUuid={projectUuid}
                            wbsItemUuid={uuid}
                            wbsItemAdditionalProperties={
                              wbsItemAdditionalProperties
                            }
                            wbsItemAdditionalPropertyValues={
                              wbsItemFormModel.additionalPropertyValues
                            }
                            onChange={onChangeAdditionalPropertyValues}
                            validate={validateWbsItemAdditionalPropertyValue}
                            validationErrors={validationErrors}
                          />
                          <TableHeader title="更新履歴" />
                          <TableRow>
                            <AutocompleteCell
                              label="登録者"
                              cellWidth="half"
                              value={wbsItemFormModel.createdBy}
                              editable={false}
                              search={async () => []}
                              displayIcon={true}
                            />
                            <DateTimeCell
                              label="登録日時"
                              cellWidth="half"
                              value={wbsItemFormModel.createdAt}
                              editable={false}
                            />
                          </TableRow>
                          <TableRow>
                            <AutocompleteCell
                              label="更新者"
                              cellWidth="half"
                              value={wbsItemFormModel.updatedBy}
                              editable={false}
                              search={async () => []}
                              displayIcon={true}
                            />
                            <DateTimeCell
                              label="更新日時"
                              cellWidth="half"
                              value={wbsItemFormModel.updatedAt}
                              editable={false}
                            />
                          </TableRow>
                          <TableRow>
                            <TextCell
                              label="更新回数"
                              cellWidth="half"
                              value={wbsItemFormModel.revision}
                              editable={false}
                            />
                          </TableRow>
                        </>
                      )}
                      {leftTab === WbsItemLeftTab.DESCRIPTION && (
                        <MultilineTextEditorTab
                          uuid={uuid}
                          externalId="task.description"
                          value={wbsItemFormModel.description}
                          onChange={onChangeDescription}
                        />
                      )}
                      {leftTab === WbsItemLeftTab.SUB_TASK && (
                        <ProjectPlanTab uuid={uuid} />
                      )}
                      {leftTab === WbsItemLeftTab.ATTACHMENT && (
                        <AttachmentTab
                          attachments={deliverableAttachments}
                          acceptingFileTypes={[]}
                          onUpload={addAttachments}
                          onDelete={deleteAttachments}
                        />
                      )}
                    </PanelRoot>
                  </TabMainArea>
                </TabPanelArea>
              </ResizableMainArea>
              <SideBarArea>
                <TabPanelArea>
                  <TaskRightTabs
                    isCreatingWbsItem={isCreatingWbsItem}
                    rightTab={rightTab}
                    onChangeRightTab={onChangeRightTab}
                  />
                  <TabMainArea>
                    <PanelRoot>
                      {rightTab === WbsItemRightTab.DISCUSSION && (
                        <DiscussionTab
                          functionUuid={functionUuid}
                          projectUuid={projectUuid}
                          uuid={uuid}
                        />
                      )}
                      {rightTab === WbsItemRightTab.ACTUAL_WORK && (
                        <TaskActualWorkTabPanel uuid={uuid} />
                      )}
                      {rightTab === WbsItemRightTab.CHANGE_LOG && (
                        <ChangeLogTabPanel
                          uuids={[uuid, ticketFormModel.uuid]}
                          getParser={getChangeLogParser}
                          labelMap={labelMap}
                        />
                      )}
                      {rightTab === WbsItemRightTab.DEVELOPMENT && (
                        <DevelopmentTabPanel uuid={uuid} />
                      )}
                      {/** Show the description tab only during creating wbs item on SingleSheet. */}
                      {rightTab === WbsItemRightTab.DESCRIPTION && (
                        <MultilineTextEditorTab
                          uuid={uuid}
                          externalId="task.description"
                          value={wbsItemFormModel.description}
                          onChange={onChangeDescription}
                        />
                      )}
                    </PanelRoot>
                  </TabMainArea>
                </TabPanelArea>
              </SideBarArea>
            </SingleSheetRoot>
          </SingleSheetWrapper>
          <TaskActualWorkRegistrationDialog
            taskUuid={wbsItemFormModel.uuid}
            {...taskActualWorkRegistrationDialogProps}
          />
          <CancelConfirmDialog {...cancelConfirmDialogProps} />
        </>
      )}
    </PageArea>
  )
}
