import {
  Avatar,
  Box,
  ClickAwayListener,
  Paper,
  Popper,
  styled,
  Typography,
} from '@mui/material'
import { useMemo } from 'react'
import DateVO from '../../../vo/DateVO'
import {
  BorderColor,
  FontWeight,
  TextColor,
} from '../../../styles/commonStyles'
import { format } from 'date-fns'
import { intl } from '../../../i18n'
import { convertDecimalHourToHMS } from '../../../utils/time'

const PaperStyle = styled(Paper)({
  width: '350px',
  borderRadius: '5px',
  fontSize: '11px',
  zIndex: 10,
  boxSizing: 'border-box',
})

const RowBody = styled(Box)({
  maxHeight: '300px',
  overflowY: 'scroll',
})

const Header = styled(Box)({
  height: '32px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  color: TextColor.WHITE,
  backgroundColor: '#888888',
  borderRadius: '5px 5px 0 0',
})

const HeaderDate = styled(Typography)({
  marginLeft: '10px',
  fontSize: '12px',
})

const TaskNameRow = styled(Box)({
  padding: '0 0 0 10px',
  height: '25px',
  overflow: 'hidden',
  boxSizing: 'border-box',
})

const TaskNameBox = styled(Box)({
  padding: '8px 0 0 0',
  display: 'flex',
  flexDirection: 'row',
  maxWidth: '190px',
  height: '100%',
  alignItems: 'center',
})

const StyledAvatar = styled(Avatar)({
  width: 20,
  height: 20,
  margin: '0 5px 0 0',
})

const TaskNameText = styled(Typography)({
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  fontWeight: FontWeight.BOLD,
  padding: '3.5px 8px 0 0',
})

const RowHead = styled(Box)({
  padding: '0 14px',
  display: 'flex',
  margin: '0 20px',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
  height: '32px',
  fontWeight: FontWeight.BOLD,
  borderBottom: `1px solid ${BorderColor.GREY}`,
})

const HeadStart = styled(Typography)({
  fontWeight: FontWeight.BOLD,
  padding: '0 0 0 10px',
})

const HeadEnd = styled(Typography)({
  fontWeight: FontWeight.BOLD,
  padding: '0 0 0 85px',
})

const HeadElapsed = styled(Typography)({
  fontWeight: FontWeight.BOLD,
  padding: '0 0 0 80px',
})

const TimerList = styled(Box)({
  padding: '0 14px',
  display: 'flex',
  margin: '0 20px',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
  height: '32px',
  borderBottom: `1px solid ${BorderColor.GREY}`,
})

const StartTime = styled(Typography)({
  padding: '0 0 0 10px',
})

const EndTime = styled(Typography)({
  padding: '0 0 0 60px',
})

const ElapsedTime = styled(Typography)({
  padding: '0 0 0 60px',
})

const TotalRow = styled(Box)({
  padding: '0 14px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-end',
  height: '32px',
})

const TaskTotalRow = styled(Box)({
  padding: '0 14px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-end',
  height: '32px',
  margin: '0 20px',
})

const Total = styled(Typography)({
  display: 'flex',
  fontWeight: FontWeight.BOLD,
  justifyContent: 'flex-end',
})

interface Props {
  anchorEl: EventTarget
  closePoper: () => void
  targetDate?: string
  timerHistorySumByTasks?: timerHistorySumByTasks
  timerElapsedTimeSum?: number
}

type timerHistorySumByTasks = {
  taskName: string
  iconUrl: string
  timerHistories: {
    taskUuid: string
    userUuid: string
    timerDate: string
    timerStartDateTime: number
    timerEndDateTime: number
    timerElapsedTime: number
  }[]
  taskTotalElapsedTime: number
}[]

export const TimerWorkReportPopper = ({
  anchorEl,
  closePoper,
  targetDate,
  timerHistorySumByTasks,
  timerElapsedTimeSum,
}: Props) => {
  const date = useMemo(
    () => new DateVO(targetDate).format('YYYY/MM/DD(ddd)'),
    [targetDate]
  )
  const open = Boolean(anchorEl)

  return (
    <Popper
      open={open}
      anchorEl={anchorEl as HTMLElement}
      placement="bottom-start"
      modifiers={[
        {
          name: 'offset',
          options: {
            offset: [-320, 0],
          },
        },
      ]}
      style={{ zIndex: 1300 }}
    >
      <ClickAwayListener onClickAway={closePoper}>
        <PaperStyle>
          <Header>
            <HeaderDate>{`${intl.formatMessage({
              id: 'workReport.popper.header.name',
            })}  ${date}`}</HeaderDate>
          </Header>
          <RowBody>
            {timerHistorySumByTasks?.map(timerHistorySumByTask => (
              <body key={timerHistorySumByTask.taskName}>
                <TaskNameRow>
                  <TaskNameBox>
                    <StyledAvatar
                      variant="circular"
                      src={timerHistorySumByTask.iconUrl}
                    />
                    <TaskNameText>
                      {timerHistorySumByTask.taskName}
                    </TaskNameText>
                  </TaskNameBox>
                </TaskNameRow>
                <RowHead>
                  <HeadStart>
                    {intl.formatMessage({
                      id: 'myWbsItems.timer.startTime',
                    })}
                  </HeadStart>
                  <HeadEnd>
                    {intl.formatMessage({
                      id: 'myWbsItems.timer.endTime',
                    })}
                  </HeadEnd>
                  <HeadElapsed>
                    {intl.formatMessage({
                      id: 'myWbsItems.timer.workingHours',
                    })}
                  </HeadElapsed>
                </RowHead>
                {timerHistorySumByTask.timerHistories.map(history => (
                  <TimerList key={history.timerStartDateTime}>
                    <StartTime>
                      {format(new Date(history.timerStartDateTime), 'HH:mm:ss')}
                    </StartTime>
                    <EndTime>
                      {format(new Date(history.timerEndDateTime), 'HH:mm:ss')}
                    </EndTime>
                    <ElapsedTime>
                      {convertDecimalHourToHMS(history.timerElapsedTime)}
                    </ElapsedTime>
                  </TimerList>
                ))}
                <TaskTotalRow>
                  <Total>
                    {`SubTotal: `}
                    {timerHistorySumByTask.taskTotalElapsedTime.toFixed(2)}
                  </Total>
                </TaskTotalRow>
              </body>
            ))}
          </RowBody>
          <TotalRow>
            <Total>
              {`Total: `}
              {timerElapsedTimeSum?.toFixed(2)}
            </Total>
          </TotalRow>
        </PaperStyle>
      </ClickAwayListener>
    </Popper>
  )
}
