import { useEffect } from 'react'
import Auth from '../../../lib/commons/auth'
import { parseUrl } from '../../../utils/urls'

const Signedout = () => {
  useEffect(() => {
    const effect = async () => {
      const tenant = Auth.getCurrentTenant()
      tenant && (await tenant.logoutFromTenant())
      const url = parseUrl(process.env.FLAGXS_DEFAULT_LOCATION_URL!)
      window.location.href = `${url.url}/login`
    }
    effect()
  }, [])
  return null
}

export default Signedout
