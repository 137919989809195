import API from '../commons/api'
import Auth from '../commons/auth'

export const defaultConverse = (input: {
  prompt: string
  history?: string
}) => {
  return API.functional.request('POST', '/api/v1/ai/default-converse', input)
}

export type AiTaskType = 'WBS_DECOMPOSITION' | 'AI_SCHEDULING'

export const determineAiTaskType = async (
  input: string
): Promise<AiTaskType | undefined> => {
  const response = await API.functional.request('GET', '/api/v1/ai/task/type', {
    input,
  })
  return response.json
}

export const taskDecomposition = (input: {
  projectUuid: string
  wbsItemUuid: string
  wbsItemTitle: string
  wbsItemDescription: string
  prompt: string
  history?: string
  tasks?: string // 既存のWBSのタスクをカンマ区切りで渡す
  startDate?: string
  endDate?: string
  type?: string
  inputForScheduling?: {
    scheduledDateTermOfParent?: {
      startDate?: string
      endDate?: string
    }
    // TODO: wbsItemsという名前は適当ではないので変更する
    wbsItems?: {
      treeUuid?: string
      estimatedHour?: number
      assigneeName?: string
    }[]
  }
}) => {
  return API.functional.request('POST', '/api/v1/ai/task-decomposition', input)
}

export type AiChildWbsItemSchedulingOutput = {
  assigneeName: string
  schedule: {
    startDate: string
    endDate: string
  }
  estimatedHour: number
}
export type AiWbsItemSchdulingOutput = {
  treeUuid: string
  assigneeName: string
  schedule: {
    startDate: string
    endDate: string
  }
  children: AiChildWbsItemSchedulingOutput[]
}
export type AiSchedulingOutput = {
  answerText: string
  output: AiWbsItemSchdulingOutput[]
}
export const aiScheduling = async (input: {
  scheduledDateTermOfParent?: {
    startDate?: string
    endDate?: string
  }
  // TODO: wbsItemsという名前は適当ではないので変更する
  wbsItems?: {
    treeUuid?: string
    estimatedHour?: number
    assigneeName?: string
    childCount: number
  }[]
}): Promise<AiSchedulingOutput | undefined> => {
  const response = await API.functional.request(
    'POST',
    '/api/v1/ai/ai-scheduling',
    input
  )
  if (!response.json) {
    return undefined
  }
  return response.json as AiSchedulingOutput
}

export const isAiAvailable = () => {
  if (['development', 'staging'].includes(process.env.FLAGXS_ENV || '')) {
    return true
  }
  const allowAiTenants = (
    process.env.FLAGXS_ALLOW_AI_FEATURE_TENANTS || ''
  ).split(',')
  const currentTenant = Auth.getCurrentTenant()?.organization?.alias
  if (!currentTenant) return false
  return allowAiTenants.includes(currentTenant)
}
