import { styled } from '@mui/system'
import { TextColor } from '../../../../styles/commonStyles'
import { Paper as MuiPaper } from '@mui/material'
import { injectIntl, WrappedComponentProps } from 'react-intl'

const Paper = styled(MuiPaper)({
  color: TextColor.WHITE,
  fontWeight: 600,
  padding: '5px 10px',
  display: 'inline-block',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  position: 'absolute',
  right: '15px',
  top: '60px',
  zIndex: 100000,
})

const EnvironmentInformation = (props: WrappedComponentProps) => {
  const { intl } = props
  const env = process.env.FLAGXS_ENV
  if (env === 'production') {
    return <></>
  }
  const name = intl.formatMessage({ id: `environment.${env}` })
  const backgroundColor = process.env.FLAGXS_ENVIRONMENT_INFO_COLOR
  return (
    <Paper id="environment-label" elevation={3} style={{ backgroundColor }}>
      {name}
    </Paper>
  )
}

export default injectIntl(EnvironmentInformation)
