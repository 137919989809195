import API, { APIResponse } from '../commons/api'
import { SingleSheetRepository } from '../../view/containers/SingleSheet'
import { UserBasic, UserProps } from './user'
import { BusinessClientProps } from './businessClient'
import { DivisionProps } from './division'
import { Tree } from '../commons/tree'
import { IItemDelta } from '../../domain/value-object/ItemDeltaInputVO'
import { DateTerm } from '../../utils/date'
import { dateVoService } from '../../domain/value-object/DateVO'

export enum ProjectStatus {
  STANDBY = 'STANDBY',
  INPROGRESS = 'INPROGRESS',
  SUSPENDED = 'SUSPENDED',
  COMPLETED = 'COMPLETED',
}

export enum WbsItemCodeFormat {
  RANDOM = 'RANDOM',
  SEQUENTIAL = 'SEQUENTIAL',
}

export const getProjectStatusColor = (status: ProjectStatus): string => {
  switch (status) {
    case ProjectStatus.STANDBY:
      return '#F7F7F7'
    case ProjectStatus.INPROGRESS:
      return '#FEFDD3'
    case ProjectStatus.SUSPENDED:
      return '#DDDDDD'
    case ProjectStatus.COMPLETED:
      return '#D4F2FF'
  }
}

export const ASSIGNED_PROJECTS_LIMITS = 10

export interface ProjectInputProps {
  displayName: string
  officialName: string
  iconUrl: string
  divisionUuid: string
  businessClientUuid: string
  projectManagerUuid: string
  scheduledDate: {
    startDate: string
    endDate: string
  }
  mainEmail: string
}

// create
export interface ProjectCreateProps extends ProjectInputProps {
  code: string
}

// update
export interface ProjectUpdateDeltaProps {
  uuid: string
  displayName?: IItemDelta<string>
  officialName?: IItemDelta<string>
  iconUrl?: IItemDelta<string>
  divisionUuid?: IItemDelta<string>
  businessClientUuid?: IItemDelta<string>
  projectManagerUuid?: IItemDelta<string>
  status?: IItemDelta<string>
  scheduledDate?: IItemDelta<DateTerm>
  mainEmail?: IItemDelta<string>
}

// delete
export interface ProjectDeleteProps extends ProjectInputProps {
  uuid: string
  lockVersion: number
}

// projects
export interface ProjectGetProjectsProps {
  searchText?: string
  displayName?: string
  code?: string
  businessClient?: string
  offset?: number
  limit?: number
  sortField?: string
  sortOrder?: string
}

export interface ProjectGetUuidProps {
  code: string
}

export interface ProjectGetProjectResponse {
  uuid: string
  code: string
  displayName: string
  officialName: string
  iconUrl: string
  businessClient: BusinessClientProps
  division: DivisionProps
  scheduledDate: {
    startDate: string
    endDate: string
  }
  mainEmail: string
  projectManager: UserBasic
  revision: string
  updatedBy: UserBasic
  updatedAt: string
  status: ProjectStatus
}

export interface ProjectGetProjectsResponse {
  total: number
  data: ProjectGetProjectResponse[]
}

// detail
export interface ProjectGetDetailProps {
  uuid: string
}
export interface ProjectGetDetailByCodeProps {
  code: string
}

export interface ProjectDetail extends Tree<ProjectDetail> {
  code: string
  displayName: string
  officialName: string
  iconUrl: string
  scheduledDate: {
    startDate: string
    endDate: string
  }
  actualDate: {
    startDate: string
    endDate: string
  }
  division: DivisionProps
  businessClient: BusinessClientProps
  projectManager: UserProps
  assigned: boolean
  status: ProjectStatus
  wbsItemCodeFormat: WbsItemCodeFormat
}

export interface ProjectBasic {
  uuid: string
  code: string
  name: string
  iconUrl: string
}

export const isValidProjectStartDate = (
  startDate: string | number | Date | undefined,
  status: ProjectStatus | undefined
): boolean => {
  if (!status) return false
  const start = dateVoService.construct(startDate)
  if (!dateVoService.isValid(start)) return false

  const today = dateVoService.construct('TODAY')
  const diff = dateVoService.diff(start, today)
  return (
    (ProjectStatus.STANDBY === status && diff >= 0) ||
    (ProjectStatus.STANDBY !== status && diff <= 0)
  )
}

export const isValidProjectEndDate = (
  endDate: string | number | Date | undefined,
  status: ProjectStatus | undefined
): boolean => {
  if (!status) return false
  const end = dateVoService.construct(endDate)
  if (!dateVoService.isValid(end)) return false

  const today = dateVoService.construct('TODAY')
  const diff = dateVoService.diff(end, today)
  return (
    (ProjectStatus.COMPLETED !== status && diff >= 0) ||
    (ProjectStatus.COMPLETED === status && diff <= 0)
  )
}

class Project implements SingleSheetRepository {
  public create = (props: ProjectCreateProps): Promise<APIResponse> => {
    return API.functional.request('POST', '/api/v1/projects', props)
  }

  public update = (props: unknown): Promise<APIResponse> => {
    throw new Error('Can not use project.update method.')
  }

  public updateDelta = (
    props: ProjectUpdateDeltaProps
  ): Promise<APIResponse> => {
    return API.functional.request('PUT', '/api/v1/projects/delta', props)
  }

  public delete = (props: ProjectDeleteProps): Promise<APIResponse> => {
    return API.functional.request('DELETE', '/api/v1/projects', props)
  }

  public getProjects = (
    props: ProjectGetProjectsProps
  ): Promise<APIResponse> => {
    return API.functional.request('GET', '/api/v1/projects/find', props)
  }

  public getDetail = (props: ProjectGetDetailProps): Promise<any> => {
    return API.functional.request(
      'GET',
      '/api/v1/projects/detail',
      {
        projectUuid: props.uuid,
      },
      true
    )
  }
  public getDetailByCode = (
    props: ProjectGetDetailByCodeProps
  ): Promise<any> => {
    return API.functional.request(
      'GET',
      '/api/v1/projects/detail',
      {
        projectCode: props.code,
      },
      true
    )
  }

  public getAssignedProjects = (): Promise<APIResponse> => {
    return API.functional.request('GET', '/api/v1/projects/assigned')
  }

  public getAssignedProjectsByUser = (
    userUuid: string | undefined
  ): Promise<APIResponse> => {
    return API.functional.request('GET', '/api/v1/projects/assigned', {
      userUuid,
    })
  }

  getBasicByCode(code: string): Promise<APIResponse> {
    return API.functional.request('GET', '/api/v1/projects/basic', { code })
  }
}

export default new Project()
