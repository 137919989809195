import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import {
  getOpenWbsItemDetailSpec,
  getWbsItemFunctionUuid,
} from '../../../../../../../../lib/functions/wbsItem'
import { ICellRendererParams, RowNode } from 'ag-grid-community'
import { WbsItemIcon } from '../../../../../../../components/icons/WbsItemIcon'
import store, { AllState } from '../../../../../../../../store'
import { getPathByExternalId } from '../../../../../../../pages'
import { pushFunctionLayer } from '../../../../../../../../store/functionLayer'
import { ClickAwayListener, SxProps, Tooltip } from '@mui/material'
import { FloatingEditableIcon } from '../../common/text'
import { inputErrorColor } from '../../../../lib/validator'
import {
  FONT_FAMILY,
  FontSize,
} from '../../../../../../../../styles/commonStyles'
import { setError } from '../../../../../../BulkSheetView/lib/validation'
import { FunctionProperty } from '../../../../../../../../lib/commons/appFunction'
import validator from '../../../../../../meta/validator'
import { List, Map } from 'immutable'
import { Comment } from '../../../../../../../../store/comments'
import {
  closeComment,
  openComment,
} from '../../../../../../../../store/information'
import CommentHeaderWbsItem, {
  mapRowDataForCommentHeader,
  WbsItemForCommentHeader,
} from '../../../../../../Comment/CommentHeaderWbsItem'
import { useSelector } from 'react-redux'
import { isProduction } from '../../../../../../../../utils/urls'
import styled from '@emotion/styled'
import { colorPalette } from '../../../../../../../style/colorPallete'

const CommentCounterArea = styled('div')({
  height: '18px',
  padding: '4px 6px',
  borderRadius: '4px',
  color: colorPalette.pink[6],
  background: colorPalette.pink[0],
  fontFamily: 'Noto Sans JP',
  fontWeight: 700,
  lineHeight: '10px',
  fontSize: FontSize.SMALL,
  textAlign: 'left',
  cursor: 'pointer',
})

type StateProps = {
  currentDataUuid?: string
  comments: Map<string, List<Comment>>
}

type CommentCounterProps = {
  wbsItem: WbsItemForCommentHeader
  numberOfComments: number | undefined
  style?: CSSProperties
}

const CommentCounter = ({
  wbsItem,
  numberOfComments,
  style,
}: CommentCounterProps) => {
  const { currentDataUuid, comments } = useSelector<AllState, StateProps>(
    state => ({
      // Used to open and close comments
      currentDataUuid: state.information.commentProps?.dataUuid,
      // Used to update numberOfComments when a comment is added or deleted in the discussion on this screen
      comments: state.comments.comments,
    })
  )

  // comment
  const commentOpened = useMemo(
    () => !!currentDataUuid && currentDataUuid === wbsItem?.uuid,
    [currentDataUuid, wbsItem?.uuid]
  )

  const formatedNumberOfComments = useMemo(() => {
    const commentsInThisWbsItem = wbsItem
      ? comments.get(getWbsItemFunctionUuid() + '-' + wbsItem.uuid)
      : undefined

    // state.comments.comments is set only if comments are displayed,
    // so get from valueGetter too
    const numOfComments = commentsInThisWbsItem
      ? commentsInThisWbsItem?.size || 0
      : numberOfComments || 0
    return 0 < numOfComments ? `+${numOfComments}` : undefined
  }, [comments, wbsItem, numberOfComments])

  const onCommentClick = useCallback(
    event => {
      event.preventDefault()
      event.stopPropagation()
      if (commentOpened) {
        store.dispatch(closeComment())
      } else {
        store.dispatch(
          openComment({
            applicationFunctionUuid: getWbsItemFunctionUuid()!,
            dataUuid: wbsItem?.uuid,
            projectUuid: wbsItem?.projectUuid,
            headerComponents: [
              <CommentHeaderWbsItem
                key={1}
                wbsItem={mapRowDataForCommentHeader(wbsItem)}
              />,
            ],
          })
        )
      }
    },
    [commentOpened, wbsItem]
  )

  if (!formatedNumberOfComments) return <></>
  return (
    <CommentCounterArea style={style} onClick={onCommentClick}>
      {formatedNumberOfComments}
    </CommentCounterArea>
  )
}

export class ProjectPlanTreeValue {
  displayName?: string
  numberOfComments?: number
  hasDiscription?: boolean

  constructor({
    displayName,
    numberOfComments = 0,
    hasDiscription = false,
  }: {
    displayName: string
    numberOfComments: number
    hasDiscription: boolean
  }) {
    this.displayName = displayName
    this.numberOfComments = numberOfComments
    this.hasDiscription = hasDiscription
  }
  // for Undo: When undoing, toString is called to get the old value.
  toString = (): string => this.displayName || ''
  equals = (other: ProjectPlanTreeValue) => {
    return (
      this.displayName === other.displayName &&
      this.numberOfComments === other.numberOfComments &&
      this.hasDiscription === other.hasDiscription
    )
  }
  isModified = (other: ProjectPlanTreeValue) => {
    return this.displayName !== other.displayName
  }
}

interface Props extends ICellRendererParams {
  value: ProjectPlanTreeValue | string
  onCloseWbsItemDetail?: (wbsItem) => void
  beforeOpenWbsItemDetail?: (uuid: string) => Promise<boolean | undefined>
  onOpenWbsItemDetail?: () => void
  uiMeta?: Partial<FunctionProperty>
  visibleCommentIcon?: boolean
}

export const ProjectPlanTreeCellRenderer = (props: Props) => {
  const {
    value,
    data,
    uiMeta,
    onCloseWbsItemDetail,
    beforeOpenWbsItemDetail,
    onOpenWbsItemDetail,
    node,
    visibleCommentIcon,
  } = props
  const ref = useRef<HTMLDivElement | null>(null)
  const textRef = useRef<HTMLDivElement | null>(null)
  const [tooltip, setTooltip] = useState('')
  const [openTooltip, setOpenTooltip] = useState(false)
  const isValid = useRef<boolean>(true)
  const [lineClamp, setLineClamp] = useState(1)
  const valueObj = useMemo(
    () =>
      value && typeof value === 'object'
        ? value
        : ({ displayName: value } as ProjectPlanTreeValue),
    [value]
  )

  useEffect(() => {
    const err = uiMeta
      ? validator
          .validate(
            valueObj.displayName,
            data,
            uiMeta as FunctionProperty,
            () => undefined
          )
          ?.getMessage()
      : undefined
    setError(data, err, props)
    setTooltip(err ?? '')
    isValid.current = !err
  }, [valueObj.displayName])

  const openDetail = useCallback(
    async (node: RowNode, event: any) => {
      const d = node.data
      const w = d?.body?.wbsItem ?? d?.wbsItem
      if (!w) return
      if (d.added || d.edited) {
        const success = await beforeOpenWbsItemDetail?.(d.uuid)
        if (!success) return
      }
      onOpenWbsItemDetail && onOpenWbsItemDetail()
      const openDetailSpec = await getOpenWbsItemDetailSpec(true, w)
      const layer = openDetailSpec.layer
      openDetailSpec.onOpen && openDetailSpec.onOpen()
      if (
        !openDetailSpec.openInDialog ||
        (event && (event['ctrlKey'] || event['metaKey']))
      ) {
        window.open(`${getPathByExternalId(layer.externalId)}/${layer.code!}`)
      } else {
        store.dispatch(
          pushFunctionLayer({
            ...layer,
            onClose: async () => {
              onCloseWbsItemDetail?.(w)
            },
          })
        )
      }
    },
    [beforeOpenWbsItemDetail]
  )

  useEffect(() => {
    if (!isValid.current) return
    const offsetWidth = ref.current?.offsetWidth
    const ctx = document.createElement('canvas').getContext('2d')
    if (valueObj.displayName && offsetWidth && ctx) {
      ctx.font = `${FontSize.MEDIUM} "${FONT_FAMILY}"`
      const textWidth = ctx.measureText(valueObj.displayName)?.width
      if (offsetWidth - textWidth < 0) {
        setTooltip(valueObj.displayName)
      }
    }
  }, [valueObj.displayName])

  useEffect(() => {
    const textElems = textRef.current
    if (!textElems) return
    const comStyles = window.getComputedStyle(textElems)
    const lineHeight = parseInt(
      comStyles.getPropertyValue('line-height').replace(/px/g, '')
    )
    const lineNo = Math.trunc(textElems.clientHeight / lineHeight)
    setLineClamp(lineNo > 0 ? lineNo : 1)
  }, [])

  const wbsItem = data.body?.wbsItem ?? data.wbsItem
  const type = wbsItem?.wbsItemType

  if (!type) return <></>
  const description = wbsItem.description

  return (
    <ClickAwayListener onClickAway={() => setOpenTooltip(false)}>
      <Tooltip
        title={tooltip}
        placement={'bottom-start'}
        componentsProps={{
          tooltip: {
            sx: {
              fontSize: `${FontSize.MEDIUM}px`,
              marginTop: '5px !important',
            },
          },
        }}
        open={isProduction ? openTooltip : false}
      >
        <div
          id={`wbs-tree-column-${props['rowIndex']}`}
          className="sevend-ag-cell-project-plan-tree"
          ref={ref}
          onClick={e => setOpenTooltip(!openTooltip)}
          onMouseLeave={e => setOpenTooltip(false)}
        >
          <div className="sevend-ag-cell-project-plan-tree__icon-field">
            <button
              className="sevend-ag-cell-project-plan-tree__icon-button"
              onClick={e => openDetail(node, e)}
            >
              <WbsItemIcon type={type} />
            </button>
            {description && <NotificationDot />}
          </div>
          {visibleCommentIcon && (
            <CommentCounter
              wbsItem={wbsItem}
              numberOfComments={valueObj.numberOfComments}
              style={{ marginLeft: '5px', marginRight: '1px' }}
            />
          )}
          <div
            ref={textRef}
            className="sevend-ag-cell-project-plan-tree__text-field"
            style={{
              backgroundColor:
                valueObj.displayName && isValid.current
                  ? 'none'
                  : inputErrorColor,
              overflow: 'hidden',
            }}
          >
            <div
              style={{
                overflow: 'hidden',
                display: '-webkit-box',
                textOverflow: 'ellipsis',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: lineClamp,
              }}
            >
              {valueObj.displayName}
            </div>
          </div>
          <FloatingEditableIcon {...props} />
        </div>
      </Tooltip>
    </ClickAwayListener>
  )
}

const NotificationDot = () => (
  <p className="sevend-ag-cell-project-plan-tree__annotation-has-data" />
)
