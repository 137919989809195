import { useCallback } from 'react'
import api from '../../lib/commons/api'
import { ProjectMemberRepository } from '../../applications/ports/projectMemberRepository'

export const useProjectMemberRepository = (): ProjectMemberRepository => {
  const searchAsReferencedEntity = useCallback(
    async (projectUuid: string, searchText: string | undefined) => {
      const response = await api.functional.request(
        'GET',
        '/api/v1/projects/members/find',
        { projectUuid, all: searchText },
        true
      )
      // TODO: Consider the logic to assure type in runtime.
      return (
        response.json.data.map(e => ({
          uuid: e.uuid,
          name: e.name,
          iconUrl: e.iconUrl,
        })) || []
      )
    },
    []
  )
  const fetchAsReferencedEntity = useCallback(async (uuid: string) => {
    const response = await api.functional.request(
      'GET',
      '/api/v1/projects/members/referenced_values',
      { userUuid: uuid },
      true
    )
    // TODO: Consider the logic to assure type in runtime.
    return response.json
  }, [])
  const fetchListAsReferencedEntity = useCallback(
    async (projectUuid: string) => {
      const response = await api.functional.request(
        'GET',
        '/api/v1/projects/members',
        { projectUuid },
        true
      )
      // TODO: Consider the logic to assure type in runtime.
      return (
        response.json.map(e => ({
          uuid: e.user.uuid,
          name: e.user.name,
          iconUrl: e.user.iconUrl,
        })) || []
      )
    },
    []
  )
  return {
    searchAsReferencedEntity,
    fetchAsReferencedEntity,
    fetchListAsReferencedEntity,
  }
}
