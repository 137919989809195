import styled from 'styled-components'
import timerStopButton from '../../../../../../../../assets/timer_stop_button.svg'

interface Props {
  iconHeight: number
  iconWidth: number
  onClick: () => void
}

const StopButton = styled('img')<Props>(({ iconHeight, iconWidth }) => ({
  height: iconHeight,
  width: iconWidth,
  cursor: 'pointer',
}))

const TimerStopButton = ({ onClick, iconHeight, iconWidth }: Props) => {
  return (
    <StopButton
      src={timerStopButton}
      onClick={onClick}
      iconHeight={iconHeight}
      iconWidth={iconWidth}
    />
  )
}

export default TimerStopButton
