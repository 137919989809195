import _ from 'lodash'
import { PageArea, PageProps } from '../index'
import store, { AllState } from '../../../store'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  acceptChild,
  ColumnQuickFilterKey,
  createNewProjectPlanNewRow,
  duplicateProjectPlanNewRow,
  duplicateProjectPlanNewRowNameOnly,
  filter,
  getEditedAncestor,
  NewWbsItemRow,
  ProjectPlanFilterRequest,
  ProjectPlanNewRow,
  SelectedColumnQuickFilterKey,
  SelectedColumnQuickFilterKeyType,
} from './projectPlanNew'
import { useProjectPlanNewGridOptions } from './gridOptions'
import {
  BodyScrollEndEvent,
  CellClickedEvent,
  CellEditingStartedEvent,
  ColDef,
  ColumnState,
  ColumnVisibleEvent,
  FilterChangedEvent,
  GetContextMenuItemsParams,
  GridApi,
  MenuItemDef,
  ModelUpdatedEvent,
  RowDataUpdatedEvent,
  RowDragEvent,
  RowGroupOpenedEvent,
  RowNode,
  SortChangedEvent,
} from 'ag-grid-community'
import { BulkSheetView } from '../../containers/BulkSheetView'
import { useBulkSheetState } from '../../containers/BulkSheetView/hooks/bulkSheetState/bulkSheetState'
import Loading from '../../components/process-state-notifications/Loading'
import { useDragTreeStyle } from '../../containers/BulkSheetView/hooks/gridEvents/treeRowDrag'
import {
  focusRow,
  getAllLeafChildren,
  getDisplayedRow,
  getRowNumber,
  getSelectedNode,
} from '../../containers/BulkSheetView/lib/gridApi'
import {
  useDialog,
  usePageState,
  usePopper,
  useProjectPlanNewData,
} from './hooks'
import {
  CustomEnumCode,
  getCustomEnumValues,
} from '../../../lib/functions/customEnumValue'
import { parse } from '../../../lib/commons/i18nLabel'
import project from '../../../lib/functions/project'
import team from '../../../lib/functions/team'
import projectMember from '../../../lib/functions/projectMember'
import sprint, { SprintStatus } from '../../../lib/functions/sprint'
import { exportExcel } from '../../containers/BulkSheet/excel'
import * as XLSX from 'xlsx'
import { intl } from '../../../i18n'
import { generateUuid } from '../../../utils/uuids'
import {
  addGlobalMessage,
  addScreenMessage,
  MessageLevel,
} from '../../../store/messages'
import { WbsItemStatus } from '../../containers/commons/AgGrid/components/cell/custom/wbsItemStatus'
import { sortByRowIndex } from '../../containers/BulkSheetView/lib/rowNode'
import {
  moveRowsToLastChild,
  slideRowsAfter,
  slideRowsBefore,
} from '../../containers/BulkSheetView/hooks/actions/moveTreeRows'
import {
  addRowsBefore,
  addRowsBelow,
  addRowsToLastChild,
  removeRows,
} from '../../containers/BulkSheetView/hooks/actions/crudTreeRows'
import {
  addRowAboveMenuItems,
  addRowsToChildMenuItems,
  addRowToChildMenuItems,
  addTicketMenuItems,
  addWorkgroupMenuItems,
  changeProcessToWorkgroupMenuItems,
  deleteRowMenuItems,
  reportMenuItems,
  switchRootWbsMenuItems,
} from './gridOptions/contextMenu'
import {
  addCopyURLAndNameMenuItems,
  addCopyURLMenuItems,
  copyAllTreeMenuItems,
  copyTreeMenuItems,
  cutTreeMenuItems,
  focusCellAfterCopy,
  groupRowMenuItems,
  pasteCutTreeMenuItems,
  pasteTreeMenuItems,
} from '../../containers/BulkSheetView/gridOptions/contextMenu'
import { DeleteRowConfirmationDialog } from '../../containers/BulkSheetView/components/dialog/DeleteRowConfirmationDialog'
import { getGanttChartWidth } from '../ProjectPlan/projectPlanOptions'
import { AddMultipleTicketDialog } from '../../components/dialogs/AddMultipleTicketDialog'
import TicketListAPI, {
  TicketListDetail,
} from '../../../lib/functions/ticketList'
import DateVO from '../../../vo/DateVO'
import TaskActualWorkDialog from '../../components/dialogs/TaskActualWorkDialog'
import { useKeyBind } from '../../hooks/useKeyBind'
import { KEY_ESC, KEY_SAVE, KEY_SPACE } from '../../model/keyBind'
import AddRowCountInputDialog from '../../containers/BulkSheet/AddRowCountInputDialog'
import MultiSelectDialog, {
  MultiSelectDialogSelection,
} from '../../components/dialogs/MultiSelectDialog'
import objects from '../../../utils/objects'
import { ProjectPlanCumulation } from '../../../lib/functions/projectPlan'
import { StatusChangePopper } from '../../components/poppers/StatusChangePopper'
import { ExcelParser } from './excel'
import { WbsItemTypeVO } from '../../../domain/value-object/WbsItemTypeVO'
import { extractValuesFromResponse } from '../../../lib/commons/api'
import { handleWarning } from '../../../handlers/globalErrorHandler'
import CancelConfirmDialog from '../../components/dialogs/CancelConfirmDialog'
import { DateFilterOperator } from '../../containers/BulkSheetView/components/filter'
import { getParent } from '../../containers/BulkSheetView/lib/tree'
import { copyRows } from '../../containers/BulkSheetView/hooks/actions/copyTreeRows'
import { getExtensions } from '../../../lib/functions/entityExtension'
import appFunctionAPI, {
  ApplicationFunctionPropertyConfiguration,
  FunctionProperty,
  PropertyType,
} from '../../../lib/commons/appFunction'
import BoolExpression from '../../../utils/boolExpression'
import SearchOptions from '../../../utils/searchOptions'
import repositories from '../../containers/meta/repositories'
import { GanttParameterVO } from '../../../domain/value-object/GanttParameterVO'
import { projectPrivate } from '../../higher-order-components/projectPrivate'
import { connect, useDispatch, useSelector } from 'react-redux'
import DateExpression from '../../../utils/dateExpression'
import { generateGanttScale } from '../../containers/commons/AgGrid/components/cell/custom/gantt/ganttUtil'
import {
  InputError,
  addInputError,
} from '../../containers/BulkSheetView/lib/validation'
import { useProjectPrivateContext } from '../../context/projectContext'
import { CUSTOM_ENUM_NONE } from '../../../lib/commons/customEnum'
import SavedUIStateDialog from '../../components/dialogs/SavedUIStateDialog'
import uiStates, {
  UiStateKey,
  UiStateScope,
} from '../../../lib/commons/uiStates'
import { SavedUIState } from '../../components/dialogs/SavedUIStateDialog/SavedUIStateList'
import { ReportReCalculator } from './gridOptions/cumulation'
import '../../../styles/agGrid.scss'
import { TagForWbsItem, fetchTags } from '../../../lib/functions/tag'
import { receivedTags } from '../../../store/tag'
import { useWorkloadUnit } from '../../hooks/useWorkloadUnit'
import ProjectPlanNewWbsHeader from './components/Header/ProjectPlanNewWbsHeader'
import ProjectPlanNewReport from './components/Header/ProjectPlanNewReport'
import ColumnSettingPopper from '../../containers/BulkSheetView/components/columnSelector/ColumnSettingPopper'
import SavePopper from '../../containers/BulkSheetView/components/header/SaveButtonArea'
import ProjectPlanNewBreadcrumb from './components/Header/ProjectPlanNewBreadcrumb'
import { useColumnSetting } from '../../containers/BulkSheetView/components/columnSelector/useColumnSetting'
import { FunctionLayer } from '../../../store/functionLayer'
import { SortedColumnState } from '../../model/bulkSheetColumnSortState'
import AlertDialog from '../../components/dialogs/AlertDialog'
import usePrevious from '../../hooks/usePrevious'
import { useLocation } from 'react-router'
import {
  runAsyncWithPerfMonitoring,
  runWithPerfMonitoring,
} from '../../../utils/monitoring'
import { needToOpenInNewTab } from '../../router'
import {
  UnsavedTicketDetail,
  openUnsavedTicketSingleSheetDialog,
} from '../Ticket/ticket'
import { doNotRequireSave, requireSave } from '../../../store/requiredSaveData'
import { useWbsItemAdditionalPropertiesOfBaseWbsItemTypes } from '../../hooks/useWbsItemAdditionalProperties'
import { WbsItemAdditionalPropertyLayoutEntity } from '../../../domain/entity/WbsItemAdditionalPropertyLayoutEntity'
import {
  FIELD_WBSITEM_ACTUALDATE_END,
  FIELD_WBSITEM_ACTUALDATE_START,
  FIELD_WBSITEM_ACTUALHOUR,
} from './gridOptions/defaultColumns'
import validator from '../../containers/meta/validator'
import SelectVO from '../../../vo/SelectVO'
import ActionPopper from './components/ActionPopper'
import { useActionPopper } from './hooks/actionPopper'
import { isProduction } from '../../../utils/urls'
import { changeGanttParameter } from '../../../store/project'
import { useGanttEditTarget } from './hooks/ganttEditTarget'
import Auth from '../../../lib/commons/auth'
import { useWbsItemCodeService } from '../../../services/adaptors/wbsItemCodeServiceAdaptor'

const mapStateToProps = (state: AllState) => ({
  ganttParameter: state.project.ganttParameter,
  tag: state.tag[state.project.selected ?? ''] ?? [],
  functionLayers: state.functionLayer.layers,
  edited: state.hasRequiredSaveData.hasRequiredSaveData,
})

type StateProps = {
  ganttParameter?: GanttParameterVO
  tags: TagForWbsItem[]
  functionLayers: Immutable.Map<number, FunctionLayer>
  edited: boolean
}
type WrapperProps = PageProps & StateProps

export enum QuickFilterKeys {
  ALL = 'ALL',
  START_BY_TODAY = 'START_BY_TODAY',
  END_BY_TODAY = 'END_BY_TODAY',
  START_DELAYED = 'START_DELAYED',
  END_DELAYED = 'END_DELAYED',
}

const fetchExtensions = async (
  functionUuid: string,
  projectUuid: string
): Promise<FunctionProperty[]> => {
  const response = await getExtensions({
    applicationFunctionUuid: functionUuid,
    groupKeys: [projectUuid],
  })
  return response.json.map(v => ({
    ...v,
    requiredIf: BoolExpression.of(v.requiredIf),
    editableIfC: BoolExpression.of(v.editableIfC),
    editableIfU: BoolExpression.of(v.editableIfU),
    hiddenIfC: BoolExpression.of(v.hiddenIfC),
    hiddenIfU: BoolExpression.of(v.hiddenIfU),
    minDate: v.minDate ? new DateExpression(v.minDate, 'min') : undefined,
    maxDate: v.maxDate ? new DateExpression(v.maxDate, 'max') : undefined,
    searchOptions: new SearchOptions(v.searchOptions),
  })) as FunctionProperty[]
}
const fetchPropertyConfigurations = async (
  functionUuid: string,
  projectUuid: string
): Promise<ApplicationFunctionPropertyConfiguration[]> => {
  const response = await appFunctionAPI.getPropertyConfiguraitons(
    functionUuid,
    [projectUuid]
  )
  return response.json.map(v => ({
    ...v,
    requiredIf: v.requiredIf ? BoolExpression.of(v.requiredIf) : undefined,
    editableIfC: v.editableIfC ? BoolExpression.of(v.editableIfC) : undefined,
    editableIfU: v.editableIfU ? BoolExpression.of(v.editableIfU) : undefined,
    hiddenIfC: v.hiddenIfC ? BoolExpression.of(v.hiddenIfC) : undefined,
    hiddenIfU: v.hiddenIfU ? BoolExpression.of(v.hiddenIfU) : undefined,
  })) as ApplicationFunctionPropertyConfiguration[]
}

export const ProjectPlanNew = connect(mapStateToProps)(
  projectPrivate(
    ({
      uuid: functionUuid,
      ganttParameter,
      tags,
      functionLayers,
      edited,
    }: WrapperProps) => {
      const { project } = useProjectPrivateContext()
      const [extensionProperties, setExtensionProperties] =
        useState<FunctionProperty[]>()
      const [propertyConfigurations, setPropertyConfigurations] =
        useState<ApplicationFunctionPropertyConfiguration[]>()
      useEffect(() => {
        const init = async () => {
          const [extensions, propertyConfigurations] = await Promise.all([
            fetchExtensions(functionUuid, project.uuid),
            fetchPropertyConfigurations(functionUuid, project.uuid),
          ])

          setExtensionProperties(extensions)
          setPropertyConfigurations(propertyConfigurations)
        }
        init()
      }, [functionUuid, project.uuid])
      const { search } = useLocation()
      const treeRootUuid = useMemo(() => {
        const params = new URLSearchParams(search)
        return params.get('treeRootUuid') || undefined
      }, [search])
      const {
        fetched: wbsItemAdditionalPropertyInitialized,
        wbsItemAdditionalProperties,
      } = useWbsItemAdditionalPropertiesOfBaseWbsItemTypes()

      // The last condition should be unnecessary, but somehow eslint does not work right.
      if (
        !wbsItemAdditionalPropertyInitialized ||
        !extensionProperties ||
        !propertyConfigurations ||
        !ganttParameter
      ) {
        return <></>
      }
      return (
        <ProjectPlanNewContent
          projectUuid={project.uuid}
          treeRootUuid={treeRootUuid}
          functionUuid={functionUuid}
          extensionProperties={extensionProperties}
          propertyConfigurations={propertyConfigurations}
          ganttParameter={ganttParameter}
          tags={tags}
          layers={functionLayers}
          edited={edited}
          wbsItemAdditionalProperties={wbsItemAdditionalProperties}
        />
      )
    }
  )
)

type Props = {
  projectUuid: string
  treeRootUuid?: string
  functionUuid: string
  extensionProperties: FunctionProperty[]
  propertyConfigurations: ApplicationFunctionPropertyConfiguration[]
  ganttParameter: GanttParameterVO
  tags: TagForWbsItem[]
  layers: Immutable.Map<number, FunctionLayer>
  edited: boolean
  wbsItemAdditionalProperties?: WbsItemAdditionalPropertyLayoutEntity
}

const ProjectPlanNewContent = ({
  projectUuid,
  treeRootUuid,
  functionUuid,
  extensionProperties,
  propertyConfigurations,
  ganttParameter,
  tags,
  layers,
  edited,
  wbsItemAdditionalProperties,
}: Props) => {
  const [loading, setLoading] = useState<boolean>(true)
  const ref = useRef<HTMLDivElement>(null)
  const [outputExcel, setOutputExcel] = useState(false)
  const [exportColIds, setExportColIds] = useState<string[]>([])

  const { generateCode } = useWbsItemCodeService()
  // Project plan data
  const {
    data,
    setData,
    enqueue,
    refresh,
    refreshRow,
    generateUpdateWbsItemDeltaRequest,
    save,
    saveSingleRow,
    deleteRows,
    rootProjectPlanUuid,
    rootWbsItem,
    switchRootWbsItem: _switchRootWbsItem,
    setProjectPlanBody,
  } = useProjectPlanNewData(projectUuid, treeRootUuid)
  const treeChangedCount = useRef<number>(0)
  // Read state in event listener
  const submit = useRef<Function>()
  submit.current = save
  const reload = useRef<Function>()
  reload.current = refresh
  const saveSingleRowFunc =
    useRef<(uuid: string) => Promise<boolean | undefined>>()
  useEffect(() => {
    saveSingleRowFunc.current = saveSingleRow
  }, [saveSingleRow])

  // Stored state
  const pageState = usePageState(functionUuid)
  const bulkSheetState = useBulkSheetState(
    functionUuid,
    rootProjectPlanUuid ? `${projectUuid}-${rootProjectPlanUuid}` : projectUuid
  )

  const dialog = useDialog()
  const popper = usePopper()
  const columnSetting = useColumnSetting()
  const [filteredColumns, setFilteredColumns] = useState<ColDef[]>([])
  const [gantt, showGantt] = useState<boolean>(false)
  const [quickFilters, setQuickFilters] = useState<QuickFilterKeys | undefined>(
    undefined
  )

  const [sortColumnsState, setSortColumnsState] = useState<SortedColumnState[]>(
    []
  )

  const prevData = useRef<ProjectPlanNewRow[]>([])
  prevData.current = data

  const hasChanged = useCallback((): boolean => {
    return (prevData.current || data).some(v => v.added || v.edited)
  }, [data])

  const tmpNewRootProjectPlanUuid = useRef<string | undefined>(undefined)
  const switchRootWbsItem = useCallback(
    async (
      newRootProjectPlanUuid: string | undefined,
      openInNewTab?: boolean
    ) => {
      if (openInNewTab) {
        const res = await project.getDetail({ uuid: projectUuid })
        const url = `${window.location.origin}/projectPlan/${res.json.code}${
          newRootProjectPlanUuid
            ? `?treeRootUuid=${newRootProjectPlanUuid}`
            : ''
        }`
        window.open(url)
        return
      }
      if (hasChanged()) {
        tmpNewRootProjectPlanUuid.current = newRootProjectPlanUuid
        dialog.openAlert()
      } else {
        _switchRootWbsItem(newRootProjectPlanUuid)
      }
    },
    [_switchRootWbsItem, dialog, hasChanged, projectUuid]
  )
  const onConfirmSwitchRootWbsItem = useCallback(() => {
    const newRootProjectPlanUuid = tmpNewRootProjectPlanUuid.current
    tmpNewRootProjectPlanUuid.current = undefined
    dialog.closeAlert()
    _switchRootWbsItem(newRootProjectPlanUuid)
  }, [_switchRootWbsItem, dialog])
  const onCancelSwitchRootWbsItem = useCallback(() => {
    tmpNewRootProjectPlanUuid.current = undefined
    dialog.closeAlert()
  }, [dialog])
  const onChangeRowHeight = useCallback((value: number) => {
    pageState.setRowHeight(value)
  }, [])

  const isOpenWbsItemDetail = useRef<boolean>(false)

  const getValidateValue = useCallback(
    (node: RowNode, colId: string, uiMeta: FunctionProperty) => {
      if (!gridOptions.api) return
      const val = gridOptions.api.getValue(colId, node)
      if (uiMeta?.propertyType === PropertyType.Select) {
        const target = uiMeta.valuesAllowed.find(v => v.value === val)
        return target ? SelectVO.fromCustomEnum(target) : val
      }
      return val
    },
    []
  )

  const validateSingleRow = useCallback(
    (uuid: string, data: ProjectPlanNewRow[]) => {
      const colIds = gridOptions.columnApi
        ? gridOptions.columnApi.getColumnState().map(colState => colState.colId)
        : []
      const allParentItems = getEditedAncestor(uuid, data)
      const treeUuid: string[] = allParentItems.map(treeItem => treeItem.uuid)
      const errors = new InputError()
      treeUuid.forEach(uuid => {
        const node: RowNode | undefined = gridOptions.api?.getRowNode(uuid)
        if (node === undefined) return new InputError()
        colIds.forEach(colId => {
          const colDef = gridOptions.columnApi?.getColumn(colId)?.getColDef()
          if (!colDef || !colDef.cellRendererParams) return
          const uiMeta: FunctionProperty = colDef.cellRendererParams.uiMeta
          const val = getValidateValue(node, colId, uiMeta)
          const err = uiMeta
            ? validator
                .validate(val, node.data, uiMeta, () => undefined)
                ?.getMessage()
            : undefined
          if (err) {
            addInputError(errors, node.id, err, colDef)
          }
        })
      })
      return errors
    },
    [getValidateValue]
  )

  const submitSingleRow = useCallback(
    async (uuid: string): Promise<boolean | undefined> => {
      const data = prevData.current
      const errors = validateSingleRow(uuid, data)
      if (errors?.hasMessage()) {
        store.dispatch(
          addGlobalMessage({
            type: MessageLevel.WARN,
            title: intl.formatMessage({ id: 'global.warning.businessError' }),
            text: errors.toMessage(id => {
              const node = gridOptions.api?.getRowNode(id)
              return getRowNumber(node).toString()
            }),
          })
        )
        return false
      }
      await saveSingleRowFunc.current?.(uuid)
      return true
    },
    [validateSingleRow, saveSingleRowFunc]
  )

  const submitSingleRowRef =
    useRef<(uuid: string) => Promise<boolean | undefined>>()
  useEffect(() => {
    submitSingleRowRef.current = submitSingleRow
  }, [submitSingleRow])

  const refreshWbsItem = useCallback(
    (wbsItemUuid: string) => {
      const row = prevData.current.find(
        row => row.body?.wbsItem?.uuid === wbsItemUuid
      )
      if (row) {
        const uuidsToRefresh = [row.uuid]
        const updatedRowIndex = row.treeValue.findIndex(
          uuid => uuid === row.uuid
        )
        if (updatedRowIndex > 0) {
          uuidsToRefresh.push(...row.treeValue.slice(0, updatedRowIndex))
        }
        refreshRow(prevData.current, uuidsToRefresh)
      }
    },
    [refreshRow]
  )

  const refreshWbsItemRef = useRef<(uuid: string) => void>(refreshWbsItem)
  useEffect(() => {
    refreshWbsItemRef.current = refreshWbsItem
  }, [refreshWbsItem])

  const ganttEditTargetNodeSelection = useGanttEditTarget()

  const gridOptions = useProjectPlanNewGridOptions({
    projectUuid,
    switchRootWbsItem: (event: MouseEvent, rootProjectPlanUuid?: string) => {
      switchRootWbsItem(rootProjectPlanUuid, needToOpenInNewTab(event))
    },
    refreshWbsItem: refreshWbsItemRef,
    submitSingleRow: submitSingleRowRef,
    updateRows: (rows: ProjectPlanNewRow[], report?: boolean) => {
      const d = prevData.current.concat()
      rows.forEach(row => {
        if (!report) {
          row.edited = true
        }
        const i = d.findIndex(v => v.uuid === row.uuid)
        if (-1 < i) {
          d.splice(i, 1, row)
        }
      })
      setData(d)
    },
    addRowToChild: (type: WbsItemTypeVO, parentUuid) => {
      const added = createNewProjectPlanNewRow(type, generateCode)
      let newData = addRowsToLastChild(prevData.current, [added], parentUuid)
      setData(newData)
      const newRow = newData.find(v => v.uuid === added.uuid)!
      addCumulationToAncestors([newRow])
      gridOptions.api?.getRowNode(parentUuid)?.setExpanded(true)
    },
    extensionProperties,
    propertyConfigurations,
    wbsItemAdditionalProperties,
    onChangeRowHeight,
    openWbsDetailDialog: () => {
      isOpenWbsItemDetail.current = true
      actionPopper.isOpen && actionPopper.close()
    },
    closeWbsDetailDialog: () => {
      isOpenWbsItemDetail.current = false
    },
    ganttEditTargetNodeSelection,
  })

  const groupDefaultExpanded = useMemo(
    () => (treeRootUuid ? 1 : 0),
    [treeRootUuid]
  )

  const addCumulationToAncestors = useCallback(
    (rows: ProjectPlanNewRow[]): void => {
      const ancestors = {}
      rows.forEach(row => {
        const ancestorNodes = (row.treeValue ?? [])
          .slice(0, -1)
          .map(id => gridOptions?.api?.getRowNode(id))
          .filter(v => v) as RowNode[]
        ReportReCalculator.add(
          row,
          ancestorNodes.map(a => ancestors[a.id!] ?? a.data)
        ).forEach(added => {
          ancestors[added.uuid] = added
        })
      })
      for (const key in ancestors) {
        const node = gridOptions?.api?.getRowNode(key)
        node?.setData(ancestors[key])
      }
    },
    [gridOptions?.api]
  )

  const deleteCumulationFromAncestors = useCallback(
    (rows: ProjectPlanNewRow[]): void => {
      const ancestors = {}
      rows.forEach(row => {
        const ancestorNodes = (row.treeValue ?? [])
          .slice(0, -1)
          .map(id => gridOptions?.api?.getRowNode(id))
          .filter(v => v) as RowNode[]
        ReportReCalculator.delete(
          row,
          ancestorNodes.map(a => ancestors[a.id!] ?? a.data)
        ).forEach(subtracted => {
          ancestors[subtracted.uuid] = subtracted
        })
      })
      for (const key in ancestors) {
        const node = gridOptions?.api?.getRowNode(key)
        node?.setData(ancestors[key])
      }
    },
    [gridOptions?.api]
  )

  useEffect(() => {
    gridOptions.context = {
      ...gridOptions.context,
      projectUuid,
      errors: new InputError(),
    }
  }, [])

  useEffect(() => {
    if (!gridOptions.context.rootProjectPlan) {
      gridOptions.context.rootProjectPlan = {}
    }
    gridOptions.context.rootProjectPlan.uuid = rootProjectPlanUuid
  }, [rootProjectPlanUuid])

  useEffect(() => {
    if (rootWbsItem && rootWbsItem.displayName) {
      document.title = rootWbsItem.displayName
    }
  }, [rootWbsItem])

  useEffect(() => {
    gridOptions.context = {
      ...gridOptions.context,
      dateFormat: pageState.dateFormat,
    }
    gridOptions.api?.refreshCells()
  }, [pageState.dateFormat])

  useEffect(() => {
    gridOptions.api?.resetRowHeights()
    if (gridOptions.api?.getColumnDef('ag-Grid-AutoColumn')) {
      gridOptions.api?.refreshCells({
        columns: ['ag-Grid-AutoColumn'],
        force: true,
      })
    }
  }, [pageState.rowHeight])

  const workloadUnitState = useWorkloadUnit(pageState.workloadUnit)
  useEffect(() => {
    gridOptions.context = {
      ...gridOptions.context,
      aggregateTarget: pageState.aggregateTarget,
      aggregateField: pageState.aggregateField,
      workloadUnit: pageState.workloadUnit,
      workloadUnitState,
    }
    gridOptions.api?.refreshCells()
  }, [
    pageState.aggregateTarget,
    pageState.aggregateField,
    pageState.workloadUnit,
    workloadUnitState,
  ])

  const filterByQuickFilters = useCallback(
    async (filters?: QuickFilterKeys, resetFilter?: boolean) => {
      if (!filters) {
        gridOptions.api?.destroyFilter('uuid')
        return
      }
      const request: ProjectPlanFilterRequest = { projectUuid }
      if (filters === QuickFilterKeys.START_BY_TODAY) {
        request.scheduledStartDate = {
          operator: DateFilterOperator.BEFORE,
          value: DateVO.now().serialize(),
        }
      }
      if (filters === QuickFilterKeys.END_BY_TODAY) {
        request.scheduledEndDate = {
          operator: DateFilterOperator.BEFORE,
          value: DateVO.now().serialize(),
        }
      }
      if (filters === QuickFilterKeys.START_DELAYED) {
        request.status = {
          values: [WbsItemStatus.TODO],
          includeBlank: false,
        }
        request.scheduledStartDate = {
          operator: DateFilterOperator.BEFORE,
          value: DateVO.now().subtractDays(1).serialize(),
        }
      }
      if (filters === QuickFilterKeys.END_DELAYED) {
        request.status = {
          values: [
            WbsItemStatus.TODO,
            WbsItemStatus.DOING,
            WbsItemStatus.REVIEW,
          ],
          includeBlank: false,
        }
        request.scheduledEndDate = {
          operator: DateFilterOperator.BEFORE,
          value: DateVO.now().subtractDays(1).serialize(),
        }
      }
      const response = await filter(request)
      const filterModel = !resetFilter ? gridOptions.api!.getFilterModel() : {}
      filterModel['uuid'] = response.json
      gridOptions.api?.setFilterModel(filterModel)
      setTimeout(() => gridOptions.api?.onFilterChanged(), 300)
    },
    []
  )

  useEffect(() => {
    filterByQuickFilters(quickFilters, false)
  }, [quickFilters])

  const [reportCumulation, setReportCumulation] =
    useState<ProjectPlanCumulation>(new ProjectPlanCumulation())
  const refreshReport = useMemo(() => {
    return _.debounce(
      () =>
        setReportCumulation(
          ProjectPlanCumulation.sum(
            data
              .filter(v => v.treeValue.length === 1 && !!v.body?.cumulation)
              .map(v => v.body!.cumulation) as ProjectPlanCumulation[]
          )
        ),
      500
    )
  }, [data])
  useEffect(() => refreshReport(), [data])

  const getAddRowTargetRowNode = useCallback(
    (api: GridApi, targetRowUuid?: string | undefined) => {
      if (!api) return
      if (!targetRowUuid) {
        const cell = api.getFocusedCell()
        return cell ? api.getDisplayedRowAtIndex(cell.rowIndex) : undefined
      }
      return api.getRowNode(targetRowUuid)
    },
    []
  )

  const addRow = useCallback(
    (
      type: WbsItemTypeVO,
      ticketListUuid?: string | undefined,
      direction?: 'Above' | 'Below',
      targetRowUuid?: string | undefined,
      focusNewRow: boolean = true
    ) => {
      const { api } = gridOptions
      if (!api) return

      const selectedNode: RowNode<ProjectPlanNewRow> | undefined =
        getAddRowTargetRowNode(api, targetRowUuid)
      const selected = selectedNode?.data
      if (!selected) return
      const parentType = getParent(prevData.current, selected)?.body?.wbsItem
        ?.wbsItemType
      if (!parentType || !type.canBeChildOf(parentType)) return
      const newRow = createNewProjectPlanNewRow(
        type,
        generateCode,
        ticketListUuid
      )
      const newData =
        direction === 'Above'
          ? addRowsBefore(prevData.current, [newRow], selected.uuid)
          : addRowsBelow(prevData.current, [newRow], selected.uuid)
      setData(newData)
      addCumulationToAncestors(newData.filter(v => v.uuid === newRow.uuid))
      focusNewRow && focusRow(api, newRow.uuid)
    },
    [gridOptions, getAddRowTargetRowNode, addCumulationToAncestors]
  )

  const addAbove = useCallback(
    (
      type: WbsItemTypeVO,
      ticketListUuid?: string,
      targetRowUuid?: string | undefined,
      focusNewRow: boolean = true
    ) => {
      addRow(type, ticketListUuid, 'Above', targetRowUuid, focusNewRow)
    },
    [addRow]
  )

  const addBelow = useCallback(
    (
      type: WbsItemTypeVO,
      ticketListUuid?: string,
      targetRowUuid?: string | undefined,
      focusNewRow: boolean = true
    ) => {
      addRow(type, ticketListUuid, 'Below', targetRowUuid, focusNewRow)
    },
    [addRow]
  )

  const addToChild = useCallback(
    (
      type: WbsItemTypeVO,
      ticketListUuid?: string,
      targetRowUuid?: string | undefined
    ) => {
      const { api } = gridOptions
      if (!api) return
      const selectedNode: RowNode<ProjectPlanNewRow> | undefined =
        getAddRowTargetRowNode(api, targetRowUuid)
      const selected = selectedNode?.data
      const parentType = selected?.body?.wbsItem.wbsItemType
      if (!selected || !parentType || !type.canBeChildOf(parentType)) return
      const newRow = createNewProjectPlanNewRow(
        type,
        generateCode,
        ticketListUuid
      )
      const newData = addRowsToLastChild(
        prevData.current,
        [newRow],
        selected.uuid
      )
      setData(newData)
      addCumulationToAncestors(newData.filter(v => v.uuid === newRow.uuid))
      api.getRowNode(selected.uuid)?.setExpanded(true)
    },
    [gridOptions, getAddRowTargetRowNode, setData, addCumulationToAncestors]
  )

  const wbsItemTypes = useMemo(() => store.getState().project.wbsItemTypes, [])

  const actionPopper = useActionPopper({
    gridOptions,
    wbsItemTypes,
    addRowAbove: addAbove,
    addRowBelow: addBelow,
    addToChildRow: addToChild,
  })

  const removeRow = useCallback(() => {
    const openDialogAfterQueue = () => {
      setTimeout(() => {
        if (!gridOptions.api) return
        const nodes = getAllLeafChildren(getSelectedNode(gridOptions.api))
        if (nodes.some(v => !v.data.body?.wbsItem?.uuid)) {
          openDialogAfterQueue()
        } else {
          dialog.openDeleteConfirmation()
        }
      }, 300)
    }
    const selectedNodes = getSelectedNode(gridOptions.api!)
    const canDelete = selectedNodes.every(n => {
      const added = n.data.added
      const c = n.data.body?.cumulation
      return added || (c && c.sumActualHour === 0 && c.actualHour === 0)
    })
    if (!canDelete) return
    const allNodes = getAllLeafChildren(selectedNodes)
    const unexpandedNodes = allNodes.filter(v => !v.data.body?.wbsItem?.uuid)
    if (0 < unexpandedNodes.length) {
      enqueue(unexpandedNodes.map(v => v.data.uuid))
      openDialogAfterQueue()
      return
    }
    dialog.openDeleteConfirmation()
  }, [])

  const treeRootProjectPlans = useCallback(
    (rows: ProjectPlanNewRow[]): ProjectPlanNewRow[] => {
      return rows.filter(row => {
        const parentUuid = row.treeValue[row.treeValue.length - 2]
        return !rows.some(p => p.uuid === parentUuid)
      })
    },
    []
  )

  const validateCopy = useCallback((nodes: RowNode[]) => {
    const rootWbsItems = treeRootProjectPlans(nodes.map(node => node.data))
      .map(v => v.body?.wbsItem)
      .filter(v => v) as NewWbsItemRow[]
    return (
      rootWbsItems.every(v => v.wbsItemType.isWorkgroup()) ||
      rootWbsItems.every(
        v =>
          v.wbsItemType.isProcess() ||
          v.wbsItemType.isDeliverableList() ||
          v.wbsItemType.isDeliverable()
      ) ||
      _.uniq(rootWbsItems.map(v => v.wbsItemType.code)).length === 1
    )
  }, [])

  const copyRow = useCallback((cut?: boolean) => {
    const { api, context } = gridOptions
    const selectedNodes = api ? getSelectedNode(api) : undefined
    if (!api || !selectedNodes || !validateCopy(selectedNodes)) return
    const currentCopiedNodes =
      context.copied?.map(v => api.getRowNode(v.id)) ?? []
    context.copied = copyRows(
      prevData.current,
      selectedNodes.map(node => node.data),
      cut
    )
    api.redrawRows({
      rowNodes: [...currentCopiedNodes, ...selectedNodes].filter(v => !!v),
    })
    focusCellAfterCopy(api)
  }, [])

  const validatePaste = useCallback((nodes: RowNode[]) => {
    const selectedWbsItems: NewWbsItemRow[] = nodes
      .map(v => v.data?.body?.wbsItem)
      .filter(v => !!v)
    const copiedRows =
      gridOptions.context.copied
        ?.map(v => prevData.current.find(d => d.uuid === v.id))
        .filter(v => v) ?? []
    const copiedWbsItems = treeRootProjectPlans(copiedRows)
      .map(v => v.body?.wbsItem)
      .filter(v => v) as NewWbsItemRow[]
    return (
      !_.isEmpty(copiedWbsItems) &&
      !_.isEmpty(selectedWbsItems) &&
      copiedWbsItems.every(c =>
        selectedWbsItems.every(p => c.wbsItemType.canBeChildOf(p.wbsItemType))
      )
    )
  }, [])

  const pasteRow = useCallback(() => {
    const { api } = gridOptions
    const selectedNodes = api ? getSelectedNode(api) : undefined
    if (
      !gridOptions.context.copied?.every(v => !v.cut) ||
      !selectedNodes ||
      !validatePaste(selectedNodes)
    ) {
      return
    }
    dialog.openColumnSelect()
  }, [])

  const pasteRows = useCallback(
    (duplicate: (row: ProjectPlanNewRow) => ProjectPlanNewRow) => {
      const { api, context } = gridOptions
      const selectedNodes = api ? getSelectedNode(api) : undefined
      if (
        !api ||
        !selectedNodes ||
        !context.copied?.every(v => !v.cut) ||
        !validatePaste(selectedNodes)
      ) {
        return
      }
      let result = prevData.current.concat()
      const copiedRows = result.filter(v =>
        context.copied.some(c => c.id === v.uuid)
      )
      const treeUuids = treeRootProjectPlans(copiedRows).map(v => v.uuid)
      const treeMap: { [uuid: string]: string[] } = {}
      copiedRows.forEach(c => {
        const root = treeUuids.find(t => c.treeValue.includes(t))
        if (!root) return
        const rootIndex = c.treeValue.findIndex(uuid => root === uuid)
        treeMap[c.uuid] = c.treeValue.slice(rootIndex)
      })
      let firstNewUuid: string | undefined
      let rows: ProjectPlanNewRow[] = []
      selectedNodes.forEach((p, i) => {
        const uuidMap = {}
        const copied = copiedRows.map(v => {
          const duplicated = duplicate(v)
          uuidMap[duplicated.uuid] = v.uuid
          uuidMap[v.uuid] = duplicated.uuid
          return duplicated
        })
        const newRows = copied.map(c => {
          const oldUuid = uuidMap[c.uuid]
          const oldTreeValue = treeMap[oldUuid]
          c.treeValue = [
            ...p.data.treeValue,
            ...oldTreeValue.map(otv => uuidMap[otv]),
          ]
          return c
        })
        if (i === 0) {
          firstNewUuid = copied[0].uuid
        }
        result = addRowsToLastChild(result, newRows, p.data.uuid)
        rows = rows.concat(newRows)
      })
      setData(result)
      setTimeout(() => {
        // Timeout to update cumulations of pasted parent rows
        addCumulationToAncestors(rows)
      }, 500)
      setTimeout(() => selectedNodes.forEach(n => n.setExpanded(true)), 300)
      firstNewUuid && focusRow(api, firstNewUuid)
    },
    []
  )

  const pasteRowsNameOnly = useCallback(
    () =>
      pasteRows((row: ProjectPlanNewRow) =>
        duplicateProjectPlanNewRowNameOnly(row, generateCode)
      ),
    [generateCode]
  )

  const pasteSelectedRows = useCallback(
    (selectedItem: MultiSelectDialogSelection[]) => {
      pasteRows((row: ProjectPlanNewRow) => {
        const wbsItemUuid = generateUuid()
        const wbsItem = {
          uuid: wbsItemUuid,
          code: generateCode(),
          type: row.body?.wbsItem?.wbsItemType?.rootType!,
          wbsItemType: row.body?.wbsItem?.wbsItemType!,
          ticketListUuid: row.body?.wbsItem?.ticketListUuid,
          status: WbsItemStatus.TODO,
          scheduledDate: { startDate: undefined, endDate: undefined },
          actualDate: { startDate: undefined, endDate: undefined },
        } as Partial<NewWbsItemRow>
        selectedItem.forEach(selected => {
          const path = `body.wbsItem.${selected.value}`
          const value = objects.getValue(row, path)
          objects.setValue(wbsItem, selected.value, value)
        })
        return {
          ...row,
          uuid: generateUuid(),
          wbsItemUuid: wbsItemUuid,
          treeValue: [],
          body: {
            wbsItem,
            cumulation: new ProjectPlanCumulation(),
          },
        } as ProjectPlanNewRow
      })
    },
    [generateCode]
  )

  const moveCutRow = useCallback(() => {
    const { api, context } = gridOptions
    const selectedNodes = api ? getSelectedNode(api) : undefined
    if (
      !api ||
      !selectedNodes ||
      !context.copied?.every(v => !!v.cut) ||
      !validatePaste(selectedNodes)
    ) {
      return
    }
    const overNode = selectedNodes[0]
    const movedData = context.copied
      .map(v => prevData.current.find(d => d.uuid === v.id))
      .filter(v => !!v)
    const fromNodes = movedData.map(v => api.getRowNode(v.uuid)).filter(v => v)
    if (!overNode || _.isEmpty(movedData)) return
    setData(moveRowsToLastChild(prevData.current, movedData, overNode.id))
    overNode.setExpanded(true)
    focusRow(api, movedData[0].uuid)

    // Refresh ancestor cumulations
    const ids = fromNodes.map(v => v.id)
    const topLevelNodes = fromNodes.filter(n => !ids.includes(n.parent?.id))
    const ancestors = {}
    // Subtract cumulations
    topLevelNodes.forEach(fromNode => {
      ReportReCalculator.delete(
        fromNode.data,
        getAncestorNodes(fromNode.parent ?? undefined).map(
          a => ancestors[a.id!] ?? a.data
        )
      ).forEach(subtracted => {
        ancestors[subtracted.uuid] = subtracted
      })
    })
    // Add cumulations
    const overAncestorNodes = getAncestorNodes(overNode)
    topLevelNodes.forEach(toNode => {
      ReportReCalculator.add(
        toNode.data,
        overAncestorNodes.map(a => ancestors[a.id!] ?? a.data)
      ).forEach(added => {
        ancestors[added.uuid] = added
      })
    })
    // Refresh cumulation
    for (const key in ancestors) {
      const node = api.getRowNode(key)
      node?.setData(ancestors[key])
    }
  }, [])

  const contextMenu = useCallback(
    (params: GetContextMenuItemsParams) => {
      if (!params.node) return []
      const selectedNodes = getSelectedNode(params.api)
      const canCopy = validateCopy(selectedNodes)
      const canPaste = validatePaste(selectedNodes)
      const canCopyPaste = canPaste && params.context.copied.every(v => !v.cut)
      const canCutPaste = canPaste && params.context.copied.every(v => !!v.cut)
      const canCopyAll =
        !params.node.data.body?.wbsItem?.wbsItemType.isWorkgroup() &&
        selectedNodes.length === 1
      const addCallback = (d, r) => {
        setData(d)
        addCumulationToAncestors(r)
      }
      const items = [
        ...switchRootWbsMenuItems(params),
        addCopyURLMenuItems(params.node?.data.body.wbsItem),
        addCopyURLAndNameMenuItems(params.node?.data.body.wbsItem),
        'separator',
        ...addRowAboveMenuItems(params, data, addCallback, generateCode),
        ...addRowToChildMenuItems(params, data, addCallback, generateCode),
        ...addRowsToChildMenuItems(params, data, {
          onAddRows: (type, parentUuid) =>
            dialog.openWbsItem({ type, parentUuid }),
        }),
        ...(rootProjectPlanUuid
          ? []
          : [
              ...addWorkgroupMenuItems(params, data, { setData }, generateCode),
              ...changeProcessToWorkgroupMenuItems(params, data, {
                setData: (
                  data: ProjectPlanNewRow[],
                  moved: ProjectPlanNewRow[]
                ) => {
                  setData(data)

                  if (!gridOptions.api) return
                  const movedNodes = moved
                    .map(v => gridOptions.api!.getRowNode(v.uuid))
                    .filter(v => v) as RowNode[]
                  // Refresh ancestor cumulations
                  const ancestors = {}
                  // Subtract cumulations
                  if (params.node) {
                    ReportReCalculator.delete(
                      params.node.data,
                      getAncestorNodes(params.node.parent ?? undefined).map(
                        a => ancestors[a.id!] ?? a.data
                      )
                    ).forEach(subtracted => {
                      ancestors[subtracted.uuid] = subtracted
                    })
                  }
                  // Refresh cumulation
                  for (const key in ancestors) {
                    const node = gridOptions.api.getRowNode(key)
                    node?.setData(ancestors[key])
                  }
                },
              }),
            ]),
        ...addTicketMenuItems(
          params,
          data,
          {
            setData: addCallback,
            onAddTicket: addTicket,
            onAddTickets: parentUuid => dialog.openTicket({ parentUuid }),
          },
          generateCode
        ),
        'separator',
        ...deleteRowMenuItems(params, selectedNodes, data, () => removeRow()),
        // Copy
        canCopy && copyTreeMenuItems(params, data),
        // Copy all
        canCopyAll &&
          copyAllTreeMenuItems(params, data, {
            afterCopy: uuids => enqueue(uuids),
          }),
        // Paste
        canCopyPaste &&
          pasteTreeMenuItems(params, {
            pasteNameOnly: () => pasteRowsNameOnly(),
            selectColumns: () => dialog.openColumnSelect(),
          }),
        // Cut
        canCopy && cutTreeMenuItems(params, data),
        canCutPaste &&
          pasteCutTreeMenuItems(params, data, {
            duplicateRow: row => duplicateProjectPlanNewRow(row, generateCode),
            setData: (
              data: ProjectPlanNewRow[],
              moved: ProjectPlanNewRow[]
            ) => {
              setData(data)

              if (!gridOptions.api) return
              const movedNodes = moved
                .map(v => gridOptions.api!.getRowNode(v.uuid))
                .filter(v => v) as RowNode[]
              // Refresh ancestor cumulations
              const ancestors = {}
              // Subtract cumulations
              movedNodes.forEach(fromNode => {
                ReportReCalculator.delete(
                  fromNode.data,
                  getAncestorNodes(fromNode.parent ?? undefined).map(
                    a => ancestors[a.id!] ?? a.data
                  )
                ).forEach(subtracted => {
                  ancestors[subtracted.uuid] = subtracted
                })
              })
              // Add cumulations
              const overAncestorNodes = getAncestorNodes(
                params.node ?? undefined
              )
              movedNodes.forEach(toNode => {
                ReportReCalculator.add(
                  toNode.data,
                  overAncestorNodes.map(a => ancestors[a.id!] ?? a.data)
                ).forEach(added => {
                  ancestors[added.uuid] = added
                })
              })
              // Refresh cumulation
              for (const key in ancestors) {
                const node = gridOptions.api.getRowNode(key)
                node?.setData(ancestors[key])
              }
            },
          }),
        ...groupRowMenuItems(params),
        ...reportMenuItems(params, data, { setData }),
      ].filter(v => !!v) as (string | MenuItemDef)[]
      actionPopper.isOpen && actionPopper.close()
      return items.filter(
        (item: string | MenuItemDef, index: number) =>
          item !== 'separator' ||
          (0 < index && items[index - 1] !== 'separator')
      )
    },
    [data, rootProjectPlanUuid, edited, actionPopper]
  )

  const dragTreeStyle = useDragTreeStyle(ref, acceptChild, gridOptions)

  const fetchStatusOptions = useCallback(async projectUuid => {
    const response = await getCustomEnumValues({
      customEnumCode: CustomEnumCode.WBS_STATUS,
      groupUuid: projectUuid,
    })
    return response.json.map(v => ({ ...v, nameI18n: parse(v.nameI18n) }))
  }, [])

  const fetchSubstatusOptions = useCallback(async projectUuid => {
    const response = await getCustomEnumValues({
      customEnumCode: CustomEnumCode.WBS_SUBSTATUS,
      groupUuid: projectUuid,
    })
    return response.json.map(v => ({ ...v, nameI18n: parse(v.nameI18n) }))
  }, [])

  const fetchPriorityOptions = useCallback(async projectUuid => {
    const response = await getCustomEnumValues({
      customEnumCode: CustomEnumCode.WBS_PRIORITY,
      groupUuid: projectUuid,
    })
    return response.json.map(v => ({ ...v, nameI18n: parse(v.nameI18n) }))
  }, [])

  const fetchDifficultyOptions = useCallback(async projectUuid => {
    const response = await getCustomEnumValues({
      customEnumCode: CustomEnumCode.WBS_DIFFICULTY,
      groupUuid: projectUuid,
    })
    return response.json.map(v => ({ ...v, nameI18n: parse(v.nameI18n) }))
  }, [])

  const fetchTeamOptions = useCallback(async projectUuid => {
    const response = await team.getTeams(projectUuid)
    return response.json
  }, [])

  const fetchMemberOptions = useCallback(async projectUuid => {
    const response = await projectMember.getProjectMembers({ projectUuid })
    return response.json.map(v => v.user)
  }, [])

  const fetchSprintOptions = useCallback(async projectUuid => {
    const response = await sprint.getSprints({
      projectUuid: projectUuid,
      statusList: [
        SprintStatus.STANDBY,
        SprintStatus.INPROGRESS,
        SprintStatus.FINISHED,
        SprintStatus.CANCELED,
      ],
    })
    return response.json
  }, [])

  const fetchTagOptions = useCallback(async projectUuid => {
    const response = await fetchTags({ projectUuid })
    return response.json
  }, [])

  const fetchExtensionOptions = useCallback(async () => {
    extensionProperties
      .filter(
        extension =>
          extension.propertyType === PropertyType.EntitySearch &&
          extension.referenceEntity
      )
      .map(async extension => {
        const repo = repositories[extension.referenceEntity!]
        const searchOptions = extension.searchOptions.build({
          wbsItem: { projectUuid },
        })
        const res = await repo.search('', { ...searchOptions, projectUuid })
        gridOptions.context = {
          ...gridOptions.context,
          [extension.referenceEntity!]: res.map(v => ({
            ...v,
            nameI18n: v.nameI18n ? parse(v.nameI18n) : undefined,
          })),
        }
      })
    extensionProperties
      .filter(
        extension =>
          extension.propertyType === PropertyType.Select &&
          extension.valuesAllowed
      )
      .map(extension => {
        gridOptions.context = {
          ...gridOptions.context,
          [extension.entityExtensionUuid]: extension.valuesAllowed.filter(
            v => v.value !== CUSTOM_ENUM_NONE
          ),
        }
      })
  }, [])

  const dispatch = useDispatch()
  const fetchSelectColumnOptions = useCallback(async projectUuid => {
    const [status, substatus, priority, difficulty, team, member, sprint, tag] =
      await Promise.all([
        fetchStatusOptions(projectUuid),
        fetchSubstatusOptions(projectUuid),
        fetchPriorityOptions(projectUuid),
        fetchDifficultyOptions(projectUuid),
        fetchTeamOptions(projectUuid),
        fetchMemberOptions(projectUuid),
        fetchSprintOptions(projectUuid),
        fetchTagOptions(projectUuid),
      ])
    const projectState = store.getState().project
    const wbsItemType = [
      ...projectState.wbsItemTypes.getAll(),
      ...projectState.ticketListTypes,
      ...projectState.ticketTypes,
    ]
    gridOptions.context = {
      ...gridOptions.context,
      wbsItemType,
      status,
      substatus,
      priority,
      difficulty,
      team,
      member,
      sprint,
      tag,
    }
    fetchExtensionOptions()
    dispatch(receivedTags(projectUuid, tag))
  }, [])

  useEffect(() => {
    // Initial data fetch
    fetchSelectColumnOptions(projectUuid)
  }, [])

  useEffect(() => {
    // For tag filter option
    gridOptions.context = {
      ...gridOptions.context,
      tags,
    }
  }, [tags])

  const onGanttParameterChanged = useMemo(
    () =>
      _.throttle(async () => {
        const [ganttDisplayTimeScale, ganttTimeScale] =
          await generateGanttScale(ganttParameter)
        gridOptions.context = {
          ...gridOptions.context,
          ganttParameter: ganttParameter,
          ganttDisplayTimeScale,
          ganttTimeScale,
        }
        gridOptions.columnApi?.setColumnWidth(
          'ganttChart',
          getGanttChartWidth(ganttParameter)
        )
        gridOptions.api?.refreshCells({ columns: ['ganttChart'], force: true })
        gridOptions.api?.refreshHeader()
        gridOptions.api?.ensureColumnVisible(
          'ganttChart',
          ganttParameter.calcNearestPosition()
        )
      }, 1000),
    [gridOptions, ganttParameter]
  )
  useEffect(() => {
    if (
      !gridOptions.api ||
      !gridOptions.columnApi ||
      !pageState.initialized ||
      !bulkSheetState.initialized
    ) {
      return
    }
    onGanttParameterChanged()
  }, [
    gridOptions,
    ganttParameter,
    pageState.initialized,
    bulkSheetState.initialized,
  ])

  const restoreExpandedRows = useCallback(() => {
    if (!bulkSheetState.expandedRowIds || !gridOptions.api) return
    setTimeout(() => {
      const removed: string[] = []
      gridOptions.api?.forEachNode(node => {
        if (node.id && bulkSheetState.expandedRowIds.includes(node.id)) {
          if (node.hasChildren()) {
            node.setExpanded(true)
          } else {
            removed.push(node.id)
          }
        }
      })
      removed.length > 0 && bulkSheetState.collapseRows(removed)
    }, 100)
  }, [bulkSheetState])

  const rememberColumnState = useCallback(() => {
    const columnState = gridOptions.columnApi?.getColumnState()
    const autoColumnState = columnState?.find(
      c => c.colId === 'ag-Grid-AutoColumn'
    )
    if (autoColumnState && gridOptions.autoGroupColumnDef) {
      gridOptions.autoGroupColumnDef.width = autoColumnState?.width
    }
    columnState && bulkSheetState.saveColumnState(columnState)
  }, [bulkSheetState, gridOptions.autoGroupColumnDef, gridOptions.columnApi])

  const onGridReady = useCallback(() => {
    if (!_.isEmpty(bulkSheetState.columnState)) {
      gridOptions.columnApi?.applyColumnState({
        state: bulkSheetState.columnState,
        applyOrder: false,
      })
    }
    setTimeout(() => setLoading(false), 2000)
  }, [bulkSheetState])

  // Restore row state.
  const onFirstDataRendered = useCallback(
    e => {
      restoreExpandedRows()
      const columnState = e.columnApi.getColumnState()
      if (!columnState?.find(v => v.colId === 'ganttChart')?.hide) {
        showGantt(true)
      }
      if (!_.isEmpty(bulkSheetState.filterState)) {
        gridOptions.api?.setFilterModel(bulkSheetState.filterState)
      }
      bulkSheetState.finishRestoring()
      setLoading(false)
    },
    [bulkSheetState]
  )

  const onColumnVisible = useCallback(
    (e: ColumnVisibleEvent) => {
      if (e.column?.getColDef().field === 'ganttChart') {
        showGantt(!!e.visible)
        if (e.visible) {
          gridOptions.api?.ensureColumnVisible(
            'ganttChart',
            ganttParameter.calcNearestPosition()
          )
        }
      } else {
        const ganttChart = (e.columns ?? []).find(
          v => v.getColDef().field === 'ganttChart'
        )
        ganttChart && showGantt(ganttChart.isVisible())
      }
      rememberColumnState()
      onSortedColumnsStateChanged(e)
    },
    [bulkSheetState]
  )

  const onFilterChanged = useCallback(
    (e: FilterChangedEvent) => {
      const filterModel = e.api.getFilterModel()
      delete filterModel['uuid']
      bulkSheetState.saveFilterState(filterModel)
      setFilteredColumns(
        Object.keys(filterModel)
          .map(col => e.api.getColumnDef(col))
          .filter(v => !!v && v.field !== 'uuid') as ColDef[]
      )
    },
    [bulkSheetState]
  )

  const onSortedColumnsStateChanged = useCallback(
    (e: SortChangedEvent) => {
      const sortedColumns = e.columnApi
        .getColumnState()
        .filter(col => !!col.sort)
        .map(col => {
          return {
            ...e.api.getColumnDef(col.colId),
            colId: col.colId,
          }
        })
        .filter(v => !!v) as ColDef[]
      e.api.setSuppressRowDrag(0 < sortedColumns.length)

      const columnState = gridOptions.columnApi?.getColumnState()
      const sortedColumnState: { [colId: string]: ColumnState } = {}
      columnState &&
        columnState.forEach(state => {
          if (state.sort) {
            sortedColumnState[state.colId] = state
          }
        })
      const sortedColumnsState: SortedColumnState[] = sortedColumns.map(col => {
        return {
          colId: col.colId,
          field: col.field,
          headerName: col.headerName,
          sort: col.colId ? sortedColumnState[col.colId]?.sort : null,
        }
      })

      setSortColumnsState(sortedColumnsState)
    },
    [bulkSheetState]
  )

  const onSortChanged = useCallback(
    (e: SortChangedEvent) => {
      rememberColumnState()
      onSortedColumnsStateChanged(e)
    },
    [bulkSheetState]
  )

  const onDeleteFilteredColumn = useCallback((column: ColDef) => {
    if (!column || !gridOptions.api) return
    const filterModel = gridOptions.api.getFilterModel()
    delete filterModel[column.colId || column.field || '']
    gridOptions.api.setFilterModel(filterModel)
  }, [])

  const resetFilters = useCallback(() => {
    if (!gridOptions.api) return

    if (!quickFilters) {
      gridOptions.api.setFilterModel([])
    } else {
      filterByQuickFilters(quickFilters, true)
    }
  }, [quickFilters])

  const onDeleteSortedColumn = useCallback((colId: string | ColDef<any>) => {
    gridOptions.columnApi?.applyColumnState({
      state: [{ colId: colId.toString(), sort: null }],
    })
  }, [])

  const onDeleteSortedAllColumns = useCallback(() => {
    gridOptions.columnApi?.applyColumnState({
      defaultState: { sort: null },
    })
  }, [])

  const onChangeSortColumnState = useCallback(
    (colId: string | ColDef<any>, sort: 'asc' | 'desc' | null) => {
      gridOptions.columnApi?.applyColumnState({
        state: [{ colId: colId.toString(), sort }],
      })
    },
    []
  )

  const onRowDataUpdated = useCallback(
    (e: RowDataUpdatedEvent<ProjectPlanNewRow>) => {
      e.api?.refreshCells({
        columns: [
          'rowNumber',
          FIELD_WBSITEM_ACTUALDATE_START,
          FIELD_WBSITEM_ACTUALDATE_END,
          FIELD_WBSITEM_ACTUALHOUR,
        ],
        force: true,
      })
    },
    [data]
  )

  const resetCopiedRows = useCallback(() => {
    const { api, context } = gridOptions
    if (!api) return
    const currentCopiedNodes =
      context.copied?.map(v => api.getRowNode(v.id)) ?? []
    context.copied = []
    currentCopiedNodes.length &&
      api.redrawRows({ rowNodes: currentCopiedNodes })
  }, [gridOptions.api, gridOptions.context])

  const onSubmit = useCallback(async () => {
    setLoading(true)
    try {
      gridOptions.api?.stopEditing()

      if (gridOptions.context.errors.hasMessage()) {
        store.dispatch(
          addGlobalMessage({
            type: MessageLevel.WARN,
            title: intl.formatMessage({ id: 'global.warning.businessError' }),
            text: gridOptions.context.errors.toMessage(id => {
              const node = gridOptions.api?.getRowNode(id)
              return !!node ? getRowNumber(node).toString() : ''
            }),
          })
        )
        return
      }

      bulkSheetState.saveImmediately()

      // Save
      const [response, sprintProductResponse, sprintBacklogResponse] =
        submit.current ? await submit.current() : await save()

      if (response.hasWarning) {
        const messages = extractValuesFromResponse(response.json, 'messages')
        handleWarning(messages, uuid => {
          const target = data.find(v => v.body?.wbsItem.uuid === uuid)
          return target?.body?.wbsItem.displayName
        })
      }

      if (
        !response.hasError &&
        !response.hasWarning &&
        !sprintProductResponse.hasError &&
        !sprintBacklogResponse.hasError
      ) {
        runAsyncWithPerfMonitoring('refreshAll after onSubmit', async () => {
          store.dispatch(
            addScreenMessage(functionUuid, {
              type: MessageLevel.SUCCESS,
              title: intl.formatMessage({ id: 'registration.complete' }),
            })
          )
          await refreshAll()
        })
      }
    } finally {
      runWithPerfMonitoring('resetCopiedRows after onSubmit', () => {
        resetCopiedRows()
      })
      setLoading(false)
    }
  }, [data, save, bulkSheetState])

  const onReload = useCallback(() => {
    if (hasChanged()) {
      dialog.openCancel()
    } else {
      refreshAll()
    }
  }, [data, bulkSheetState.expandedRowIds, treeChangedCount])

  const refreshAll = useCallback(
    async (forceRefreshTree?: boolean) => {
      if (!gridOptions.api) return
      setLoading(true)
      try {
        const fetchBodyUuid = !forceRefreshTree
          ? getDisplayedRow(gridOptions.api).map(node => node.data.uuid)
          : undefined
        const refreshRows = reload.current ?? refresh
        await refreshRows(rootProjectPlanUuid, fetchBodyUuid, {
          forceRefreshTree: forceRefreshTree || treeChangedCount.current > 1500,
        })
      } finally {
        resetCopiedRows()
        gridOptions.context = {
          ...gridOptions.context,
          onTree: false,
          draggableNodeId: undefined,
          errors: new InputError(),
        }
        setTimeout(() => {
          gridOptions?.api?.refreshCells({
            columns: [
              'body.wbsItem.status',
              'body.wbsItem.new.status',
              'ganttChart',
            ],
            force: true,
          })
        }, 500)
        setLoading(false)
        treeChangedCount.current = 0
      }
    },
    [rootProjectPlanUuid, bulkSheetState.expandedRowIds, treeChangedCount]
  )

  const isFirstRender = useRef(true)
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
      return
    }
    refreshAll(true)
  }, [rootProjectPlanUuid])
  const prevRestoredAt = usePrevious(bulkSheetState.restoredAt)
  useEffect(() => {
    if (
      prevRestoredAt &&
      bulkSheetState.restoredAt &&
      prevRestoredAt < bulkSheetState.restoredAt
    ) {
      setTimeout(() => {
        // setTimeout is to wait for rows are rendered.
        restoreExpandedRows()
      }, 300)
    }
  }, [restoreExpandedRows, prevRestoredAt, bulkSheetState.restoredAt])

  const onExportExcel = useCallback(
    colIds => {
      const uuids = data.filter(v => !v.body).map(v => v.uuid)
      setLoading(true)
      setOutputExcel(true)
      setExportColIds(colIds)
      enqueue(uuids)
    },
    [data, enqueue]
  )
  useEffect(() => {
    if (outputExcel && !data.some(v => !v.body)) {
      setOutputExcel(false)
      exportExcel({
        fileNamePrefix: 'project_plan',
        gridOptions,
        exportColIds,
        shouldRowBeSkipped: params =>
          !params.node.data.body || !params.node.displayed,
      })
      setLoading(false)
    }
  }, [data, exportColIds, gridOptions, outputExcel])

  const onImportExcel = useCallback(
    (raw: Uint8Array) => {
      setLoading(true)
      setTimeout(() => {
        try {
          // Convert excel file
          const workbook = XLSX.read(raw, { type: 'array', dense: true })
          const worksheet = workbook.Sheets[workbook.SheetNames[0]]
          const source: object[] = XLSX.utils.sheet_to_json(worksheet)

          // Validate
          const parser = new ExcelParser(
            source,
            {
              data,
              context: gridOptions.context,
              treeRootUuid: rootProjectPlanUuid,
            },
            generateCode
          )
          const messages = parser.validate()
          if (messages.length > 0) {
            store.dispatch(
              addGlobalMessage({
                type: MessageLevel.WARN,
                title: intl.formatMessage({ id: 'projectPlan.import.warning' }),
                text: messages.join('\n'),
              })
            )
            return
          }

          // Convert
          const newData = parser.parse()
          newData.length > 1500 && setData([]) // Prevent slow processing
          setData(newData)
        } finally {
          setLoading(false)
        }
      }, 300)
    },
    [data, rootProjectPlanUuid]
  )
  const getNotPinnedColumns = () => {
    const columnApi = gridOptions.columnApi
    if (!columnApi) return []
    const allColumnStates = columnApi.getColumnState()
    const notPinnedColumns = allColumnStates
      .filter(cs => !cs.pinned && !cs.hide)
      .map(cs => cs.colId)
    return notPinnedColumns
  }

  const onSwitchingGanttChartDisplay = useCallback(
    (isDisplayGantt: boolean) => {
      const columnApi = gridOptions.columnApi
      if (!columnApi) return
      if (isDisplayGantt) {
        columnApi.applyColumnState({
          state: [{ colId: 'ganttChart', hide: false }],
        })
        const newRange = {
          start: new DateVO().getFirstDayOfMonth(),
          end: new DateVO().addMonths(1).getLastDayOfMonth(),
        }
        const { unit, startDay } = ganttParameter
        store.dispatch(
          changeGanttParameter(
            projectUuid,
            new GanttParameterVO(unit, startDay, newRange)
          )
        )
        gridOptions.api?.ensureColumnVisible('ganttChart')
      } else {
        columnApi.applyColumnState({
          state: [{ colId: 'ganttChart', hide: true }],
        })
        gridOptions.api?.ensureColumnVisible(getNotPinnedColumns()[0])
      }
      showGantt(isDisplayGantt)
    },
    [gantt]
  )

  const onChangeColumnFilter = useCallback(
    (value: ColumnQuickFilterKey) => {
      if (value === ColumnQuickFilterKey.INITIAL) {
        pageState.setSelectedColumnQuickFilterKey(
          SelectedColumnQuickFilterKey.NONE
        )
        gridOptions.columnApi?.resetColumnState()
        const filterModel = gridOptions.api?.getFilterModel()
        const quick = filterModel?.['uuid']
        gridOptions.api?.setFilterModel(quick ? { uuid: quick } : null)
        gridOptions.columnApi?.setColumnWidth(
          'ganttChart',
          getGanttChartWidth(ganttParameter)
        )
      } else if (value === ColumnQuickFilterKey.RESTORE) {
        pageState.setSelectedColumnQuickFilterKey(
          SelectedColumnQuickFilterKey.NONE
        )
        dialog.openUiState()
      } else {
        pageState.setSelectedColumnQuickFilterKey(
          value.toString() as SelectedColumnQuickFilterKeyType
        )
        let pinned: string[] = []
        let notPinned: string[] = []
        if (value === ColumnQuickFilterKey.PLANNING) {
          pinned = [
            'drag',
            'rowNumber',
            'body.wbsItem.status',
            'action',
            'ag-Grid-AutoColumn',
            'body.wbsItem.team',
            'body.wbsItem.accountable',
            'body.wbsItem.responsible',
            'body.wbsItem.scheduledDate.startDate',
            'body.wbsItem.scheduledDate.endDate',
          ]
          notPinned = [
            'body.wbsItem.estimatedHour.deliverable',
            'body.wbsItem.estimatedHour',
            'ganttChart',
          ]
        } else if (value === ColumnQuickFilterKey.WORK_RESULT) {
          pinned = [
            'drag',
            'rowNumber',
            'body.wbsItem.status',
            'action',
            'ag-Grid-AutoColumn',
            'body.wbsItem.team',
            'body.wbsItem.accountable',
            'body.wbsItem.responsible',
            'body.wbsItem.scheduledDate.startDate',
            'body.wbsItem.scheduledDate.endDate',
            'body.wbsItem.actualDate.startDate',
            'body.wbsItem.actualDate.endDate',
          ]
          notPinned = [
            'body.wbsItem.estimatedHour.deliverable',
            'body.wbsItem.estimatedHour',
            'body.cumulation.actualHour',
            'ganttChart',
          ]
        } else if (value === ColumnQuickFilterKey.PROGRESS) {
          pinned = [
            'drag',
            'rowNumber',
            'body.wbsItem.status',
            'action',
            'ag-Grid-AutoColumn',
            'body.wbsItem.team',
            'body.wbsItem.accountable',
            'body.wbsItem.responsible',
            'progress.scheduledProgressRate',
            'progress.progressRate',
          ]
          notPinned = [
            'progress.total',
            'progress.scheduledToBe',
            'progress.completed',
            'progress.preceding',
            'progress.delayed',
            'progress.remaining',
            'ganttChart',
          ]
        } else if (value === ColumnQuickFilterKey.PRODUCTIVITY) {
          pinned = [
            'drag',
            'rowNumber',
            'body.wbsItem.status',
            'action',
            'ag-Grid-AutoColumn',
            'body.wbsItem.team',
            'body.wbsItem.accountable',
            'body.wbsItem.responsible',
          ]
          notPinned = [
            'body.wbsItem.estimatedHour.deliverable',
            'body.wbsItem.estimatedHour',
            'body.cumulation.actualHour',
            'progress.completed',
            'evm.costPerformanceIndex',
            'evm.schedulePerformanceIndex',
            'evm.estimateToComplete',
            'evm.estimateAtCompletion',
            'ganttChart',
          ]
        }
        const visibleColumnIds = [...pinned, ...notPinned]
        const columnApi = gridOptions.columnApi
        if (!columnApi) return
        const allColumnStates = columnApi.getColumnState()
        const visibleColumnStates = allColumnStates
          .filter(cs => visibleColumnIds.includes(cs.colId))
          .sort(
            (a, b) =>
              visibleColumnIds.findIndex(id => id === a.colId) -
              visibleColumnIds.findIndex(id => id === b.colId)
          )
          .map(cs => ({
            ...cs,
            pinned: pinned.includes(cs.colId),
            hide: false,
            width:
              cs.colId === 'ganttChart'
                ? getGanttChartWidth(ganttParameter)
                : cs.width,
          }))

        const hiddenColumnStates = allColumnStates
          .filter(cs => !visibleColumnIds.includes(cs.colId))
          .map(cs => ({ ...cs, hide: true }))

        columnApi.applyColumnState({
          state: [...visibleColumnStates, ...hiddenColumnStates],
          applyOrder: false,
        })
        gridOptions.api?.ensureColumnVisible(notPinned[0])
      }
    },
    [
      gridOptions.columnApi,
      ganttParameter,
      pageState.selectedColumnQuickFilterKey,
    ]
  )

  const onDeleteRows = useCallback(() => {
    const allChildren = getAllLeafChildren(
      getSelectedNode(gridOptions.api!)
    ).map(v => v.data) as ProjectPlanNewRow[]
    const target = _.uniqBy(allChildren, 'uuid')
    const newData = removeRows(data, target)
    setData(newData)
    deleteCumulationFromAncestors(target)
    deleteRows(target)
    dialog.closeDeleteConfirmation()
  }, [data, gridOptions.api])

  // Enqueue on empty rows are displayed.
  // This is necessary to fetch body after reloaded.
  const onDisplayedRowUpdated = useMemo(
    () =>
      _.debounce((api: GridApi) => {
        if (!ref.current) return
        const fetchBodyUuid = getDisplayedRow(api)
          .filter(node => !node.data.body)
          .map(node => node.data.uuid)
        enqueue(fetchBodyUuid)
      }, 500),
    [enqueue]
  )

  const onBodyScrollEnd = useCallback(
    (event: BodyScrollEndEvent) => {
      if (event.direction === 'horizontal') return
      onDisplayedRowUpdated(event.api)
    },
    [onDisplayedRowUpdated]
  )

  const onModelUpdated = useCallback(
    (event: ModelUpdatedEvent) => {
      onDisplayedRowUpdated(event.api)
    },
    [onDisplayedRowUpdated]
  )

  // Enqueue children on row group is opened.
  // Save row state after row group opened or closed.
  const onRowGroupOpened = useCallback(
    (event: RowGroupOpenedEvent) => {
      const key = event.node.key
      if (!key) return
      if (event.expanded) {
        bulkSheetState.expandRows([key])

        // Fetch body
        const children = event.node.allLeafChildren.filter(
          childNode =>
            childNode.parent?.id === event.node.id && !childNode.data.body
        )
        const displayedRows = getDisplayedRow(event.api)
        const fetchTargetUuid = children
          .filter(childNode =>
            displayedRows.some(row => row.id === childNode.id)
          )
          .map(childNode => childNode.data.uuid)
        enqueue(fetchTargetUuid)
      } else {
        bulkSheetState.collapseRows([key])
      }
    },
    [enqueue, bulkSheetState]
  )

  const getAncestorNodes = useCallback((node?: RowNode): RowNode[] => {
    const result: RowNode[] = []
    let n: RowNode | undefined = node
    while (n && n.id !== 'ROOT_NODE_ID') {
      result.unshift(n)
      n = n.parent?.key ? n.parent : undefined
    }
    return result
  }, [])

  const changeWorkgroupToProcessOfMovedData = (
    movedData: ProjectPlanNewRow[]
  ) => {
    const wbsItemTypes = store.getState().project.wbsItemTypes
    const processType = wbsItemTypes.process
    movedData.forEach((movedRowData: ProjectPlanNewRow) => {
      if (!movedRowData.body) return
      const wbsItemType = movedRowData.body.wbsItem.wbsItemType
      if (!wbsItemType || !wbsItemType.isWorkgroup()) {
        return
      }
      if (!movedRowData.editedData) {
        movedRowData.editedData = {}
      }
      movedRowData.editedData['body.wbsItem.wbsItemType'] =
        movedRowData.body.wbsItem.wbsItemType
      movedRowData.body.wbsItem.wbsItemType = processType
    })
  }

  const onRowDragEnd = useCallback(
    (event: RowDragEvent) => {
      const ids = event.nodes.map(v => v.id)
      const topLevelNodes = event.nodes.filter(n => !ids.includes(n.parent?.id))
      const movedData = sortByRowIndex(topLevelNodes).map(node => node.data)
      const overNode = event.overNode

      if (overNode && overNode.id === gridOptions.context.draggableNodeId) {
        try {
          if (gridOptions.context.onTree) {
            changeWorkgroupToProcessOfMovedData(movedData)
            const result = moveRowsToLastChild(data, movedData, overNode.id)
            const count = _.sum(topLevelNodes.map(v => v.allChildrenCount ?? 0))
            if (count > 1500) {
              treeChangedCount.current = treeChangedCount.current + count
              // Refresh all to avoid slow process
              gridOptions.api && setData([])
              setData(result)
              const parentNode = gridOptions.api?.getRowNode(overNode.id ?? '')
              parentNode?.setExpanded(true)
              // Restore expanded rows
              restoreExpandedRows()
            } else {
              setData(result)
              overNode.setExpanded(true)
            }
            // Focus new position
            topLevelNodes[0].id && focusRow(event.api, topLevelNodes[0].id)

            // Refresh ancestor cumulations
            const ancestors = {}
            // Subtract cumulations
            topLevelNodes.forEach(fromNode => {
              ReportReCalculator.delete(
                fromNode.data,
                getAncestorNodes(fromNode.parent ?? undefined).map(
                  a => ancestors[a.id!] ?? a.data
                )
              ).forEach(subtracted => {
                ancestors[subtracted.uuid] = subtracted
              })
            })
            // Add cumulations
            const overAncestorNodes = getAncestorNodes(overNode)
            topLevelNodes.forEach(toNode => {
              ReportReCalculator.add(
                toNode.data,
                overAncestorNodes.map(a => ancestors[a.id!] ?? a.data)
              ).forEach(added => {
                ancestors[added.uuid] = added
              })
            })
            // Refresh cumulation
            for (const key in ancestors) {
              const node = event.api.getRowNode(key)
              node?.setData(ancestors[key])
            }

            return
          } else {
            // If moved toward down, move after overNode.
            if (event.overIndex > (event.node.rowIndex || 0)) {
              setData(slideRowsAfter(data, movedData, overNode.id!))
              return
            }
            setData(slideRowsBefore(data, movedData, overNode.id!))
          }
        } finally {
          // Clear drag cell style
          dragTreeStyle.refreshDragStyle()
        }
      }
    },
    [data]
  )

  const onCellClicked = useCallback(
    (e: CellClickedEvent) => {
      const { field } = e.colDef
      if (
        field === 'body.wbsItem.displayName' &&
        !isOpenWbsItemDetail.current
      ) {
        const pointer: PointerEvent = e.event as PointerEvent
        const clickEl = (pointer.currentTarget ?? pointer.target) as HTMLElement
        if (
          !!clickEl.closest('.sevend-ag-cell-project-plan-tree__icon-button')
        ) {
          // Do not display ActionPopper when clicking on an icon in the name column
          // (Prevent action buttons from appearing on single sheet screens)
          return
        }
        const anchor = clickEl.closest('.ag-row') as HTMLElement
        const anchorRect: DOMRect | undefined = anchor?.getBoundingClientRect()
        const target = anchor?.getElementsByClassName(
          'sevend-ag-cell-project-plan-tree__text-field'
        )
        const targetRect: DOMRect | undefined =
          0 < (target?.length || 0)
            ? target?.item(0)?.getBoundingClientRect()
            : undefined

        const wbsItem: NewWbsItemRow | undefined = (e.data as ProjectPlanNewRow)
          .body?.wbsItem
        const targetRow = prevData.current?.filter(
          row => row.body?.wbsItem?.uuid === wbsItem?.uuid
        )
        const targetRowUuid = _.isEmpty(targetRow)
          ? undefined
          : targetRow[0].uuid

        // Hide addRowAbove because continuous clicks are not possible
        actionPopper.open({
          offsetX: (targetRect?.left ?? 0) - (anchorRect?.left ?? 0),
          offsetY: 0,
          anchor,
          targetRowUuid,
          wbsItem,
        })
      }
      if (e.data.added) return
      if (field === 'body.wbsItem.status') {
        const getAgCellElement = (elem): any => {
          if (!elem || elem.className.toString().includes('sevend-ag-cell')) {
            return elem
          }
          return getAgCellElement(elem.parentElement)
        }
        const target = getAgCellElement(e.event!.target)
        if (!target) return
        popper.open(
          target,
          e.data.body!.wbsItem,
          generateUpdateWbsItemDeltaRequest(e.data)
        )
      }
      if (field === 'body.cumulation.actualHour') {
        if (e.value <= Number.EPSILON) return
        const w = e.data.body?.wbsItem
        if (!w || !w.wbsItemType.isTask()) return
        dialog.openActualResult(w.uuid)
      }
    },
    [popper, dialog, actionPopper, generateUpdateWbsItemDeltaRequest]
  )

  const onCellEditingStarted = useCallback(
    ({ api, context, node }: CellEditingStartedEvent) => {
      if (context.copied?.some(v => v.id === node.id)) {
        api.stopEditing()
        resetCopiedRows()
        focusCellAfterCopy(api)
      }
      actionPopper.close()
    },
    [actionPopper]
  )

  const onCellDoubleClicked = useCallback(() => popper.close(), [popper])

  const onSpaceKeyDown = useCallback(() => {
    const { api } = gridOptions
    const focusedCell = api?.getFocusedCell()
    const focusedNode = api?.getDisplayedRowAtIndex(focusedCell?.rowIndex ?? -1)
    if (
      !focusedCell ||
      !focusedNode ||
      !document.activeElement!.classList.contains('ag-cell')
    ) {
      return
    }
    focusedNode.setExpanded(!focusedNode.expanded)
  }, [gridOptions.api])

  const onEscKeyDown = useCallback(() => {
    resetCopiedRows()
    actionPopper.close()
  }, [resetCopiedRows, actionPopper])

  useKeyBind(
    [
      { key: KEY_SAVE, fn: onSubmit, stopDefaultBehavior: true },
      { key: KEY_SPACE, fn: onSpaceKeyDown },
      { key: KEY_ESC, fn: onEscKeyDown },
      { key: 'alt+shift+l', fn: () => addAbove(wbsItemTypes.task) },
      { key: 'alt+shift+k', fn: () => addAbove(wbsItemTypes.deliverable) },
      { key: 'alt+shift+j', fn: () => addAbove(wbsItemTypes.deliverableList) },
      { key: 'alt+shift+h', fn: () => addAbove(wbsItemTypes.process) },
      { key: 'mod+shift+l', fn: () => addToChild(wbsItemTypes.task) },
      { key: 'mod+shift+k', fn: () => addToChild(wbsItemTypes.deliverable) },
      {
        key: 'mod+shift+j',
        fn: () => addToChild(wbsItemTypes.deliverableList),
        stopDefaultBehavior: true,
      },
      { key: 'mod+shift+h', fn: () => addToChild(wbsItemTypes.process) },
      { key: 'alt+shift+d', fn: () => removeRow() },
      { key: 'alt+shift+c', fn: () => copyRow() },
      {
        key: 'alt+shift+x',
        fn: () => copyRow(true),
        stopDefaultBehavior: true,
      },
      { key: 'mod+i', fn: () => pasteRow() },
      {
        key: 'mod+shift+i',
        fn: () => pasteRowsNameOnly(),
        stopDefaultBehavior: true,
      },
      { key: 'mod+shift+v', fn: () => moveCutRow() },
    ],
    [onSubmit]
  )

  const getLastSelectTicketListUiStateKey = useCallback(
    (ticketType: string) =>
      `${UiStateKey.LastSelectedTicketListToAddTicket}-${projectUuid}-${ticketType}`,
    [projectUuid]
  )

  const addTicket = useCallback(
    async (parentUuid: string, ticketType: WbsItemTypeVO) => {
      const hasRequiredSaveData: boolean = edited
      if (!ticketType || !ticketType.isTicket()) return
      const parentRow = prevData.current.find(row => row.uuid === parentUuid)
      const parentWbsItem = parentRow?.body?.wbsItem
      const parentType = parentWbsItem?.wbsItemType
      if (
        !parentWbsItem ||
        !parentType ||
        parentType.canBeChildOf(ticketType)
      ) {
        return
      }
      const response = await TicketListAPI.getAll({
        projectUuid,
        ticketType: ticketType.code,
      })
      const ticketLists: TicketListDetail[] = response?.json
      if (_.isEmpty(ticketLists)) {
        store.dispatch(
          addGlobalMessage({
            type: MessageLevel.WARN,
            title: intl.formatMessage({ id: 'global.warning.businessError' }),
            text: intl.formatMessage(
              {
                id: 'ticket.create.error.ticketlist.not.exists',
              },
              { type: ticketType.name }
            ),
          })
        )
        return
      }
      const added: ProjectPlanNewRow = createNewProjectPlanNewRow(
        ticketType,
        generateCode
      )
      const newData: ProjectPlanNewRow[] = addRowsToLastChild(
        prevData.current,
        [added],
        parentUuid
      )
      setData(newData)
      const newRow: ProjectPlanNewRow = newData.find(
        v => v.uuid === added.uuid
      )!
      addCumulationToAncestors([newRow])

      const getUiStateResponse = await uiStates.get({
        key: getLastSelectTicketListUiStateKey(ticketType.code),
        scope: UiStateScope.User,
      })
      const ticketListUuid = getUiStateResponse?.json?.value
      const ticketList: TicketListDetail | undefined = ticketListUuid
        ? ticketLists.find(
            (ticketList: TicketListDetail) =>
              ticketList.uuid === getUiStateResponse.json.value
          )
        : undefined

      const detail: UnsavedTicketDetail = {
        uuid: generateUuid(),
        wbsItem: {
          uuid: newRow.body!.wbsItem.uuid!,
          code: newRow.body!.wbsItem.code!,
          projectUuid: projectUuid,
          status: newRow.body!.wbsItem.status!,
          type: newRow.body!.wbsItem.type,
          ticketType: newRow.body!.wbsItem.ticketType!,
        },
        ticketList: ticketList
          ? {
              uuid: ticketList.uuid,
              code: ticketList.wbsItem.code,
              wbsItemUuid: ticketList.wbsItem.uuid,
              ticketType: ticketList.ticketType,
              projectUuid: projectUuid,
              displayName: ticketList.wbsItem.displayName,
            }
          : undefined,
        parentWbsItem: {
          uuid: parentWbsItem.uuid,
          code: parentWbsItem.code,
          projectUuid: projectUuid,
          type: parentType.rootType,
          displayName: parentWbsItem.displayName,
          ticketType: parentWbsItem.ticketType!,
        },
        ticketType: newRow.body?.wbsItem.ticketType,
        projectPlanUuid: newRow.uuid,
      }
      openUnsavedTicketSingleSheetDialog(detail, async () => {
        const { api } = gridOptions
        if (!api || !detail.wbsItem.uuid) return
        const targetRow = prevData.current.find(
          row => row.body?.wbsItem?.uuid === detail.wbsItem.uuid
        )
        if (targetRow) {
          const refreshedRows = await refreshRow(
            prevData.current,
            targetRow.treeValue
          )
          const registeredRow = refreshedRows.find(
            (row: ProjectPlanNewRow) => row.uuid === targetRow.uuid
          )
          if (!registeredRow) {
            deleteCumulationFromAncestors([targetRow])
          } else {
            if (
              registeredRow.body &&
              registeredRow.body.wbsItem.ticketType &&
              registeredRow.body.wbsItem.ticketListUuid
            ) {
              uiStates.update({
                key: getLastSelectTicketListUiStateKey(
                  registeredRow.body.wbsItem.ticketType
                ),
                scope: UiStateScope.User,
                value: registeredRow.body.wbsItem.ticketListUuid,
              })
            }
            api.getRowNode(parentUuid)?.setExpanded(true)
            focusRow(api, newRow.uuid)
          }
          if (hasRequiredSaveData) {
            dispatch(requireSave())
          }
        }
      })
    },
    [
      edited,
      projectUuid,
      gridOptions,
      prevData,
      setData,
      addCumulationToAncestors,
      deleteCumulationFromAncestors,
      refreshRow,
      dispatch,
      getLastSelectTicketListUiStateKey,
    ]
  )

  if (!pageState.initialized || !bulkSheetState.initialized) {
    return <></>
  }

  return (
    <PageArea>
      {layers.size === 1 && (
        <SavePopper loading={loading} onSubmit={onSubmit} />
      )}
      {rootProjectPlanUuid && (
        <ProjectPlanNewBreadcrumb
          rootProjectPlanUuid={rootProjectPlanUuid}
          onSelectBreadcrumb={(event, breadcrumb) =>
            switchRootWbsItem(breadcrumb.uuid, needToOpenInNewTab(event))
          }
        />
      )}
      <ProjectPlanNewReport
        aggregateField={pageState.aggregateField}
        aggregateTarget={pageState.aggregateTarget}
        workloadUnit={pageState.workloadUnit}
        cumulation={reportCumulation}
        data={data}
        projectUuid={projectUuid}
        rootWbsItem={rootWbsItem?.uuid}
        onChangeOpenReport={pageState.setReport}
        openReport={pageState.report}
      />
      <ProjectPlanNewWbsHeader
        onChangeAggregateTarget={pageState.setAggregateTarget}
        aggregateTarget={pageState.aggregateTarget}
        onChangeAggregateField={pageState.setAggregateField}
        aggregateField={pageState.aggregateField}
        onChangeWorkloadUnit={pageState.setWorkloadUnit}
        workloadUnit={pageState.workloadUnit}
        quickFilters={quickFilters ?? undefined}
        onChangeQuickFilters={setQuickFilters}
        onChangeColumnFilter={onChangeColumnFilter}
        onSwitchingGanttChart={onSwitchingGanttChartDisplay}
        gantt={gantt}
        rowHeight={pageState.rowHeight}
        onChangeHeight={pageState.setRowHeight}
        onClickImportExcel={onImportExcel}
        onClickExportExcel={dialog.openExcel}
        filteredColumns={filteredColumns}
        onDeleteFilteredColumn={onDeleteFilteredColumn}
        resetFilters={resetFilters}
        onDeleteSortedColumn={onDeleteSortedColumn}
        onDeleteSortedAllColumns={onDeleteSortedAllColumns}
        onChangeSortColumnState={onChangeSortColumnState}
        onReload={onReload}
        onClickColumnSettingButton={columnSetting.toggle}
        columnSettingOpen={columnSetting.isOpen}
        onClickFavoriteColumnFilterButton={dialog.openUiState}
        sortColumnsState={sortColumnsState}
        currentFormat={pageState.dateFormat}
        onChangeDateFormat={(value: string) => pageState.setDateFormat(value)}
        onChangeOpenReport={pageState.setReport}
        openReport={pageState.report}
        rootWbsItem={rootWbsItem?.uuid}
        data={data}
        setData={setData}
        setProjectPlanBody={setProjectPlanBody}
        selectedColumnQuickFilterKey={pageState.selectedColumnQuickFilterKey}
      />
      <BulkSheetView
        ref={ref}
        gridOptions={gridOptions}
        rowData={data}
        getContextMenuItems={contextMenu}
        rowHeight={pageState.rowHeight}
        groupDefaultExpanded={groupDefaultExpanded}
        // Events and actions
        onGridReady={onGridReady}
        onFirstDataRendered={onFirstDataRendered}
        onRowGroupOpened={onRowGroupOpened}
        onColumnVisible={onColumnVisible}
        onColumnResized={rememberColumnState}
        onColumnMoved={rememberColumnState}
        onFilterChanged={onFilterChanged}
        onSortChanged={onSortChanged}
        onRowDataUpdated={onRowDataUpdated}
        onBodyScrollEnd={onBodyScrollEnd}
        onModelUpdated={onModelUpdated}
        onRowDragEnter={dragTreeStyle.onRowDragEnter}
        onRowDragMove={dragTreeStyle.onRowDragMove}
        onRowDragEnd={onRowDragEnd}
        onRowDragLeave={dragTreeStyle.refreshDragStyle}
        onCellClicked={onCellClicked}
        onCellEditingStarted={onCellEditingStarted}
        onCellDoubleClicked={onCellDoubleClicked}
        onCellValueChanged={actionPopper.onCellValueChanged}
      />
      <ColumnSettingPopper
        anchorEl={columnSetting.anchorEl}
        open={columnSetting.isOpen}
        close={columnSetting.close}
        columnApi={gridOptions?.columnApi ?? undefined}
        gridApi={gridOptions?.api ?? undefined}
        height={ref.current?.offsetHeight}
        openSavedUiStateDialog={dialog.openUiState}
        initializeColumnState={() =>
          onChangeColumnFilter(ColumnQuickFilterKey.INITIAL)
        }
        applicationFunctionUuid={functionUuid}
        uiStateKey={UiStateKey.BulkSheetUIStateColumnAndFilter}
        columnState={bulkSheetState.columnState}
        offset={120}
      />
      <Loading isLoading={loading} elem={ref.current} />
      {dialog.deleteConfirmation && gridOptions.api && (
        <DeleteRowConfirmationDialog
          target={_.uniqBy(
            getAllLeafChildren(getSelectedNode(gridOptions.api)),
            'data.uuid'
          ).map(
            v =>
              v.data.body?.wbsItem.displayName ??
              `(${intl.formatMessage({ id: 'displayName.undefined' })})`
          )}
          onConfirm={onDeleteRows}
          onClose={dialog.closeDeleteConfirmation}
        />
      )}
      {dialog.excel && (
        <MultiSelectDialog
          onClose={dialog.closeExcel}
          onSubmit={(allList, selectedItem) => {
            const selectedColIds = selectedItem.map(col => col.value)
            onExportExcel(selectedColIds)
          }}
          dialogTitle={intl.formatMessage({
            id: 'dialog.exceloutput.columnselect.title',
          })}
          submitButtonTitle={intl.formatMessage({
            id: 'dialog.exceloutput.submit',
          })}
          allCheckBoxLabel={intl.formatMessage({
            id: 'dialog.exceloutput.columnselect.allcheckboxlabel',
          })}
          getSelectList={() => {
            return (gridOptions.columnApi?.getColumns() || [])
              .filter(
                column =>
                  ![
                    'uuid',
                    'drag',
                    'action',
                    'body.deliverableAttachmentSummary',
                    'ganttChart',
                  ].includes(column.getColId())
              )
              .map(column => ({
                value: column.getColId(),
                displayName: gridOptions.columnApi?.getDisplayNameForColumn(
                  column,
                  null
                ),
                defaultChecked:
                  column.isVisible() ||
                  [
                    'body.wbsItem.displayName',
                    'body.wbsItem.code',
                    'body.wbsItem.type',
                  ].includes(column.getColId()),
              })) as MultiSelectDialogSelection[]
          }}
          hideIcon={true}
        />
      )}
      {dialog.wbsItem && (
        <AddRowCountInputDialog
          open={true}
          title={intl.formatMessage(
            { id: 'projectPlan.add' },
            { name: dialog.wbsItemType!.name }
          )}
          submitHandler={(addRowCount: number | undefined) => {
            const rows = Array.from({ length: addRowCount ?? 0 }).map(_ =>
              createNewProjectPlanNewRow(dialog.wbsItemType!, generateCode)
            )
            const newData = addRowsToLastChild(data, rows, dialog.parentUuid)
            // ↑のdialog.parentUuidは、wbsItemではなく行のuuid
            setData(newData)
            const uuids = rows.map(v => v.uuid)
            addCumulationToAncestors(
              newData.filter(v => uuids.includes(v.uuid))
            )

            gridOptions.api?.getRowNode(dialog.parentUuid)?.setExpanded(true)
            dialog.closeWbsItem()
            focusRow(gridOptions.api!, rows[0].uuid)
          }}
          closeHandler={dialog.closeWbsItem}
        />
      )}
      {dialog.ticket && (
        <AddMultipleTicketDialog
          open={true}
          projectUuid={projectUuid}
          title={intl.formatMessage({
            id: 'bulksheet.contextMenu.ticket',
          })}
          submitHandler={(
            addRowCount?: number,
            ticketTypeCode?: string,
            ticketList?: TicketListDetail
          ) => {
            try {
              const ticketTypes = store.getState().project.ticketTypes
              const ticketType = ticketTypes.find(
                v => v.code === ticketTypeCode
              )
              if (addRowCount && ticketType && ticketList) {
                let d = data.concat()
                let firstNewUuid: string | undefined
                let uuids: string[] = []
                Array.from({ length: addRowCount }).forEach((_, i) => {
                  const newRow = createNewProjectPlanNewRow(
                    ticketType,
                    generateCode,
                    ticketList.uuid
                  )
                  if (i === 0) {
                    firstNewUuid = newRow.uuid
                  }
                  d = addRowsToLastChild(d, [newRow], dialog.parentUuid)
                  uuids.push(newRow.uuid)
                })
                setData(d)
                addCumulationToAncestors(d.filter(v => uuids.includes(v.uuid)))
                gridOptions.api
                  ?.getRowNode(dialog.parentUuid)
                  ?.setExpanded(true)
                firstNewUuid && focusRow(gridOptions.api!, firstNewUuid)
              }
            } finally {
              dialog.closeTicket()
            }
          }}
          closeHandler={dialog.closeTicket}
          initialValue={1}
        />
      )}
      {dialog.columnSelect && (
        <MultiSelectDialog
          onClose={dialog.closeColumnSelect}
          onSubmit={(
            allList: MultiSelectDialogSelection[],
            selectedItem: MultiSelectDialogSelection[]
          ) => {
            pasteSelectedRows(selectedItem)
            const selectedNodes = getSelectedNode(gridOptions.api!)
            selectedNodes.forEach(n => n.setExpanded(true))
            dialog.closeColumnSelect()
          }}
          dialogTitle={intl.formatMessage({
            id: 'dialog.contextMenu.paste.selectedCol.title',
          })}
          submitButtonTitle={intl.formatMessage({
            id: 'dialog.contextMenu.paste.selectedCol.submit',
          })}
          allCheckBoxLabel={intl.formatMessage({
            id: 'dialog.exceloutput.columnselect.allcheckboxlabel',
          })}
          getSelectList={() => {
            const label = id => intl.formatMessage({ id: `projectPlan.${id}` })
            return [
              {
                value: 'type',
                displayName: label('type'),
                defaultChecked: true,
              },
              {
                value: 'ticketType',
                displayName: label('ticketType'),
                defaultChecked: true,
              },
              {
                value: 'displayName',
                displayName: label('displayName'),
                defaultChecked: true,
              },
              { value: 'status', displayName: label('status') },
              { value: 'substatus', displayName: label('substatus') },
              { value: 'priority', displayName: label('priority') },
              { value: 'difficulty', displayName: label('difficulty') },
              { value: 'description', displayName: label('description') },
              { value: 'team', displayName: label('team') },
              { value: 'accountable', displayName: label('accountable') },
              { value: 'responsible', displayName: label('responsible') },
              { value: 'assignee', displayName: label('assignee') },
              { value: 'estimatedHour', displayName: label('estimatedHour') },
              {
                value: 'scheduledDate.startDate',
                displayName: label('scheduledDate.start'),
              },
              {
                value: 'scheduledDate.endDate',
                displayName: label('scheduledDate.end'),
              },
              {
                value: 'actualDate.startDate',
                displayName: label('actualDate.start'),
              },
              {
                value: 'actualDate.endDate',
                displayName: label('actualDate.end'),
              },
              { value: 'tags', displayName: label('tags') },
            ]
          }}
          hideIcon={true}
          excludeValues={['type', 'ticketType', 'displayName']}
        />
      )}
      {dialog.actualResult && (
        <TaskActualWorkDialog
          open={true}
          taskUuid={dialog.taskUuid ?? ''}
          closeHandler={dialog.closeActualResult}
        />
      )}
      {dialog.cancel && (
        <CancelConfirmDialog
          open={true}
          onConfirm={() => {
            dialog.closeCancel()
            refreshAll()
          }}
          onClose={dialog.closeCancel}
        />
      )}
      {dialog.alert && (
        <AlertDialog
          isOpen={true}
          title={intl.formatMessage({
            id: 'projectPlan.switchRoot.confirmation.title',
          })}
          message={intl.formatMessage({
            id: 'projectPlan.switchRoot.confirmation.message',
          })}
          submitButtonTitle={intl.formatMessage({ id: 'dialog.ok' })}
          submitHandler={onConfirmSwitchRootWbsItem}
          closeButtonTitle={intl.formatMessage({ id: 'dialog.cancel' })}
          closeHandler={onCancelSwitchRootWbsItem}
        />
      )}
      {dialog.uiState && (
        <SavedUIStateDialog
          applicationFunctionUuid={functionUuid}
          open={true}
          title={intl.formatMessage({
            id: 'savedUIState.BULK_SHEET_UI_STATE_COLUMN_AND_FILTER',
          })}
          uiStateKey={UiStateKey.BulkSheetUIStateColumnAndFilter}
          sharable={false}
          currentUIState={{
            columnState: bulkSheetState.columnState,
            filterState: bulkSheetState.filterState,
          }}
          onSelect={(uiState: SavedUIState) => {
            const { columnState, filterState } = uiState.UIState
            if (columnState) {
              bulkSheetState.saveColumnState(columnState)
              gridOptions.columnApi?.applyColumnState({
                state: columnState,
                applyOrder: false,
              })
            }
            if (filterState) {
              bulkSheetState.saveFilterState(filterState)
              gridOptions.api?.setFilterModel(filterState)
              pageState.setSelectedColumnQuickFilterKey(
                SelectedColumnQuickFilterKey.NONE
              )
            }
            dialog.closeUiState()
          }}
          onClose={dialog.closeUiState}
        />
      )}
      <StatusChangePopper
        anchorEl={popper.anchorEl}
        projectUuid={projectUuid}
        wbsItem={popper.wbsItem}
        wbsItemDelta={popper.wbsItemDelta}
        onClose={popper.close}
        onAfterUpdate={wbsItem => {
          const row = data.find(r => r.body?.wbsItem?.uuid === wbsItem.uuid)
          if (row) {
            const uuidsToRefresh = [row.uuid]
            const updatedRowIndex = row.treeValue.findIndex(
              uuid => uuid === row.uuid
            )
            if (updatedRowIndex > 0) {
              uuidsToRefresh.push(...row.treeValue.slice(0, updatedRowIndex))
            }
            enqueue(uuidsToRefresh)
            if (
              prevData.current.filter(
                v => v.uuid !== row.uuid && (v.edited || v.added)
              ).length === 0
            ) {
              dispatch(doNotRequireSave())
            }
          }
        }}
      />
      {!isProduction && actionPopper.isOpen && (
        <ActionPopper
          anchorEl={actionPopper.anchor}
          originX={actionPopper.offsetX}
          originY={actionPopper.offsetY}
          height={actionPopper.getTargetRowHeight()}
          addAboveButtonProps={actionPopper.addAboveRowButtonProps}
          addBelowButtonProps={actionPopper.addBelowRowButtonProps}
          addChildButtonProps={actionPopper.addChildRowButtonProps}
          onClose={actionPopper.close}
        />
      )}
    </PageArea>
  )
}
