import { useCallback } from 'react'
import { WbsItemCodeService } from '../../applications/ports/wbsItemCodeService'
import { useProjectPrivateContext } from '../../view/context/projectContext'
import { WbsItemCodeFormat } from '../../lib/functions/project'

export const useWbsItemCodeService = (): WbsItemCodeService => {
  const { project } = useProjectPrivateContext()

  const generateCode = useCallback(() => {
    return project?.wbsItemCodeFormat === WbsItemCodeFormat.SEQUENTIAL
      ? project.code + '-'
      : Math.random().toString(36).slice(-8).toUpperCase()
  }, [project])
  return { generateCode }
}
