import {
  Avatar,
  Box,
  Button,
  ClickAwayListener,
  Typography,
} from '@mui/material'
import ActualWorkingHoursIcon from '../SideMenu/MinimizedMenu/ActualWorkingHoursIcon'
import NotificationsIcon from '../SideMenu/commons/NotificationsIcon'
import { intl } from '../../../i18n'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { NavLink } from 'react-router-dom'
import {
  Color,
  FontSize,
  FontWeight,
  TextColor,
} from '../../../styles/commonStyles'
import ExpandMore from '@mui/icons-material/ExpandMoreRounded'
import UserMenu from './UserMenu'
import HeaderSearchBoxArea from './HeaderSearchBoxArea'
import MyTaskIcon from '../../../assets/my_task_icon.png'
import { ExpandLess } from '@mui/icons-material'
import Title from '../../components/headers/HeaderBar/Title'
import WbsItemCommentUserSummaryIcon from '../SideMenu/commons/WbsItemCommentUserSummaryIcon'
import { colorPalette } from '../../style/colorPallete'
import { UserDetail } from '../../../lib/functions/user'
import { AllState } from '../../../store'
import { connect, useDispatch, useSelector } from 'react-redux'
import { BootstrapTooltip } from '../../components/tooltips/Tooltip/BootStrapToopTip'
import HelpOutlineRoundedIcon from '@mui/icons-material/HelpOutlineRounded'
import functions, { APPLICATION_FUNCTION_EXTERNAL_ID } from '../../pages'
import { ProjectDetail } from '../../../lib/functions/project'
import { Cockpit } from '../../../lib/commons/appFunction'
import MeasuringTimer from './HeaderMeasuringTimerBox'
import { fetchTimerItem } from '../../../store/measuringTimer'

const PROJECT_ICON_SIZE: number = 14

type StateProps = {
  user: UserDetail | undefined
  project: ProjectDetail | undefined
  currentExternalId: string | undefined
}

const MenuTopButton = ({
  icon,
  label,
  toPathName,
  navLinkRef,
}: {
  icon: JSX.Element
  label: string
  toPathName: string
  navLinkRef: React.ForwardRefExoticComponent<
    Pick<any, string | number | symbol> & React.RefAttributes<unknown>
  >
}) => {
  return (
    <Button
      variant="outlined"
      component={navLinkRef}
      to={{ pathname: toPathName }}
      sx={{
        borderRadius: '4px',
        height: '28px',
        width: '98px',
        background: colorPalette.skyBlue[7],
        boxShadow: 'none',
        margin: '0 0 0 10px',
        padding: '0 6px',
        border: 'none',
        '&:hover': {
          backgroundColor: colorPalette.skyBlue[5],
          boxShadow: 'none',
          border: 'none',
        },
      }}
    >
      {icon}
      <Box
        component="span"
        sx={{
          whiteSpace: 'nowrap',
          textTransform: 'lowercase',
          overflow: 'hidden',
          padding: '0 4px',
          color: TextColor.WHITE,
        }}
      >
        {label}
      </Box>
    </Button>
  )
}

const mapStateToProps = (state: AllState) => ({
  user: state.user.user,
  project: state.project.current,
  currentExternalId: state.appFunction.currentExternalId,
})

export const UserInformationHeader = connect(mapStateToProps)(
  ({ user, project, currentExternalId }: StateProps) => {
    const [anchorEl, setAnchorEl] = useState<Element>()
    const [openMenu, setOpenMenu] = useState<boolean>(false)
    const dispatch = useDispatch()
    const { taskName, parentWbsItemName, startTime, wbsItemTypeUrl, taskUuid } =
      useSelector((state: AllState) => ({
        taskName: state.measuringTimer.taskName,
        parentWbsItemName: state.measuringTimer.parentWbsItemName,
        startTime: state.measuringTimer.startTime,
        wbsItemTypeUrl: state.measuringTimer.wbsItemTypeUrl,
        taskUuid: state.measuringTimer.taskUuid,
      }))

    useEffect(() => {
      dispatch(fetchTimerItem())
    }, [])

    const navLinkRef = React.forwardRef((props: any, ref: any) => {
      return (
        <div>
          <NavLink innerRef={ref} {...props} />
        </div>
      )
    })

    const handleProjectMenuClose = () => {
      setAnchorEl(undefined)
      setOpenMenu(false)
    }

    const openMenuIconRef = useRef<HTMLSpanElement>(null)

    const myTaskPath = useMemo(
      () =>
        functions[APPLICATION_FUNCTION_EXTERNAL_ID.MY_WBS_ITEMS].defaultPath,
      []
    )

    const currentFunctionCokpit: Cockpit | undefined = useMemo(
      () =>
        currentExternalId ? functions[currentExternalId].cockpit : undefined,
      [currentExternalId]
    )

    return (
      <Box
        style={{
          height: '56px',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          borderBottom: `1px solid ${colorPalette.monotone[2]}`,
        }}
      >
        <Box sx={{ paddingLeft: '20px' }}>
          <Title depth={0} />
          {Cockpit.Project === currentFunctionCokpit && project && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <Avatar
                src={project?.iconUrl}
                sx={{ height: PROJECT_ICON_SIZE, width: PROJECT_ICON_SIZE }}
              />
              <Typography
                sx={{
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  fontWeight: FontWeight.BOLD,
                  fontSize: FontSize.MEDIUM,
                }}
              >
                {project.displayName}
              </Typography>
            </Box>
          )}
        </Box>
        <Box
          sx={{ display: 'flex', margin: '0 0 0 auto', alignItems: 'center' }}
        >
          <HeaderSearchBoxArea />
          <WbsItemCommentUserSummaryIcon
            style={{
              margin: '0 5px 0 25px',
            }}
          />
          <NotificationsIcon iconSize={20} />
          <ActualWorkingHoursIcon />
          <MenuTopButton
            icon={
              <img
                style={{
                  padding: '3px',
                  color: colorPalette.skyBlue[2],
                  height: '20px',
                  width: '20px',
                }}
                src={MyTaskIcon}
              />
            }
            label={intl.formatMessage({
              id: 'menu.myTask',
            })}
            toPathName={myTaskPath}
            navLinkRef={navLinkRef}
          />
          <BootstrapTooltip
            arrow={true}
            title={intl.formatMessage({ id: 'login.supportSite' })}
          >
            <button
              style={{
                background: 'transparent',
                border: 'none',
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                padding: '6px',
                margin: '0 35px 0 16px',
              }}
              onClick={() => {
                window.open(process.env.FLAGXS_SUPPORT_SITE_URL, '_blank')
              }}
            >
              <HelpOutlineRoundedIcon
                sx={{ color: colorPalette.monotone[4] }}
              />
            </button>
          </BootstrapTooltip>
        </Box>
        {taskUuid && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              borderLeft: `1px solid ${colorPalette.monotone[2]}`,
              justifyContent: 'center',
            }}
          >
            <MeasuringTimer
              taskName={taskName}
              parentWbsItemName={parentWbsItemName}
              wbsItemType={wbsItemTypeUrl}
              startTime={startTime || 0}
              taskUuid={taskUuid}
            />
          </Box>
        )}
        <Box
          sx={{
            width: taskUuid ? '100px' : '240px',
            display: 'flex',
            height: '100%',
            alignItems: 'center',
            borderLeft: `1px solid ${colorPalette.monotone[2]}`,
            justifyContent: 'center',
          }}
        >
          <Avatar
            src={user?.iconUrl}
            variant="circular"
            sx={{
              height: '32px',
              width: '32px',
              margin: '10px',
              border: `1px solid ${colorPalette.monotone[2]}`,
            }}
          />
          {!taskUuid && (
            <Typography
              sx={{
                marginRight: '10px',
                width: '100px',
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 2,
                overflow: 'hidden',
                color: colorPalette.monotone[10],
                fontSize: '12px',
                fontWeight: 700,
              }}
            >
              {user?.name}
            </Typography>
          )}
          <ClickAwayListener onClickAway={handleProjectMenuClose}>
            <span style={{ cursor: 'pointer' }} ref={openMenuIconRef}>
              {openMenu ? (
                <ExpandLess
                  sx={{ color: Color.MONOTONE }}
                  onClick={() => {
                    setAnchorEl(undefined)
                    setOpenMenu(false)
                  }}
                />
              ) : (
                <ExpandMore
                  sx={{ color: Color.MONOTONE }}
                  onClick={() => {
                    setAnchorEl(openMenuIconRef.current || undefined)
                    setOpenMenu(true)
                  }}
                />
              )}
            </span>
          </ClickAwayListener>
        </Box>
        <UserMenu openMenu={openMenu} anchorEl={anchorEl} loginUser={user} />
      </Box>
    )
  }
)
