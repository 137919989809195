import { RowNode } from 'ag-grid-community'
import { useCallback, useMemo, useRef } from 'react'
import {
  GanntEditTargetNodeSelectionProps,
  GanttCellRendererProps,
} from '../../../containers/commons/AgGrid/components/cell/custom/gantt/CellRenderer'

export const useGanttEditTarget = (): GanntEditTargetNodeSelectionProps => {
  const ganntEditTargetNodes = useRef<RowNode[] | undefined>()

  const getGanttEditTargetNodes = useCallback(
    (params: GanttCellRendererProps) => {
      if (!params.api) return

      const lastTargetNodes =
        ganntEditTargetNodes?.current ?? params.api.getSelectedNodes()
      return lastTargetNodes?.some(node => node.key === params.node.key)
        ? lastTargetNodes
        : [params.node]
    },
    [ganntEditTargetNodes]
  )

  const rememberGanttEditTargetNodes = useCallback(
    (nodes: RowNode[] | undefined) => {
      ganntEditTargetNodes.current = nodes
    },
    []
  )

  const clearGanttEditTargetNodes = useCallback(() => {
    ganntEditTargetNodes.current = undefined
  }, [])

  const ganttEditTargetNodeSelection: GanntEditTargetNodeSelectionProps =
    useMemo(
      () => ({
        getTargetNodes: getGanttEditTargetNodes,
        rememberTargetNodes: rememberGanttEditTargetNodes,
        clearTargetNodes: clearGanttEditTargetNodes,
      }),
      [
        getGanttEditTargetNodes,
        rememberGanttEditTargetNodes,
        clearGanttEditTargetNodes,
      ]
    )

  return ganttEditTargetNodeSelection
}
