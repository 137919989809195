import { InputBase, InputBaseProps } from '@mui/material'
import { styled } from '@mui/system'

const HideWheelStyle = {
  margin: 0,
  '-webkit-appearance': 'none',
  appearance: 'none',
}

const StyledInput = styled(InputBase)({
  width: '100%',
  '& > input::-webkit-inner-spin-button': HideWheelStyle,
  '& > input::-webkit-outer-spin-button': HideWheelStyle,
})

export const NumberTextInput = (props: InputBaseProps) => {
  return (
    <StyledInput
      {...props}
      type="number"
      onFocus={e =>
        e.target.addEventListener(
          'wheel',
          function (e) {
            e.preventDefault()
          },
          { passive: false }
        )
      }
    />
  )
}
