import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  APPLICATION_FUNCTION_EXTERNAL_ID,
  getPathByExternalId,
  PageArea,
  PageProps,
} from '..'
import { WbsItemType } from '../../../domain/entity/WbsItemEntity'
import { projectPrivate } from '../../higher-order-components/projectPrivate'
import { useProjectPrivateContext } from '../../context/projectContext'
import {
  FunctionProperty,
  PropertyType,
} from '../../../lib/commons/appFunction'
import { useRiskData } from './hooks/riskData'
import { BulkSheetView } from '../../containers/BulkSheetView'
import { getExtensions } from '../../../lib/functions/entityExtension'
import BoolExpression from '../../../utils/boolExpression'
import DateExpression from '../../../utils/dateExpression'
import SearchOptions from '../../../utils/searchOptions'
import { usePageState } from './hooks/pageState'
import { useBulkSheetState } from '../../containers/BulkSheetView/hooks/bulkSheetState/bulkSheetState'
import {
  CellClickedEvent,
  ColDef,
  ColumnApi,
  ColumnState,
  ColumnVisibleEvent,
  FilterChangedEvent,
  GetContextMenuItemsParams,
  GridApi,
  GridReadyEvent,
  MenuItemDef,
  RowDragEvent,
  RowNode,
  SortChangedEvent,
} from 'ag-grid-community'
import { useRiskGridOptions } from './gridOptions'
import { StatusChangePopper } from '../../components/poppers/StatusChangePopper'
import store from '../../../store'
import {
  addGlobalMessage,
  addScreenMessage,
  MessageLevel,
} from '../../../store/messages'
import { intl } from '../../../i18n'
import {
  getRowNumber,
  getSelectedNode,
} from '../../containers/BulkSheetView/lib/gridApi'
import { extractValuesFromResponse } from '../../../lib/commons/api'
import { handleWarning } from '../../../handlers/globalErrorHandler'
import { createRiskRow, RiskRow } from './risks'
import { useDialog } from './hooks/dialog'
import { usePopper } from '../ProjectPlanNew/hooks'
import {
  CustomEnumCode,
  getCustomEnumValues,
} from '../../../lib/functions/customEnumValue'
import { parse } from '../../../lib/commons/i18nLabel'
import team from '../../../lib/functions/team'
import projectMember from '../../../lib/functions/projectMember'
import sprint from '../../../lib/functions/sprint'
import { fetchTags } from '../../../lib/functions/tag'
import repositories from '../../containers/meta/repositories'
import { CUSTOM_ENUM_NONE } from '../../../lib/commons/customEnum'
import { useDispatch } from 'react-redux'
import { receivedTags } from '../../../store/tag'
import Loading from '../../components/process-state-notifications/Loading'
import {
  ContextMenuItemId,
  getMenuIconHtml,
} from '../../containers/commons/AgGrid/lib/contextMenu'
import AddRowCountInputDialog from '../../containers/BulkSheet/AddRowCountInputDialog'
import { DeleteRowConfirmationDialog } from '../../containers/BulkSheetView/components/dialog/DeleteRowConfirmationDialog'
import _ from 'lodash'
import { useDragStyle } from '../../containers/BulkSheetView/hooks/gridEvents/rowDrag'
import SavedUIStateDialog from '../../components/dialogs/SavedUIStateDialog'
import { UiStateKey } from '../../../lib/commons/uiStates'
import { SavedUIState } from '../../components/dialogs/SavedUIStateDialog/SavedUIStateList'
import { useWorkloadUnit } from '../../hooks/useWorkloadUnit'
import { TicketListSelector } from '../Tickets/Header/TicketListSelector'
import TicketsWbsHeader from '../Tickets/Header/TicketsWbsHeader'
import ColumnSettingPopper from '../../containers/BulkSheetView/components/columnSelector/ColumnSettingPopper'
import { useColumnSetting } from '../../containers/BulkSheetView/components/columnSelector/useColumnSetting'
import SavePopper from '../../containers/BulkSheetView/components/header/SaveButtonArea'
import { SortedColumnState } from '../../model/bulkSheetColumnSortState'
import { useKeyBind } from '../../hooks/useKeyBind'
import { KEY_SAVE } from '../../model/keyBind'
import { useWbsItemCodeService } from '../../../services/adaptors/wbsItemCodeServiceAdaptor'
import { WbsItemCodeGenerator } from '../../../applications/ports/wbsItemCodeService'

type WrapperProps = PageProps

export enum ColumnQuickFilterKey {
  INITIAL = 'INITIAL',
  RESTORE = 'RESTORE',
}

export const Risks = projectPrivate(({ uuid: functionUuid }: WrapperProps) => {
  const { project } = useProjectPrivateContext()
  const [extensionProperties, setExtensionProperties] =
    useState<FunctionProperty[]>()

  const fetchExtensions = useCallback(async () => {
    const projectPlan = store
      .getState()
      .appFunction.functions.find(
        v =>
          v.externalId === APPLICATION_FUNCTION_EXTERNAL_ID.PROJECTPLAN_NEW_EDIT
      )
    const [riskExtension, wbsItemExtension] = await Promise.all([
      getExtensions({
        applicationFunctionUuid: functionUuid,
        groupKeys: ['RISK'],
      }),
      getExtensions({ applicationFunctionUuid: projectPlan!.uuid }),
    ])
    setExtensionProperties(
      [...riskExtension.json, ...wbsItemExtension.json].map(v => ({
        ...v,
        requiredIf: BoolExpression.of(v.requiredIf),
        editableIfC: BoolExpression.of(v.editableIfC),
        editableIfU: BoolExpression.of(v.editableIfU),
        hiddenIfC: BoolExpression.of(v.hiddenIfC),
        hiddenIfU: BoolExpression.of(v.hiddenIfU),
        minDate: v.minDate ? new DateExpression(v.minDate, 'min') : undefined,
        maxDate: v.maxDate ? new DateExpression(v.maxDate, 'max') : undefined,
        searchOptions: new SearchOptions(v.searchOptions),
      })) as FunctionProperty[]
    )
  }, [])

  useEffect(() => {
    fetchExtensions()
  }, [])

  // The last condition should be unnecessary, but somehow eslint does not work right.
  if (!extensionProperties) return <></>
  return (
    <RiskContent
      functionUuid={functionUuid}
      projectUuid={project.uuid}
      extensionProperties={extensionProperties}
    />
  )
})

type Props = {
  functionUuid: string
  projectUuid: string
  extensionProperties: FunctionProperty[]
}

const RiskContent = ({
  projectUuid,
  functionUuid,
  extensionProperties,
}: Props) => {
  const pageState = usePageState(functionUuid)
  const bulkSheetState = useBulkSheetState(functionUuid, projectUuid)
  const {
    data,
    setData,
    refresh,
    save,
    deleteRows,
    generateWbsItemDeltaRequest,
  } = useRiskData(projectUuid, pageState.ticketList?.uuid)
  const dataRef = useRef<RiskRow[]>([])
  dataRef.current = data
  const reload = useRef<Function>()
  useEffect(() => {
    reload.current = refresh
  }, [pageState.ticketList?.uuid, refresh])

  const [loading, setLoading] = useState<boolean>(false)
  const ref = useRef<HTMLDivElement>(null)
  const [gridApi, setGridApi] = useState<GridApi>()
  const [columnApi, setColumnApi] = useState<ColumnApi>()
  const [filteredColumns, setFilteredColumns] = useState<ColDef[]>([])

  const [sortColumnsState, setSortColumnsState] = useState<SortedColumnState[]>(
    []
  )

  const dialog = useDialog()
  const popper = usePopper()
  const columnSetting = useColumnSetting()

  const { generateCode } = useWbsItemCodeService()

  const saveSingleRowFunc =
    useRef<(uuid: string) => Promise<boolean | undefined>>()

  useEffect(() => {
    gridOptions.context = {
      ...gridOptions.context,
      dateFormat: pageState.dateFormat,
    }
    gridApi?.refreshCells({ force: true })
  }, [gridApi, pageState.dateFormat])

  useEffect(() => {
    gridApi?.resetRowHeights()
  }, [gridApi, pageState.rowHeight])

  const workloadUnitState = useWorkloadUnit(pageState.workloadUnit)
  useEffect(() => {
    gridOptions.context = {
      ...gridOptions.context,
      workloadUnit: pageState.workloadUnit,
      workloadUnitState,
      aggregateField: pageState.aggregateField,
    }
    gridApi?.refreshCells()
  }, [
    gridApi,
    pageState.workloadUnit,
    workloadUnitState,
    pageState.aggregateField,
  ])

  const addRows = useCallback(
    (
      row: RiskRow | undefined,
      count: number,
      generateCode: WbsItemCodeGenerator
    ) => {
      const d = dataRef.current.concat()
      const prevIndex = d.findIndex(v => v.uuid === row?.uuid)
      const index = prevIndex < 0 ? d.length : prevIndex + 1
      const newRows = Array.from({ length: count }).map(_ =>
        createRiskRow(generateCode, pageState.ticketList?.uuid)
      )
      d.splice(index, 0, ...newRows)
      setData(d)
      refreshRowNumber()
    },
    []
  )

  const gridOptions = useRiskGridOptions({
    projectUuid,
    refresh: () => reload.current?.(),
    submitSingleRow: saveSingleRowFunc,
    addRow: (row: RiskRow) => addRows(row, 1, generateCode),
    extensionProperties,
  })

  const dragStyle = useDragStyle(gridOptions)

  const contextMenu = useCallback(
    (params: GetContextMenuItemsParams<RiskRow>): (string | MenuItemDef)[] => {
      if (!params.node) return []
      const selectedNodes: RowNode<RiskRow>[] = getSelectedNode(params.api)
      const selectedRows = selectedNodes
        .map(v => v.data)
        .filter(v => v) as RiskRow[]
      const isActualHourRegistered = selectedRows.some(
        row =>
          !(
            row.cumulation &&
            row.cumulation.sumActualHour === 0 &&
            row.cumulation.actualHour === 0
          )
      )

      if (selectedRows.length <= 0) return []
      const targetRow: RiskRow = selectedRows[selectedRows.length - 1]

      const items = [
        {
          name: intl.formatMessage({ id: 'bulksheet.contextMenu.url.copy' }),
          action: () => {
            const wbsItem = targetRow.wbsItem
            if (!wbsItem) return

            const externalId =
              wbsItem.ticketType === 'RISK' && wbsItem.type === WbsItemType.TASK
                ? APPLICATION_FUNCTION_EXTERNAL_ID.RISK
                : APPLICATION_FUNCTION_EXTERNAL_ID.WBS_ITEM
            const path = getPathByExternalId(externalId)
            const url = `${window.location.origin}${path}/${wbsItem.code}`
            navigator.clipboard.writeText(url)
          },
          icon: getMenuIconHtml(ContextMenuItemId.COPY_URL),
        },
        {
          name: intl.formatMessage({
            id: 'bulksheet.contextMenu.urlAndName.copy',
          }),
          action: () => {
            const wbsItem = targetRow.wbsItem
            if (!wbsItem) return

            const externalId =
              wbsItem.ticketType === 'RISK' && wbsItem.type === WbsItemType.TASK
                ? APPLICATION_FUNCTION_EXTERNAL_ID.RISK
                : APPLICATION_FUNCTION_EXTERNAL_ID.WBS_ITEM
            const path = getPathByExternalId(externalId)
            const urlAndName = `${wbsItem.displayName}\n${window.location.origin}${path}/${wbsItem.code}`
            navigator.clipboard.writeText(urlAndName)
          },
          icon: getMenuIconHtml(ContextMenuItemId.COPY_URL_AND_NAME),
        },
        'separator',
        {
          name: intl.formatMessage({
            id: 'bulksheet.contextMenu.insert.row',
          }),
          action: () => addRows(params.node?.data, 1, generateCode),
          icon: getMenuIconHtml(ContextMenuItemId.ADD_ROW),
          shortcut: intl.formatMessage(
            { id: 'bulksheet.contextMenu.shortcut.alt.shift' },
            { shortcutKey: intl.formatMessage({ id: 'L' }) }
          ),
        },
        {
          name: intl.formatMessage({
            id: 'bulksheet.contextMenu.insert.multipleRow',
          }),
          action: () => dialog.openAddMultiple(params.node?.data?.uuid),
          icon: getMenuIconHtml(ContextMenuItemId.ADD_MULTIPLE_ROW),
        },
        'separator',
        {
          name: intl.formatMessage(
            { id: 'bulksheet.contextMenu.delete' },
            { count: selectedNodes.length }
          ),
          icon: getMenuIconHtml(ContextMenuItemId.REMOVE_ROW),
          action: () => dialog.openDeleteConfirmation(),
          disabled: isActualHourRegistered,
          tooltip: isActualHourRegistered
            ? intl.formatMessage({
                id: 'bulksheet.contextMenu.remove.disabled.reason.actual.registerd',
              })
            : undefined,
          shortcut: intl.formatMessage({
            id: 'bulksheet.contextMenu.shortcut.row.delete',
          }),
        },
      ].filter(v => !!v) as (string | MenuItemDef)[]
      return items.filter(
        (item: string | MenuItemDef, index: number) =>
          item !== 'separator' ||
          (0 < index && items[index - 1] !== 'separator')
      )
    },
    []
  )

  const fetchStatusOptions = useCallback(async projectUuid => {
    const response = await getCustomEnumValues({
      customEnumCode: CustomEnumCode.WBS_STATUS,
      groupUuid: projectUuid,
    })
    return response.json.map(v => ({ ...v, nameI18n: parse(v.nameI18n) }))
  }, [])

  const fetchSubstatusOptions = useCallback(async projectUuid => {
    const response = await getCustomEnumValues({
      customEnumCode: CustomEnumCode.WBS_SUBSTATUS,
      groupUuid: projectUuid,
    })
    return response.json.map(v => ({ ...v, nameI18n: parse(v.nameI18n) }))
  }, [])

  const fetchPriorityOptions = useCallback(async projectUuid => {
    const response = await getCustomEnumValues({
      customEnumCode: CustomEnumCode.WBS_PRIORITY,
      groupUuid: projectUuid,
    })
    return response.json.map(v => ({ ...v, nameI18n: parse(v.nameI18n) }))
  }, [])

  const fetchDifficultyOptions = useCallback(async projectUuid => {
    const response = await getCustomEnumValues({
      customEnumCode: CustomEnumCode.WBS_DIFFICULTY,
      groupUuid: projectUuid,
    })
    return response.json.map(v => ({ ...v, nameI18n: parse(v.nameI18n) }))
  }, [])

  const fetchTeamOptions = useCallback(async projectUuid => {
    const response = await team.getTeams(projectUuid)
    return response.json
  }, [])

  const fetchMemberOptions = useCallback(async projectUuid => {
    const response = await projectMember.getProjectMembers({ projectUuid })
    return response.json.map(v => v.user)
  }, [])

  const fetchSprintOptions = useCallback(async () => sprint.searchAll(), [])

  const fetchTagOptions = useCallback(async projectUuid => {
    const response = await fetchTags({ projectUuid })
    return response.json
  }, [])

  const fetchExtensionOptions = useCallback(async () => {
    extensionProperties
      .filter(
        extension =>
          extension.propertyType === PropertyType.EntitySearch &&
          extension.referenceEntity
      )
      .map(async extension => {
        const repo = repositories[extension.referenceEntity!]
        const searchOptions = extension.searchOptions.build({
          wbsItem: { projectUuid },
        })
        const res = await repo.search('', { ...searchOptions, projectUuid })
        gridOptions.context = {
          ...gridOptions.context,
          [extension.referenceEntity!]: res.map(v => ({
            ...v,
            nameI18n: v.nameI18n ? parse(v.nameI18n) : undefined,
          })),
        }
      })
    extensionProperties
      .filter(
        extension =>
          extension.propertyType === PropertyType.Select &&
          extension.valuesAllowed
      )
      .map(extension => {
        gridOptions.context = {
          ...gridOptions.context,
          [extension.entityExtensionUuid]: extension.valuesAllowed.filter(
            v => v.value !== CUSTOM_ENUM_NONE
          ),
        }
      })
  }, [])

  const dispatch = useDispatch()
  const fetchSelectColumnOptions = useCallback(async projectUuid => {
    const [status, substatus, priority, difficulty, team, member, sprint, tag] =
      await Promise.all([
        fetchStatusOptions(projectUuid),
        fetchSubstatusOptions(projectUuid),
        fetchPriorityOptions(projectUuid),
        fetchDifficultyOptions(projectUuid),
        fetchTeamOptions(projectUuid),
        fetchMemberOptions(projectUuid),
        fetchSprintOptions(),
        fetchTagOptions(projectUuid),
      ])
    const projectState = store.getState().project
    const wbsItemType = [
      ...projectState.wbsItemTypes.getAll(),
      ...projectState.ticketListTypes,
      ...projectState.ticketTypes,
    ]
    gridOptions.context = {
      ...gridOptions.context,
      wbsItemType,
      status,
      substatus,
      priority,
      difficulty,
      team,
      member,
      sprint,
      tag,
    }
    fetchExtensionOptions()
    dispatch(receivedTags(projectUuid, tag))
  }, [])

  useEffect(() => {
    // Initial data fetch
    fetchSelectColumnOptions(projectUuid)
  }, [])

  const refreshRowNumber = useCallback(() => {
    setTimeout(() => {
      gridApi?.refreshCells({
        columns: ['rowNumber'],
        force: true,
      })
    }, 500)
  }, [gridApi])

  const onSubmit = useCallback(async () => {
    setLoading(true)
    try {
      gridApi?.stopEditing()

      if (gridOptions.context.errors?.hasMessage()) {
        store.dispatch(
          addGlobalMessage({
            type: MessageLevel.WARN,
            title: intl.formatMessage({ id: 'global.warning.businessError' }),
            text: gridOptions.context.errors.toMessage(id => {
              const node = gridApi?.getRowNode(id)
              return !!node ? getRowNumber(node).toString() : ''
            }),
          })
        )
        return
      }

      bulkSheetState.saveImmediately()

      // Save
      const [response, sprintResponse] = await save()

      if (response.hasWarning) {
        const messages = extractValuesFromResponse(response.json, 'messages')
        handleWarning(messages, uuid => {
          const target = data.find(v => v.wbsItem.uuid === uuid)
          return target?.wbsItem.displayName
        })
      }

      if (
        !response.hasError &&
        !response.hasWarning &&
        !sprintResponse.hasError
      ) {
        store.dispatch(
          addScreenMessage(functionUuid, {
            type: MessageLevel.SUCCESS,
            title: intl.formatMessage({ id: 'registration.complete' }),
          })
        )
        await refresh()
      }
    } finally {
      setLoading(false)
    }
  }, [data, save, bulkSheetState])

  const onReload = useCallback(async () => {
    await refresh()
    refreshRowNumber()
  }, [refresh, refreshRowNumber])

  const rememberColumnState = useCallback(() => {
    const columnState = columnApi?.getColumnState()
    columnState && bulkSheetState.saveColumnState(columnState)
  }, [bulkSheetState, columnApi])

  const onGridReady = useCallback(
    (event: GridReadyEvent<RiskRow>) => {
      setGridApi(event.api)
      setColumnApi(event.columnApi)
      if (!_.isEmpty(bulkSheetState.columnState)) {
        event.columnApi.applyColumnState({
          state: bulkSheetState.columnState,
          applyOrder: true,
        })
      }
      setTimeout(() => setLoading(false), 2000)
    },
    [bulkSheetState.columnState]
  )

  // Restore row state.
  const onFirstDataRendered = useCallback(
    e => {
      if (!_.isEmpty(bulkSheetState.filterState)) {
        gridApi?.setFilterModel(bulkSheetState.filterState)
      }
      bulkSheetState.finishRestoring()
      setLoading(false)
    },
    [bulkSheetState]
  )

  const onColumnVisible = useCallback(
    (e: ColumnVisibleEvent) => {
      rememberColumnState()
      onSortedColumnsStateChanged(e)
    },
    [bulkSheetState]
  )

  const onFilterChanged = useCallback(
    (e: FilterChangedEvent) => {
      const filterModel = e.api.getFilterModel()
      delete filterModel['uuid']
      bulkSheetState.saveFilterState(filterModel)
      setFilteredColumns(
        Object.keys(filterModel)
          .map(col => e.api.getColumnDef(col))
          .filter(v => !!v && v.field !== 'uuid') as ColDef[]
      )
    },
    [bulkSheetState]
  )

  const onSortedColumnsStateChanged = useCallback(
    (e: SortChangedEvent) => {
      const sortedColumns = e.columnApi
        .getColumnState()
        .filter(col => !!col.sort)
        .map(col => {
          return {
            ...e.api.getColumnDef(col.colId),
            colId: col.colId,
          }
        })
        .filter(v => !!v) as ColDef[]
      e.api.setSuppressRowDrag(0 < sortedColumns.length)

      const columnState = gridOptions.columnApi?.getColumnState()
      const sortedColumnState: { [colId: string]: ColumnState } = {}
      columnState &&
        columnState.forEach(state => {
          if (state.sort) {
            sortedColumnState[state.colId] = state
          }
        })
      const sortedColumnsState: SortedColumnState[] = sortedColumns.map(col => {
        return {
          colId: col.colId,
          field: col.field,
          headerName: col.headerName,
          sort: col.colId ? sortedColumnState[col.colId]?.sort : null,
        }
      })

      setSortColumnsState(sortedColumnsState)
    },
    [bulkSheetState]
  )

  const onSortChanged = useCallback(
    (e: SortChangedEvent) => {
      rememberColumnState()
      onSortedColumnsStateChanged(e)
    },
    [bulkSheetState]
  )

  const onChangeColumnFilter = useCallback(
    (value: ColumnQuickFilterKey) => {
      if (value === ColumnQuickFilterKey.INITIAL) {
        columnApi?.resetColumnState()
        const filterModel = gridApi?.getFilterModel()
        const quick = filterModel?.['uuid']
        gridApi?.setFilterModel(quick ? { uuid: quick } : null)
      } else if (value === ColumnQuickFilterKey.RESTORE) {
        dialog.openUiState()
      }
    },
    [gridApi, columnApi]
  )

  const onRowDragEnd = useCallback(
    (event: RowDragEvent<RiskRow>) => {
      const movedIds = event.nodes.map(v => v.id)
      const overNode = event.overNode
      try {
        if (!overNode || movedIds.includes(overNode.id)) {
          return
        }
        const result = data.concat().filter(v => !movedIds.includes(v.uuid))
        const moved = data.concat().filter(v => movedIds.includes(v.uuid))
        // If moved toward down, move after overNode.
        const index =
          result.findIndex(v => v.uuid === overNode.id!) +
          (event.overIndex > (event.node.rowIndex || 0) ? 1 : 0)
        result.splice(index, 0, ...moved.map(v => ({ ...v, edited: true })))
        setData(result)
      } finally {
        // Clear drag cell style
        dragStyle.refreshDragStyle()
      }
    },
    [data]
  )

  const onCellClicked = useCallback((e: CellClickedEvent) => {
    const { field } = e.colDef
    if (e.data.added) return
    if (field === 'wbsItem.status') {
      const getAgCellElement = (elem): any => {
        if (!elem || elem.className.toString().includes('sevend-ag-cell')) {
          return elem
        }
        return getAgCellElement(elem.parentElement)
      }
      const target = getAgCellElement(e.event!.target)
      if (!target) return
      popper.open(target, e.data.wbsItem, generateWbsItemDeltaRequest(e.data)!)
    }
    if (field === 'cumulation.actualHour') {
      const w = e.data.wbsItem
      if (!w || !w.wbsItemType.isTask()) return
      dialog.openActualResult(w.uuid)
    }
  }, [])

  const onCellDoubleClicked = useCallback(() => popper.close(), [popper])

  const onDeleteFilteredColumn = useCallback((column: ColDef) => {
    if (!column || !gridOptions.api) return
    const filterModel = gridOptions.api.getFilterModel()
    delete filterModel[column.colId || column.field || '']
    gridOptions.api.setFilterModel(filterModel)
  }, [])

  const resetFilters = useCallback(() => {
    if (!gridOptions.api) return
    gridOptions.api.setFilterModel([])
  }, [])

  const onDeleteSortedColumn = useCallback((colId: string | ColDef<any>) => {
    gridOptions.columnApi?.applyColumnState({
      state: [{ colId: colId.toString(), sort: null }],
    })
  }, [])

  const onDeleteSortedAllColumns = useCallback(() => {
    gridOptions.columnApi?.applyColumnState({
      defaultState: { sort: null },
    })
  }, [])

  const onChangeSortColumnState = useCallback(
    (colId: string | ColDef<any>, sort: 'asc' | 'desc' | null) => {
      gridOptions.columnApi?.applyColumnState({
        state: [{ colId: colId.toString(), sort }],
      })
    },
    []
  )

  useKeyBind(
    [
      {
        key: KEY_SAVE,
        fn: onSubmit,
        stopDefaultBehavior: true,
      },
    ],
    [data, save]
  )

  if (!pageState.initialized || !bulkSheetState.initialized) {
    return <></>
  }

  return (
    <PageArea>
      <SavePopper loading={loading} onSubmit={onSubmit} />
      <TicketListSelector
        ticketType={'RISK'}
        ticketListUuid={pageState.ticketList?.uuid}
        onChangeTicketList={pageState.setTicketList}
      />
      <TicketsWbsHeader
        onChangeAggregateField={pageState.setAggregateField}
        aggregateField={pageState.aggregateField}
        onChangeWorkloadUnit={pageState.setWorkloadUnit}
        workloadUnit={pageState.workloadUnit}
        rowHeight={pageState.rowHeight}
        onChangeHeight={pageState.setRowHeight}
        filteredColumns={filteredColumns}
        onDeleteFilteredColumn={onDeleteFilteredColumn}
        resetFilters={resetFilters}
        onDeleteSortedColumn={onDeleteSortedColumn}
        onDeleteSortedAllColumns={onDeleteSortedAllColumns}
        onChangeSortColumnState={onChangeSortColumnState}
        onReload={onReload}
        onClickColumnSettingButton={columnSetting.toggle}
        columnSettingOpen={columnSetting.isOpen}
        onClickFavoriteColumnFilterButton={dialog.openUiState}
        sortColumnsState={sortColumnsState}
        // onClickImportExcel={bulkSheet?.importExcel}
        // onClickExportExcel={() =>
        //   bulkSheet?.openExcelOutputColumnSelectDialog()
        // }
        currentFormat={pageState.dateFormat}
        onChangeDateFormat={(value: string) => pageState.setDateFormat(value)}
      />
      <BulkSheetView
        ref={ref}
        gridOptions={gridOptions}
        rowData={data}
        getContextMenuItems={contextMenu}
        rowHeight={pageState.rowHeight}
        // Events and actions
        onGridReady={onGridReady}
        onFirstDataRendered={onFirstDataRendered}
        onColumnVisible={onColumnVisible}
        onColumnResized={rememberColumnState}
        onColumnMoved={rememberColumnState}
        onFilterChanged={onFilterChanged}
        onSortChanged={onSortChanged}
        // onModelUpdated={onModelUpdated}
        onRowDragEnter={dragStyle.onRowDragEnter}
        onRowDragMove={dragStyle.onRowDragMove}
        onRowDragEnd={onRowDragEnd}
        onRowDragLeave={dragStyle.refreshDragStyle}
        onCellClicked={onCellClicked}
        // onCellEditingStarted={onCellEditingStarted}
        onCellDoubleClicked={onCellDoubleClicked}
      />
      <Loading isLoading={loading} elem={ref.current} />
      <StatusChangePopper
        anchorEl={popper.anchorEl}
        projectUuid={projectUuid}
        wbsItem={popper.wbsItem}
        wbsItemDelta={popper.wbsItemDelta}
        onClose={popper.close}
        onAfterUpdate={() => refresh()}
      />
      <ColumnSettingPopper
        anchorEl={columnSetting.anchorEl}
        open={columnSetting.isOpen}
        columnApi={gridOptions?.columnApi ?? undefined}
        gridApi={gridOptions?.api ?? undefined}
        close={columnSetting.close}
        height={ref.current?.offsetHeight}
        openSavedUiStateDialog={dialog.openUiState}
        initializeColumnState={() =>
          onChangeColumnFilter(ColumnQuickFilterKey.INITIAL)
        }
        applicationFunctionUuid={functionUuid}
        uiStateKey={UiStateKey.BulkSheetUIStateColumnAndFilter}
        columnState={bulkSheetState.columnState}
        offset={110}
      />
      {dialog.addMultiple && (
        <AddRowCountInputDialog
          open={true}
          title={intl.formatMessage({
            id: 'bulksheet.contextMenu.insert.multipleRow.title',
          })}
          submitHandler={(count: number | undefined) => {
            const prevSibling = data.find(
              v => v.uuid === dialog.prevSiblingUuid
            )
            addRows(prevSibling, count ?? 1, generateCode)
            dialog.closeAddMultiple()
          }}
          closeHandler={dialog.closeAddMultiple}
        />
      )}
      {dialog.deleteConfirmation && gridApi && (
        <DeleteRowConfirmationDialog
          target={_.uniqBy(getSelectedNode(gridApi), 'data.uuid').map(
            (v: RowNode<RiskRow>) =>
              v.data?.wbsItem.displayName ??
              `(${intl.formatMessage({ id: 'displayName.undefined' })})`
          )}
          onConfirm={() => {
            const deletedRows = getSelectedNode(gridApi).map(
              v => v.data as RiskRow
            )
            deleteRows(deletedRows)
            const deletedUuids = deletedRows.map(v => v.uuid)
            setData(
              data.filter(v => !deletedUuids.some(uuid => uuid === v.uuid))
            )
            dialog.closeDeleteConfirmation()
          }}
          onClose={dialog.closeDeleteConfirmation}
        />
      )}
      {dialog.uiState && (
        <SavedUIStateDialog
          applicationFunctionUuid={functionUuid}
          open={true}
          title={intl.formatMessage({
            id: 'savedUIState.BULK_SHEET_UI_STATE_COLUMN_AND_FILTER',
          })}
          uiStateKey={UiStateKey.BulkSheetUIStateColumnAndFilter}
          sharable={false}
          currentUIState={{
            columnState: bulkSheetState.columnState,
            filterState: bulkSheetState.filterState,
          }}
          onSelect={(uiState: SavedUIState) => {
            const { columnState, filterState } = uiState.UIState
            if (columnState) {
              bulkSheetState.saveColumnState(columnState)
              columnApi?.applyColumnState({
                state: columnState,
                applyOrder: true,
              })
            }
            if (filterState) {
              bulkSheetState.saveFilterState(filterState)
              gridApi?.setFilterModel(filterState)
            }
            dialog.closeUiState()
          }}
          onClose={dialog.closeUiState}
        />
      )}
    </PageArea>
  )
}
