import {
  Avatar,
  Box,
  ClickAwayListener,
  Divider as MuiDivider,
  IconButton,
  List,
  ListItem,
  Paper,
  Popover as MuiPopover,
  PopoverOrigin,
  Tooltip,
  Typography,
} from '@mui/material'
import { styled } from '@mui/system'
import {
  BorderColor,
  FontWeight,
  IconColor,
  TextColor,
} from '../../../styles/commonStyles'
import DateVO from '../../../vo/DateVO'
import { OpenInNewRounded } from '@mui/icons-material'
import { WbsItemStatus } from '../../containers/commons/AgGrid/components/cell/custom/wbsItemStatus'
import WbsItemStatusLabel from '../labels/WbsItemStatusLabel'
import { injectIntl, WrappedComponentProps } from 'react-intl'
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm'
import { DISPLAY_DATE_FORMAT_WITH_DAY } from '../../../utils/date'
import { DelayIcon } from '../../containers/commons/AgGrid/components/cell/custom/wbsItemStatus/delayIcon'
import { BaseWbsItemType } from '../../../store/project'
import { WbsItemTypeVO } from '../../../domain/value-object/WbsItemTypeVO'
import { AllState } from '../../../store'
import { connect } from 'react-redux'
import { ProjectDetail } from '../../../lib/functions/project'
import { intl } from '../../../i18n'

const Popover = styled(MuiPopover)({
  width: '422px',
  borderRadius: '5px',
  fontSize: '11px',
  zIndex: 10,
})
const Header = styled(Box)({
  height: '32px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  color: TextColor.WHITE,
  backgroundColor: '#888888',
  borderRadius: '5px 5px 0 0',
})
const HeaderDate = styled(Typography)({
  marginLeft: '10px',
  fontSize: '12px',
})
const ActualWorkList = styled(List)({
  padding: '0 0 0 10px',
  maxHeight: '420px',
  overflowX: 'hidden',
  overflowY: 'scroll',
}) as typeof List
const ActualWorkRow = styled(ListItem)({
  height: '70px',
  padding: 0,
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
})
const OpenInNewIconButton = styled(IconButton)({
  color: IconColor.WHITE,
  width: '11px',
  height: '11px',
  margin: 5,
})
const Divider = styled(MuiDivider)({
  borderTop: `1px dotted ${BorderColor.GREY}`,
  background: 'transparent',
})
const LastDivider = styled(MuiDivider)({
  borderTop: `1px solid ${BorderColor.GREY}`,
  background: 'transparent',
  margin: '0 10px',
})
const TaskHeader = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  maxHeight: '14px',
})
const TaskStatus = styled('div')({
  display: 'inline-block',
  minWidth: '56px',
})
const TaskEndDate = styled(Typography)({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  fontSize: '11px',
  marginTop: '6px',
  marginLeft: '8px',
})
const TaskEndDateIcon = styled(AccessAlarmIcon)({
  fontSize: '13pt',
  marginRight: '2px',
})
const DeliverableName = styled(Typography)({
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  maxWidth: '320px',
  fontSize: '11px',
  margin: '9px 0 2px 0',
  fontWeight: FontWeight.BOLD,
})
const TaskNameBox = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
})
const TaskName = styled(Typography)({
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  maxWidth: '320px',
  fontSize: '11px',
  marginTop: '2px',
})
const TaskActualHour = styled(Typography)({
  fontSize: '11px',
  fontWeight: 'bold',
})
const TotalRow = styled(Box)({
  padding: '0 14px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-end',
  height: '32px',
})
const Total = styled(Typography)({
  display: 'flex',
  fontWeight: FontWeight.BOLD,
  justifyContent: 'flex-end',
})

interface StateProps {
  wbsItemTypes: BaseWbsItemType
}

interface Props extends StateProps, WrappedComponentProps {
  anchorEl: any
  date: DateVO
  actualWorks: ActualWork[]
  actualHour: number
  event: PointerEvent
  onClose: () => void
  openDetail?: () => void
}

export type ActualWork = {
  project?: ProjectDetail
  taskCode: string
  taskName: string
  taskStatus: WbsItemStatus
  deliverableName: string
  hour: number
  endDate: string
  ticketType: string
  wbsItemType: WbsItemTypeVO
}

const DelayIconDiv = styled('div')({
  display: 'flex',
  margin: '0 3px',
})

const TaskActualWorkTaskDialog = (props: Props) => {
  const {
    anchorEl,
    date,
    actualWorks,
    actualHour,
    event,
    onClose,
    openDetail,
  } = props
  const open = Boolean(anchorEl)
  const anchorOrigin: PopoverOrigin = {
    vertical: 'bottom',
    horizontal: 'right',
  }
  const transformOrigin: PopoverOrigin = {
    vertical: 'top',
    horizontal: 'left',
  }
  if (event.view && event.x > event.view.innerWidth / 2) {
    anchorOrigin.horizontal = 'left'
    transformOrigin.horizontal = 'right'
  }
  if (event.view && event.y > event.view.innerHeight / 2) {
    anchorOrigin.vertical = 'top'
    transformOrigin.vertical = 'bottom'
  }
  const taskType = props.wbsItemTypes?.task
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      PaperProps={{
        style: { width: '100%' },
      }}
    >
      <ClickAwayListener onClickAway={onClose}>
        <Paper>
          <Header>
            <HeaderDate>{`${intl.formatMessage({
              id: 'workReport.dialog.header.name',
            })}  ${date.format('YYYY/MM/DD(ddd)')}`}</HeaderDate>
            {openDetail && (
              <Tooltip
                title={props.intl.formatMessage({
                  id: 'dialog.view.tasks',
                })}
                placement="top"
              >
                <OpenInNewIconButton onClick={openDetail}>
                  <OpenInNewRounded fontSize={'small'} />
                </OpenInNewIconButton>
              </Tooltip>
            )}
          </Header>
          <ActualWorkList>
            {actualWorks.map((actualWork, index) => {
              return (
                <>
                  {index !== 0 && <Divider />}
                  <ActualWorkRow>
                    <Box>
                      <TaskHeader>
                        {actualWork.project && (
                          <Tooltip
                            title={actualWork.project.displayName}
                            arrow={true}
                            enterDelay={500}
                            placement="bottom-start"
                          >
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                maxWidth: '190px',
                                height: '100%',
                                alignItems: 'center',
                              }}
                            >
                              <Avatar
                                variant="circular"
                                src={actualWork.project.iconUrl}
                                sx={{
                                  width: 20,
                                  height: 20,
                                  margin: '0 5px 0 0',
                                }}
                              />
                              <Typography
                                sx={{
                                  overflow: 'hidden',
                                  whiteSpace: 'nowrap',
                                  textOverflow: 'ellipsis',
                                  fontWeight: FontWeight.BOLD,
                                  padding: '0 8px 0 0',
                                }}
                              >
                                {actualWork.project.displayName}
                              </Typography>
                            </Box>
                          </Tooltip>
                        )}
                        <TaskStatus>
                          <WbsItemStatusLabel status={actualWork.taskStatus} />
                        </TaskStatus>
                        {actualWork.endDate && (
                          <TaskEndDate>
                            <TaskEndDateIcon />
                            {new DateVO(actualWork.endDate).format(
                              DISPLAY_DATE_FORMAT_WITH_DAY
                            )}
                          </TaskEndDate>
                        )}
                        <DelayIconDiv>
                          <DelayIcon
                            wbsItem={{
                              status: actualWork.taskStatus,
                              scheduledDate: {
                                endDate: actualWork.endDate,
                              },
                            }}
                          />
                        </DelayIconDiv>
                      </TaskHeader>
                      <Tooltip
                        title={actualWork.deliverableName}
                        arrow={true}
                        enterDelay={500}
                        placement="bottom-start"
                      >
                        <DeliverableName>
                          {actualWork.deliverableName}
                        </DeliverableName>
                      </Tooltip>
                      <Tooltip
                        title={actualWork.taskName}
                        arrow={true}
                        enterDelay={500}
                        placement="bottom-start"
                      >
                        <TaskNameBox>
                          {(actualWork.wbsItemType?.iconUrl ??
                            taskType?.iconUrl) && (
                            <img
                              src={
                                actualWork.wbsItemType?.iconUrl ??
                                taskType?.iconUrl
                              }
                            />
                          )}
                          <TaskName>{actualWork.taskName}</TaskName>
                        </TaskNameBox>
                      </Tooltip>
                    </Box>
                    <TaskActualHour>
                      {Number(actualWork.hour).toFixed(2).toString()}
                    </TaskActualHour>
                  </ActualWorkRow>
                </>
              )
            })}
          </ActualWorkList>
          <LastDivider />
          <TotalRow>
            <Total>Total: {Number(actualHour).toFixed(2).toString()}</Total>
          </TotalRow>
        </Paper>
      </ClickAwayListener>
    </Popover>
  )
}

const mapStateToProps = (state: AllState) => ({
  wbsItemTypes: state.project.wbsItemTypes,
})

export default connect(mapStateToProps)(injectIntl(TaskActualWorkTaskDialog))
