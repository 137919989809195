export enum TimerStatus {
  MEASURING = 'measuring',
  FINISHED = 'finished',
}

export type TimerEntity = {
  userUuid: string
  taskUuid: string
  timerStatus: TimerStatus
}

export type MeasuringTimer = {
  taskName: string
  parentWbsItemName: string
  startTime: number
  wbsItemTypeUrl: string
  taskUuid: string
}

export type TimerMeasuringTaskOfUser = {
  userUuid: string
  userName: string
  userIconUrl: string
  taskUuid: string
  taskName: string
  parentDeliverableName: string
  startTime: number
}
