import { InputBase, styled } from '@mui/material'
import { ValuePanelInputRoot } from './commons'
import {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import Tooltip from '../../../../../components/tooltips/Tooltip'
import { useDebounce } from '../../../../../hooks/useDebounce'
import { intl } from '../../../../../../i18n'
import { colorPalette } from '../../../../../style/colorPallete'
import { NumberTextInput } from '../../../../../components/inputs/NumberTextInput'

type HourInputProps = {
  label: string
  value: number | undefined
  onChange?: (v: number | undefined) => void
  editable?: boolean
}
export const HourInput = ({
  label,
  value,
  onChange,
  editable = true,
}: HourInputProps) => {
  const [inputValue, setInputValue] = useState('')
  const changedByInput = useRef(false)
  const hourUnitLabel = useMemo(
    () => intl.formatMessage({ id: 'singleSheet.header.hours' }),
    []
  )

  useEffect(() => {
    changedByInput.current = false
    setInputValue(value ? value + '' : '')
  }, [value])

  const round = useCallback((v: number | undefined): number | undefined => {
    return v ? Math.round(v * 100) / 100 : undefined
  }, [])
  const parse = useCallback((v: string): number => {
    const parsed = parseFloat(v)
    if (isNaN(parsed) || parsed < 0) return 0
    return parsed
  }, [])

  const onChangeInput = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    changedByInput.current = true
    setInputValue(e.target.value)
  }, [])
  const debouncedInputValue = useDebounce(inputValue, 300)
  useEffect(() => {
    if (!changedByInput.current) return
    const normalized = parse(debouncedInputValue)
    const newValue = round(normalized)
    onChange && onChange(newValue)
  }, [debouncedInputValue, parse, round, onChange])

  const onKeyDown = useCallback(e => {
    if (e.key === 'Enter') {
      e.currentTarget.blur()
    }
  }, [])

  return (
    <Tooltip message={label}>
      <Root>
        <TextInput
          type="number"
          value={inputValue}
          onChange={onChangeInput}
          disabled={!editable}
          onKeyDown={onKeyDown}
        />
        <HourUnitLabel>{hourUnitLabel}</HourUnitLabel>
      </Root>
    </Tooltip>
  )
}

const Root = styled(ValuePanelInputRoot)({
  width: '60px',
})
const TextInput = styled(NumberTextInput)({
  height: '10px',
  '& .MuiInputBase-input': {
    width: '100%',
    textAlign: 'right',
    padding: 0,
  },
  '& .MuiInputBase-input.Mui-disabled': {
    color: colorPalette.monotone[10],
    '-webkit-text-fill-color': colorPalette.monotone[10],
  },
})
const HourUnitLabel = styled('span')({
  whiteSpace: 'nowrap',
  fontSize: '11px',
})
