import React from 'react'
import { ICellRendererParams, RowNode } from 'ag-grid-community'
import { GanttBackground } from './GanttBackground'
import { GanttSlider } from './GanttSlider'
import { ProjectPlanRow } from '../../../../../../../../pages/ProjectPlan/projectPlanOptions'
import objects from '../../../../../../../../../utils/objects'
import { GanttParameterVO } from '../../../../../../../../../domain/value-object/GanttParameterVO'
import { CalendarDateVO } from '../../../../../../../../../domain/value-object/CalendarDateVO'

const ChartContainer = React.forwardRef<
  HTMLDivElement,
  { children: React.ReactNode; rowId: string }
>((props, ref) => (
  <div
    {...props}
    id={`sevend-ag-cell-gantt-chart-${props.rowId}`}
    className="sevend-ag-cell-gantt-chart"
    ref={ref}
  />
))

const SliderContainer = ({ children, rowHeight }) => {
  return (
    <div
      style={{
        left: 0,
        width: '100%',
        height: '3px',
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        top: rowHeight / 2 - 4,
        margin: '-1px',
      }}
    >
      {children}
    </div>
  )
}

// By default, the selected row of the AGGrid is the edit target.
// Set GanntEditTargetNodeSelectionProps if you want to customize the target row.
export interface GanntEditTargetNodeSelectionProps {
  getTargetNodes: (params: GanttCellRendererProps) => RowNode[] | undefined
  rememberTargetNodes: (nodes: RowNode[] | undefined) => void
  clearTargetNodes: () => void
}

export interface GanttCellRendererProps extends ICellRendererParams {
  displayTimeScale: CalendarDateVO[]
  timeScale: CalendarDateVO[]
  parameter: GanttParameterVO
  updateRows: (row: ProjectPlanRow[]) => void
  setParameter: (param: GanttParameterVO) => void
  editable?: boolean
  dataPath?: string
  customEditTargetNodeSelection?: GanntEditTargetNodeSelectionProps
}

interface Props extends GanttCellRendererProps {}

const CellRenderer = (props: Props) => {
  const { context } = props
  const ganttParams = {
    ...props,
    parameter: context.ganttParameter,
    displayTimeScale: context.ganttDisplayTimeScale,
    timeScale: context.ganttTimeScale,
  }
  if (
    !objects.getValue(props.data, props.dataPath ?? 'wbsItem') ||
    !ganttParams.displayTimeScale ||
    !ganttParams.timeScale
  ) {
    return <></>
  }
  return (
    <>
      <ChartContainer rowId={props.node.id || ''}>
        <GanttBackground {...ganttParams} />
      </ChartContainer>
      <SliderContainer rowHeight={props.node.rowHeight || 32}>
        <GanttSlider {...ganttParams} editable={props.editable !== false} />
      </SliderContainer>
    </>
  )
}

export default CellRenderer
